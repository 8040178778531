import { setCredentials } from 'redux/slices/authSlice';

import { customerApi } from './customerApi';

export interface ResetPasswordRequest {
  email: string;
}
export interface ResetPasswordValidateRequest {
  token: string;
}
export interface InvitationValidateRequest {
  token: string;
}

export interface UpdatePasswordRequest {
  password: string;
  password_confirmation: string;
  token: string;
}

export interface CreatePasswordRequest {
  password: string;
  password_confirmation: string;
  token: string;
}

export interface AuthResponse {
  access_token: string;
}

export interface MfaCreateResponse {
  uuid: string;
}

export interface MfaStatusResponse extends AuthResponse {
  status: string;
}

export const authenticationApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, LoginRequest>({
      query: (credentials) => {
        return {
          url: '/v1/authentication/token',
          credentials: 'include',
          method: 'POST',
          body: credentials
        };
      },
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled.then(({ data }) => {
          if (data) {
            dispatch(setCredentials(data));
          }
        });
      }
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/v1/authentication/token',
        credentials: 'include',
        method: 'DELETE'
      })
    }),
    createMfaRequest: builder.mutation<MfaCreateResponse, void>({
      query: () => {
        return {
          url: '/v1/authentication/mfa_tokens',
          credentials: 'include',
          method: 'POST'
        };
      }
    }),
    checkMfaRequest: builder.query<MfaStatusResponse, MfaStatusRequest>({
      query: (params) => {
        return {
          url: '/v1/authentication/mfa_tokens/status',
          credentials: 'include',
          params: params
        };
      },
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled.then(({ data }) => {
          if (data?.status == 'approved') {
            dispatch(setCredentials(data));
          }
        });
      }
    }),
    verifyMfaRequest: builder.mutation<any, MfaVerifyRequest>({
      query: (params) => {
        return {
          url: '/v1/authentication/mfa_tokens/verify',
          credentials: 'include',
          method: 'POST',
          body: params
        };
      },
      onQueryStarted(_, { queryFulfilled, dispatch }) {
        queryFulfilled.then(({ data }) => {
          if (data) {
            dispatch(setCredentials(data));
          }
        });
      }
    }),
    requestSms: builder.mutation<MfaSmsResponse, void>({
      query: () => {
        return {
          url: '/v1/authentication/mfa_tokens/request_sms',
          method: 'POST'
        };
      }
    }),
    registerMfaUser: builder.mutation<void, MfaSetupRequest>({
      query: (params) => {
        return {
          url: '/v1/authentication/mfa/register',
          method: 'POST',
          body: params
        };
      }
    }),
    forgotPassword: builder.mutation<void, ResetPasswordRequest>({
      query: (params) => ({
        url: '/v1/authentication/passwords/forgot',
        method: 'POST',
        body: params
      })
    }),
    validateResetPasswordToken: builder.query<
      void,
      ResetPasswordValidateRequest
    >({
      query: ({ token }) => ({
        url: '/v1/authentication/passwords/validate_reset_token',
        credentials: 'include',
        params: { token }
      })
    }),
    validateInvitationToken: builder.query<void, InvitationValidateRequest>({
      query: ({ token }) => ({
        url: '/v1/authentication/invitations/validate',
        credentials: 'include',
        params: { token }
      })
    }),
    resetPassword: builder.mutation<void, UpdatePasswordRequest>({
      query: (params) => ({
        url: '/v1/authentication/passwords/reset',
        method: 'PUT',
        body: params
      })
    }),
    createPassword: builder.mutation<void, CreatePasswordRequest>({
      query: (params) => ({
        url: '/v1/authentication/invitations/accept',
        method: 'PUT',
        body: params
      })
    })
  }),
  overrideExisting: false
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useForgotPasswordMutation,
  useValidateResetPasswordTokenQuery,
  useResetPasswordMutation,
  useCreatePasswordMutation,
  useValidateInvitationTokenQuery,
  useRequestSmsMutation,
  useCheckMfaRequestQuery,
  useCreateMfaRequestMutation,
  useVerifyMfaRequestMutation,
  useRegisterMfaUserMutation
} = authenticationApi;
