import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { OrderRowData } from 'redux/api/collections/orders/responses';

import { OrderBadge } from '@atoms/badges/OrderBadge/OrderBadge';
import { FormattedIdentifier } from '@atoms/FormattedIdentifier/FormattedIdentifier';
import { TransactionIconPlus } from '@atoms/Icon';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

export const useOrderColumnDefinitions = () => {
  const columnHelper = createColumnHelper<OrderRowData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'icon',
        cell: () => (
          <div className="m-auto w-6">
            <TransactionIconPlus height="32" width="32" />
          </div>
        ),
        meta: {
          className: 'w-20'
        }
      }),
      columnHelper.accessor('created_at', {
        id: 'date',
        header: 'Created at',
        cell: (info) => {
          const dateTime = info.getValue<string>();

          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor(
        (row) => ({ id: row.uuid, reference: row.reference }),
        {
          id: 'idReference',
          header: 'ID/Reference',
          cell: (info) => {
            const { id, reference } = info.getValue<{
              id: string;
              reference: string;
            }>();

            return (
              <div className="uppercase">
                <FormattedIdentifier value={id} className="text-xs" />
                <FormattedIdentifier
                  value={reference}
                  className="font-medium"
                />
              </div>
            );
          },
          meta: {
            className: 'w-28'
          }
        }
      ),
      columnHelper.accessor(
        (row) => ({ amount: row.amount, currency: row.currency }),
        {
          id: 'amount',
          header: 'Amount',
          cell: (info) => {
            const { amount, currency } = info.getValue<{
              amount: number;
              currency: string;
            }>();

            const formattedAmount = new MoneyFormatter({ currency: currency })
              .format(amount)
              .replace(`-${currency}`, '');

            return (
              <>
                <div className="text-xs font-normal uppercase">{currency}</div>
                <div className="whitespace-nowrap text-base font-medium">
                  {formattedAmount}
                </div>
              </>
            );
          },
          meta: {
            className: 'w-28 pr-4',
            alignment: 'right'
          }
        }
      ),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Status',
        cell: (info) => <OrderBadge state={info.getValue<string>()} />,
        meta: {
          className: 'w-48 pr-4',
          alignment: 'right'
        }
      }),
      columnHelper.display({
        id: 'column_params',
        cell: () => (
          <ChevronRightIcon className="-ml-2 h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000" />
        ),
        meta: {
          className: 'w-10 pl-0'
        }
      })
    ],
    []
  );
  return columns;
};
