import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import AuthyIcon from 'assets/images/authy-icon.svg';

import {
  useCheckMfaRequestQuery,
  useCreateMfaRequestMutation,
  useRequestSmsMutation,
  useVerifyMfaRequestMutation
} from 'redux/api/authenticationApi';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { MfaCodeField } from '@atoms/MfaCodeField/MfaCodeField';

interface Props {
  onMfaSuccess: () => void;
}

export const MfaVerifyForm = ({ onMfaSuccess }: Props) => {
  const navigate = useNavigate();
  const codeRef = useRef<HTMLInputElement>(null);

  const [isMfaPolling, setIsMfaPolling] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);

  // form attributes
  const [code, setCode] = useState<string>('');
  const oneTimeCodeLength = code.length < 7;

  const [createMfaRequest, { data: mfaRequest, isSuccess: mfaSuccess }] =
    useCreateMfaRequestMutation();

  const [
    verifyMfaRequest,
    {
      error: verifyMfaErrors,
      isSuccess: isVerifyingSuccess,
      isError: isVerifyingError
    }
  ] = useVerifyMfaRequestMutation();

  const [requestSms] = useRequestSmsMutation();

  const { data: mfaStatus } = useCheckMfaRequestQuery(
    { approval_request_id: mfaRequest?.uuid as string },
    {
      pollingInterval: 2000,
      skip: !mfaSuccess || !isMfaPolling
    }
  );

  const handleSubmit = async (e: FormEvent) => {
    if (isLoading) {
      return;
    }

    e.preventDefault();

    setIsLoading(true);

    await verifyMfaRequest({ mfa_token: code });
  };

  const requestSmsHandler = async () => {
    try {
      const result = await requestSms().unwrap();

      toast.success(result.message, {
        position: 'bottom-center',
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    } catch (error: any) {
      if (error.data) {
        const errorMessages = error.data.errors
          .map((e: any) => e.title)
          .join('. ');
        toast.error(errorMessages, {
          position: 'bottom-center',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      }
    }
  };

  useEffect(() => {
    if (isVerifyingSuccess == true) {
      setIsLoading(false);
      onMfaSuccess();
    }
    if (isVerifyingError == true) {
      if (
        verifyMfaErrors &&
        'error' in verifyMfaErrors &&
        verifyMfaErrors.error === 'EXPIRED_AUTHENTICATION'
      ) {
        navigate('/login', { state: { mfaExpired: true } });
        return;
      }

      const { data = { errors: [] } }: { data?: any } =
        verifyMfaErrors as FetchBaseQueryError;
      setErrors(data.errors.map((e: any) => e.title));
      setIsLoading(false);
    }
  }, [isVerifyingSuccess, isVerifyingError]);

  useEffect(() => {
    codeRef.current?.focus();
    createMfaRequest();
  }, []);

  useEffect(() => {
    if (mfaStatus == undefined) return;

    if (mfaStatus?.status != 'pending') {
      setIsMfaPolling(false);
    }
    if (mfaStatus?.status == 'approved') {
      onMfaSuccess();
    }
  }, [mfaStatus]);

  useEffect(() => {
    setErrors([]);
  }, [code]);

  return (
    <div className="align-center flex flex-col">
      <div className="flex">
        <AuthyIcon className="mr-3 inline size-9" />
        <div>
          <h1 className="text-2xl font-medium md:pb-2 md:text-4xl">
            Multi-factor authentication
          </h1>
          <h2 className="mb-16 text-center text-sm md:text-lg">
            Please enter token from your Authy App or{' '}
            <a
              className="cursor-pointer font-medium text-inpay-blue-1000 hover:text-inpay-green-primary-1000 hover:underline"
              onClick={requestSmsHandler}
            >
              request sms
            </a>
          </h2>
        </div>
      </div>

      <form
        className="max-width-[506px] flex flex-wrap items-end gap-2"
        onSubmit={handleSubmit}
      >
        <MfaCodeField
          ref={codeRef}
          className="w-full grow md:w-auto"
          label="Authy token"
          name="mfa_token"
          onChange={({ target }) => setCode(target.value)}
          errorMessage={errors.join(', ')}
        />
        <PrimaryButton
          size="large" // Note: we are forcing h-12 to match input height
          className="h-12"
          type="submit"
          value="Check token"
          disabled={oneTimeCodeLength || isLoading}
        >
          Check token
        </PrimaryButton>
      </form>
    </div>
  );
};
