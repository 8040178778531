import { useMemo } from 'react';

import { formatDate } from 'utils/dayjs';

import {
  PayoutsStatsParams,
  useGetCategoryCountsStatsQuery
} from 'redux/api/payouts/payoutsStatsApi';

import { payoutStates } from 'models/payout';
import { StateStats } from 'models/stats';

import { useGlobalFilters } from 'hooks/useGlobalFilters';
import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';
import { OrgLink } from '@atoms/OrgLink';

interface Props {
  includeAllOption?: boolean;
}

export const WidgetPayoutStatuses = ({ includeAllOption = false }: Props) => {
  const orgNavigate = useOrgNavigate();
  const { filters } = useGlobalFilters();

  const dateRange = useMemo<PayoutsStatsParams>(() => {
    const queryParams: PayoutsStatsParams = {};
    if (filters?.dateRange?.from)
      queryParams.created_after = formatDate(
        filters.dateRange.from,
        'utc',
        'iso'
      );
    if (filters?.dateRange?.to)
      queryParams.created_before = formatDate(
        filters.dateRange.to,
        'utc',
        'iso'
      );
    return queryParams;
  }, [filters.dateRange]);

  const {
    data: statsResponse,
    isSuccess,
    error
  } = useGetCategoryCountsStatsQuery(dateRange);

  const totalCount = statsResponse?.meta?.count || 0;
  const stateCategories: Array<StateStats> = statsResponse?.data || [];

  const onBadgeClick = (state?: string) => {
    if (state) {
      orgNavigate(`/payouts?state=${state}`);
    } else {
      orgNavigate('/payouts');
    }
  };

  const states = payoutStates
    .sort((a, b) => {
      return a.order - b.order;
    })
    .filter((state) =>
      stateCategories.some((cat) => {
        return cat.count > 0 && cat?.code === state?.id;
      })
    );

  return totalCount > 0 ? (
    <section
      aria-label="Payouts statistics"
      className="w-full rounded-lg bg-white p-4 shadow-plain-lg"
    >
      <h3 className="text-sm font-bold">
        <OrgLink
          className="align-center flex cursor-pointer text-sm hover:text-inpay-green-primary-1000"
          to="/payouts"
        >
          Payout requests:
          <span className="ml-1 font-normal">{totalCount}</span>
        </OrgLink>
      </h3>
      <ul className="mb-1 mt-4 flex flex-col gap-2">
        {isSuccess && includeAllOption && (
          <PayoutBadge
            state="All"
            label="All"
            className="align-center flex cursor-pointer text-sm"
            onClick={() => onBadgeClick()}
          />
        )}
        {isSuccess &&
          states.map((stateCategory) => {
            const category = stateCategories.find(
              (c) => c.code === stateCategory.id
            );
            if (!category) return null;
            return (
              <li key={category.code}>
                <PayoutBadge
                  className="align-center flex cursor-pointer text-sm"
                  label={category.name}
                  state={category.code}
                  count={category.count}
                  onClick={() => onBadgeClick(category.code)}
                />
              </li>
            );
          })}
        {error && <p>Error loading category count stats</p>}
      </ul>
    </section>
  ) : null;
};
