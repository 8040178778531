import { WithdrawalRequestsRequest } from 'redux/api/accounts/withdrawalsApi';

import { LocalState } from './localState';

export type Params = LocalState & { organisationId: string };

export const apiParams = function (state: Params): WithdrawalRequestsRequest {
  const params: WithdrawalRequestsRequest = {
    organisation_id: state.organisationId,
    page: {
      size: 20,
      ...(state.cursorBefore && { cursor_before: state.cursorBefore }),
      ...(state.cursorAfter && { cursor_after: state.cursorAfter }),
      ...(state.includeCursor && { include_cursor: state.includeCursor })
    },
    ...(state.filters.status &&
      state.filters.status.trim() !== '' && { filter: state.filters }),
    ...(state.filters.reference &&
      state.filters.reference.trim() !== '' && { filter: state.filters })
  };
  return params;
};
