import type { TransactionData } from 'models/transaction';

export const transactionTypeMapping: {
  [entryType: string]: {
    type: TransactionData['entry_type'];
    description: string;
  };
} = {
  refund_cancelled_fee: {
    type: 'Payout',
    description: 'Cancelled fee'
  },
  refund_returned_fee: { type: 'Payout', description: 'Returned fee' },
  invoice_fee: { type: 'Collection', description: 'Fee' },
  withdrawal_fee: { type: 'Withdrawal', description: 'Fee' },
  refund_returned: { type: 'Payout', description: 'Returned' },
  refund_cancelled: { type: 'Payout', description: 'Cancelled' },
  withdrawal: { type: 'Withdrawal', description: 'Request' },
  settlement_fee: { type: 'Payout', description: 'Settlement fee' },
  increase_credit: { type: 'Collection', description: 'Account funding' },
  invoice: { type: 'Collection', description: 'Order' },
  bonus: { type: 'Manual Adjustment', description: 'Bonus' },
  forwarded_from: {
    type: 'Internal Adjustment',
    description: 'Forwarded from'
  },
  forwarded_to: { type: 'Internal Adjustment', description: 'Forwarded to' }
};
