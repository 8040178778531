import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useIsFirstRender } from 'usehooks-ts';

import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import {
  selectDateRange,
  setCustomRange,
  setDateRangeType
} from 'redux/slices/globalFiltersSlice';

import { DateRangeType } from 'models/dateRange';

export interface GlobalQueryParams {
  'dateRange.type'?: string;
  'dateRange.from'?: string;
  'dateRange.to'?: string;
}

export const useGlobalFilters = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isFirst = useIsFirstRender();

  const dateRange = useSelector(selectDateRange, shallowEqual);

  const queryParams = useMemo<GlobalQueryParams>(() => {
    return {
      'dateRange.type': dateRange.type,
      'dateRange.from':
        dateRange.type !== 'custom' ? undefined : dateRange.from,
      'dateRange.to': dateRange.type !== 'custom' ? undefined : dateRange.to
    };
  }, [dateRange]);

  const filters = useMemo(() => ({ dateRange }), [dateRange]);

  if (isFirst) {
    const dateRangeType = searchParams.get('dateRange.type') as DateRangeType;

    if (dateRangeType) {
      if (dateRangeType != 'custom') {
        dispatch(setDateRangeType(dateRangeType));
      } else {
        const from = dayjs(
          searchParams.get('dateRange.from'),
          DATE_FORMAT
        ).format(DATE_FORMAT);
        const to = dayjs(searchParams.get('dateRange.to'), DATE_FORMAT).format(
          DATE_FORMAT
        );
        dispatch(setCustomRange({ from, to }));
      }
    }
  }

  const setDateRange = (params: DateRangeParams) => {
    if (params.type == 'custom') {
      dispatch(setCustomRange({ from: params.from, to: params.to }));
    } else {
      dispatch(setDateRangeType(params.type));
    }
  };

  return {
    filters,
    setDateRange,
    queryParams
  };
};

export type DateRangeParams =
  | {
      type: Exclude<DateRangeType, 'custom'>;
    }
  | {
      type: 'custom';
      to: string;
      from: string;
    };
