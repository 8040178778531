import { useRef } from 'react';

import { AMOUNT_FILTER_ENABLED } from 'constants/environment';

import { useGetReasonCodesByStatesQuery } from 'redux/api/payouts/payoutsStatsApi';
import { FiltersState } from 'redux/slices/pages.payoutsSlice';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import {
  stateToRequestParams,
  useLocalState
} from 'components/pages/Payouts/localState';

import { AmountFilter } from './AmountFilter';
import { PAYOUTS_FILTERS_TEST_ID } from './constants';
import { CurrencyFilter } from './CurrencyFilter';
import { StateAccordion } from './StateAccordion';

interface Props {
  onFilterChange: (value: FiltersState) => void;
  filters?: any;
}

export const PayoutsFilters = ({ onFilterChange, filters }: Props) => {
  const { state } = useLocalState();
  const { filters: globalFilters } = useGlobalFilters();

  const { resetState } = useLocalState();

  const filtersToIgnore = ['state', 'reasonCode'];
  const trimmedFilters = Object.fromEntries(
    Object.entries(state.filters).filter(([e]) => !filtersToIgnore.includes(e))
  );

  const { data: { data: states } = {} } = useGetReasonCodesByStatesQuery(
    stateToRequestParams({ filters: { ...trimmedFilters }, ...globalFilters })
  );
  const childRef = useRef<{ resetInputValues(): void }>(null);

  const onCurrencyChange = (value?: string) => {
    onFilterChange({
      currency: value
    });
  };

  const onAmountChange = (value: {
    from: number | null;
    to: number | null;
  }) => {
    onFilterChange({
      amountFrom: value.from?.toString() || undefined,
      amountTo: value.to?.toString() || undefined
    });
  };

  return (
    <section
      aria-label="Filters"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="mx-6 flex h-13 shrink-0 items-center justify-between">
        <div className="shrink-0 font-bold">Filter</div>
        <button
          className="shrink-0 cursor-pointer"
          onClick={() => {
            resetState();
            childRef.current?.resetInputValues();
          }}
        >
          Clear All
        </button>
      </div>
      <div className="border-inpay-pumice grid h-10 shrink-0 grid-cols-2 border-y bg-inpay-green-secondary-light-200">
        <div>
          <CurrencyFilter
            onCurrencyChange={onCurrencyChange}
            value={filters.currency}
          />
        </div>
        {AMOUNT_FILTER_ENABLED && (
          <div>
            <AmountFilter
              onAmountChange={onAmountChange}
              value={{
                from: filters.amountFrom,
                to: filters.amountTo
              }}
            />
          </div>
        )}
      </div>
      <div className="overflow-auto" data-testid={PAYOUTS_FILTERS_TEST_ID}>
        <StateAccordion
          activeFilters={filters}
          states={states}
          onCategoryChange={onFilterChange}
        />
      </div>
    </section>
  );
};
