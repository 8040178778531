/* eslint-disable no-console */
import { hasValue } from 'utils/hasValue';

/**
 * @description object that formats money, you give it a currency and it will format it properly,
 * if you don't give it a currency, it will only format the currency without appending the symbol
 * @example See usage examples in the formatter.test.ts file
 */
export class MoneyFormatter {
  private readonly _numberFormat: Intl.NumberFormat;

  constructor(
    options: {
      currency?: string;
      locale?: string;
      sign?: boolean;
    } = {}
  ) {
    if (!hasValue(options.currency)) {
      console.warn(
        'MoneyFormatter: a currency code was not provided to the formatter, please provide one if you can'
      );
    }

    const params: Intl.NumberFormatOptions = {
      currency: options.currency || undefined,
      currencyDisplay: 'narrowSymbol',
      style: options.currency ? 'currency' : 'decimal',
      signDisplay: options.sign ? 'always' : 'auto',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    };

    this._numberFormat = Intl.NumberFormat(options.locale, params);
  }

  public format(value: number | bigint): string {
    try {
      return this._numberFormat.format(Number(value));
    } catch (e) {
      console.warn('Do not deconstruct this method directly when importing it');
      return Intl.NumberFormat().format(Number(value));
    }
  }

  public formatToParts(
    number?: number | bigint | undefined
  ): ReturnType<Intl.NumberFormat['formatToParts']> {
    try {
      return this._numberFormat.formatToParts(number);
    } catch (e) {
      console.warn('Do not deconstruct this method directly when importing it');
      return Intl.NumberFormat().formatToParts(number);
    }
  }
}
