import { ComponentPropsWithRef, forwardRef, useState } from 'react';

export const FileInputField = forwardRef<
  HTMLInputElement,
  ComponentPropsWithRef<'input'>
>((props, ref) => {
  const [fileName, setFileName] = useState<string>();

  return (
    <div className="mb-4 flex flex-col">
      <label className="mb-0.5" htmlFor="file-picker">
        <span className="pl-2 text-xs">Attach payout request batch file</span>
        <div className="relative flex h-12 w-full items-center justify-end rounded-lg bg-white p-1 text-base shadow-plain focus:shadow-plain-lg group-hover:shadow-plain-lg">
          <input
            className="absolute h-12 w-0"
            id="file-picker"
            type="file"
            accept="text/csv"
            ref={ref}
            {...props}
            onChange={(event) => {
              props.onChange && props.onChange(event);
              setFileName(event?.target?.files?.[0]?.name);
            }}
          />
          <span className="w-80 truncate pl-2">{fileName}</span>
          <span className="right-32 flex h-10 w-40 cursor-pointer items-center justify-center rounded-lg bg-inpay-green-primary-1000 text-white hover:bg-inpay-dark-green-1000">
            Attach file
          </span>
        </div>
        <div className="pl-2 pt-1 text-xs text-inpay-black-primary-400">
          File encoding must be: UTF-8
        </div>
      </label>
    </div>
  );
});

FileInputField.displayName = 'FileInputField';
