import { DialogOverlay } from '@radix-ui/react-dialog';
import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<typeof DialogOverlay>;

const Overlay = forwardRef(
  (
    { children, className, ...props }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <DialogOverlay
      onClick={(e) => e.stopPropagation()}
      id="dialog"
      className={twMerge(
        'fixed inset-0 bg-inpay-green-primary-1000/90',
        className
      )}
      {...props}
      ref={ref}
    >
      {children}
    </DialogOverlay>
  )
);

Overlay.displayName = 'Overlay';
export { Overlay };
