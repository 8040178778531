import { hasValue } from 'utils/hasValue';

import { type User } from 'models/user';

import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

export interface UserDetailsProps {
  data: User;
  onEditClick?: () => void | undefined;
}

export const UserProfileShow = ({ data, onEditClick }: UserDetailsProps) => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex justify-between">
        <div>
          <span className="text-xs">First Name</span>
          <div className="font-medium" data-testid="first_name">
            {data.first_name}
          </div>
        </div>
        <SecondaryButton size="large" onClick={onEditClick}>
          Edit Profile
        </SecondaryButton>
      </div>
      <div>
        <span className="text-xs">Last Name</span>
        <div className="font-medium" data-testid="last_name">
          {data.last_name}
        </div>
      </div>
      <div>
        <span className="text-xs">Email</span>
        <div className="font-medium" data-testid="email">
          {data.email}
        </div>
      </div>
      {hasValue(data.created_at) ? (
        <div>
          <span className="text-xs">Created</span>
          <FormattedDate
            value={data.created_at}
            className="font-medium"
            data-testid="created_at"
          />
        </div>
      ) : null}
    </div>
  );
};
