import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger
} from '@radix-ui/react-accordion';
import cn from 'classnames';

import { ReasonCodesByStates } from 'redux/api/payouts/payoutsStatsApi';
import { FiltersState, LocalState } from 'redux/slices/pages.payoutsSlice';

import { payoutStates } from 'models/payout';

import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';

import { ListItem } from './PayoutFilterListItem';

export interface StateAccordionProps {
  onCategoryChange: (filters: FiltersState) => void;
  batchId?: string;
  states?: ReasonCodesByStates;
  activeFilters?: LocalState['filters'];
}

export const StateAccordion = ({
  onCategoryChange,
  states,
  activeFilters
}: StateAccordionProps) => {
  return (
    <Accordion
      type="single"
      collapsible
      value={activeFilters?.state || ''}
      onValueChange={(value) => {
        onCategoryChange({
          state: value,
          reasonCode: undefined,
          accountNumber: undefined,
          currency: undefined,
          amountFrom: undefined,
          amountTo: undefined
        });
      }}
    >
      <ul>
        <ListItem
          className="cursor-pointer"
          isSelected={!activeFilters?.state}
          onClick={() =>
            onCategoryChange({
              state: undefined,
              reasonCode: undefined,
              accountNumber: undefined,
              currency: undefined,
              amountFrom: undefined,
              amountTo: undefined
            })
          }
        >
          <PayoutBadge
            label="All"
            state="All"
            selected={!activeFilters?.state}
          />
        </ListItem>
        {payoutStates.map((category) => {
          const isSelected = activeFilters?.state === category.value;
          const reasonCodes = states?.[category.id]?.reason_codes;
          const isPresent = (states?.[category.id]?.payout_count || 0) > 0;

          return (
            <AccordionItem
              key={category.value}
              value={category.value}
              disabled={!isPresent}
            >
              <AccordionHeader asChild>
                <AccordionTrigger
                  className={`group w-full shrink-0${
                    isPresent ? ' cursor-pointer' : undefined
                  }`}
                >
                  <ListItem
                    isSelected={isSelected}
                    onClick={() => {
                      // If we are selecting already active filter, remove it
                      const state =
                        category.id != activeFilters?.state
                          ? category.id
                          : undefined;
                      onCategoryChange({
                        state: state,
                        reasonCode: undefined,
                        accountNumber: undefined,
                        currency: undefined,
                        amountFrom: undefined,
                        amountTo: undefined
                      });
                    }}
                  >
                    <PayoutBadge
                      label={category.label}
                      state={category.value}
                      selected={isSelected}
                    />
                    {reasonCodes?.length ? (
                      <ChevronDownIcon className="group-radix-state-open:scale-flip w-7 stroke-inpay-gray-primary-800 transition-all duration-300 group-hover:!stroke-inpay-black-primary-1000" />
                    ) : null}
                  </ListItem>
                </AccordionTrigger>
              </AccordionHeader>
              <AccordionContent>
                {reasonCodes?.map((reasonCode) => {
                  const reasonCodeId = reasonCode.reason_code_id.toString();

                  return (
                    <div
                      className={cn(
                        'flex h-14 cursor-pointer flex-row items-center px-6',
                        category.style.color,
                        category.style.hover,
                        {
                          [category.style.activeColor]:
                            activeFilters?.reasonCode === reasonCodeId
                        }
                      )}
                      key={reasonCodeId}
                      onClick={() =>
                        onCategoryChange({
                          state: category.id,
                          reasonCode: reasonCodeId,
                          accountNumber: undefined,
                          currency: undefined,
                          amountFrom: undefined,
                          amountTo: undefined
                        })
                      }
                    >
                      <span>{reasonCode.external_message}</span>
                    </div>
                  );
                })}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </ul>
    </Accordion>
  );
};
