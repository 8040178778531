import { BadgeStyleParams } from 'components/ui/atoms/badges/badgeStyle';

export const orderStates: Array<OrderState> = [
  {
    id: 'received',
    value: 'received',
    order: 1,
    label: 'Received',
    style: {
      color: 'bg-inpay-gray-primary-400',
      activeColor: 'bg-inpay-gray-primary-600',
      hover: 'hover:bg-inpay-gray-primary-600'
    }
  },
  {
    id: 'processing',
    value: 'processing',
    order: 2,
    label: 'Processing',
    style: {
      color: 'bg-inpay-blue-400',
      activeColor: 'bg-inpay-blue-600',
      hover: 'hover:bg-inpay-blue-600'
    }
  },
  {
    id: 'processing_refund',
    value: 'processing_refund',
    order: 3,
    label: 'Processing refund',
    style: {
      color: 'bg-inpay-blue-400',
      activeColor: 'bg-inpay-blue-600',
      hover: 'hover:bg-inpay-blue-600'
    }
  },
  {
    id: 'cancelled',
    value: 'cancelled',
    order: 4,
    label: 'Cancelled',
    style: {
      color: 'bg-inpay-red-400',
      activeColor: 'bg-inpay-red-600',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'authorised',
    value: 'authorised',
    order: 5,
    label: 'Authorised',
    style: {
      color: 'bg-inpay-blue-400',
      activeColor: 'bg-inpay-blue-600',
      hover: 'hover:bg-inpay-blue-600'
    }
  },
  {
    id: 'pending',
    value: 'pending',
    order: 6,
    label: 'Pending',
    style: {
      color: 'bg-inpay-purple-400',
      activeColor: 'bg-inpay-purple-600',
      hover: 'hover:bg-inpay-purple-600'
    }
  },
  {
    id: 'action_required',
    value: 'action_required',
    order: 7,
    label: 'Action required',
    style: {
      color: 'bg-inpay-yellow-400',
      activeColor: 'bg-inpay-yellow-600',
      hover: 'hover:bg-inpay-yellow-600'
    }
  },
  {
    id: 'completed',
    value: 'completed',
    order: 8,
    label: 'Completed',
    style: {
      color: 'bg-inpay-green-secondary-light-600',
      activeColor: 'bg-inpay-green-secondary-light-800',
      hover: 'hover:bg-inpay-green-secondary-light-800'
    }
  },
  {
    id: 'refunded',
    value: 'refunded',
    order: 9,
    label: 'Refunded',
    style: {
      color: 'bg-inpay-red-400',
      activeColor: 'bg-inpay-red-600',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'partially_refunded',
    value: 'partially_refunded',
    order: 10,
    label: 'Partially refunded',
    style: {
      color: 'bg-inpay-red-400',
      activeColor: 'bg-inpay-red-600',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'action_required_for_refund',
    value: 'action_required_for_refund',
    order: 11,
    label: 'Action required for refund',
    style: {
      color: 'bg-inpay-yellow-400',
      activeColor: 'bg-inpay-yellow-600',
      hover: 'hover:bg-inpay-yellow-600'
    }
  }
];

export type OrderState = {
  id: string;
  value: string;
  order: number;
  label: string;
  style: BadgeStyleParams;
};

export interface Order {
  uuid: string;
  created_at: string;
  amount: number;
  currency: string;
  status: OrderState;
  reference: string;
  virtual_account_number?: string;
  amountWithCurrency?: {
    amount: number;
    currency: string;
  };
  debtor: OrderDebtor;
}

export interface OrderDebtor {
  country_of_residence: string;
  created_at: string;
  date_of_birth: string;
  email: string;
  full_name: string;
}

export interface OrderStatusTransition {
  created_at: string;
  status: string;
}
