import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

export const TableHeader = ({ title, children }: Props) => {
  return (
    <div className="table-header flex items-center justify-between">
      <div className="pb-4 text-2xl font-medium">{title}</div>
      {children}
    </div>
  );
};
