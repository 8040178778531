import { useMemo } from 'react';

import { formatDate } from 'utils/dayjs';

import {
  OrdersStatsParams,
  useGetOrdersCategoryCountsStatsQuery
} from 'redux/api/collections/ordersStatsApi';

import { OrderState, orderStates } from 'models/order';
import { StateStats } from 'models/stats';

import { useGlobalFilters } from 'hooks/useGlobalFilters';
import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { OrderBadge } from '@atoms/badges/OrderBadge/OrderBadge';
import { OrgLink } from '@atoms/OrgLink';

interface Props {
  includeAllOption?: boolean;
}

interface StateWithCount extends OrderState {
  count: number;
}

export const WidgetOrderStatuses = ({ includeAllOption = false }: Props) => {
  const orgNavigate = useOrgNavigate();
  const { filters } = useGlobalFilters();

  const dateRange = useMemo<OrdersStatsParams>(() => {
    const queryParams: OrdersStatsParams = {};
    if (filters?.dateRange?.from)
      queryParams.created_after = formatDate(
        filters.dateRange.from,
        'utc',
        'iso'
      );
    if (filters?.dateRange?.to)
      queryParams.created_before = formatDate(
        filters.dateRange.to,
        'utc',
        'iso'
      );
    return queryParams;
  }, [filters.dateRange]);

  const {
    data: statsResponse,
    isSuccess,
    error
  } = useGetOrdersCategoryCountsStatsQuery(dateRange);

  const totalCount = statsResponse?.meta?.orders_count || 0;
  const stateCategories: Array<StateStats> = statsResponse?.data || [];

  const onBadgeClick = (state?: string) => {
    if (state) {
      orgNavigate(`/collections/orders?status=${state}`);
    } else {
      orgNavigate('/collections/orders');
    }
  };

  const states = useMemo<Array<StateWithCount>>(() => {
    const filteredStates = [...orderStates].filter((state) =>
      stateCategories.some((cat) => {
        return cat.count > 0 && cat.code === state.id;
      })
    );

    const statesWithCount = filteredStates.map((s) => {
      const count =
        stateCategories.find((cat) => cat.code === s.id)?.count || 0;
      return { ...s, count };
    });

    const orderedStates = statesWithCount.sort((a, b) => {
      return a.order - b.order;
    });

    return orderedStates;
  }, [stateCategories]);

  return totalCount > 0 ? (
    <section
      aria-label="Orders statistics"
      className="w-full rounded-lg bg-white p-4 shadow-plain-lg"
    >
      <h3 className="text-sm font-bold">
        <OrgLink
          className="align-center flex cursor-pointer text-sm hover:text-inpay-green-primary-1000"
          to="/collections/orders"
        >
          Collection orders:{' '}
          <span className="ml-1 font-normal">{totalCount}</span>
        </OrgLink>
      </h3>
      {error ? (
        <p>Error loading category count stats</p>
      ) : (
        <ol className="mb-1 mt-4 flex flex-col gap-2">
          {isSuccess && includeAllOption ? (
            <li>
              <OrderBadge
                state="All"
                label="All"
                className="align-center flex cursor-pointer text-sm"
                onClick={() => onBadgeClick()}
              />
            </li>
          ) : null}
          {isSuccess
            ? states.map((state) => {
                return (
                  <li key={state.id}>
                    <OrderBadge
                      className="align-center flex cursor-pointer text-sm"
                      label={state.label}
                      state={state.id}
                      count={state.count}
                      onClick={() => onBadgeClick(state.id)}
                    />
                  </li>
                );
              })
            : null}
        </ol>
      )}
    </section>
  ) : null;
};
