export const CREATE_MANUAL_PAYOUT_DIALOG_TEST_ID =
  'create-manual-payout-dialog';

export const CANCEL_TEXT = 'Cancel';
export const YES_TEXT = 'Yes';
export const NO_TEXT = 'No';
export const NEXT = 'Next';

export const ARE_YOU_SURE =
  'Are you sure you want to exit your unfinished manual payout request?';

export const IT_WILL_BE_DELETED = 'It will be deleted.';

export const EXIT_UNFINISHED_MANUAL_PAYOUT_REQUEST =
  'Exit unfinished manual payout request';

export const DATA_REQUIRED_SELECTOR_TEST_ID = 'data-required';

export const PAYOUT_REQUEST_STEP_NEXT_BUTTON =
  'payout-request-step-next-button';

export const FINISHED_CHECKMARK = 'finished-checkmark';

export const PAYOUT_NEEDS_APPROVAL =
  'The payout request has been sent for approval. It needs to be approved before it will be processed.';
