import { SVGProps } from 'react';

export const WithdrawFundsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m5.25 2.25-3 3 3 3m-3-3h6m10.5 10.5 3 3-3 3m3-3h-6m-3.75-3a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5" />
    </svg>
  );
};
