import { useState } from 'react';
import { useSelector } from 'react-redux';

import { hasValue } from 'utils/hasValue';

import { useFetchUserQuery } from 'redux/api/usersApi';
import { selectOrganisationId, selectUserId } from 'redux/slices/authSlice';

import { Loading } from 'components/pages/Loading';

import { UserProfileEdit } from './UserProfileEdit';
import { UserProfileShow } from './UserProfileShow';

export const UserProfile = () => {
  const userId = useSelector(selectUserId);
  const organisationId = useSelector(selectOrganisationId);

  const [isEdit, setIsEdit] = useState(false);

  const {
    data: { data: userData } = {},
    isLoading,
    isError,
    isSuccess
  } = useFetchUserQuery({ organisationId, id: userId });

  const onEditClick = () => setIsEdit(true);
  const onFormClose = () => setIsEdit(false);

  if (!userId) return null;

  return (
    <div role="complementary" aria-label="User Profile" className="h-full p-8">
      <div className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg">
        <div>
          <div className="border-inpay-gray-primary-300 flex items-center justify-between border-b px-6 py-3.5 text-xl font-semibold">
            <header>{isEdit ? 'Edit Profile' : 'User Profile'}</header>
          </div>
        </div>
        <div className="overflow-auto">
          {isLoading && <Loading />}
          {isSuccess && hasValue(userData) && (
            <>
              {!isEdit ? (
                <UserProfileShow data={userData} onEditClick={onEditClick} />
              ) : (
                <UserProfileEdit
                  data={userData}
                  onCancelClick={onFormClose}
                  onSuccess={onFormClose}
                />
              )}
            </>
          )}
          {isError && (
            <div className="flex h-full items-center justify-center">
              Something went wrong
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
