export const API_URL = process.env.API_URL || 'http://localhost';

// HOTJAR configuration
export const HJID = process.env.HJID && Number(process.env.HJID);
export const HJSV = process.env.HJSV && Number(process.env.HJSV);

export const AUTHY_DISABLED = ['1', 'yes', 'true'].includes(
  process.env.AUTHY_DISABLED || 'false'
);

export const COLLECTIONS_ENABLED = ['1', 'yes', 'true'].includes(
  process.env.COLLECTIONS_ENABLED || 'false'
);

export const AMOUNT_FILTER_ENABLED = ['1', 'yes', 'true'].includes(
  process.env.AMOUNT_FILTER_ENABLED || 'false'
);
