import { ForwardedRef, forwardRef, useState } from 'react';

import {
  InputField,
  InputFieldProps
} from 'components/ui/molecules/InputField';

export const PasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    const [show, setShow] = useState<boolean>(false);
    const { children, ...inputProps } = props;
    return (
      <InputField {...inputProps} type={show ? 'text' : 'password'} ref={ref}>
        {children ? (
          children
        ) : (
          <a
            className="absolute right-4 cursor-pointer align-middle text-sm font-medium leading-12 text-inpay-blue-1000 hover:text-inpay-green-primary-1000"
            onClick={() => setShow(!show)}
          >
            {show ? 'Hide' : 'Show'}
          </a>
        )}
      </InputField>
    );
  }
);
PasswordField.displayName = 'PasswordField';
