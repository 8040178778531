import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { FormEventHandler, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';

import { Content, Overlay, Title } from '@organisms/Dialog';
import { createBatchTemplateDialogSchema as schema } from 'validators/createBatchTemplateDialog';

import {
  useGetLocalInstrumentsQuery,
  useLazyGetProductSchemasQuery
} from 'redux/api/payouts/batchTemplateApi';

import { Corridor, LocalInstrument, ProductSchema } from 'models/batchTemplate';

import { useDownloadFile } from 'hooks/useDownloadFile';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { SelectChoice, Selector } from '@molecules/Selector/Selector';

import { CsvSelector } from './CsvSelector';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
}

export const CreateBatchTemplateDialog = ({ open, onClose }: Props) => {
  const [localInstruments, setLocalInstruments] = useState<LocalInstrument[]>();
  const [corridors, setCorridors] = useState<Corridor[]>();
  const [corridorId, setCorridorId] = useState<string>();
  const [productSchemas, setProductSchemas] = useState<ProductSchema[]>();
  const [dowloaded, setDowloaded] = useState<boolean>(false);
  const { data: localInstrumentsData } = useGetLocalInstrumentsQuery();
  const downloadFile = useDownloadFile();

  const [getProductSchemas] = useLazyGetProductSchemasQuery();

  const fetchingProductSchemaData = async () => {
    try {
      const { data: productSchema } = await getProductSchemas(corridorId);
      setProductSchemas(productSchema?.data);
    } catch (error: any) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (corridorId) {
      fetchingProductSchemaData();
    }
  }, [corridorId]);

  useEffect(() => {
    if (localInstrumentsData?.data) {
      setLocalInstruments(localInstrumentsData.data);
    }
    setProductSchemas([]);
  }, [localInstrumentsData]);

  const {
    handleSubmit,
    setError,
    formState,
    setValue,
    reset: resetForm,
    getValues
  } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const localInstrumentsOptions = useMemo(() => {
    setProductSchemas([]);
    return Array.isArray(localInstruments)
      ? localInstruments.map((i) => ({ label: i.name, value: i.name }))
      : [];
  }, [localInstruments]);

  useEffect(() => {
    if (
      !getValues('localInstrument') &&
      localInstrumentsOptions?.length === 1
    ) {
      onLocalInstrumentChange(localInstrumentsOptions[0].value);
    }
  }, [localInstrumentsOptions]);

  const corridorsOptions = useMemo<SelectChoice[]>(() => {
    setProductSchemas([]);
    return Array.isArray(corridors)
      ? corridors.map((i) => ({
          label: i.label,
          value: i.id.toString()
        }))
      : [];
  }, [corridors, localInstruments]);

  useEffect(() => {
    if (!getValues('corridor') && corridorsOptions?.length === 1) {
      onCorridorChange(corridorsOptions[0].value);
    }
  }, [corridorsOptions]);

  const productSchemasOptions = useMemo(() => {
    return Array.isArray(productSchemas)
      ? productSchemas.map((i) => ({
          label: i.label,
          value: i.id.toString()
        }))
      : [];
  }, [productSchemas, corridors, localInstruments]);

  useEffect(() => {
    if (!getValues('productSchema') && productSchemasOptions?.length === 1) {
      onProductSchemaChange(productSchemasOptions[0].value);
    }
  }, [productSchemasOptions]);

  const onLocalInstrumentChange = (value: string) => {
    setDowloaded(false);
    setCorridorId(undefined);
    setProductSchemas([]);
    const corridorData = localInstruments?.find((i) => i.name === value);
    setCorridors(corridorData ? corridorData.products : []);
    setValue('localInstrument', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue('corridor', '', {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const onCorridorChange = (value: string) => {
    setDowloaded(false);
    setCorridorId(value);
    setProductSchemas([]);
    setValue('corridor', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue('productSchema', '', {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const onProductSchemaChange = (value: string) => {
    setDowloaded(false);
    setValue('productSchema', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event: any) => {
    try {
      await handleSubmit(async (data) => {
        downloadFile(`payouts/schemas/${data.productSchema}/csv_template`);
        setDowloaded(true);
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  const onDialogClose = (value: boolean) => {
    resetForm();
    onClose(value);
    setLocalInstruments([]);
    setCorridors([]);
    setCorridorId(undefined);
    setProductSchemas([]);
    setDowloaded(false);
  };

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content>
            <Title>
              <DownloadIcon className="size-6 stroke-1 group-hover:stroke-1.5" />
              <span className="grow pl-2">Create batch template</span>
            </Title>
            <div className="flex justify-center rounded-b-lg bg-inpay-gray-primary-200 p-6">
              <div className="w-72">
                <form onSubmit={onSubmit} autoComplete="off">
                  <div className="mb-4 flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">Format</label>
                    <CsvSelector />
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">Transfer type</label>
                    <Selector
                      selectChoices={localInstrumentsOptions}
                      onChange={onLocalInstrumentChange}
                      name="localInstrument"
                      value={getValues('localInstrument')}
                      disabled={
                        !localInstrumentsOptions ||
                        localInstrumentsOptions.length === 0 ||
                        localInstrumentsOptions.length === 1
                      }
                    />
                  </div>
                  <div className="mb-4 flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">Corridor</label>
                    <Selector
                      selectChoices={corridorsOptions}
                      onChange={onCorridorChange}
                      name="corridor"
                      disabled={
                        !corridorsOptions ||
                        corridorsOptions.length === 0 ||
                        corridorsOptions.length === 1
                      }
                      value={getValues('corridor')}
                    />
                  </div>
                  <div className="mb-10 flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">Data required</label>
                    <Selector
                      selectChoices={productSchemasOptions}
                      onChange={onProductSchemaChange}
                      name="productSchema"
                      disabled={
                        !productSchemasOptions ||
                        productSchemasOptions.length === 0 ||
                        productSchemasOptions.length === 1
                      }
                      value={getValues('productSchema')}
                    />
                  </div>
                  {dowloaded === false ? (
                    <PrimaryButton
                      type="submit"
                      size="large"
                      className="w-full"
                      disabled={!formState.isValid}
                    >
                      Download CSV template
                    </PrimaryButton>
                  ) : (
                    <PrimaryButton
                      type="submit"
                      size="large"
                      className="w-full"
                      onClick={() => onClose(false)}
                    >
                      CSV dowloaded, close window
                    </PrimaryButton>
                  )}

                  {formState.errors.base && (
                    <div
                      role="alert"
                      className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
                      style={{
                        filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
                      }}
                    >
                      {formState.errors.base.message}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
