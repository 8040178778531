import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { twMerge } from 'tailwind-merge';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

interface Props {
  label: string;
  isActive: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
}

export const TabButton = ({ label, isActive, onClick }: Props) => {
  return (
    <PrimaryButton
      className={twMerge(
        cn(
          'rounded-2.5xl px-6 py-2',
          !isActive &&
            'bg-inpay-gray-primary-400 text-inpay-green-primary-1000 hover:bg-inpay-green-primary-200'
        )
      )}
      aria-label={`${label} tab`}
      title={label}
      aria-pressed={isActive}
      onClick={onClick}
    >
      {label}
    </PrimaryButton>
  );
};
