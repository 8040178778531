import { UploadBatchResponse } from 'redux/api/payouts/uploadBatchApi';

interface Props {
  data: UploadBatchResponse;
  isApprovalEnabled: boolean;
  isBatchesPageEnabled: boolean;
}

export const CorrectDataHandler = ({
  data,
  isApprovalEnabled,
  isBatchesPageEnabled
}: Props) => {
  if (data.data && data.meta) {
    return (
      data && (
        <div>
          <div>
            Format: <span className="font-semibold">CSV</span>
          </div>
          <div>
            Name: <span className="font-semibold">{data.data.filename}</span>
          </div>
          <div>
            Type:{' '}
            <span className="font-semibold">{data.meta.local_instrument}</span>
          </div>
          <div>
            Corridor:
            <span className="ml-1 font-semibold">
              {data.meta.currency.iso} ({data.meta.currency.name}) -{' '}
              {data.meta.country.iso} ({data.meta.country.name})
            </span>
          </div>
          <div>
            Total:
            <span className="ml-1 font-semibold">
              {data.meta.total_payout_requests}{' '}
              {data.meta.total_payout_requests !== 1
                ? 'payout requests'
                : 'payout request'}
            </span>
          </div>
          {isBatchesPageEnabled && isApprovalEnabled && (
            <div className="mt-6 rounded-md bg-inpay-yellow-200 px-4 py-2.5 text-sm">
              The batch has been sent for approval. <br /> It needs to be
              approved before payouts will <br />
              be processed.
            </div>
          )}
        </div>
      )
    );
  }
  return null;
};
