import { useMemo, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { withdrawalRequestStatuses } from 'models/withdrawal';

export type WithdrawalRequestStatusValue =
  (typeof withdrawalRequestStatuses)[0]['value'];

export interface FiltersState {
  status?: WithdrawalRequestStatusValue;
  reference?: string | null;
}
export type SortingState = '-date';

export type PaginationState = {
  includeCursor?: boolean;
  cursorBefore: string | null;
  cursorAfter: string | null;
};

export type LocalState = PaginationState & {
  sorting: SortingState;
  filters: FiltersState;
};

type Action =
  | { type: 'setCursorBefore'; value: string | null }
  | { type: 'setCursorAfter'; value: string | null }
  | { type: 'setFilters'; value: FiltersState }
  | { type: 'setSorting'; value: string };

function reducer(state: LocalState, action: Action): LocalState {
  switch (action.type) {
    case 'setSorting':
      return {
        ...state,
        sorting: (action.value as SortingState) || '-date'
      };
    case 'setFilters':
      return {
        ...state,
        filters: action.value
      };
    case 'setCursorBefore':
      return {
        ...state,
        cursorBefore: action.value,
        cursorAfter: null,
        includeCursor: undefined
      };
    case 'setCursorAfter':
      return {
        ...state,
        cursorAfter: action.value,
        cursorBefore: null,
        includeCursor: undefined
      };
  }
}

const setCursorBefore = (value: string | null): Action => ({
  type: 'setCursorBefore',
  value
});
const setCursorAfter = (value: string | null): Action => ({
  type: 'setCursorAfter',
  value
});
const setFilters = (value: FiltersState): Action => ({
  type: 'setFilters',
  value
});
const setSorting = (value: string): Action => ({
  type: 'setSorting',
  value
});

export function useLocalState(defaultState?: Partial<LocalState>) {
  const [searchParams] = useSearchParams();

  const [initialState] = useState<LocalState>({
    filters: {
      status:
        (searchParams.get('status') as FiltersState['status']) || undefined,
      reference:
        (searchParams.get('unique_identifier') as FiltersState['reference']) ||
        undefined
    },
    sorting: (searchParams.get('sort') as SortingState) || '-date',
    includeCursor: defaultState?.includeCursor || undefined,
    cursorBefore:
      defaultState?.cursorBefore || searchParams.get('cursorBefore') || null,
    cursorAfter:
      defaultState?.cursorAfter || searchParams.get('cursorAfter') || null
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const queryParams = useMemo(() => {
    return {
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore,
      status: state.filters.status
    };
  }, [state]);

  return {
    state,
    queryParams,
    dispatch,
    setCursorBefore,
    setCursorAfter,
    setFilters,
    setSorting
  };
}
