type InfoRowProps = {
  label: string;
  value: string;
  subValue?: string;
};

export const InfoRow = ({ label, value, subValue }: InfoRowProps) => (
  <div className="break-words">
    <span>{label}: </span>
    <span className="font-semibold">{value}</span>
    {subValue && <span> {subValue}</span>}
  </div>
);
