import * as yup from 'yup';

import { isPasswordValid } from 'validators/password';

export const updatePasswordFormSchema = yup
  .object({
    password: yup
      .string()
      .label('Password')
      .required()
      .test('isValidPass', '${path} is not valid', isPasswordValid),
    password_confirmation: yup
      .string()
      .label('Password confirmation')
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match')
  })
  .required();
