import { getISOByParam } from 'iso-country-currency';

// Gets country code based on currency code
// Resolves `EUR` & `USD` to `EU` & `USD` respectively
// Other country codes rely on `iso-country-currency` for lookup
export const getCountryCode = (currencyCode: string) => {
  if (currencyCode === 'EUR') return 'EU';
  if (currencyCode === 'USD') return 'US';

  // FIX: the BE has this mistake, this is a hotpatch
  if (currencyCode === 'BYR') {
    return getISOByParam('currency', 'BYN');
  }

  try {
    return getISOByParam('currency', currencyCode);
  } catch (e) {
    return '';
  }
};
