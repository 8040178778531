import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getJwtScopes, getParsedJwt } from 'utils/jwt';

import { AuthResponse } from 'redux/api/authenticationApi';
import { RootState } from 'redux/store';

import { AuthJwt, AuthScope } from 'models/authJwt';

type LogoutState = false | 'expired' | 'logout';

interface AuthState {
  accessToken: null | string;
  scopes: AuthScope[];
  organisationId?: number;
  role?: string;
  userId?: number;
  // dirty hack to reduce unnecessary requests from RequireAuth after resetting the root state
  logoutState: LogoutState;
}

export const initialState: AuthState = {
  accessToken: null,
  scopes: [],
  logoutState: false,
  organisationId: undefined,
  role: undefined,
  userId: undefined
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }: PayloadAction<AuthResponse>) => {
      const parsedToken = getParsedJwt<AuthJwt>(payload.access_token);
      state.accessToken = payload.access_token;
      state.scopes = getJwtScopes(parsedToken);
      state.userId = parsedToken?.user.id;
      state.logoutState = false;
    },
    setOrganisationId: (state, { payload }: PayloadAction<number>) => {
      state.organisationId = payload;
    },
    setUserId: (state, { payload }: PayloadAction<number>) => {
      state.userId = payload;
    },
    setRole: (state, { payload }: PayloadAction<string>) => {
      state.role = payload;
    },
    logout: (state) => {
      state.logoutState = 'logout';
    },
    expired: (state) => {
      state.logoutState = 'expired';
    }
  }
});

export const {
  setCredentials,
  setRole,
  setOrganisationId,
  setUserId,
  logout,
  expired
} = slice.actions;

export const selectAuthToken = (state: RootState) => state.auth.accessToken;
export const selectLogoutState = (state: RootState): LogoutState =>
  state.auth.logoutState;
export const selectAuthScopes = (state: RootState): AuthScope[] =>
  state.auth.scopes;
export const selectOrganisationId = (state: RootState): number | undefined =>
  state.auth.organisationId;
export const selectRole = (state: RootState): string | undefined =>
  state.auth.role;
export const selectUserId = (state: RootState): number | undefined =>
  state.auth.userId;
export default slice.reducer;
