import { MouseEvent, useEffect } from 'react';

import { DataTable } from '@organisms/DataTable';

import { useFetchOrderByUuidOrInpayExternalReferenceQuery } from 'redux/api/collections/orders';
import { ServerError } from 'redux/api/errors';

import { Order } from 'models/order';

import { TABLE_NAME } from 'components/pages/Search/constants';

import { apiParams, extractOrder } from './apiAdapter';
import { ORDERS_SEARCH_RESULT_TEST_ID } from './constants';
import { useOrderColumnDefinitions } from './orderColumns';

interface Props {
  onRowClick: (event: MouseEvent<HTMLElement>, rowData: Order) => void;
  onSuccess: (order: Order | undefined) => void;
  searchParams: any;
}

export const OrdersSearchResults = ({
  onRowClick,
  searchParams,
  onSuccess
}: Props) => {
  const {
    currentData: { data: orderRowData } = {},
    error,
    isFetching,
    isSuccess
  } = useFetchOrderByUuidOrInpayExternalReferenceQuery(apiParams(searchParams));

  const columnDefinitions = useOrderColumnDefinitions();

  useEffect(() => {
    const noResults = error && 'status' in error && error.status == 404;
    if (!isFetching && (isSuccess || noResults)) {
      onSuccess(orderRowData && extractOrder(orderRowData));
    }
  }, [isFetching, isSuccess, orderRowData, error]);

  return (
    <>
      {error && 'data' in error && error.status !== 404 ? (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{(error.data as ServerError).error}</div>
        </div>
      ) : null}

      {orderRowData ? (
        <DataTable
          columns={columnDefinitions}
          name={TABLE_NAME.SEARCH_RESULT_COLLECTION_ORDER}
          className="h-auto"
          topBar="name"
          data={[orderRowData]}
          isFetching={isFetching}
          onRowClick={onRowClick}
          data-testid={ORDERS_SEARCH_RESULT_TEST_ID}
        />
      ) : null}

      {isFetching && (
        <div
          role="progressbar"
          aria-label="searching orders"
          className="flex h-40 w-full items-center justify-center"
        >
          Searching orders
        </div>
      )}
    </>
  );
};
