import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps } from '@organisms/DataTable';

import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { useFetchBatchesQuery } from 'redux/api/payouts/batchesApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { APPROVAL_STATUSES, ApprovalStatus } from 'models/ApprovalWorkFlow';
import { Batch } from 'models/batches';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { Loading } from 'components/pages/Loading';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';
import { TableLayout } from 'components/ui/templates/TableLayout';

import { apiParams } from './apiAdapter';
import { useBatchColumns } from './batchColumns';
import { BatchDetail } from './BatchDetail';
import { BatchesHeader } from './BatchesHeader';
import { BATCHES_TABLE_TEST_ID } from './constants';
import { FiltersState, useLocalState } from './localState';

const routes = [
  {
    path: ':batchId',
    element: <BatchDetail />
  }
];

const statusLabels = [
  { key: 'waiting_review', label: 'Pending approval' },
  { key: 'completed', label: 'Approved' },
  { key: 'rejected', label: 'Declined' }
];

export const Batches = () => {
  const organisationId = useSelector(selectOrganisationId);
  if (!organisationId) throw new Error('Missing organisation id');

  const { '*': batchId } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();

  const [pagination, setPagination] = useState<PaginatorProps>();

  const {
    isLoading: isLoadingOrgConfiguration,
    isError: isOrgConfigurationError,
    data: { data: orgSettings } = {}
  } = useFetchOrganisationConfigurationsQuery(organisationId);

  const isApprovalEnabled = (orgSettings?.batch_number_of_approvers || 0) > 0;

  const { state, dispatch, setCursorAfter, setCursorBefore, setFilters } =
    useLocalState(isApprovalEnabled, {
      cursorAfter: batchId,
      includeCursor: !!batchId
    });

  useEffect(() => {
    const status = searchParams.get('status') as ApprovalStatus;
    if (!status) return;

    const loadStatusFromQuery =
      isApprovalEnabled &&
      status &&
      state.filters.status === undefined &&
      APPROVAL_STATUSES.includes(status);

    if (loadStatusFromQuery) {
      dispatch(setFilters({ status: status as FiltersState['status'] }));
      dispatch(setCursorAfter(null));
      dispatch(setCursorBefore(null));
    }
  }, [search]);

  const {
    value: isCreateBatchDialogVisible,
    toggle: toggleCreateBatchDialog,
    setValue: setIsCreateBatchDialogVisible
  } = useBoolean(false);
  const {
    value: isUploadDialogVisible,
    toggle: toggleUploadDialog,
    setValue: setIsCreateUploadDialogVisible
  } = useBoolean(false);

  useEffect(() => {
    if (isApprovalEnabled && state.filters.status === undefined)
      dispatch(setFilters({ status: 'waiting_review' }));
  }, [isApprovalEnabled]);

  const {
    data: { data: batches = [], meta } = {},
    isFetching,
    error
  } = useFetchBatchesQuery(
    apiParams({
      organisationId: organisationId.toString(),
      filters: {
        ...state.filters,
        status: state.filters.status
      },
      sorting: state.sorting,
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore,
      includeCursor: state.includeCursor
    })
  );

  useEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...state.filters,
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [batchId, state.filters.status, state.cursorAfter, state.cursorBefore]);

  const batchColumnDefinitions = useBatchColumns({ isApprovalEnabled });

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  const tableName = useMemo(() => {
    return isApprovalEnabled
      ? statusLabels?.find((l) => l.key === state.filters.status)?.label
      : 'Count';
  }, [isApprovalEnabled, state.filters.status]);

  if (!orgSettings || isLoadingOrgConfiguration) return <Loading />;

  const nextPage = () => {
    if (meta?.cursor_next) {
      if (batchId) navigate({ pathname: '.', search: location.search });
      dispatch(setCursorAfter(meta.cursor_next));
    }
  };

  const prevPage = () => {
    if (meta?.cursor_prev) {
      if (batchId) navigate({ pathname: '.', search: location.search });
      dispatch(setCursorBefore(meta.cursor_prev));
    }
  };

  const onRowClick = (
    _event: React.MouseEvent<HTMLElement>,
    rowData: Batch
  ) => {
    if (!isApprovalEnabled) {
      navigate(`/org/${organisationId}/batches/${rowData.id}/batch_payouts`);
    } else {
      if (rowData.id.toString() === batchId) {
        navigate({ pathname: '.', search: location.search });
      } else {
        navigate({
          pathname: rowData.id.toString(),
          search: location.search
        });
      }
    }
  };

  const onTabClick = (
    tabValue: 'completed' | 'rejected' | 'waiting_review'
  ) => {
    dispatch(setFilters({ status: tabValue }));
    dispatch(setCursorAfter(null));
    dispatch(setCursorBefore(null));
    if (batchId) navigate({ pathname: '.' });
  };

  const headerProps = {
    toggleCreateBatchDialog,
    isCreateBatchDialogVisible,
    setIsCreateBatchDialogVisible,
    toggleUploadDialog,
    isUploadDialogVisible,
    setIsCreateUploadDialogVisible,
    isApprovalEnabled,
    onTabClick,
    state
  };

  return (
    <TableLayout header={<BatchesHeader {...headerProps} />}>
      {isOrgConfigurationError && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>Something went wrong</div>
        </div>
      )}
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{error.error}</div>
        </div>
      )}
      <DetailedLayout routes={routes}>
        <DataTable
          data-testid={BATCHES_TABLE_TEST_ID}
          columns={batchColumnDefinitions}
          isFetching={isFetching}
          name={tableName}
          topBar="name-with-count"
          data={batches}
          currentRow={{
            value: batchId,
            field: 'id'
          }}
          onRowClick={onRowClick}
          total={meta?.total}
          pagination={{
            ...pagination,
            onClickNext: nextPage,
            onClickPrev: prevPage
          }}
        />
      </DetailedLayout>
      {(isFetching || isLoadingOrgConfiguration) && <LoadingOverlay />}
    </TableLayout>
  );
};
