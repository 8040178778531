import { NewUser, Roles, User } from 'models/user';

import { customerApi } from './customerApi';

interface UpdateUserRequest {
  data: User;
  organisationId: number;
}
interface CreateUserRequest {
  data: NewUser;
  organisationId: number;
}

interface UserResponse {
  data: User;
}

export const usersApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    updateUser: builder.mutation<UserResponse, UpdateUserRequest>({
      query: (params) => {
        return {
          url: `/v1/customer/organisations/${params.organisationId}/users/${params.data.id}`,
          method: 'PUT',
          body: params
        };
      },
      invalidatesTags: ['Users']
    }),
    createUser: builder.mutation<UserResponse, CreateUserRequest>({
      query: (params) => {
        return {
          url: `/v1/customer/organisations/${params.organisationId}/users`,
          method: 'POST',
          body: params
        };
      },
      invalidatesTags: ['Users']
    }),
    fetchUser: builder.query<UserResponse, any>({
      query: ({ id, organisationId }) =>
        `/v1/customer/organisations/${organisationId}/users/${id}`,
      providesTags: ['Users']
    }),
    fetchUsers: builder.query<
      {
        data: Array<User>;
        meta: {
          total: number;
        };
      },
      {
        organisationId: number;
      }
    >({
      query: ({ organisationId }) =>
        `/v1/customer/organisations/${organisationId}/users`,
      providesTags: ['Users']
    }),
    getRoles: builder.query<
      {
        data: Array<Roles>;
      },
      void
    >({
      query: () => '/v1/customer/roles',
      providesTags: ['Users']
    })
  })
});

export const {
  useFetchUsersQuery,
  useFetchUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useGetRolesQuery
} = usersApi;
