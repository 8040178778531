import { Link } from 'react-router-dom';

import { CheckCircleIcon } from 'components/ui/atoms/Icon';

export const UpdatePasswordSuccess = () => {
  return (
    <div className="w-80 text-center">
      <CheckCircleIcon className="mx-auto mb-4 h-12" />
      <h1 className="mb-10 text-center text-[2rem] font-medium tracking-[0.01rem]">
        New password created
      </h1>
      <p>You can now log in</p>
      <p>with your new password.</p>
      <Link className="inpay-link mt-20 inline-block font-medium" to="/login">
        Back to login page
      </Link>
    </div>
  );
};
