import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryWithReauth } from './baseApi';

export const customerApi = createApi({
  reducerPath: 'customerApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Organisations',
    'Payouts',
    'VirtualAccounts',
    'Orders',
    'Payments',
    'Stats',
    'Transactions',
    'Reports',
    'BatchTemplates',
    'Batches',
    'Currencies',
    'PayoutDraft',
    'BusinessUnits',
    'Users',
    'FeatureFlags',
    'WithdrawalRequests'
  ],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<any, void>({
      query: () => '/v1/customer/current_user'
    })
  })
});

export const { useGetCurrentUserQuery, useLazyGetCurrentUserQuery } =
  customerApi;
