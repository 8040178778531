import cn from 'classnames';

import { Restricted } from 'containers/Restricted';

import { OrgLink } from 'components/ui/atoms/OrgLink';

import { SidebarItemProps } from './SidebarItem';

export interface SidebarSingleItemProps extends SidebarItemProps {
  isActive: boolean;
}

export const SidebarSingleItem = ({
  item,
  isActive,
  'data-testid': dataTestId
}: SidebarSingleItemProps) => {
  const Icon = item.icon;

  return (
    <Restricted requiredPermission={item.requiredPermission}>
      <OrgLink
        to={item.path}
        title={item.title}
        aria-label={item.title}
        aria-current={isActive ? 'page' : undefined}
        className={cn(
          'flex h-10 items-center rounded-lg px-2.5 transition-width duration-300 ease-in-out',
          {
            'bg-inpay-green-primary-mixes-60%-secondary': isActive,
            'hover:bg-inpay-dark-green-1000': !isActive
          }
        )}
        data-testid={dataTestId}
      >
        <Icon className="size-5 shrink-0 scale-110 stroke-1" />
        <div className="overflow-hidden">
          <span className="inline-block whitespace-nowrap p-2">
            {item.title}
          </span>
        </div>
      </OrgLink>
    </Restricted>
  );
};
