import { MouseEvent, useEffect } from 'react';

import { DataTable } from '@organisms/DataTable';

import { useFetchPaymentByUuidOrInpayUniqReferenceQuery } from 'redux/api/collections/payments';
import { ServerError } from 'redux/api/errors';

import { Payment } from 'models/payment';

import { TABLE_NAME } from 'components/pages/Search/constants';

import { apiParams } from './apiAdapter';
import { PAYMENTS_SEARCH_RESULT_TEST_ID } from './constants';
import { usePaymentColumns } from './paymentColumns';

interface Props {
  onRowClick: (event: MouseEvent<HTMLElement>, rowData: PaymentRow) => void;
  onSuccess: (payment: Payment | undefined) => void;
  searchParams: any;
}

export interface PaymentRow extends Payment {
  amountWithCurrency?: {
    amount: number;
    currency: string;
  };
}

export const PaymentsSearchResults = ({
  onRowClick,
  searchParams,
  onSuccess
}: Props) => {
  const {
    data: { data: paymentRowData } = {},
    error,
    isFetching,
    isSuccess
  } = useFetchPaymentByUuidOrInpayUniqReferenceQuery(apiParams(searchParams));

  const columns = usePaymentColumns();

  useEffect(() => {
    const noResults = error && 'status' in error && error.status == 404;
    if (!isFetching && (isSuccess || noResults)) {
      onSuccess(paymentRowData);
    }
  }, [isFetching, isSuccess, paymentRowData]);

  return (
    <>
      {error && 'data' in error && error.status !== 404 ? (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{(error.data as ServerError).error}</div>
        </div>
      ) : null}

      {paymentRowData ? (
        <DataTable
          columns={columns}
          className="h-auto"
          name={TABLE_NAME.SEARCH_RESULT_COLLECTION_PAYMENT}
          topBar="name"
          data={[paymentRowData]}
          isFetching={isFetching}
          onRowClick={onRowClick}
          data-testid={PAYMENTS_SEARCH_RESULT_TEST_ID}
        />
      ) : null}

      {isFetching && (
        <div
          role="progressbar"
          aria-label="searching payments"
          className="flex h-40 w-full items-center justify-center"
        >
          Searching payments
        </div>
      )}
    </>
  );
};
