import { TrashIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBoolean } from 'usehooks-ts';

import { hasValue } from 'utils/hasValue';

import { REVIEWER_ROLES } from 'constants/roles';

import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import {
  useApproveOrDeclineBatchMutation,
  useFetchBatchQuery
} from 'redux/api/payouts/batchesApi';
import { useFetchUserQuery } from 'redux/api/usersApi';
import { selectUserId } from 'redux/slices/authSlice';

import { ApprovalAction, Reviewer } from 'models/ApprovalWorkFlow';

import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { LinkWithQuery } from '@atoms/LinkWithQuery';

import { DeclineDialog } from '@molecules/DeclineDialog';
import { FormattedDateTime } from '@molecules/FormattedDateTime';

export const BatchDetail = () => {
  const userId = useSelector(selectUserId);
  const { batchId, organisationId } = useParams();

  const navigate = useOrgNavigate();

  const {
    value: isDeclineDialogVisible,
    toggle: toggleDeclineDialog,
    setValue: setIsDeclineDialogVisible
  } = useBoolean(false);

  const { data: { data: batchDetails } = {}, isLoading } = useFetchBatchQuery({
    organisationId,
    id: batchId
  });
  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: userData } = {} } = useFetchUserQuery({
    organisationId,
    id: userId
  });

  const [approveOrDeclineBatch] = useApproveOrDeclineBatchMutation();

  if (!hasValue(orgSettings) || !hasValue(organisationId) || !hasValue(batchId))
    return null;

  const isApprovalEnabled = orgSettings.batch_number_of_approvers > 0;

  if (!isApprovalEnabled) {
    navigate('/batches');
  }

  const requiredNumberOfApprovals = orgSettings.batch_number_of_approvers;

  const remainingApprovals = batchDetails?.approvals
    ? requiredNumberOfApprovals - batchDetails.approvals.length
    : requiredNumberOfApprovals;

  const isAdminReviewerOrOperationsMemberReviewer: boolean =
    hasValue(userData?.role) && REVIEWER_ROLES.includes(userData?.role ?? '');

  const isApprovedByUser = batchDetails?.approvals?.some(
    (approval: Reviewer) => approval.user_id === userId
  );

  const navigateToPayoutBatches = (batchId: number) => {
    navigate(`/batches/${batchId}/batch_payouts`);
  };

  const handleApproveOrDecline = async (selectedAction: ApprovalAction) => {
    try {
      await approveOrDeclineBatch({
        organisationId: organisationId,
        batchId: batchId,
        action: selectedAction
      });
      if (selectedAction === 'approve') {
        if (remainingApprovals === 1) {
          // Redirect to approved page if the user is the last one to approve
          navigate('/batches?status=completed');
        } else {
          // Redirect to pending approval if other approvals are still required
          navigate(`/batches/${batchDetails?.id}?status=waiting_review`);
        }
        toast.success('Batch has been approved', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      } else if (selectedAction === 'reject') {
        toast.success('Batch has been declined', {
          position: 'top-right',
          icon: (
            <TrashIcon className="stroke-inpay-green-primary-mixes-80%-secondary" />
          ),
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
        navigate('/batches?status=rejected');
      }
    } catch (error) {
      toast.error('Error while approving/declining', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    }
  };

  const formatApprovalNames = (approvals: Reviewer[]) => {
    return approvals.map((approval) => {
      return (
        <div
          key={approval.user_id}
          className="border-inpay-gray-primary-300 flex items-center justify-between gap-3 border-b py-3 first-of-type:pt-0 last-of-type:border-0"
        >
          <div className="font-medium">
            {approval.user_id === userId ? 'You' : approval.user_name}
          </div>
          <div className="text-right">
            <FormattedDateTime value={approval.created_at} />
          </div>
        </div>
      );
    });
  };

  const hasApprovals: boolean = (batchDetails?.approvals?.length || 0) > 0;

  const canApprove =
    isApprovalEnabled &&
    isAdminReviewerOrOperationsMemberReviewer &&
    !isApprovedByUser &&
    batchDetails !== undefined &&
    batchDetails.status === 'waiting_review' &&
    !batchDetails.rejections?.length &&
    batchDetails.creator_id !== userId;

  if (!batchId) return null;

  return (
    <div
      role="complementary"
      aria-label="Batch details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="border-inpay-gray-primary-300 flex items-center justify-between border-b px-6 py-3.5 font-medium">
        <span>Details</span>
        <LinkWithQuery to=".." className="cursor-pointer" aria-label="Close">
          <XMarkIcon className="size-6 text-inpay-black-primary-400" />
        </LinkWithQuery>
      </div>
      {!isLoading && (
        // TODO (LTJ): Make PayoutDraftApprovalSection.tsx reusable and use here
        <div className="flex flex-col p-6">
          {batchDetails?.rejections && batchDetails.rejections.length > 0 ? (
            <div className="flex flex-col gap-3">
              <span className="mr-1 font-medium">Declined by: </span>
              {batchDetails.rejections.map((rejection) => (
                <div
                  key={rejection.user_id}
                  className="flex items-center justify-between gap-3 py-3 first-of-type:p-0"
                >
                  <div className="font-medium">
                    {rejection.user_id === userId ? 'You' : rejection.user_name}
                  </div>
                  <div className="text-right">
                    <FormattedDateTime value={rejection.created_at} />
                  </div>
                </div>
              ))}
              <div className="mb-6 rounded-md bg-inpay-gray-primary-200 px-4 py-2 text-sm">
                The batch is view-only. It can not be restored.
              </div>
            </div>
          ) : (
            batchDetails?.approvals && (
              <div className="flex flex-col">
                {hasApprovals && (
                  <div className="flex flex-col gap-3">
                    <span className="mr-1 font-medium">Approved by: </span>
                    {formatApprovalNames(batchDetails?.approvals)}
                  </div>
                )}
                {remainingApprovals !== 0 && (
                  <div className="mb-6 rounded-md bg-inpay-gray-primary-200 px-4 py-2 text-sm">
                    Needs to be approved by{' '}
                    {remainingApprovals === 1
                      ? '1 person.'
                      : `${remainingApprovals} people.`}
                  </div>
                )}
              </div>
            )
          )}

          <div className="border-inpay-gray-primary-300 flex justify-between border-t">
            <SecondaryButton
              onClick={() => {
                navigateToPayoutBatches(Number.parseInt(batchId));
              }}
              className="mt-6"
            >
              View Payouts
            </SecondaryButton>
            {canApprove && (
              <div className="mt-6 flex gap-4">
                <SecondaryButton onClick={toggleDeclineDialog}>
                  Decline
                </SecondaryButton>

                <PrimaryButton
                  onClick={() => handleApproveOrDecline('approve')}
                >
                  Approve
                </PrimaryButton>
              </div>
            )}
          </div>
          {isDeclineDialogVisible && (
            <DeclineDialog
              open={isDeclineDialogVisible}
              onClose={setIsDeclineDialogVisible}
              handleApproveOrDecline={handleApproveOrDecline}
              variant="batch"
            />
          )}
        </div>
      )}
    </div>
  );
};
