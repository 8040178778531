// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes BBqyw4meUVG323gqyTYj{0%{height:0}to{height:var(--radix-accordion-content-height)}}@keyframes EROrO7bZBgZAEryYolKa{0%{height:var(--radix-accordion-content-height)}to{height:0}}.Nc11HWs6DcEx8EFGHmqv[data-state=closed]{animation:EROrO7bZBgZAEryYolKa .3s ease-out forwards}.Nc11HWs6DcEx8EFGHmqv[data-state=open]{animation:BBqyw4meUVG323gqyTYj .3s ease-out forwards}", "",{"version":3,"sources":["webpack://./src/components/ui/organisms/DetailsBox/styles.module.css"],"names":[],"mappings":"AAAA,gCACE,GACE,QACF,CACA,GACE,4CACF,CACF,CACA,gCACE,GACE,4CACF,CACA,GACE,QACF,CACF,CAEA,yCACE,oDACF,CACA,uCACE,oDACF","sourcesContent":["@keyframes growDown {\n  from {\n    height: 0;\n  }\n  to {\n    height: var(--radix-accordion-content-height);\n  }\n}\n@keyframes growUp {\n  from {\n    height: var(--radix-accordion-content-height);\n  }\n  to {\n    height: 0;\n  }\n}\n\n.animated-content[data-state='closed'] {\n  animation: growUp 300ms ease-out forwards;\n}\n.animated-content[data-state='open'] {\n  animation: growDown 300ms ease-out forwards;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animated-content": "Nc11HWs6DcEx8EFGHmqv",
	"growUp": "EROrO7bZBgZAEryYolKa",
	"growDown": "BBqyw4meUVG323gqyTYj"
};
export default ___CSS_LOADER_EXPORT___;
