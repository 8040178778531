import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

export type PaginationState = {
  includeCursor?: boolean;
  cursorBefore?: string;
  cursorAfter?: string;
};

export type FiltersState = {
  state?: string;
  accountNumber?: string;
  reasonCode?: string;
  currency?: string;
  amountFrom?: string;
  amountTo?: string;
};
export type TabValue = 'completed' | 'rejected' | 'waiting_review' | undefined;

export type LocalState = {
  pagination: PaginationState;
  sort: string;
  filters: FiltersState;
  tab: TabValue;
};

export const initialState: LocalState = {
  pagination: {},
  sort: '-date',
  tab: undefined,
  filters: {
    state: undefined,
    accountNumber: undefined,
    reasonCode: undefined,
    currency: undefined,
    amountFrom: undefined,
    amountTo: undefined
  }
};

const slice = createSlice({
  name: 'pagesPayouts',
  initialState,
  reducers: {
    updateFilters: (
      state,
      { payload }: PayloadAction<LocalState['filters']>
    ) => {
      state.filters = { ...state.filters, ...payload };
      state.pagination = {};
    },
    setSort: (state, { payload }: PayloadAction<string>) => {
      state.sort = payload;
      state.pagination = {};
    },
    setTab: (
      state,
      {
        payload
      }: PayloadAction<'completed' | 'rejected' | 'waiting_review' | undefined>
    ) => {
      state.tab = payload;
      state.sort = initialState.sort; // Reset sort when changing the tab
      state.pagination = {}; // Reset pagination when changing the tab
    },
    setCursorBefore: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.pagination.cursorBefore = payload;
      state.pagination.cursorAfter = undefined;
      state.pagination.includeCursor = undefined;
    },
    setCursorAfter: (state, { payload }: PayloadAction<string | undefined>) => {
      state.pagination.cursorAfter = payload;
      state.pagination.cursorBefore = undefined;
      state.pagination.includeCursor = undefined;
    },
    setIncludeCursor: (state, { payload }: PayloadAction<boolean>) => {
      state.pagination.includeCursor = payload;
    },
    reset: () => initialState
  }
});

export const selectState = (state: RootState) => state.pagesPayouts;
export const selectSort = (state: RootState) => state.pagesPayouts.sort;
export const selectPagination = (state: RootState) =>
  state.pagesPayouts.pagination;
export const selectFilters = (state: RootState) => state.pagesPayouts.filters;

export const {
  reset,
  setSort,
  setTab,
  setCursorBefore,
  setCursorAfter,
  setIncludeCursor,
  updateFilters
} = slice.actions;

export default slice.reducer;
