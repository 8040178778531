import { useState } from 'react';

import { PrimaryButton } from './buttons/PrimaryButton';
import { CookieIcon } from './Icon';

export function CookieBanner() {
  const [showBanner, setShowBanner] = useState(
    !localStorage.getItem('cookiesAccepted')
  );

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  if (showBanner === false) return null;

  return (
    <div className="flex w-screen justify-center">
      <div
        id="cookie-banner"
        className="m-6 flex flex-row flex-wrap items-center justify-center gap-4 rounded-lg bg-inpay-gray-secondary-400 p-6 text-inpay-black-primary-600 lg:w-1/2 lg:flex-nowrap lg:justify-between"
      >
        <CookieIcon
          width={55}
          height={55}
          className="min-w-12"
          stroke="#004B4B"
        />
        <p>
          Our website uses cookies to enhance your experience. By continuing to
          use our website, you agree to our use of cookies. You cannot proceed
          without accepting cookies.
        </p>
        <div className="flex items-center whitespace-nowrap">
          <PrimaryButton
            className="w-full font-medium"
            type="submit"
            value="Submit"
            size="large"
            onClick={handleAcceptCookies}
          >
            I understand
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
