import { WithdrawalAccount, WithdrawalRequestsData } from 'models/withdrawal';

import { NoWithdrawalAccounts } from './NoWithdrawalAccounts';
import { NoWithdrawalRequests } from './NoWithdrawalRequests';
import { WithdrawalsTable } from './WithdrawalsTable';

interface Props {
  withdrawalsArePossible: boolean;
  withdrawalRequestsData: WithdrawalRequestsData[];
  withdrawalAccounts: WithdrawalAccount[];
  isWithdrawalAccountsError: boolean;
  isWithdrawalAccountsLoading: boolean;
  organisationId: number;
}

export const WithdrawalsContent = ({
  withdrawalsArePossible,
  withdrawalRequestsData,
  withdrawalAccounts,
  isWithdrawalAccountsError,
  isWithdrawalAccountsLoading,
  organisationId
}: Props) => {
  if (!withdrawalsArePossible) {
    return <NoWithdrawalAccounts />;
  }

  if (!withdrawalRequestsData.length) {
    return (
      <NoWithdrawalRequests
        withdrawalAccounts={withdrawalAccounts}
        isWithdrawalAccountsError={isWithdrawalAccountsError}
        isWithdrawalAccountsLoading={isWithdrawalAccountsLoading}
        organisationId={organisationId}
      />
    );
  }

  return <WithdrawalsTable withdrawalRequestsData={withdrawalRequestsData} />;
};
