import cn from 'classnames';

interface Props {
  value: string;
  className?: string;
}

export const FormattedIdentifier = ({ value, className = '' }: Props) => {
  if (!value) {
    return <p className="text-sm">N/A</p>;
  }

  return (
    <p className={cn(className)} aria-label={value} title={value}>
      <span>{value}</span>
    </p>
  );
};
