import { TrashIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useBoolean } from 'usehooks-ts';

import { hasValue } from 'utils/hasValue';

import { REVIEWER_ROLES } from 'constants/roles';

import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { useApproveOrDeclinePayoutMutation } from 'redux/api/payouts/payoutsApi';
import { useFetchUserQuery } from 'redux/api/usersApi';
import { selectUserId } from 'redux/slices/authSlice';

import { ApprovalAction, Reviewer } from 'models/ApprovalWorkFlow';
import { PayoutDraftDetailData } from 'models/payout';

import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { DeclineDialog } from '@molecules/DeclineDialog';
import { FormattedDateTime } from '@molecules/FormattedDateTime';

interface Props {
  payoutData: PayoutDraftDetailData;
  isLoading: boolean;
}

export const PayoutDraftApprovalSection = ({
  payoutData,
  isLoading
}: Props) => {
  const userId = useSelector(selectUserId);
  const { payoutId, organisationId } = useParams();

  const navigate = useOrgNavigate();

  const {
    value: isDeclineDialogVisible,
    toggle: toggleDeclineDialog,
    setValue: setIsDeclineDialogVisible
  } = useBoolean(false);

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: userData } = {} } = useFetchUserQuery({
    organisationId,
    id: userId
  });

  const [approveOrDeclinePayout] = useApproveOrDeclinePayoutMutation();

  if (!organisationId || !orgSettings || !payoutId || !userData?.role)
    return null;

  const isApprovalEnabled: boolean =
    (orgSettings?.manual_payout_number_of_approvers ?? 0) > 0;

  if (!isApprovalEnabled) {
    navigate('/payouts');
  }

  const requiredNumberOfApprovals =
    orgSettings.manual_payout_number_of_approvers ?? 0;

  const remainingApprovals: number =
    requiredNumberOfApprovals - (payoutData?.approvals?.length ?? 0);

  const isReviewer: boolean =
    hasValue(userData?.role) && REVIEWER_ROLES.includes(userData.role);

  const isApprovedByUser = payoutData?.approvals?.some(
    (approval: Reviewer) => approval.user_id === userId
  );

  const handleApproveOrDecline = async (selectedAction: ApprovalAction) => {
    try {
      await approveOrDeclinePayout({
        organisationId: organisationId,
        payoutId: payoutId,
        action: selectedAction
      });

      if (selectedAction === 'approve') {
        toast.success('Payout has been approved', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });

        if (remainingApprovals === 1) {
          // Redirect to approved page if the user is the last one to approve
          navigate('/payouts?status=completed');
        }
      } else if (selectedAction === 'reject') {
        toast.success('Payout has been declined', {
          position: 'top-right',
          icon: (
            <TrashIcon className="stroke-inpay-green-primary-mixes-80%-secondary" />
          ),
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
        navigate('/payout?status=rejected');
      }
    } catch (error) {
      toast.error('Error while approving/declining', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    }
  };

  const formatApprovalNames = (approvals: Reviewer[]) => {
    return approvals.map((approval) => {
      return (
        <div
          key={approval.user_id}
          className="border-inpay-gray-primary-300 flex items-center justify-between gap-3 border-b py-3 first-of-type:pt-0 last-of-type:border-0"
        >
          <div className="font-medium">
            {approval.user_id === userId ? 'You' : approval.user_name}
          </div>
          <div className="text-right">
            <FormattedDateTime value={approval.created_at} />
          </div>
        </div>
      );
    });
  };

  const hasApprovals: boolean = (payoutData?.approvals?.length || 0) > 0;

  const canApprove: boolean =
    isApprovalEnabled &&
    isReviewer &&
    !isApprovedByUser &&
    payoutData !== undefined &&
    payoutData.approval_status === 'waiting_review' &&
    !payoutData.rejections?.length &&
    payoutData.creator_id !== userId;

  return (
    <div aria-label="Payout details">
      {!isLoading && (
        <div className="flex flex-col pb-4">
          {payoutData?.rejections && payoutData.rejections.length > 0 ? (
            <div className="flex flex-col gap-3">
              <span className="mr-1 font-medium">Declined by: </span>
              {payoutData.rejections.map((rejection) => (
                <div
                  key={rejection.user_id}
                  className="flex items-center justify-between gap-3 py-3 first-of-type:p-0"
                >
                  <div className="font-medium">
                    {rejection.user_id === userId ? 'You' : rejection.user_name}
                  </div>
                  <div className="text-right">
                    <FormattedDateTime value={rejection.created_at} />
                  </div>
                </div>
              ))}
              <div className="mb-6 rounded-md bg-inpay-gray-primary-200 px-4 py-2 text-sm">
                The payout is view-only. It can not be restored.
              </div>
            </div>
          ) : (
            payoutData?.approvals && (
              <div className="flex flex-col">
                {hasApprovals && (
                  <div className="flex flex-col gap-3">
                    <span className="mr-1 font-medium">Approved by: </span>
                    {formatApprovalNames(payoutData?.approvals)}
                  </div>
                )}
                {remainingApprovals !== 0 && (
                  <div className="mb-6 rounded-md bg-inpay-gray-primary-200 px-4 py-2 text-sm">
                    Needs to be approved by{' '}
                    {remainingApprovals === 1
                      ? '1 more person.'
                      : `${remainingApprovals} more people.`}
                  </div>
                )}
              </div>
            )
          )}

          <div className="border-inpay-gray-primary-300 flex justify-end border-t">
            {canApprove && (
              <div className="mt-6 flex gap-4">
                <SecondaryButton onClick={toggleDeclineDialog}>
                  Decline
                </SecondaryButton>

                <PrimaryButton
                  onClick={() => handleApproveOrDecline('approve')}
                >
                  Approve
                </PrimaryButton>
              </div>
            )}
          </div>
          {isDeclineDialogVisible && (
            <DeclineDialog
              open={isDeclineDialogVisible}
              onClose={setIsDeclineDialogVisible}
              handleApproveOrDecline={handleApproveOrDecline}
              variant="payout"
            />
          )}
        </div>
      )}
    </div>
  );
};
