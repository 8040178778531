import { Dialog, DialogPortal } from '@radix-ui/react-dialog';

import { Content, Overlay } from '@organisms/Dialog';

import { ApprovalAction } from 'models/ApprovalWorkFlow';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { TextButton } from '@atoms/buttons/TextButton';

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  handleApproveOrDecline: (selectedAction: ApprovalAction) => Promise<void>;
  variant: 'batch' | 'payout';
}

export const DeclineDialog = ({
  open,
  onClose,
  handleApproveOrDecline,
  variant
}: Props) => {
  const handleDecline = async () => {
    await handleApproveOrDecline('reject');
    onClose(false);
  };

  const onDialogClose = (value: boolean) => {
    onClose(value);
  };

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content>
            <div className="w-96 rounded-lg bg-inpay-gray-primary-200 p-6">
              <div className="flex flex-col gap-8">
                <div className="flex flex-col gap-4">
                  <div className="text-lg font-bold">Decline {variant}?</div>
                  <div>
                    {variant === 'batch'
                      ? `This ${name} will be declined and the enclosed payouts will
                    not be processed.`
                      : `This ${name} will be declined and will
                    not be processed.`}
                  </div>
                </div>
                <div className="flex flex-row-reverse gap-6">
                  <TextButton onClick={() => onClose(false)}>Cancel</TextButton>
                  <PrimaryButton size="large" onClick={() => handleDecline()}>
                    Decline
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
