import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AccountWidget } from '@organisms/AccountWidget';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';

import { VirtualAccount } from 'models/virtualAccount';

import { AccountWidgetSkeleton } from '@molecules/AccountWidgetSkeleton';

const MIN_LOADING_TIME_MS = 500;

interface Props {
  classNames?: string;
}

export const DashboardAccountWidgets = ({ classNames }: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { data, error, isSuccess, isLoading, requestId } =
    useFetchVirtualAccountsQuery({ organisationId: organisationId });
  const accountsInfo = data?.data ? data.data : [];

  const [isReadyToRender, setIsReadyToRender] = useState(false);

  useEffect(() => {
    setIsReadyToRender(false);
    const timeoutTimer = setTimeout(() => {
      setIsReadyToRender(true);
    }, MIN_LOADING_TIME_MS);
    return () => clearTimeout(timeoutTimer);
  }, [requestId]);

  const isAccountsInformationAvailable: boolean = isSuccess && isReadyToRender;
  const showLoading: boolean = isLoading || !isReadyToRender;
  const showError: boolean = !!error && isReadyToRender;
  const hasAccounts: boolean = accountsInfo && accountsInfo.length > 0;

  return (
    <div className={classNames}>
      {isAccountsInformationAvailable &&
        (hasAccounts ? (
          <section
            className="flex flex-col flex-wrap gap-2"
            aria-label="Account balances"
          >
            {accountsInfo.map((accountInfo: VirtualAccount, index) => (
              <AccountWidget key={index} accountInfo={accountInfo} />
            ))}
          </section>
        ) : (
          <div className="w-full rounded-lg bg-white p-8 text-center shadow-plain-lg">
            <h2 className="text-xl font-medium">No data found</h2>
            <p className="mt-6">There are not yet accounts available.</p>
          </div>
        ))}
      {showLoading && (
        <div role="progressbar" className="mb-4 flex flex-wrap gap-4 ">
          <AccountWidgetSkeleton />
          <AccountWidgetSkeleton />
          <AccountWidgetSkeleton />
        </div>
      )}
      {showError && (
        <div className="flex h-40 w-full items-center justify-center rounded-lg bg-white p-4 text-xs shadow-plain-lg">
          Accounts information is temporarily unavailable.
        </div>
      )}
    </div>
  );
};
