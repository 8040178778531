import { ReactNode } from 'react';

export const TableLayout = ({
  header,
  children
}: {
  header?: ReactNode;
  children: ReactNode;
}) => {
  return (
    // TODO (LTJ): Pass the TopBar height to height calculation dynamically
    //  We are unable to use Outlet contexts because of multiple outlets in `<Require... />`
    //  We can create a custom context provider, or a new `layoutSlice` in `redux`
    <div className="flex flex-col overflow-auto px-8">
      <div className="my-6">{header}</div>
      <div>{children}</div>
    </div>
  );
};
