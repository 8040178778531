import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { VirtualAccountSummary } from 'redux/api/accounts/virtualAccountApi';

import { AccountDetailsField } from './types';

export const formatPayloadToDetails = (
  accountData: VirtualAccountSummary
): Array<AccountDetailsField> => {
  const moneyFormatter = new MoneyFormatter({
    currency: accountData.currency_code
  });
  return [
    {
      name: 'Closing',
      amount: moneyFormatter.format(Number(accountData.closing_balance))
    },
    {
      name: 'Opening',
      amount: moneyFormatter.format(Number(accountData.opening_balance))
    },
    {
      name: 'Current',
      amount: moneyFormatter.format(Number(accountData.current_balance))
    }
  ];
};
