import { FormEvent, useState } from 'react';

import AuthyIcon from 'assets/images/authy-icon.svg';

import { useRegisterMfaUserMutation } from 'redux/api/authenticationApi';
import { useLazyGetCurrentUserQuery } from 'redux/api/customerApi';

import { CellPhoneField } from '@atoms/CellPhoneField/CellPhoneField';

interface Props {
  onSuccess: () => void;
}

interface Error {
  title: string;
}

interface ErrorResponse {
  data: {
    errors?: Array<Error>;
    error?: string;
  };
}

export const MfaSetupForm = ({ onSuccess }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [cellphone, setCellphone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');
  const [fetchUser] = useLazyGetCurrentUserQuery();
  const [registerMfaUser] = useRegisterMfaUserMutation();

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (isLoading) {
      return;
    }

    setIsLoading(true);
    setErrors([]);

    try {
      await registerMfaUser({ cellphone, country_code: countryCode }).unwrap();
      await fetchUser().unwrap();

      onSuccess();
    } catch (error) {
      const { data } = error as ErrorResponse;

      if (data.errors) {
        setErrors(data.errors.map((e) => e.title));
      } else if (data.error) {
        setErrors([data.error]);
      } else {
        setErrors(['Unknow error']);
      }
    }

    setIsLoading(false);
  };

  const updatePhone = (countryCode: string, cellPhone: string): void => {
    setCountryCode(countryCode);
    setCellphone(cellPhone);
  };

  return (
    <div className="flex max-w-xl flex-col">
      <h1 className="pb-1 text-center text-2xl font-medium md:pb-4 md:text-4xl">
        <AuthyIcon className="mr-3 inline size-9" />
        <br className="md:hidden" />
        Setup multi-factor authentication
      </h1>
      <h2 className="mb-5 text-center text-sm font-normal md:text-xl">
        To keep your Inpay account secure, it is required to enable multi factor
        authentication.
      </h2>

      <form className="md:grid md:grid-cols-10" onSubmit={handleSubmit}>
        <div className="md:col-span-7">
          <CellPhoneField label="Cellphone" onChange={updatePhone} />
        </div>
        <div className="md:col-span-3 md:ml-4">
          <button
            className="mt-3 h-14 w-full rounded-md bg-inpay-green-primary-1000 px-4 text-sm font-medium text-white focus:outline-none disabled:bg-inpay-black-primary-400 md:mt-7"
            disabled={isLoading}
            type="submit"
          >
            Enable MFA
          </button>
        </div>
      </form>
      {errors.length > 0 && (
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      )}
    </div>
  );
};
