import { PayoutsFilters } from '@organisms/PayoutsFilters/PayoutsFilters';

import { FiltersState } from 'redux/slices/pages.payoutsSlice';

import { useLocalState } from './localState';

export const FiltersSection = () => {
  const { state, updateFilters } = useLocalState({});

  const onFilterChange = (filters: FiltersState) => {
    updateFilters(filters);
  };

  return (
    <div className="flex max-h-full w-full flex-1 flex-col space-y-4">
      <PayoutsFilters onFilterChange={onFilterChange} filters={state.filters} />
    </div>
  );
};
