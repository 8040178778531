import { hasValue } from 'utils/hasValue';

import { convertRoleToLabel } from 'constants/roles';

import { type User } from 'models/user';

import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

export interface UserDetailsProps {
  data: User;
  onEditClick?: () => void | undefined;
}

export const UserDetailsShow = ({ data, onEditClick }: UserDetailsProps) => {
  return (
    <div className="flex flex-col gap-6 p-6">
      <div>
        <label className="text-xs">Name</label>
        <div className="font-medium">{data.first_name}</div>
      </div>
      <div>
        <label className="text-xs">Last Name</label>
        <div className="font-medium">{data.last_name}</div>
      </div>
      <div>
        <label className="text-xs">Email</label>
        <div className="font-medium">{data.email}</div>
      </div>
      {hasValue(data.role) ? (
        <div>
          <label className="text-xs">Role</label>
          <div className="font-medium">{convertRoleToLabel(data.role)}</div>
        </div>
      ) : null}
      {hasValue(data.created_at) ? (
        <div>
          <label className="text-xs">Created</label>
          <FormattedDate value={data.created_at} className="font-medium" />
        </div>
      ) : null}
      <div className="border-inpay-gray-primary-300 border-t">
        <SecondaryButton
          size="large"
          type="submit"
          onClick={onEditClick}
          className="float-right mt-4"
        >
          Edit Profile
        </SecondaryButton>
      </div>
    </div>
  );
};
