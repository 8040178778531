export type ApprovalAction = 'approve' | 'reject';

export const APPROVAL_STATUSES = [
  'completed',
  'waiting_review',
  'rejected'
] as const;

export type ApprovalStatus = (typeof APPROVAL_STATUSES)[number];

export interface Reviewer {
  user_id: number;
  user_name: string;
  created_at: string;
  approved: boolean;
}
