import cn from 'classnames';

import styles from './styles.module.css';

const inRange = (
  time: number,
  fromTime?: number,
  toTime?: number,
  hoveredDate?: Date
) => {
  if (!fromTime && !toTime) return false;

  if (fromTime && toTime) {
    return fromTime < time && toTime > time;
  } else {
    const selectedDate = (fromTime || toTime) as number;
    return (
      hoveredDate &&
      ((selectedDate < time && hoveredDate.getTime() > time) ||
        (time < selectedDate && time > hoveredDate.getTime()))
    );
  }
};

export const DayOfMonth = ({
  selectable,
  hoveredDate,
  outsideDay,
  dayIndex,
  date,
  selectedRange,
  ...props
}: any) => {
  const time = date.getTime();
  const fromTime = selectedRange.from?.getTime();
  const toTime = selectedRange.to?.getTime();
  const isHovered = hoveredDate && time == hoveredDate.getTime();

  const isFirst =
    fromTime && toTime
      ? fromTime == time
      : (fromTime == time && hoveredDate && hoveredDate.getTime() >= time) ||
        (isHovered && time < fromTime);
  const isLast =
    fromTime && toTime
      ? toTime == time
      : (fromTime == time && hoveredDate && hoveredDate.getTime() <= time) ||
        (isHovered && time > fromTime);
  const isInRange = inRange(time, fromTime, toTime, hoveredDate);
  const isInFullRange = isInRange || isFirst || isLast;

  return (
    <button
      {...props}
      className={cn('relative h-8 text-center font-normal', {
        'bg-inpay-red-200': !selectable,
        [`${styles['day']} bg-inpay-green-secondary-light-200`]: isInRange,
        'text-inpay-green-primary-1000': isInFullRange,
        'text-inpay-black-primary-400': outsideDay && !isInFullRange,
        'text-inpay-orange-1000': dayIndex > 4 && !isInFullRange,
        'rounded-l-full bg-inpay-green-primary-1000 !text-white': isFirst,
        'rounded-r-full bg-inpay-green-primary-1000 !text-white': isLast
      })}
    >
      {selectable ? date.getDate() : 'X'}
    </button>
  );
};
