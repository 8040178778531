import { XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { formatHours } from 'utils/dayjs';
import { getCountryName } from 'utils/formatters/getCountryName';
import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { DetailsBox, Item } from '@organisms/DetailsBox';

import { payoutsApi } from 'redux/api/payouts/payoutsApi';

import {
  IReasonCode,
  ReasonCode,
  Metadata as ReasonCodeMetadata
} from 'models/reasonCode';

import { Badge } from '@atoms/badges/Badge/Badge';
import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';
import { FormattedIdentifier } from '@atoms/FormattedIdentifier/FormattedIdentifier';
import { LinkWithQuery } from '@atoms/LinkWithQuery';

import { Loading } from 'components/pages/Loading';

type PayoutSummary = {
  [key: string]: {
    [key: string]: ReactElement;
  };
};

const PAYOUT_SUMMARIES_PER_STATUS: PayoutSummary = {
  rejected: {
    default: <p>Sorry, we could not process this payout request.</p>
  },
  pending: {
    default: <p>The payout request is Pending waiting future execution.</p>
  },
  returned: {
    default: <p>This payout has been returned.</p>
  },
  action_required: {
    awaiting_kyc_documents: (
      <div>
        <p className="mb-4">
          Due to our anti-fraud/AML and general compliance obligations, we
          kindly ask you to send us the following documents to process the
          payment.
        </p>
        <p className="mb-4">
          Supporting invoice/collaboration agreement/other relevant
          documentation if the remitter or beneficiary, or both are business
          entities.
        </p>
        <p className="mb-4">
          POI (proof of identity such as copy of a passport, ID card) and POA
          (proof of residence such as a utility bill or account statement) if
          the remitter/ beneficiary is an individual.
        </p>
      </div>
    ),
    awaiting_extended_kyc: (
      <div>
        <p className="mb-4">
          Due to our anti-fraud/AML and general compliance obligations, we
          kindly ask you to provide us with additional information about this
          payment:
        </p>
        <ol className="mb-4 ml-4 list-decimal">
          <li className="mb-4">
            What is the relationship between the ordering party and the
            beneficiary? Please advise the detailed purpose of the payment
            including full details of the underlying transaction.
          </li>
          <li className="mb-4">
            What is the source of funds and from which country do the funds
            originate?
          </li>
          <li>
            Supporting invoice/collaboration agreement/other relevant
            documentation if the remitter or beneficiary, or both are business
            entities.
          </li>
        </ol>
      </div>
    )
  }
};
const amountFormatter = new MoneyFormatter();

const reasonCodeToSection: { [key: string]: string } = {
  invalid_creditor: 'receiver',
  invalid_ultimate_debtor: 'sender',
  invalid_creditor_account: 'receiver-bank',
  invalid_general_data: 'payout-request',
  missing_capability: 'payout-request',
  waiting_future_execution: 'payout-request'
};

export const PayoutDetails = () => {
  const { payoutId } = useParams();
  const {
    data: { data: payoutData } = {},
    isLoading,
    isError,
    isSuccess
  } = payoutsApi.useFetchPayoutQuery({ reference: payoutId });

  const reasonCodes: ReasonCode[] = payoutData
    ? payoutData.reason_codes.map((attrs: IReasonCode) => {
        const meta: ReasonCodeMetadata = {
          requestedExecutionDate: payoutData.requested_execution_date
        };

        return new ReasonCode(attrs, payoutData.status, meta);
      })
    : [];

  const hasReasonCodes = !!payoutData && reasonCodes.length > 0;
  const statusesWithSummaries = Object.keys(PAYOUT_SUMMARIES_PER_STATUS);
  const hasPayoutSummary = statusesWithSummaries.includes(payoutData?.status);

  const [activeSection, setActiveSection] = useState(() => {
    return 'payout-request';
  });
  useEffect(() => {
    if (hasReasonCodes) {
      setActiveSection('summary');
    }
  }, [hasReasonCodes]);

  const getPayoutSummary = (): ReactElement | undefined => {
    const { status } = payoutData;
    let summaryKey = 'default';

    if (status) {
      if (status === 'action_required') {
        const isExtendedKyc = reasonCodes.find(
          (reasonCode) => reasonCode.symbol === 'awaiting_extended_kyc'
        );

        summaryKey = isExtendedKyc
          ? 'awaiting_extended_kyc'
          : 'awaiting_kyc_documents';
      }

      return PAYOUT_SUMMARIES_PER_STATUS[status]?.[summaryKey];
    }
  };

  // This method gets the error with the current error structure we are sending.
  // It might be change after this task is done:
  // https://inpay.atlassian.net/browse/API-919
  // ATT. @dmuneras
  const getAttributeErrors = (section: string, attrName: string) => {
    const { errors } = payoutData;
    const sectionErrors = errors?.[section];

    if (sectionErrors) {
      const relevantError = sectionErrors.find(
        (error: any) => error.pointer === attrName
      );

      return relevantError ? relevantError.details : [];
    }

    return [];
  };

  // This method gets the error with the current error structure we are sending.
  // It might be change after this task is done:
  // https://inpay.atlassian.net/browse/API-919
  // Currently it collects all errors with pointer general to show them on top
  // since they are not related to any field.
  // ATT. @dmuneras
  const getGeneralErrors = () => {
    const { errors } = payoutData;
    let generalErrorDetails: Array<string> = [];

    for (const reasonCode in errors) {
      const reasonCodeErrors = errors?.[reasonCode];

      if (reasonCodeErrors) {
        const errors = reasonCodeErrors.find(
          (error: any) => error.pointer === 'general'
        );

        if (errors?.details) {
          generalErrorDetails = generalErrorDetails.concat(errors.details);
        }
      }
    }

    return generalErrorDetails;
  };

  const connectionSumToErr = (reasonCode: string) => {
    const section = reasonCodeToSection[reasonCode];
    if (section) setActiveSection(section);
  };

  const data = useMemo(() => {
    if (isSuccess) {
      const sections: Item[] = [];
      if (hasPayoutSummary) {
        sections.push({
          header: 'Summary',
          key: 'summary',
          data: [],
          customChildren: (
            <div className="pb-6">
              <div className="reason-codes mb-4">
                {hasReasonCodes && (
                  <div className="flex flex-wrap gap-2">
                    {reasonCodes.map((reasonCode) => {
                      return (
                        <Badge
                          label={reasonCode.label()}
                          className={cn(reasonCode.visualTheme(), {
                            'cursor-pointer':
                              Object.keys(payoutData.errors).length > 0 ||
                              reasonCode.hasWaitingForFutureExecutionSymbol()
                          })}
                          onClick={(e) => {
                            e.stopPropagation();
                            connectionSumToErr(reasonCode.symbol);
                          }}
                          key={reasonCode.symbol}
                        />
                      );
                    })}
                  </div>
                )}
                {!hasReasonCodes && (
                  <Badge
                    key="unknown"
                    label="Unknown"
                    className={ReasonCode.DEFAULT_THEME}
                  />
                )}
              </div>
              <div className="summary"></div>
              {getPayoutSummary()}
            </div>
          )
        });
      }

      // TODO:We need to improve naming to pass items to the sections.
      // ATT. @dmuneras
      sections.push(
        {
          header: 'Payout Request',
          key: 'payout-request',
          errors: getGeneralErrors(),
          data: [
            {
              key: 'Account:',
              value: payoutData.debtor_account.currency,
              subValue: payoutData.debtor_account.number,
              errors: getAttributeErrors('general_data', 'account')
            },
            {
              key: 'Reference:',
              value: (
                <FormattedIdentifier
                  value={payoutData.inpay_unique_reference}
                />
              ),
              errors: getAttributeErrors(
                'general_data',
                'inpay_unique_reference'
              )
            },
            {
              key: 'End-to-end ID:',
              value: <FormattedIdentifier value={payoutData.end_to_end_id} />,
              errors: getAttributeErrors('general_data', 'end_to_end_id')
            },
            {
              key: 'Requested execution date:',
              value: (
                <FormattedDate
                  value={payoutData.requested_execution_date}
                  className="text-base font-medium"
                />
              ),
              errors: getAttributeErrors(
                'general_data',
                'requested_execution_date'
              )
            },
            {
              key: 'Amount:',
              value: (
                <div>
                  <div>{amountFormatter.format(payoutData.amount)}</div>
                  <div className="text-xs font-normal">
                    {payoutData.currency}
                  </div>
                </div>
              ),
              errors: getAttributeErrors('general_data', 'amount')
            },
            {
              key: 'Purpose Code:',
              value: payoutData.purpose_code,
              errors: getAttributeErrors('general_data', 'purpose_code')
            },
            {
              key: 'Destination Country:',
              value: getCountryName(payoutData.creditor_account.country_code),
              errors: getAttributeErrors('creditor_account', 'country_code')
            },
            {
              key: 'Selected Product:',
              value: payoutData.local_instrument,
              errors: getAttributeErrors('general_data', 'local_instrument')
            }
          ]
        },
        {
          header: 'Sender',
          key: 'sender',
          data: [
            {
              key: 'Full Name:',
              value: payoutData.ultimate_debtor.full_name,
              errors: getAttributeErrors('ultimate_debtor', 'full_name')
            },
            {
              key: 'Name:',
              value: payoutData.ultimate_debtor.name,
              errors: getAttributeErrors('ultimate_debtor', 'name')
            },
            {
              key: 'Email:',
              value: payoutData.ultimate_debtor.email,
              errors: getAttributeErrors('ultimate_debtor', 'email')
            },
            {
              key: 'Mobile number:',
              value: payoutData.ultimate_debtor.mobile_number,
              errors: getAttributeErrors('ultimate_debtor', 'mobile_number')
            },
            {
              key: 'Date of Birth:',
              value: (
                <FormattedDate
                  value={payoutData.ultimate_debtor.birthdate}
                  className="text-base font-medium"
                />
              ),
              errors: getAttributeErrors('ultimate_debtor', 'birthdate')
            },
            {
              key: 'Address:',
              value: payoutData.ultimate_debtor.address,
              errors: getAttributeErrors('ultimate_debtor', 'address')
            },
            {
              key: 'Address lines:',
              value: payoutData.ultimate_debtor.address_lines,
              errors: getAttributeErrors('ultimate_debtor', 'address_lines')
            },
            {
              key: 'Street name:',
              value: payoutData.ultimate_debtor.street_name,
              errors: getAttributeErrors('ultimate_debtor', 'street_name')
            },
            {
              key: 'Postal Code:',
              value: payoutData.ultimate_debtor.postal_code,
              errors: getAttributeErrors('ultimate_debtor', 'postcode')
            },
            {
              key: 'City:',
              value: payoutData.ultimate_debtor.city,
              errors: getAttributeErrors('ultimate_debtor', 'city')
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.ultimate_debtor.country_code),
              errors: getAttributeErrors('ultimate_debtor', 'country_code')
            },
            {
              key: 'State:',
              value: payoutData.ultimate_debtor.state,
              errors: getAttributeErrors('ultimate_debtor', 'state')
            },
            {
              key: 'Country of birth:',
              value: getCountryName(
                payoutData.ultimate_debtor.country_of_birth_code
              ),
              errors: getAttributeErrors(
                'ultimate_debtor',
                'country_of_birth_code'
              )
            },
            {
              key: 'Country of residence:',
              value: getCountryName(
                payoutData.ultimate_debtor.country_of_residence_code
              ),
              errors: getAttributeErrors(
                'ultimate_debtor',
                'country_of_residence_code'
              )
            },
            {
              key: 'ID number:',
              value: payoutData.ultimate_debtor.id_number,
              errors: getAttributeErrors('ultimate_debtor', 'id_number')
            },
            {
              key: 'ID type:',
              value: payoutData.ultimate_debtor.id_type,
              errors: getAttributeErrors('ultimate_debtor', 'id_type')
            },
            {
              key: 'Type:',
              value: payoutData.ultimate_debtor.type,
              errors: getAttributeErrors('ultimate_debtor', 'type')
            }
          ]
        },
        {
          header: 'History',
          key: 'history',
          data: [],
          customChildren: payoutData.status_transitions.map(
            (transition: any, index: number) => (
              <div
                className="border-inpay-gray-primary-300 flex justify-between gap-2 border-b py-6 first-of-type:pt-0 last-of-type:border-0"
                key={index}
              >
                <div>
                  <div className="text-xs">Status</div>
                  <PayoutBadge state={transition.name} />
                </div>
                <div className="text-right">
                  <FormattedDate value={transition.timestamp} />
                  <p className="font-semibold">
                    {formatHours(transition.timestamp)} UTC
                  </p>
                </div>
              </div>
            )
          )
        },
        {
          header: 'Receiver Bank',
          key: 'receiver-bank',
          data: [
            {
              key: 'IBAN:',
              value: payoutData.creditor_account.iban,
              errors: getAttributeErrors('creditor_account', 'iban')
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.creditor_account.country_code),
              errors: getAttributeErrors('creditor_account', 'country_code')
            },
            {
              key: 'Account number:',
              value: payoutData.creditor_account.account_number,
              errors: getAttributeErrors('creditor_account', 'account_number')
            },
            {
              key: 'Clearing system ID:',
              value: payoutData.creditor_account.bank_clearing_system_id,
              errors: getAttributeErrors(
                'creditor_account',
                'bank_clearing_system_id'
              )
            },
            {
              key: 'Clearing system code:',
              value: payoutData.creditor_account.bank_clearing_system_code,
              errors: getAttributeErrors(
                'creditor_account',
                'bank_clearing_system_code'
              )
            },
            {
              key: 'BIC:',
              value: payoutData.creditor_account.bank_bic,
              errors: getAttributeErrors('creditor_account', 'bank_bic')
            },
            {
              key: 'Branch ID:',
              value: payoutData.creditor_account.bank_branch_id,
              errors: getAttributeErrors('creditor_account', 'bank_branch_id')
            },
            {
              key: 'Bank name:',
              value: payoutData.creditor_account.bank_name,
              errors: getAttributeErrors('creditor_account', 'bank_name')
            }
          ]
        },
        {
          header: 'Receiver',
          key: 'receiver',
          data: [
            {
              key: 'Full Name:',
              value: payoutData.creditor.full_name,
              errors: getAttributeErrors('creditor', 'full_name')
            },
            {
              key: 'Name:',
              value: payoutData.creditor.name,
              errors: getAttributeErrors('creditor', 'name')
            },
            {
              key: 'Email:',
              value: payoutData.creditor.email,
              errors: getAttributeErrors('creditor', 'email')
            },
            {
              key: 'Date of Birth:',
              value: (
                <FormattedDate
                  value={payoutData.creditor.birthdate}
                  className="font-semibold"
                />
              ),
              errors: getAttributeErrors('creditor', 'birthdate')
            },
            {
              key: 'Address:',
              value: payoutData.creditor.address
            },
            {
              key: 'Postal Code:',
              value: payoutData.creditor.postal_code,
              errors: getAttributeErrors('creditor', 'postcode')
            },
            {
              key: 'City:',
              value: payoutData.creditor.city,
              errors: getAttributeErrors('creditor', 'city')
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.creditor.country_code),
              errors: getAttributeErrors('creditor', 'country_code')
            },
            {
              key: 'State:',
              value: payoutData.creditor.state,
              errors: getAttributeErrors('creditor', 'state')
            },
            {
              key: 'Mobile number:',
              value: payoutData.creditor.mobile_number,
              errors: getAttributeErrors('ultimate_debtor', 'mobile_number')
            }
          ]
        }
      );
      return sections;
    }

    return [];
  }, [payoutData, isSuccess]);

  const updateActiveSection = (section: string) => {
    if (section !== '' && section !== activeSection)
      return setActiveSection(section);
    setActiveSection(hasPayoutSummary ? 'summary' : 'payout-request');
  };

  return (
    <div
      role="complementary"
      aria-label="Payout details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="payout-overview__header">
        <div className="flex items-center justify-between px-6 py-3.5">
          <span>Details</span>
          <LinkWithQuery to=".." className="cursor-pointer" aria-label="Close">
            <XMarkIcon className="size-6" />
          </LinkWithQuery>
        </div>
        <div className="border-inpay-gray-primary-300 border-y bg-inpay-green-secondary-light-200 py-2 text-sm">
          &nbsp;
        </div>
      </div>
      <div className="overflow-auto">
        {isLoading && <Loading />}
        {isSuccess && (
          <DetailsBox
            data={data}
            activeSection={activeSection}
            setActiveSection={updateActiveSection}
          />
        )}
        {isError && (
          <div className="flex h-full items-center justify-center">
            Something went wrong
          </div>
        )}
      </div>
    </div>
  );
};
