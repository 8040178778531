import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';

import { NewPayoutsContent } from './NewPayoutsContent';
import { PayoutsContent } from './PayoutsContent';
import { StateGate } from './StateGate';

export const Payouts = () => {
  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  // CBP-2001-approval-workflow-manual-payouts: temporary until the feature flag is completely removed
  const isManualPayoutApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-2001-approval-workflow-manual-payouts'];

  return (
    <StateGate>
      {isManualPayoutApprovalFlowEnabled ? (
        <NewPayoutsContent />
      ) : (
        <PayoutsContent />
      )}
    </StateGate>
  );
};
