import cn from 'classnames';
import { useMemo } from 'react';
import { default as ReactCountryFlag } from 'react-country-flag';

import { getCountryCode } from 'utils/formatters/getCountryCode';
import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';
import { virtualAccountLabel } from 'utils/helpers';

import { VirtualAccount } from 'models/virtualAccount';

import { OrgNavLink } from 'components/ui/atoms/OrgNavLink';

interface Props {
  accountInfo: VirtualAccount;
}

export const AccountItem = ({ accountInfo }: Props) => {
  const countryIsoCode = useMemo(
    () => getCountryCode(accountInfo.currency_code),
    [accountInfo.currency_code]
  );

  return (
    <OrgNavLink
      key={accountInfo.account_number}
      to={`/accounts/${accountInfo.account_number}`}
      className={({ isActive }) =>
        cn(
          'group/item flex min-h-12 flex-col justify-center pl-6 pr-2 text-sm',
          {
            'bg-inpay-green-primary-mixes-60%-secondary': isActive,
            'hover:bg-inpay-dark-green-1000': !isActive
          }
        )
      }
    >
      <div className="flex flex-row items-center gap-2">
        <div className="flex flex-col">
          <ReactCountryFlag
            countryCode={countryIsoCode}
            style={{
              borderRadius: '50%',
              objectFit: 'cover',
              height: '22px',
              width: '22px'
            }}
            svg
          />
        </div>

        <div className="flex flex-col py-2">
          <div className="flex flex-row items-center gap-2">
            {virtualAccountLabel(accountInfo)}
          </div>

          <div className="flex justify-between text-[.8rem] text-inpay-yellow-400 group-hover/item:text-inpay-yellow-600">
            No. {virtualAccountNumberFormatter(accountInfo.account_number)}
          </div>
        </div>
      </div>
    </OrgNavLink>
  );
};
