/* eslint-disable camelcase */
import {
  CryptoFundingInformation,
  FundingInformation
} from 'models/fundingInformation';
import { TransactionData } from 'models/transaction';
import { VirtualAccount } from 'models/virtualAccount';

import { customerApi } from '../customerApi';

type VirtualAccountTransactionsKeys =
  | 'unique_identifier'
  | 'page_size'
  | 'sort'
  | 'include_cursor'
  | 'cursor_before'
  | 'created_after'
  | 'created_before'
  | 'cursor_after'
  | 'account_number';

export interface VirtualAccountsResponse {
  data: VirtualAccount[];
}

export interface VirtualAccountsRequest {
  organisationId: string | number;
  product_id?: number;
}

export interface VirtualAccountSummaryResponse {
  data: VirtualAccountSummary;
}

export interface VirtualAccountSummary {
  opening_balance: string;
  closing_balance: string;
  current_balance: string;
  currency_code: string;
}

export interface VirtualAccountTransactionsResponse {
  data: Array<TransactionData>;
  meta: {
    cursor_next: number;
    cursor_prev: number;
    has_previous_page: boolean;
    has_next_page: boolean;
    total: number;
    has_payouts: boolean;
  };
}

export interface FundingInformationResponse {
  data: {
    wire: FundingInformation;
    crypto: CryptoFundingInformation[];
  };
}

export interface FundingInformationRequest {
  organisationId: string;
  virtualAccountNumber: string;
}
export interface AcceptFundingRequest {
  organisationId: string;
  virtualAccountNumber: string;
  fundingInformationId: string;
}
export interface AcceptFundingResponse {
  uuid: string;
  email: string;
  funding_reference: string;
  owner_name: string;
  virtual_account_number: number;
  banking_rail_id: number;
  inpay_bank_account_id: number;
  organisation_id: number;
}

export type TransactionsRequest = {
  [key in VirtualAccountTransactionsKeys]?: string;
} & {
  organisationId: string;
  accountNumber: string;
};

export const virtualAccountApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchVirtualAccounts: builder.query<
      VirtualAccountsResponse,
      VirtualAccountsRequest
    >({
      query: ({ organisationId, ...props }) => {
        const queryParams = Object.entries(props).reduce<
          Record<string, string>
        >((acc, [key, value]) => {
          if (value) acc[key] = value.toString();
          return acc;
        }, {});

        return `/v1/reconciliation/organisations/${organisationId}/accounts?${new URLSearchParams(
          queryParams
        )}`;
      },
      providesTags: ['VirtualAccounts']
    }),
    fetchVirtualAccountSummary: builder.query<
      VirtualAccountSummaryResponse,
      any
    >({
      query: ({ virtual_account_number, created_after, created_before }) => {
        return `/v1/reconciliation/accounts/${virtual_account_number}/summary?${new URLSearchParams(
          { created_before, created_after }
        )}`;
      },
      providesTags: ['VirtualAccounts']
    }),
    // maybe we should remove or move it to transactionsAPI instead
    fetchAccountStatement: builder.query<
      VirtualAccountTransactionsResponse,
      TransactionsRequest
    >({
      query: ({ organisationId, accountNumber, ...params }) => {
        return `/v2/virtual_bank/organisations/${organisationId}/accounts/${accountNumber}/movements?${new URLSearchParams(params)}`;
      },
      providesTags: ['VirtualAccounts']
    }),
    fetchFundingInformation: builder.query<
      FundingInformationResponse,
      FundingInformationRequest
    >({
      query: ({ organisationId, virtualAccountNumber }) => {
        return `/v1/collection/organisations/${organisationId}/funding_instructions/${virtualAccountNumber}`;
      },
      providesTags: ['VirtualAccounts']
    }),
    updateAccountFunding: builder.mutation<
      AcceptFundingResponse,
      AcceptFundingRequest
    >({
      query: ({
        organisationId,
        virtualAccountNumber,
        fundingInformationId
      }) => ({
        url: `/v1/collection/organisations/${organisationId}/funding_instructions/${virtualAccountNumber}/accept_changes`,
        method: 'PUT',
        body: { configuration_uuid: fundingInformationId }
      })
    }),
    createAccountFunding: builder.mutation<any, any>({
      query: ({ virtual_account_number, params }) => {
        return {
          url: `/v1/reconciliation/accounts/${virtual_account_number}/funding`,
          credentials: 'include',
          method: 'POST',
          body: params
        };
      }
    })
  })
});

export const {
  useFetchVirtualAccountsQuery,
  useLazyFetchVirtualAccountSummaryQuery,
  useFetchFundingInformationQuery,
  useUpdateAccountFundingMutation,
  useFetchAccountStatementQuery,
  useCreateAccountFundingMutation,
  useLazyFetchVirtualAccountsQuery
} = virtualAccountApi;
