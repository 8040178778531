import { PaymentByUuidOrInpayUniqReferenceRequest } from './requests';
import { PaymentResponse } from './responses';

import { customerApi } from '../../customerApi';

export const paymentsApi = customerApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchPayment: builder.query<PaymentResponse, any>({
      query: ({ reference }) => `/v1/collection/payments/${reference}`,
      providesTags: ['Payments']
    }),
    fetchPaymentByUuidOrInpayUniqReference: builder.query<
      PaymentResponse,
      PaymentByUuidOrInpayUniqReferenceRequest
    >({
      query: (params) => {
        const urlParams = new URLSearchParams(params);
        return `/v1/collection/payments/find_by_uuid_or_inpay_unique_reference?${urlParams}`;
      },
      providesTags: ['Payments']
    })
  })
});

export const {
  useFetchPaymentQuery,
  useFetchPaymentByUuidOrInpayUniqReferenceQuery
} = paymentsApi;
