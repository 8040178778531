import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type LocalState = { q: string | null };

export function useLocalState(defaultState?: Partial<LocalState>) {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<LocalState>({
    q: (defaultState && defaultState.q) || searchParams.get('q')
  });

  const queryParams = useMemo(() => {
    return {
      q: state.q
    };
  }, [state]);

  useEffect(() => {
    if (searchParams.get('q')) {
      setState({ ...state, q: searchParams.get('q') });
    }
  }, [searchParams]);

  return {
    state,
    queryParams
  };
}

export function searchStateToParams(state: any) {
  return { unique_identifier: state.q } as any;
}
