import * as yup from 'yup';

export const addAccountFundingDialogSchema = yup
  .object({
    virtual_account_number: yup
      .string()
      .label('Account')
      .required('Please select account'),
    end_to_end_id: yup.string().label('Own reference').required(),
    amount: yup
      .string()
      .matches(/^[^0].*$/, 'Amount must be without leading zero')
      .matches(/^([0-9]*[.])?[0-9]+$/, 'Amount must be a number')
      .typeError('Amount must be a number')
      .label('Amount')
      .required(),
    remittance_description: yup.string().optional(),
    currency: yup.string().required()
  })
  .required();
