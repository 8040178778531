import {
  SortingState,
  useReactTable,
  getCoreRowModel,
  flexRender,
  OnChangeFn,
  functionalUpdate,
  Header,
  getFilteredRowModel,
  HeaderGroup,
  getFacetedUniqueValues,
  ColumnFiltersState
  // Cell
} from '@tanstack/react-table';
import cn from 'classnames';
import { useState, useEffect } from 'react';

import { ColumnHeader } from './ColumnHeader';
import { Paginator } from './Paginator';
import { DataTableProps } from './types';

// Used to determine whether sorting param is descending or ascending
export const sortingRegexp = /^(-?)([\w.\-_]+)$/;

/**
 * @deprecated please use an antd table instead
 */
export function DataTable<RowT extends object>({
  data,
  columns,
  name,
  topBar = undefined,
  total,
  hasData = true,
  noDataMessage,
  onSortingChange,
  onFilteringChange,
  filters,
  initialSorting = [{ id: 'date', desc: true }],
  onRowClick,
  checkIfDisabled,
  pagination,
  isFetching,
  currentRow,
  className = '',
  'data-testid': testId
}: DataTableProps<RowT>) {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);

  const onSortingChangeInternal: OnChangeFn<SortingState> = (updater) => {
    const newState = functionalUpdate(updater, sorting);
    if (newState != sorting) setSorting(newState);
    onSortingChange && onSortingChange(newState);
  };

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(
    filters || []
  );

  // Initializing hook
  useEffect(() => {
    // Hide columns
    columns.forEach((col) => {
      if (col.meta?.hidden && col.id && !col.enableHiding)
        table.getColumn(col.id)?.toggleVisibility(false);
    });
  }, []);

  // Notify subscribers on columnFilters changes
  useEffect(() => {
    if (
      onFilteringChange &&
      JSON.stringify(filters) !== JSON.stringify(columnFilters)
    ) {
      onFilteringChange(columnFilters);
    }
  }, [columnFilters]);

  // Update internal columnFilters based on consumer changes
  useEffect(() => {
    if (JSON.stringify(filters) !== JSON.stringify(columnFilters)) {
      setColumnFilters(filters || []);
    }
  }, [filters]);

  const table = useReactTable<RowT>({
    data,
    columns,
    defaultColumn: {
      enableSorting: false,
      header: '',
      enableColumnFilter: false
    },
    state: {
      sorting,
      columnFilters
    },
    sortDescFirst: true,
    enableSortingRemoval: false,
    enableColumnFilters: true,
    enableFilters: true,
    manualSorting: true,
    manualFiltering: true,
    onSortingChange: onSortingChangeInternal,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  });

  return (
    <div
      className={cn(
        'datatable flex h-[75vh] flex-col overflow-auto drop-shadow-plain-lg',
        className
      )}
      data-testid={testId}
    >
      {topBar ? (
        <div className="border-b-inpay-gray-primary-300 flex gap-1 rounded-t-lg border-b bg-white px-6 py-3.5">
          <span className="font-bold">
            {name}
            {topBar === 'name-with-count' && ':'}
          </span>
          {topBar === 'name-with-count' && (
            <span className="table-row-count">{total}</span>
          )}
        </div>
      ) : null}
      <div
        className={cn(
          'datatable max-h-[75vh] overflow-auto bg-white',
          { 'rounded-t-lg': !name, 'rounded-b-lg': !pagination },
          { 'rounded-b-lg': !hasData }
        )}
      >
        <table
          className="regular w-full table-fixed border-separate border-spacing-0"
          role="table"
          aria-label={name}
        >
          <thead className="sticky top-0 bg-inpay-green-secondary-light-200">
            {table.getHeaderGroups().map((headerGroup: HeaderGroup<RowT>) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: Header<RowT, unknown>) => (
                  <ColumnHeader
                    key={header.id}
                    header={header}
                    allValues={header.column.columnDef.meta?.values}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={cn('bg-white', isFetching && 'hidden')}>
            {table.getRowModel().rows.map((row) => {
              const isDisabled = checkIfDisabled
                ? checkIfDisabled(row.original)
                : false;

              const isSelected =
                currentRow?.field &&
                (row.original as any)[currentRow.field] == currentRow.value;

              return (
                <tr
                  key={row.id}
                  onClick={
                    onRowClick
                      ? (event) => {
                          onRowClick(event, row.original);
                        }
                      : undefined
                  }
                  data-active={isSelected || null}
                  role="row"
                  className={cn(
                    'data-table__row group h-5 hover:bg-inpay-gray-secondary-600',
                    'active:bg-inpay-gray-secondary-600',
                    {
                      'cursor-pointer': onRowClick && !isDisabled,
                      'text-inpay-gray-primary-1000': isDisabled
                    }
                  )}
                >
                  {row.getVisibleCells().map((cell) => {
                    const extendedColumnDef = cell.column.columnDef;

                    return (
                      <td
                        key={cell.id}
                        role="cell"
                        className={cn(
                          extendedColumnDef.meta?.className,
                          extendedColumnDef.meta?.alignment === 'right'
                            ? 'text-right'
                            : 'text-left',
                          'border-b-inpay-gray-primary-300 h-18 border-b group-last:border-b-0'
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {!hasData && (
          <div role="status" className="w-full rounded-b-lg">
            {noDataMessage}
          </div>
        )}
      </div>
      {pagination && hasData && (
        <div
          className={cn(
            'border-t-inpay-gray-primary-300 flex h-13 w-full shrink-0 items-center justify-center rounded-b-lg bg-white px-6',
            { 'border-t': data.length > 0 }
          )}
        >
          <Paginator {...pagination} />
        </div>
      )}
    </div>
  );
}
