import { useEffect } from 'react';
import { useParams } from 'react-router';

import { logEventToHotjar } from 'utils/logging';

import { WidgetFXRate } from '@organisms/WidgetFXRate/WidgetFXRate';
import { WidgetOrderStatuses } from '@organisms/WidgetOrderStatuses';
import { WidgetPayoutStatuses } from '@organisms/WidgetPayoutStatuses';
import { DashboardAccountWidgets } from 'containers/DashboardAccountWidgets';
import { Restricted } from 'containers/Restricted';

import {
  useFetchContractedProductsQuery,
  useFetchOrganisationConfigurationsQuery
} from 'redux/api/organisations/organisationsApi';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { DrilldownTables } from 'components/ui/templates/DrilldownTables/DrilldownTables';

const DASHBOARD_DATE_RANGE = 'd30';

export const Dashboard = () => {
  const { setDateRange, filters } = useGlobalFilters();

  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { data: productsData } =
    useFetchContractedProductsQuery(organisationId);

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const hasPayoutProducts =
    (productsData?.meta?.payout_products_count || 0) > 0;
  const hasCollectionProducts =
    (productsData?.meta?.collection_products_count || 0) > 0;

  useEffect(() => {
    logEventToHotjar('dashboard:page-open');

    setDateRange({
      type: DASHBOARD_DATE_RANGE
    });
  }, []);

  const isDateRangeUpdated = filters.dateRange.type === DASHBOARD_DATE_RANGE;
  const isExchangeRatesEnabled = orgSettings?.fx_widget_enabled;

  return (
    <>
      {isDateRangeUpdated && (
        <div
          role="tabpanel"
          className="flex size-full flex-col items-center overflow-auto p-8"
        >
          <div className="dashboard flex w-full max-w-7xl gap-8">
            <div className="flex max-w-[960px] grow flex-col gap-4">
              <Restricted requiredPermission="accounts:read">
                <DashboardAccountWidgets />
              </Restricted>
              {isExchangeRatesEnabled ? (
                <WidgetFXRate organisationId={organisationId} />
              ) : null}
              {hasPayoutProducts ? <DrilldownTables /> : null}
            </div>
            <div className="flex min-w-64 flex-col gap-4">
              {hasPayoutProducts ? <WidgetPayoutStatuses /> : null}
              {hasCollectionProducts ? <WidgetOrderStatuses /> : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
