import { useBoolean } from 'usehooks-ts';

import { FundingInformationDialog } from '@organisms/AccountFundingDialog';
import { AddAccountFundingDialog } from '@organisms/AccountFundingDialog/AddAccountFundingDialog';
import { WireFundingInformation } from '@organisms/AccountFundingDialog/WireFundingInformation';

interface Props {
  isAccountFundingInfoEnabled: boolean;
}

export const AccountFundingItem = ({ isAccountFundingInfoEnabled }: Props) => {
  const { value, toggle, setValue } = useBoolean(false);

  return (
    <>
      <a
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
        className="group flex h-12 items-center whitespace-nowrap pl-6 text-sm hover:bg-inpay-dark-green-1000"
      >
        {isAccountFundingInfoEnabled ? 'Account funding' : 'Add funds'}
      </a>
      {value && isAccountFundingInfoEnabled ? (
        <FundingInformationDialog
          open={value}
          onClose={setValue}
          FundingDetailsComponent={WireFundingInformation}
        />
      ) : (
        // CBP-2466-account-funding: temporary until the feature flag is completely removed
        <AddAccountFundingDialog open={value} onClose={setValue} />
      )}
    </>
  );
};
