import { useParams } from 'react-router';

import { hasValue } from 'utils/hasValue';

import { useUpdateAccountFundingMutation } from 'redux/api/accounts/virtualAccountApi';

import { FundingInformation } from 'models/fundingInformation';

import { CopyButton } from '@atoms/buttons/CopyButton';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

interface FundingSectionProps {
  fundingInformationData?: FundingInformation;
  isFundingInformationChanged?: boolean;
  virtualAccountNumber?: string;
  refetchFundingInformationData: () => void;
}
export const FundingSection = ({
  fundingInformationData,
  isFundingInformationChanged,
  virtualAccountNumber,
  refetchFundingInformationData
}: FundingSectionProps) => {
  const { organisationId } = useParams();

  const [updateFunding] = useUpdateAccountFundingMutation();

  const handleUpdateFundingInformationQuery = async () => {
    try {
      if (
        hasValue(organisationId) &&
        hasValue(virtualAccountNumber) &&
        hasValue(fundingInformationData)
      ) {
        await updateFunding({
          organisationId,
          virtualAccountNumber,
          fundingInformationId: fundingInformationData.uuid
        });
        refetchFundingInformationData();
      }
    } catch (error) {
      console.error('Error updating funding information:', error);
    }
  };

  if (!virtualAccountNumber) return null;
  if (!organisationId) throw new Error('Missing organisation id');
  if (!fundingInformationData)
    throw new Error('Missing funding information data');

  return fundingInformationData ? (
    <div className="flex flex-col gap-6 py-6 first-of-type:pt-0">
      <div className="flex flex-col gap-2 pt-4">
        {isFundingInformationChanged ? (
          <div className="flex flex-col gap-2 rounded-md bg-inpay-yellow-400 p-4">
            <div className="font-semibold">Funding updated</div>
            <div className="flex justify-between gap-8">
              <div className="text-sm">
                Please make sure that all members of your organisation are aware
                of the new payment instructions.
              </div>
              <PrimaryButton
                type="submit"
                onClick={() => handleUpdateFundingInformationQuery()}
                className="flex items-center whitespace-nowrap text-sm font-medium"
              >
                I got it!
              </PrimaryButton>
            </div>
          </div>
        ) : null}

        <div className="font-semibold">Funding instructions</div>

        <div className="text-sm">
          Please go to your online bank and make a funds transfer using the
          payment details below:
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-xs">Beneficiary Name</div>
          <div className="font-semibold">
            {fundingInformationData.beneficiary.full_name}
          </div>
        </div>
        <CopyButton
          label="Beneficiary full name"
          textToCopy={fundingInformationData.beneficiary.full_name}
        />
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-xs"> Beneficiary IBAN</div>
          <div className="font-semibold">
            {fundingInformationData.beneficiary.iban}
          </div>
        </div>
        <CopyButton
          label="IBAN"
          textToCopy={fundingInformationData.beneficiary.iban}
        />
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-xs"> Beneficiary Bank</div>
          <div className="font-semibold">
            {fundingInformationData.beneficiary.bank_name}
          </div>
        </div>
        <CopyButton
          label="Bank name"
          textToCopy={fundingInformationData.beneficiary.bank_name}
        />
      </div>
      <div className="flex items-center justify-between">
        <div>
          <div className="text-xs">
            Your funding reference (Include in Payment details)
          </div>
          <div className="font-semibold">
            {fundingInformationData.funding_reference}
          </div>
        </div>
        <CopyButton
          label="Funding reference"
          textToCopy={fundingInformationData.funding_reference}
        />
      </div>
      <div className="rounded-md bg-inpay-blue-200 p-3 text-sm">
        Please make sure to include your funding reference in payment details.
      </div>
    </div>
  ) : (
    <div className="pb-6">
      Funding information not available for this account.
    </div>
  );
};
