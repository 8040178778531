import { CloseOutlined } from '@ant-design/icons';
import { Button, Collapse, CollapseProps, Divider, Skeleton } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { useFetchWithdrawalRequestQuery } from 'redux/api/accounts/withdrawalsApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { WithdrawalRequestsData } from 'models/withdrawal';

import { WithdrawalDetailsHistory } from './WithdrawalDetailsHistory';
import { WithdrawalDetailsItem } from './WithdrawalDetailsItem';

interface Props {
  selectedRowData: WithdrawalRequestsData;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const formatter = new MoneyFormatter();

export const WithdrawalDetails = ({ selectedRowData, setOpen }: Props) => {
  const organisationId = useSelector(selectOrganisationId);
  if (!organisationId) throw new Error('Missing organisation id');

  const {
    data: { data: withdrawalRequestFullData } = {},
    isLoading: isWithdrawalRequestLoading
  } = useFetchWithdrawalRequestQuery({
    organisationId: organisationId.toString(),
    id: selectedRowData.id
  });

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Funds withdrawal',
      children: (
        <div>
          {isWithdrawalRequestLoading ? (
            <Skeleton />
          ) : (
            <WithdrawalDetailsItem
              title="Reference"
              item={selectedRowData.reference}
            />
          )}
          <WithdrawalDetailsItem
            title="Amount"
            item={formatter.format(selectedRowData.amount)}
            secondaryItem={selectedRowData.currency_code}
          />
          <WithdrawalDetailsItem
            title="Bank"
            item={
              withdrawalRequestFullData?.creditor_account?.bank_name
                ? withdrawalRequestFullData.creditor_account.bank_name
                : 'Information not found'
            }
          />
          <WithdrawalDetailsItem
            title="IBAN"
            item={
              withdrawalRequestFullData?.creditor_account?.iban
                ? withdrawalRequestFullData.creditor_account.iban
                : 'Information not found'
            }
          />
          <WithdrawalDetailsItem
            title="Beneficiary"
            item={
              withdrawalRequestFullData?.creditor_account?.creditor_name
                ? withdrawalRequestFullData.creditor_account.creditor_name
                : 'Information not found'
            }
          />
        </div>
      )
    },
    {
      key: '2',
      label: 'History',
      children: (
        <div>
          {withdrawalRequestFullData ? (
            <WithdrawalDetailsHistory
              withdrawalRequestFullData={withdrawalRequestFullData}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      )
    }
  ];

  return (
    <div style={{ flex: 0.25, borderRadius: '.75rem' }}>
      <div
        className="flex p-5 text-2xl font-medium"
        style={{
          background: '#fff',
          justifyContent: 'space-between',
          borderTopLeftRadius: '.75rem',
          borderTopRightRadius: '.75rem'
        }}
      >
        Details:
        <Button icon={<CloseOutlined />} onClick={() => setOpen(false)} />
      </div>
      <Divider style={{ borderColor: '#D8D7D6', margin: 0 }} />
      <div
        className="p-3"
        style={{ paddingLeft: '1.5rem', backgroundColor: 'rgb(222, 250, 234)' }}
      >
        Account Information:{' '}
        <strong>
          {selectedRowData.currency_code}{' '}
          {selectedRowData.debtor_account_number}
        </strong>
      </div>
      <Divider style={{ borderColor: '#D8D7D6', margin: 0 }} />
      <Collapse
        className="withdrawal-details-collapse shadow-lg"
        expandIconPosition="end"
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        items={items}
        style={{
          background: '#F8F7F6'
        }}
      />
    </div>
  );
};
