import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { formatDate } from 'utils/dayjs';
import { getCountryName } from 'utils/formatters/getCountryName';

import { useFetchOrderQuery } from 'redux/api/collections/orders';

import { Payment } from 'models/payment';

import { PaymentBadge } from '@atoms/badges/PaymentBadge/PaymentBadge';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

import { Loading } from 'components/pages/Loading';
import { DetailsBox } from 'components/ui/organisms/DetailsBox';

interface Props {
  onTransactionSourceLoaded?: (data: any) => void;
  reference: string;
}

export const OrderTransactionDetails = ({
  onTransactionSourceLoaded,
  reference
}: Props) => {
  const {
    data: { data: orderData } = {},
    isLoading,
    isError,
    isSuccess
  } = useFetchOrderQuery({ reference });

  useEffect(() => {
    if (orderData && onTransactionSourceLoaded) {
      onTransactionSourceLoaded(orderData);
    }
  }, [isSuccess, isError]);

  const data = useMemo(() => {
    if (!isSuccess || !orderData) return [];

    return [
      {
        header: 'Order',
        key: 'order',
        data: [
          { key: 'Business Unit:', value: orderData.business_unit },
          { key: 'ID:', value: orderData.uuid },
          { key: 'Reference:', value: orderData.reference }
        ]
      },
      {
        header: 'Debtor information',
        key: 'debtor',
        data: [
          { key: 'Full Name:', value: orderData.debtor.full_name },
          {
            key: 'Date of Birth:',
            value: formatDate(orderData.debtor.date_of_birth)
          },
          { key: 'Email:', value: orderData.debtor.email },
          {
            key: 'Country of residence:',
            value:
              getCountryName(orderData.debtor.country_of_residence) ||
              orderData.debtor.country_of_residence
          }
        ]
      },
      {
        header: 'Payments',
        key: 'payments',
        data: [],
        customChildren: orderData.payments.length ? (
          orderData.payments.map((payment: Payment, index: number) => (
            <div
              className="border-inpay-gray-primary-300 flex justify-between gap-2 border-b py-6 first-of-type:pt-0 last-of-type:border-0"
              key={index}
            >
              <div>
                <div className="text-xs">Status</div>
                <PaymentBadge type={payment.status} />
              </div>
              <div className="text-right">
                <FormattedDate value={payment.created_at} />
                <p className="font-semibold">
                  {payment.inpay_unique_reference}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="pb-6">No payments made to this order</div>
        )
      }
    ];
  }, [orderData, isSuccess]);

  return (
    <div className="relative max-h-full w-full flex-col overflow-auto rounded-lg bg-white shadow-plain-lg">
      <div className="order-overview__header sticky top-0">
        <div className="flex items-center justify-between bg-white px-6 py-3.5">
          <span>Details</span>
          <Link to=".." className="cursor-pointer" aria-label="Close">
            <XMarkIcon className="size-6" />
          </Link>
        </div>
        <div className="border-inpay-gray-primary-300 h-10 border-y bg-inpay-green-secondary-light-200"></div>
      </div>
      {isLoading && <Loading className="bg-white" />}
      {isSuccess && <DetailsBox data={data} defaultValue="sender" />}
      {isError && <div>error</div>}
    </div>
  );
};
