import * as yup from 'yup';

export const newUserDialogSchema = yup
  .object({
    first_name: yup.string().label('First Name').required(),
    last_name: yup.string().label('Last Name').required(),
    email: yup
      .string()
      .label('E-mail')
      .email('Must be a valid email')
      .required(),
    role: yup.string().label('Role').required()
  })
  .required();
