import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import styles from './styles.module.css';

interface Props {
  ariaLabel: string;
  className?: string;
}

// An inline loading indicator.
export const RollingLoader = ({ className = '', ariaLabel }: Props) => {
  return (
    <span
      role="progressbar"
      aria-label={ariaLabel}
      className={twMerge(cn(styles.loader, className))}
    />
  );
};
