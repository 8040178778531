import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import { API_DATE_FORMAT, DATE_FORMAT } from 'utils/dayjs';

import {
  PayoutsStatsParams,
  useGetStatsPerReasonCodesQuery
} from 'redux/api/payouts/payoutsStatsApi';

import { payoutStates } from 'models/payout';
import { StateTarget } from 'models/stats';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { DrilldownTable } from 'components/ui/organisms/DrilldownTable/DrilldownTable';

const STATE_CATEGORIES_TO_SHOW: Array<StateTarget> = [
  'returned',
  'rejected',
  'action_required'
];

export const DrilldownTables = () => {
  const { filters } = useGlobalFilters();

  const formatDate = function (stringDate: string) {
    return dayjs(stringDate, DATE_FORMAT).format(API_DATE_FORMAT);
  };

  const queryParams: PayoutsStatsParams = {};

  if (filters.dateRange.from) {
    queryParams.created_after = formatDate(filters.dateRange.from);
  }

  if (filters.dateRange.to) {
    queryParams.created_after = formatDate(filters.dateRange.to);
  }

  const { data, isSuccess } = useGetStatsPerReasonCodesQuery(queryParams);
  const responseData = data?.data;

  const hasDataForStateCategory = (category: StateTarget) => {
    return !!responseData && responseData[category]?.meta?.count > 0;
  };

  const { organisationId } = useParams();
  const navigate = useNavigate();

  const navigateTo = (section: string) => {
    return () => {
      navigate(`/org/${organisationId}/payouts?state=${section}`);
    };
  };

  return (
    <>
      {isSuccess && (
        <div className="flex flex-wrap gap-4">
          {STATE_CATEGORIES_TO_SHOW.map((category) => {
            if (hasDataForStateCategory(category) && responseData) {
              return (
                <DrilldownTable
                  key={category}
                  stateId={category}
                  title={
                    payoutStates.find((p) => p.id === category)?.label ||
                    category
                  }
                  data={responseData[category]}
                  className="w-116"
                  onHeaderClick={navigateTo(category)}
                />
              );
            }
          })}
        </div>
      )}
    </>
  );
};
