import {
  Cog8ToothIcon as SettingsIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import { ReactElement, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { Restricted } from 'containers/Restricted';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';
import { useFetchContractedProductsQuery } from 'redux/api/organisations/organisationsApi';

import { useFeatureAccessControl } from 'hooks/useFeatureAccessControl';

import { CREATE_MANUAL_PAYOUT_TEST_ID } from 'components/pages/Payouts/constants';
import {
  AccountsIcon,
  CollectionsIcon,
  DashboardIcon,
  PayoutsIcon
} from 'components/ui/atoms/Icon';

import {
  ACCOUNT_WITHDRAWALS_ITEM_TEST_ID,
  ACCOUNTS_SIDEBAR_ITEM_TEST_ID,
  BATCHES_SIDEBAR_ITEM_TEST_ID,
  COLLECTION_SIDEBAR_ITEM_TEST_ID,
  CREATE_MANUAL_PAYOUT_SIDEBAR_ITEM_TEST_ID,
  EXPORTED_TRANSACTIONS_SIDEBAR_ITEM_TEST_ID,
  ORDERS_SIDEBAR_ITEM_TEST_ID,
  PAYOUT_REQUESTS_SIDEBAR_ITEM_TEST_ID,
  PAYOUTS_SIDEBAR_ITEM_TEST_ID,
  USER_MANAGEMENT_SIDEBAR_ITEM_TEST_ID
} from './constants';
import { AccountFundingItem } from './customItems/AccountFundingItem';
import { AccountItem } from './customItems/AccountItem';
import { CreateManualPayoutItem } from './customItems/CreateManualPayoutItem';
import { CryptoAccountFundingItem } from './customItems/CryptoAccountFundingItem';
import { SidebarMenuItem, SidebarMenuSubItem } from './SidebarItem';

/**
 *
 * This creates a list of items that should be rendered in the navbar.
 */
export const useSidebarMenu = () => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const {
    isManualPayoutApprovalFlowEnabled,
    isManualPayoutEnabled,
    isBatchesPageEnabled,
    isAccountFundingInfoEnabled,
    isWithdrawalRequestsEnabled
  } = useFeatureAccessControl();

  const { data: accountsData } = useFetchVirtualAccountsQuery({
    organisationId: organisationId
  });
  const { data: productsData } =
    useFetchContractedProductsQuery(organisationId);

  const accountsInfo = useMemo(
    () => accountsData?.data ?? [],
    [accountsData?.data]
  );

  const hasOnlySharedAccounts = accountsInfo.every(
    (account) => account.owner === false
  );

  const hasPayoutProducts =
    (productsData?.meta?.payout_products_count ?? 0) > 0;

  const hasCollectionProducts =
    (productsData?.meta?.collection_products_count ?? 0) > 0;

  const buildMenuItems = () => {
    const virtualAccountSidebarItems: (SidebarMenuSubItem | ReactElement)[] = [
      <Restricted
        requiredPermission="accounts:funding"
        key="account-funding-dialog-item"
      >
        <AccountFundingItem
          isAccountFundingInfoEnabled={isAccountFundingInfoEnabled}
        />
      </Restricted>
    ];

    if (isAccountFundingInfoEnabled) {
      virtualAccountSidebarItems.push(
        <Restricted
          requiredPermission="accounts:funding"
          key="crypto-account-funding-dialog-item"
        >
          <CryptoAccountFundingItem />
        </Restricted>
      );
    }

    virtualAccountSidebarItems.push({
      id: 'Exported transactions',
      ['data-testid']: EXPORTED_TRANSACTIONS_SIDEBAR_ITEM_TEST_ID,
      title: 'Exported transactions',
      path: '/accounts/exported_transactions',
      requiredPermission: 'account_transactions:read'
    });

    const payoutsSidebarItems: (SidebarMenuSubItem | ReactElement)[] = [
      {
        id: 'payout_requests',
        ['data-testid']: PAYOUT_REQUESTS_SIDEBAR_ITEM_TEST_ID,
        title: isManualPayoutApprovalFlowEnabled
          ? 'Payouts'
          : 'Payout requests',
        path: '/payouts',
        requiredPermission: 'payouts:read'
      }
    ];

    if (isWithdrawalRequestsEnabled) {
      virtualAccountSidebarItems.push({
        id: 'withdrawals',
        ['data-testid']: ACCOUNT_WITHDRAWALS_ITEM_TEST_ID,
        title: 'Withdrawals',
        path: '/accounts/withdrawals',
        requiredPermission: 'withdrawals:read'
      });
    }

    for (const accountInfo of accountsInfo) {
      virtualAccountSidebarItems.push(
        <Restricted
          requiredPermission="accounts:read"
          key={accountInfo.account_number}
        >
          <AccountItem accountInfo={accountInfo} />
        </Restricted>
      );
    }

    const rootItems: SidebarMenuItem[] = [
      {
        id: 'dashboard',
        title: 'Dashboard',
        path: '/',
        end: true,
        icon: DashboardIcon,
        requiredPermission: 'dashboard:read'
      }
    ];

    if (hasPayoutProducts) {
      rootItems.push({
        id: PAYOUTS_SIDEBAR_ITEM_TEST_ID,
        title: 'Payouts',
        path: '/payouts',
        icon: PayoutsIcon,
        subItems: payoutsSidebarItems,
        requiredPermission: 'payouts:read'
      });
      if (isManualPayoutEnabled && !isManualPayoutApprovalFlowEnabled) {
        payoutsSidebarItems.push(
          <Restricted
            requiredPermission="payouts:create"
            key={CREATE_MANUAL_PAYOUT_TEST_ID}
          >
            <CreateManualPayoutItem
              data-testid={CREATE_MANUAL_PAYOUT_SIDEBAR_ITEM_TEST_ID}
            />
          </Restricted>
        );
      }
      if (isBatchesPageEnabled) {
        payoutsSidebarItems.push({
          id: 'batches',
          ['data-testid']: BATCHES_SIDEBAR_ITEM_TEST_ID,
          title: 'Batches',
          path: '/batches',
          requiredPermission: 'payout_batches:read'
        });
      }
    }

    if (hasCollectionProducts) {
      rootItems.push({
        id: COLLECTION_SIDEBAR_ITEM_TEST_ID,
        title: 'Collections',
        path: '/collections',
        icon: CollectionsIcon,
        subItems: [
          {
            id: 'orders',
            ['data-testid']: ORDERS_SIDEBAR_ITEM_TEST_ID,
            title: 'Orders',
            path: '/collections/orders',
            requiredPermission: 'collection_orders:read'
          }
        ],
        requiredPermission: 'collections:read'
      });
    }

    if (!hasOnlySharedAccounts) {
      rootItems.push({
        id: ACCOUNTS_SIDEBAR_ITEM_TEST_ID,
        title: 'Accounts',
        path: '/accounts',
        icon: AccountsIcon,
        subItems: virtualAccountSidebarItems,
        requiredPermission: 'accounts:read'
      });
    }

    rootItems.push({
      id: USER_MANAGEMENT_SIDEBAR_ITEM_TEST_ID,
      title: 'User Management',
      path: '/users',
      end: true,
      icon: UserGroupIcon,
      requiredPermission: 'users:read'
    });

    return rootItems;
  };

  const sidebarItems = useMemo(buildMenuItems, [
    isAccountFundingInfoEnabled,
    isManualPayoutApprovalFlowEnabled,
    isWithdrawalRequestsEnabled,
    hasPayoutProducts,
    hasCollectionProducts,
    hasOnlySharedAccounts,
    accountsInfo,
    isManualPayoutEnabled,
    isBatchesPageEnabled
  ]);

  const settingsItems = useMemo(() => {
    const settingsItems: (SidebarMenuSubItem | ReactElement)[] = [];

    settingsItems.push({
      id: 'settings',
      title: 'My Profile',
      path: '/settings/profile'
    });

    const rootItems: SidebarMenuItem[] = [];

    if (settingsItems.length > 0) {
      rootItems.push({
        id: 'settings',
        title: 'Settings',
        path: '/settings',
        icon: SettingsIcon,
        subItems: settingsItems
      });
    }

    return rootItems;
  }, []);

  return { sidebarItems, settingsItems };
};
