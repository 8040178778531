import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { transactionTypeMapping } from 'constants/transactionTypes';

import { TransactionData } from 'models/transaction';

import { FormattedIdentifier } from '@atoms/FormattedIdentifier/FormattedIdentifier';
import { TransactionIconPlus, TransactionIconMinus } from '@atoms/Icon';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

const formatter = new MoneyFormatter();

export const useTransactionsColumns = () => {
  const columnHelper = createColumnHelper<TransactionData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(({ amount }) => ({ amount }), {
        id: 'amount_icon',
        cell: (info) => {
          const { amount } = info.getValue<{ amount: number }>();

          return (
            <div className="m-auto w-6">
              {amount < 0 && <TransactionIconMinus height="32" width="32" />}
              {amount >= 0 && <TransactionIconPlus height="32" width="32" />}
            </div>
          );
        },
        meta: {
          className: 'w-20'
        }
      }),
      columnHelper.accessor('date', {
        id: 'date',
        header: 'Date',
        cell: (info) => {
          const dateTime = info.getValue<string>();

          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor(
        (row) => ({
          id: row.order_id,
          reference: row.reference
        }),
        {
          id: 'idReference',
          header: 'ID/Reference',
          cell: (info) => {
            const value = info.getValue<{ id: string; reference: string }>();
            return (
              <div className="text-left uppercase">
                {value?.id && (
                  <FormattedIdentifier value={value?.id} className="text-xs" />
                )}
                {value?.reference && (
                  <FormattedIdentifier
                    value={value?.reference}
                    className="text-base font-medium"
                  />
                )}
                {!(value?.id || value?.reference) && <p>N/A</p>}
              </div>
            );
          },
          meta: {
            className: 'w-28'
          }
        }
      ),
      columnHelper.accessor(({ amount, currency }) => ({ amount, currency }), {
        id: 'amountWithCurrency',
        header: 'Amount',
        cell: (info) => {
          const { amount, currency } = info.getValue<{
            amount: number;
            currency: string;
          }>();
          const formattedAmount = formatter.format(amount);

          return (
            <>
              <div className="text-xs font-normal uppercase">{currency}</div>
              <div className="text-base font-medium">{formattedAmount}</div>
            </>
          );
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor('entry_type', {
        id: 'entry_type',
        header: 'Type',
        cell: (info) => {
          const value = info.getValue<string>();

          const typeDetails = transactionTypeMapping[value] || {
            type: value,
            description: 'N/A'
          };

          return (
            <div className="m-auto text-left">
              <div className="text-xs font-normal">{typeDetails.type}</div>
              <div className="text-base font-medium">
                {typeDetails.description}
              </div>
            </div>
          );
        },
        meta: {
          alignment: 'right',
          className: 'w-48 pr-6'
        }
      }),

      columnHelper.display({
        id: 'column_params',
        cell: () => (
          <ChevronRightIcon className="-ml-2 h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        ),
        meta: {
          className: 'w-12 pr-3.5 pl-1'
        }
      })
    ],
    []
  );

  return columns;
};
