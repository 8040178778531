import { Dispatch, SetStateAction } from 'react';

import { CreateBatchTemplateDialog } from '@organisms/CreateBatchTemplateDialog';
import { UploadBatchDialog } from '@organisms/UploadBatchDialog/UploadBatchDialog';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { LocalState } from './localState';
import { TabButton } from './TabButton';

interface Props {
  toggleCreateBatchDialog: () => void;
  isCreateBatchDialogVisible: boolean;
  setIsCreateBatchDialogVisible: Dispatch<SetStateAction<boolean>>;
  toggleUploadDialog: () => void;
  isUploadDialogVisible: boolean;
  setIsCreateUploadDialogVisible: Dispatch<SetStateAction<boolean>>;
  isApprovalEnabled: boolean;
  onTabClick: (tabValue: 'completed' | 'rejected' | 'waiting_review') => void;
  state: LocalState;
}

export const BatchesHeader = ({
  toggleCreateBatchDialog,
  isCreateBatchDialogVisible,
  setIsCreateBatchDialogVisible,
  toggleUploadDialog,
  isUploadDialogVisible,
  setIsCreateUploadDialogVisible,
  isApprovalEnabled,
  onTabClick,
  state
}: Props) => {
  return (
    // TODO (LTJ): This is not needed
    <div>
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-medium">Batches</h1>
        <div className="flex gap-4">
          <SecondaryButton onClick={toggleCreateBatchDialog}>
            Create Batch template
          </SecondaryButton>
          {isCreateBatchDialogVisible && (
            <CreateBatchTemplateDialog
              open={isCreateBatchDialogVisible}
              onClose={setIsCreateBatchDialogVisible}
            />
          )}
          <PrimaryButton onClick={toggleUploadDialog}>
            Upload batch file
          </PrimaryButton>
          {isUploadDialogVisible && (
            <UploadBatchDialog
              open={isUploadDialogVisible}
              onClose={setIsCreateUploadDialogVisible}
              setIsCreateBatchDialogVisible={setIsCreateBatchDialogVisible}
            />
          )}
        </div>
      </div>
      {isApprovalEnabled && (
        <div className="flex gap-3 pb-4">
          <TabButton
            onClick={() => {
              onTabClick('waiting_review');
            }}
            label="Pending approval"
            isActive={state.filters.status === 'waiting_review'}
          />
          <TabButton
            onClick={() => {
              onTabClick('completed');
            }}
            label="Approved"
            isActive={state.filters.status === 'completed'}
          />
          <TabButton
            onClick={() => {
              onTabClick('rejected');
            }}
            label="Declined"
            isActive={state.filters.status === 'rejected'}
          />
        </div>
      )}
    </div>
  );
};
