import { SVGProps } from 'react';

export const TransactionIconPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path width="32" height="32" d="M0 0H24V24H0V0z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 15h5.25v-5.25a5.25 5.25 0 1 0 -5.25 5.25Zm0 3.75h9V9.75c0 -4.97 -4.03 -9 -9 -9S0.75 4.78 0.75 9.75s4.03 9 9 9Z"
      className="fill-inpay-gray-primary-400"
    />
    <path
      d="M12.75 18a5.25 5.25 0 1 0 5.25 -5.25h-5.25v5.25Z"
      className="fill-inpay-green-primary-mixes-80%-secondary"
    />
    <path
      d="M17.475 15.375v2.1h-2.1v1.05h2.1v2.1h1.05v-2.1h2.1v-1.05h-2.1v-2.1h-1.05Z"
      fill="white"
    />
  </svg>
);
