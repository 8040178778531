import { CurrenciesResponse } from 'models/currency';

import { customerApi } from './customerApi';

export const currenciesApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCurrencies: builder.query<CurrenciesResponse, void>({
      query: () => '/v1/payouts/organisation_product_currencies',
      providesTags: ['Currencies']
    })
  })
});

export const { useFetchCurrenciesQuery } = currenciesApi;
