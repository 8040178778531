import { Outlet } from 'react-router-dom';

import InpayLogo from 'assets/images/inpay-logo-white.svg';

export const AuthenticationLayout = () => {
  return (
    <main className="md:bg-inpay-auth h-screen overflow-y-auto">
      <Outlet />
      <InpayLogo className="absolute right-12 top-10 hidden h-auto w-24 md:block" />
    </main>
  );
};
