import { TrashIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useBoolean } from 'usehooks-ts';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { hasValue } from 'utils/hasValue';

import { REVIEWER_ROLES } from 'constants/roles';

import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import {
  useApproveOrDeclineBatchMutation,
  useFetchBatchQuery
} from 'redux/api/payouts/batchesApi';
import { useFetchUserQuery } from 'redux/api/usersApi';
import { selectUserId } from 'redux/slices/authSlice';

import { ApprovalAction, Reviewer } from 'models/ApprovalWorkFlow';
import { BatchDetail } from 'models/batches';

import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { DeclineDialog } from '@molecules/DeclineDialog';

import { TableLayout } from 'components/ui/templates/TableLayout';

import { BatchPayoutsHeader } from './BatchPayoutsHeader';
import { BatchDraftPayoutsList } from './BatchPayoutsList/BatchDraftPayoutsList';
import { BatchPayoutsList } from './BatchPayoutsList/BatchPayoutsList';

import { Loading } from '../Loading';

export const BatchPayoutsContent = () => {
  const userId = useSelector(selectUserId);

  const { batchId } = useParams();
  const { organisationId } = useParams();

  const navigate = useOrgNavigate();

  const {
    data: { data: batchDetails } = {},
    isSuccess: isBatchSuccess,
    isLoading: isBatchLoading,
    isError: isBatchError
  } = useFetchBatchQuery({
    organisationId,
    id: batchId
  });

  const {
    isError: isOrgSettingsError,
    isSuccess: isOrgSettingsSuccess,
    isLoading: isOrgSettingsLoading,
    data: { data: orgSettings } = {}
  } = useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: userData } = {} } = useFetchUserQuery({
    organisationId,
    id: userId
  });

  const [approveOrDeclineBatch] = useApproveOrDeclineBatchMutation();

  const {
    value: isDeclineDialogVisible,
    toggle: toggleDeclineDialog,
    setValue: setIsDeclineDialogVisible
  } = useBoolean(false);

  if (
    !hasValue(orgSettings) ||
    !hasValue(batchId) ||
    !hasValue(userId) ||
    !hasValue(organisationId)
  ) {
    return null;
  }

  const isReviewer: boolean =
    hasValue(userData?.role) && REVIEWER_ROLES.includes(userData?.role ?? '');

  const isApprovalEnabled = orgSettings.batch_number_of_approvers > 0;

  const remainingApprovals = batchDetails?.approvals
    ? orgSettings.batch_number_of_approvers - batchDetails.approvals.length
    : orgSettings.batch_number_of_approvers;

  const isApprovedByUser = batchDetails?.approvals?.some(
    (approval: Reviewer) => approval.user_id === userId
  );

  const navigatePostApproval = () => {
    if (remainingApprovals === 1) {
      // Redirect to approved page if the user is the last one to approve
      navigate('/batches?status=completed');
    } else {
      // Redirect to pending approval if other approvals are still required
      navigate(`/batches/${batchDetails?.id}?status=waiting_review`);
    }
  };

  const handleApproveOrDecline = async (selectedAction: ApprovalAction) => {
    try {
      await approveOrDeclineBatch({
        organisationId: organisationId,
        batchId: batchId,
        action: selectedAction
      });
      if (selectedAction === 'approve') {
        toast.success('Batch has been approved', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });

        navigatePostApproval();
      } else if (selectedAction === 'reject') {
        toast.success('Batch has been declined', {
          position: 'top-right',
          icon: (
            <TrashIcon className="stroke-inpay-green-primary-mixes-80%-secondary" />
          ),
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
        navigate('/batches?status=rejected');
      }
    } catch (error) {
      toast.error('Error while approving/declining', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    }
  };

  const formatBatchDetails = (batchDetails: BatchDetail) => {
    const localInstrument = batchDetails?.local_instrument || '';
    const countryName = batchDetails?.country.name || '';

    let formattedAmount = '';
    if (batchDetails?.total_amount) {
      formattedAmount = new MoneyFormatter().format(batchDetails.total_amount);
    }

    const currencyIso = batchDetails?.currency.iso || '';

    return `${localInstrument}, ${countryName}, ${formattedAmount} ${currencyIso}`;
  };

  const canApprove =
    isApprovalEnabled &&
    isReviewer &&
    !isApprovedByUser &&
    batchDetails !== undefined &&
    batchDetails.status === 'waiting_review' &&
    !batchDetails.rejections?.length &&
    batchDetails.creator_id !== userId;

  return (
    <>
      {(isBatchLoading || isOrgSettingsLoading) && <Loading />}
      {(isBatchError || isOrgSettingsError) && (
        <div className="flex h-full items-center justify-center">
          Something went wrong
        </div>
      )}

      {isBatchSuccess && isOrgSettingsSuccess && (
        <TableLayout
          header={
            <BatchPayoutsHeader
              toggleDeclineDialog={toggleDeclineDialog}
              batchDetails={batchDetails}
              isApprovalEnabled={isApprovalEnabled}
              isBatchSuccess={isBatchSuccess}
              userId={userId}
              requiredNumberOfApprovals={orgSettings.batch_number_of_approvers}
              canApprove={canApprove}
              formatBatchDetails={formatBatchDetails}
              handleApproveOrDecline={handleApproveOrDecline}
            />
          }
        >
          {isDeclineDialogVisible && (
            <DeclineDialog
              open={isDeclineDialogVisible}
              onClose={setIsDeclineDialogVisible}
              handleApproveOrDecline={handleApproveOrDecline}
              variant="batch"
            />
          )}
          {batchDetails?.status === 'completed' ? (
            <BatchPayoutsList />
          ) : (
            <BatchDraftPayoutsList />
          )}
        </TableLayout>
      )}
    </>
  );
};
