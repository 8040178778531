import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';
import { hasValue } from 'utils/hasValue';

import { DetailsBox, Item } from '@organisms/DetailsBox';

import {
  useFetchFundingInformationQuery,
  useLazyFetchVirtualAccountSummaryQuery
} from 'redux/api/accounts/virtualAccountApi';
import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';

import { formatPayloadToDetails } from './formatPaymentToDetails';
import { FundingSection } from './FundingSection';
import { AccountDetailsField, AccountDetailsProps } from './types';

export const AccountDetails = ({
  virtualAccountNumber,
  fromDate,
  toDate,
  currencyCode
}: AccountDetailsProps) => {
  const { organisationId } = useParams();
  const [virtualAccountDetails, setVirtualAccountDetails] = useState<
    AccountDetailsField[]
  >([]);
  const [error, setError] = useState<string>();

  const [fetchBalanceOverviewData] = useLazyFetchVirtualAccountSummaryQuery();

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const {
    data: { data: fundingInformationData } = {},
    refetch: refetchFundingInformationData
  } = useFetchFundingInformationQuery(
    hasValue(organisationId) && hasValue(virtualAccountNumber)
      ? { organisationId, virtualAccountNumber }
      : skipToken
  );

  const isFundingInformationChanged = fundingInformationData?.wire?.changed;
  // CBP-2466-account-funding: temporary until the feature flag is completely removed
  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();
  const isAccountFundingInfoEnabled =
    !!featureFlagsData?.['CBP-2466-account-funding'] &&
    orgSettings?.account_funding_enabled !== undefined &&
    orgSettings.account_funding_enabled > 0;

  const fetchingData = useCallback(async () => {
    try {
      const { data: virtualAccountDetailsResponse } =
        await fetchBalanceOverviewData({
          virtual_account_number: virtualAccountNumber,
          created_after: fromDate,
          created_before: toDate
        });

      if (virtualAccountDetailsResponse?.data) {
        const formattedDetails = formatPayloadToDetails(
          virtualAccountDetailsResponse.data
        );
        setVirtualAccountDetails(formattedDetails);
      }
    } catch {
      setError('There was an error fetching the account details');
    }
  }, [virtualAccountNumber, fromDate, toDate, fetchBalanceOverviewData]);

  useEffect(() => {
    virtualAccountNumber && fetchingData();
  }, [virtualAccountNumber, fromDate, toDate, fetchingData]);

  const balanceSection = useMemo<Item>(() => {
    return {
      header: 'Balance',
      key: 'balance',
      data: [],
      customChildren: virtualAccountDetails
        ? virtualAccountDetails.map(
            (virtualAccountDetail: AccountDetailsField) => (
              <div
                className="flex justify-between gap-4 border-b border-inpay-gray-primary-600 py-6 font-semibold last-of-type:border-0"
                key={virtualAccountDetail.name}
              >
                <div>{virtualAccountDetail.name}</div>
                <div>
                  {virtualAccountDetail.currencySymbol}{' '}
                  {virtualAccountDetail.amount}
                </div>
              </div>
            )
          )
        : null
    };
  }, [virtualAccountDetails]);

  const fundingSection = useMemo<Item>(() => {
    return {
      header: 'Account funding',
      key: 'funding',
      headerIcon: isFundingInformationChanged ? (
        <ExclamationCircleIcon className="size-6 stroke-2 text-inpay-orange-1000" />
      ) : null,
      data: [],
      customChildren: (
        <FundingSection
          fundingInformationData={fundingInformationData?.wire}
          isFundingInformationChanged={isFundingInformationChanged}
          refetchFundingInformationData={refetchFundingInformationData}
          virtualAccountNumber={virtualAccountNumber}
        />
      )
    };
  }, [
    refetchFundingInformationData,
    virtualAccountNumber,
    isFundingInformationChanged,
    fundingInformationData
  ]);

  // CBP-2466-account-funding: temporary until the feature flag is completely removed
  const sections =
    isAccountFundingInfoEnabled && fundingInformationData
      ? [balanceSection, fundingSection]
      : [balanceSection];

  if (!virtualAccountNumber) return null;
  if (!organisationId) throw new Error('Missing organisation id');

  return (
    <div
      role="complementary"
      aria-label="Balance details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <h2 className="border-b-inpay-pumice flex gap-1 border-b px-6 py-3.5 font-bold">
        Details
      </h2>
      {virtualAccountNumber && (
        <>
          <div className="border-b-inpay-gray-primary-300 border-b bg-inpay-green-secondary-light-200 px-6 py-[0.57rem] text-left text-sm">
            Account information:
            <span className="ml-1 font-bold">
              {currencyCode}{' '}
              {virtualAccountNumberFormatter(virtualAccountNumber)}
            </span>
          </div>
          <div className="overflow-auto">
            {virtualAccountDetails && (
              <DetailsBox data={sections} defaultValue="balance" />
            )}
            {error && <p className="px-10 py-6">{error}</p>}
          </div>
        </>
      )}
    </div>
  );
};
