import { XMarkIcon } from '@heroicons/react/24/outline';
import { DialogClose, DialogTitle } from '@radix-ui/react-dialog';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<typeof DialogTitle> & {
  withoutClose?: boolean;
};

export const Title = ({
  children,
  className,
  withoutClose,
  ...props
}: Props) => (
  <DialogTitle
    className={twMerge(
      'border-b-inpay-gray-primary-300 flex h-12 shrink-0 items-center justify-between gap-2 rounded-t-lg border-b bg-white px-6',
      className
    )}
    {...props}
  >
    {children}
    {!withoutClose && (
      <DialogClose
        aria-label="Close"
        className="outline-none focus:outline-none"
      >
        <XMarkIcon
          className="-m-1 size-6 outline-none focus:outline-none"
          strokeWidth={1}
        />
      </DialogClose>
    )}
  </DialogTitle>
);
