import { ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import cn from 'classnames';

export interface StatsTableProps {
  data: any[];
  columns: ColumnDef<any>[];
  title?: string;
  onHeaderClick?: () => void;
  totalPercentage?: number;
  className?: string;
}

export const StatsTable = ({
  title,
  data,
  columns,
  onHeaderClick,
  totalPercentage,
  className = ''
}: StatsTableProps) => {
  const table = useReactTable({
    data,
    columns,
    defaultColumn: {
      header: '',
      footer: ''
    },
    getCoreRowModel: getCoreRowModel()
  });

  const hasOnHeaderClickAction = !!onHeaderClick;
  const footerGroups = table.getFooterGroups();

  let hasFooterInfo = false;

  if (footerGroups.length > 0) {
    for (const header of footerGroups[0].headers) {
      if (header.column.columnDef.footer) {
        hasFooterInfo = true;
        break;
      }
    }
  }

  return (
    <div className="data-table__wrapper flex-1">
      <div className="datatable overflow-hidden rounded-lg bg-white shadow-plain-lg">
        <table
          role="table"
          aria-label={title}
          className={cn(className, 'w-full text-inpay-black-primary-1000')}
        >
          <thead className="border-inpay-gray-primary-300 top-0 m-0 border-b bg-inpay-green-secondary-light-200">
            <tr onClick={onHeaderClick}>
              <td
                colSpan={columns.length}
                className={cn(
                  'border-inpay-gray-primary-300 group items-center rounded-t-lg border-b bg-white px-4 py-3.5 text-center font-medium',
                  {
                    'cursor-pointer hover:bg-inpay-gray-secondary-600':
                      hasOnHeaderClickAction
                  }
                )}
              >
                <div className="flex items-baseline gap-1">
                  <span className="text-md font-bold">{title}:</span>
                  {totalPercentage}%
                  {hasOnHeaderClickAction && (
                    <ChevronRightIcon className="invisible ml-1 size-4 stroke-inpay-black-primary-1000 stroke-3 group-hover:visible" />
                  )}
                </div>
              </td>
            </tr>

            {table.getHeaderGroups().map((headerGroup: any) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn(
                      header.column.columnDef.className,
                      'border-none px-4 py-2 text-center text-xs font-normal'
                    )}
                  >
                    {header.isPlaceholder ? null : (
                      <span className="whitespace-nowrap">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  className="data-table__row group max-h-5 text-sm hover:bg-inpay-gray-primary-200"
                >
                  {row.getVisibleCells().map((cell: any) => (
                    <td
                      key={cell.id}
                      className={`break-words p-4 text-center ${cell.column.columnDef.className}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
          {hasFooterInfo && (
            <tfoot className="bg-inpay-gray-primary-200 text-sm">
              {footerGroups.map((group) => (
                <tr key={group.id}>
                  {group.headers.map((column) => (
                    <td key={column.id} className="px-4 text-center">
                      {flexRender(
                        column.column.columnDef.footer,
                        column.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
};
