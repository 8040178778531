import { SVGProps } from 'react';

export const WithdrawalRequestsIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 20C14.9587 20 20 20 20 20C20 20 20 14.9073 20 13C20 9.13401 16.866 6 13 6C9.13401 6 6 9.13401 6 13C6 16.866 9.13401 20 13 20ZM13 25C16.2402 25 25 25 25 25C25 25 25 16.3872 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        fill="#E2E4E3"
      />
      <path
        d="M17 24C17 27.866 20.134 31 24 31C27.866 31 31 27.866 31 24C31 20.134 27.866 17 24 17H17V24Z"
        fill="#F38E74"
      />
      <path
        d="M20.4001 24.75L24.6001 24.75L24.6001 27L27.6001 24L24.6001 21L24.6001 23.25L20.4001 23.25L20.4001 24.75Z"
        fill="#F1F2F1"
      />
    </svg>
  );
};
