export const TRANSLATIONS = {
  CANCEL_ORDER_SUCCESS: (orderId: string) =>
    `Successfully cancelled order ${orderId}`,
  CANCEL_ORDER_ERROR: 'Error while cancelling the order.',
  CANCEL_ORDER: 'Cancel order',
  ARE_YOU_SURE: 'Are you sure you want to cancel this collection order?',
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  DETAILS: 'Details',
  YES: 'Yes',
  NO: 'No',
  DISABLED_CONDITIONS:
    'An order can only be cancelled if it is in the Received or Pending state'
};
