interface Props {
  title: string;
  item: any;
  secondaryItem?: string;
}

export const WithdrawalDetailsItem = ({
  title,
  item,
  secondaryItem
}: Props) => {
  return (
    <div className="break-words pb-6">
      {title && <div>{title}</div>}
      {item && (
        <div>
          {item}
          {secondaryItem && ' ' + secondaryItem}
        </div>
      )}
    </div>
  );
};
