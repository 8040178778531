import { ReactNode } from 'react';

import { PermitAction, usePermissions } from 'hooks/usePermissions';

interface Props {
  children: ReactNode;
  requiredPermission?: PermitAction;
}

export const Restricted = ({ children, requiredPermission }: Props) => {
  const { isPermitted } = usePermissions();

  if (!requiredPermission || isPermitted(requiredPermission)) {
    return <>{children}</>;
  }

  return null;
};
