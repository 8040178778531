import {
  NavigateFunction,
  NavigateOptions,
  To,
  useNavigate,
  useParams
} from 'react-router-dom';

export const useOrgNavigate = function (): NavigateFunction {
  const { organisationId } = useParams();
  const navigate = useNavigate();

  const orgNavigate: NavigateFunction = function (
    to: To | number,
    options: NavigateOptions = {}
  ) {
    if (typeof to == 'number') {
      navigate(to);
    } else {
      if (typeof to == 'string' && (to == '' || to[0] == '/')) {
        to = `/org/${organisationId}${to}`;
      }
      navigate(to, options);
    }
  };
  return orgNavigate;
};
