import { XMarkIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { MouseEvent, useEffect, useState } from 'react';

import { logEventToHotjar } from 'utils/logging';

import { Content as DialogContent, Overlay, Title } from '@organisms/Dialog';

import {
  useDeleteManualPayoutDraftMutation,
  useFetchManualPayoutDraftQuery
} from 'redux/api/payouts/payoutsApi';

import { CancelButton } from './ConfirmCancelDialog';
import { CREATE_MANUAL_PAYOUT_DIALOG_TEST_ID } from './constants';
import { Content } from './Content';

interface Props {
  onClose: () => void;
}

export const CreateManualPayoutDialog = ({ onClose }: Props) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { isSuccess, error } = useFetchManualPayoutDraftQuery();
  const [removeDraft] = useDeleteManualPayoutDraftMutation();

  useEffect(() => {
    logEventToHotjar('create-manual-payout:dialog-open');
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (!isDialogOpen) {
        removeDraft().then(() => setIsDialogOpen(true));
      }
    } else if (error && 'status' in error && error.status == 404) {
      setIsDialogOpen(true);
    }
  }, [isSuccess, error]);

  const onCancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // order is matter! :)
    // the dialog should be closed before removing draft
    // Then we avoid cache-invalidation and calling unnecessary fetchPayoutDraft query
    onCloseHandler();
    removeDraft();
  };

  const onCloseHandler = () => {
    setIsDialogOpen(false);
    onClose();
  };

  return (
    <>
      {isDialogOpen && (
        <Dialog open={isDialogOpen} onOpenChange={onClose}>
          <DialogPortal>
            <Overlay>
              <DialogContent
                onInteractOutside={(e) => e.preventDefault()}
                onEscapeKeyDown={(e) => e.preventDefault()}
                className="flex w-114 flex-col"
                data-testid={CREATE_MANUAL_PAYOUT_DIALOG_TEST_ID}
              >
                <Title className="border-none" withoutClose>
                  <span>Create manual payout</span>
                  <CancelButton onAction={onCancel}>
                    <XMarkIcon className="-m-1 size-8 outline-none focus:outline-none" />
                  </CancelButton>
                </Title>
                <Content onFinish={onCloseHandler} onCancel={onCancel} />
              </DialogContent>
            </Overlay>
          </DialogPortal>
        </Dialog>
      )}
    </>
  );
};
