import { ArrowRightIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import dayjs, { API_DATE_FORMAT } from 'utils/dayjs';

import { useFetchFXRateQuery } from 'redux/api/organisations/fxApi';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { ErrorPanel } from '@atoms/ErrorPanel';

import { Selector } from '@molecules/Selector/Selector';

import { TRANSLATIONS } from './constants';
import { formatExchangeRate } from './formatExchangeRate';

interface Props {
  organisationId: string;
  payCurrencies: Array<string>;
  buyCurrencies: Array<string>;
}

export const FXRateCalculator = ({
  organisationId,
  payCurrencies,
  buyCurrencies
}: Props) => {
  const missingOptions = buyCurrencies.length < 1 || payCurrencies.length < 1;
  if (missingOptions) {
    console.error(TRANSLATIONS.ERROR_MISSING_OPTIONS);
  }

  const [payCurrency, setPayCurrency] = useState(payCurrencies[0] ?? '');
  const [buyCurrency, setBuyCurrency] = useState(buyCurrencies[0] ?? '');

  const { isFetching, isError, error, data } = useFetchFXRateQuery({
    organisationId,
    payCurrency,
    buyCurrency
  });

  useEffect(() => {
    if (payCurrency === buyCurrency) {
      const alternativeBuyCurrency = buyCurrencies.find(
        (currency) => currency !== payCurrency
      );
      if (alternativeBuyCurrency) setBuyCurrency(alternativeBuyCurrency);
      else {
        const alternativePayCurrency = payCurrencies.find(
          (currency) => currency !== buyCurrency
        );
        if (alternativePayCurrency) setPayCurrency(alternativePayCurrency);
        else {
          console.error(TRANSLATIONS.ERROR_NO_UNIQUE);
        }
      }
    }
  }, [payCurrencies, buyCurrencies, payCurrency, buyCurrency]);

  // NOTE (LTJ): FX Rate might not be available.
  const rateUnavailable =
    !isFetching && isError && (error as any)?.status === 404;

  const isComponentErrors =
    !isFetching && ((isError && !rateUnavailable) || missingOptions);
  if (isComponentErrors)
    return <ErrorPanel message={TRANSLATIONS.ERROR_LOADING} />;

  const exchangeRateData = data?.data;
  const estimatedNextUpdate = dayjs(exchangeRateData?.utc_time)
    .add(1, 'day')
    .startOf('day')
    .format(API_DATE_FORMAT);
  const retrievedText = rateUnavailable
    ? ''
    : `Information retrieved on ${exchangeRateData?.utc_time}.`;
  const nextUpdateText = `Next update: ${estimatedNextUpdate} 00:00 (UTC).`;
  const resultsText = rateUnavailable
    ? 'FX Rate not supported.'
    : formatExchangeRate(exchangeRateData);

  return (
    <div className="flex flex-col gap-2">
      <div
        className="text-inpay-gray-primary-1000"
        data-testid="updateScheduleText"
      >
        {isFetching ? <BlindingDots /> : `${retrievedText} ${nextUpdateText}`}
      </div>
      <div className="flex items-baseline gap-8">
        <div className="flex w-68 gap-2">
          <Selector
            selectChoices={payCurrencies
              .filter((currency) => currency !== buyCurrency)
              .map((currency) => ({
                value: currency,
                label: currency
              }))}
            value={payCurrency}
            onChange={setPayCurrency}
          />
          <ArrowRightIcon
            className={cn(
              isFetching && 'animate-pulse',
              'w-16 stroke-inpay-green-primary-1000 stroke-[0.5]'
            )}
          />
          <Selector
            selectChoices={buyCurrencies
              .filter((currency) => currency !== payCurrency)
              .map((currency) => ({
                value: currency,
                label: currency
              }))}
            value={buyCurrency}
            onChange={setBuyCurrency}
          />
        </div>
        <div
          className="h-full text-inpay-gray-primary-1000"
          data-testid="resultsText"
        >
          {isFetching ? <BlindingDots /> : resultsText}
        </div>
      </div>
    </div>
  );
};
