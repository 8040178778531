import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@radix-ui/react-collapsible';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { DateRangeOption, DateRangeType } from 'models/dateRange';

import { DateRangeParams } from 'hooks/useGlobalFilters';

import { CustomFilters } from './CustomFilters';
import { DateRangeLabel } from './DateRangeLabel';
import { dateRangeOptions } from './dateRangeOptions';
import { TogglingFilters } from './TogglingFilters';

export interface DateRangeDefaultFilters {
  type: DateRangeType;
  from?: string;
  to?: string;
}

export interface DateRangeFilterProps {
  disabledFilters?: DateRangeType[];
  onChange?: (filters: DateRangeParams) => void;
  defaultFilters?: DateRangeDefaultFilters;
  disabled?: boolean;
  closeOnChange?: boolean;
  classNames?: DateRangeFilterClassNames;
}

interface DateRangeFilterClassNames {
  className?: string;
  triggerClassName?: string;
}

export const DateRangeFilter = ({
  disabledFilters = [],
  onChange,
  defaultFilters,
  disabled = false,
  closeOnChange = false,
  classNames
}: DateRangeFilterProps) => {
  const [isCustomOpen, setIsCustomOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [currentFilter, setCurrentFilter] = useState<DateRangeOption>(
    dateRangeOptions['d30']
  );

  const triggerProps = {
    className: twMerge(
      cn(
        'h-12 w-full cursor-default rounded-lg px-4',
        classNames?.className,
        classNames?.triggerClassName
      )
    )
  };

  const updateValue = (value: DateRangeType) => {
    if (value == 'custom' || (!value && currentFilter.value == 'custom')) {
      setIsCustomOpen(true);
    } else if (!value) {
      return;
    } else {
      updateFilter(dateRangeOptions[value]);
    }
  };

  const selectCustomRange = (value: any) => {
    const filter = dateRangeOptions.custom;
    if (filter.value == 'custom')
      filter.range = {
        to: value.to && dayjs(value.to),
        from: value.from && dayjs(value.from)
      }; //ts check
    updateFilter(filter);
    setIsCustomOpen(false);
    setIsOpen(false);
  };

  const updateFilter = (filter: DateRangeOption) => {
    setCurrentFilter(filter);

    closeOnChange && filter.value != 'custom' && setIsOpen(false);
    onChange &&
      (filter.value == 'custom'
        ? onChange({
            type: filter.value,
            from: filter.range.from?.format(DATE_FORMAT),
            to: filter.range.to?.format(DATE_FORMAT)
          })
        : onChange({
            type: filter.value
          }));
  };

  /*
    This useEffect observes changes in the defaultFilters because we need to
    reflect changes triggered from the outside.

    Ex.

    When a user navigates to an individual transaction, we need to ensure the
    transaction is in the transactions table. We can only know the range to use
    after loading the transaction information. Therefore the change in the
    global filters gets triggered by the component rendering the details.
    Once it happens we need to reflect the change in the date picker so it aligns
    with the data rendered in the table.

    Att. @dmuneras. A react noob. Improvements welcome.
  */
  useEffect(() => {
    if (defaultFilters?.type) {
      if (defaultFilters.type == 'custom') {
        const filter = dateRangeOptions.custom;
        if (filter.value == 'custom')
          filter.range = {
            from: dayjs(defaultFilters.from, DATE_FORMAT),
            to: dayjs(defaultFilters.to, DATE_FORMAT)
          };
        setCurrentFilter(filter);
      } else {
        setCurrentFilter(dateRangeOptions[defaultFilters.type]);
      }
    }
  }, [defaultFilters]);

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return (
    <div className={`relative h-11 w-72 select-none ${classNames?.className}`}>
      <Collapsible
        ref={ref}
        open={isOpen}
        onOpenChange={setIsOpen}
        disabled={disabled}
        className={cn('w-72 min-w-fit rounded-lg', {
          group: !disabled,
          'border border-white bg-white shadow-plain': !isOpen,
          'hover:shadow-plain-lg': !isOpen && !disabled,
          'border-inpay-gray-primary-300 border bg-inpay-gray-primary-200 shadow-plain-lg':
            isOpen
        })}
      >
        <CollapsibleTrigger {...triggerProps}>
          <DateRangeLabel
            filter={currentFilter}
            isOpen={isOpen}
            disabled={disabled}
            isCustomOpen={isCustomOpen}
            closeCustom={() => setIsCustomOpen(false)}
          />
        </CollapsibleTrigger>
        <CollapsibleContent className="w-full p-4">
          {isCustomOpen ? (
            <CustomFilters
              defaultValue={{
                from:
                  currentFilter.value == 'custom'
                    ? currentFilter?.range?.from
                    : undefined,
                to:
                  currentFilter.value == 'custom'
                    ? currentFilter?.range?.to
                    : undefined
              }}
              onSelect={selectCustomRange}
            />
          ) : (
            <TogglingFilters
              disabledFilters={disabledFilters}
              value={currentFilter.value}
              onValueChange={updateValue}
            />
          )}
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};
