import classNames from 'classnames';

import styles from './styles.module.css';

interface Props {
  className?: string;
  sorted: false | 'desc' | 'asc';
}

export const SortingIcon = ({ className, sorted }: Props) => {
  return (
    <span
      className={classNames(styles['icon'], className, {
        [styles['icon__asc']]: sorted == 'asc',
        [styles['icon__desc']]: sorted == 'desc'
      })}
    />
  );
};
