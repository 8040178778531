import { Divider, Flex, Tag } from 'antd/es';

import { formatDate, formatHours } from 'utils/dayjs';

import {
  WithdrawalRequestData,
  withdrawalRequestStatuses
} from 'models/withdrawal';

interface Props {
  withdrawalRequestFullData: WithdrawalRequestData;
}

export const WithdrawalDetailsHistory = ({
  withdrawalRequestFullData
}: Props) => {
  const reversedTransitions = [
    ...withdrawalRequestFullData.status_transitions
  ].reverse();

  return reversedTransitions.map((transition, index) => {
    const transitionStatusStyle = withdrawalRequestStatuses.find(
      (s) => s.value === transition.status
    )?.style;

    const tagClassName = `w-fit max-w-full px-2 py-1 ${transitionStatusStyle?.background} ${transitionStatusStyle?.hover}`;

    return (
      <div key={index} className="w-[300px]">
        <Flex className="justify-between pb-1">
          <div>Status</div>
          <div>
            {transition.created_at ? formatDate(transition.created_at) : ''}
          </div>
        </Flex>
        <Flex className="justify-between">
          <Tag className={`${tagClassName} `}>
            {transition.status[0].toUpperCase() + transition.status.slice(1)}
          </Tag>

          <div className="text-xl font-semibold">
            {transition.created_at
              ? formatHours(transition.created_at, 'utc') + ' UTC'
              : ''}
          </div>
        </Flex>
        {index < withdrawalRequestFullData.status_transitions.length - 1 && (
          <Divider />
        )}
      </div>
    );
  });
};
