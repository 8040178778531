import { ArrowRightOnRectangleIcon as LoginIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { type InferType } from 'yup';

import { allFieldsDirty, setFormErrors } from 'utils/formHelpers';

import { loginFormSchema as schema } from 'validators/loginForm';

import { useLoginMutation } from 'redux/api/authenticationApi';
import { useLazyGetCurrentUserQuery } from 'redux/api/customerApi';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { InputField } from 'components/ui/molecules/InputField';
import { PasswordField } from 'components/ui/molecules/PasswordField';

interface LoginFormProps {
  onLoginSuccess: (user: any) => void;
  loginExpired: boolean;
}

interface Inputs extends InferType<typeof schema> {
  base: null;
}

const styledLoginIcon = (
  <LoginIcon
    width="40"
    height="40"
    className="size-10 self-center stroke-inpay-black-primary-1000 stroke-1.5"
  />
);

export const LoginForm = ({ onLoginSuccess, loginExpired }: LoginFormProps) => {
  const locationState = useLocation().state as { mfaExpired: boolean };
  const isExpired = locationState?.mfaExpired;
  const [login] = useLoginMutation();
  const [fetchUser] = useLazyGetCurrentUserQuery();

  const { register, handleSubmit, setError, formState } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      await handleSubmit(async (data) => {
        await login(data).unwrap();
        const userResult = await fetchUser().unwrap();
        onLoginSuccess(userResult.user);
      })(event);
    } catch (error: any) {
      setFormErrors({ error, setError, fields: Object.keys(schema.fields) });
    }
  };

  return (
    <>
      {loginExpired ? (
        <div className="align-center mb-5 flex w-full flex-col justify-center">
          {styledLoginIcon}
          <h1 className="mt-5 text-center text-[2rem] font-medium">
            Your login expired
          </h1>
        </div>
      ) : (
        <div className="align-center mb-10 flex w-full justify-center">
          {styledLoginIcon}
          <h1 className="ml-5 text-center text-[2rem] font-medium">Log in</h1>
        </div>
      )}
      {loginExpired && (
        <p className="mb-8 text-center tracking-tight">Please log in again.</p>
      )}
      <form onSubmit={onSubmit}>
        <InputField
          {...register('email')}
          className="mb-10"
          label="Email"
          name="email"
          autoComplete="username"
          errorMessage={formState.errors.email?.message}
        />
        <PasswordField
          {...register('password')}
          className="mb-10"
          label="Password"
          name="password"
          autoComplete="current-password"
          errorMessage={formState.errors.password?.message}
        />
        <PrimaryButton
          className="w-full font-medium"
          type="submit"
          value="Submit"
          size="large"
          disabled={
            formState.isSubmitSuccessful ||
            !allFieldsDirty(formState.dirtyFields, Object.keys(schema.fields))
          }
        >
          Log in
        </PrimaryButton>
      </form>
      {formState.errors.base && (
        <div
          role="alert"
          className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
          style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
        >
          {formState.errors.base.message}
        </div>
      )}
      {isExpired && (
        <div
          role="alert"
          className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
          style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
        >
          Your token was expired, please login again
        </div>
      )}
    </>
  );
};
