import { formatHours } from 'utils/dayjs';

import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

interface Props {
  value: string;
}

/**
 * Formatted date and time presentation element
 * @value string Serialized ISO DateTime value
 * @returns A rendered element presenting the date and time
 */
export const FormattedDateTime = ({ value }: Props) => {
  if (!value) {
    return <p>Not available</p>;
  }
  const utcString = new Date(value)?.toString();

  return (
    <time dateTime={value} title={utcString || value}>
      <FormattedDate value={value} />
      <div className="text-base font-medium">{formatHours(value, 'utc')}</div>
    </time>
  );
};
