import { useMemo } from 'react';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';
import { useFetchCurrenciesQuery } from 'redux/api/currenciesApi';

import { ErrorPanel } from '@atoms/ErrorPanel';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import { TRANSLATIONS } from './constants';
import { FXRateCalculator } from './FXRateCalculator';

interface Props {
  organisationId: string;
}

export const WidgetFXRate = ({ organisationId }: Props) => {
  const {
    isLoading: loadingAccounts,
    isError: isErrorLoadingAccounts,
    data: { data: virtualAccountsData } = {}
  } = useFetchVirtualAccountsQuery({ organisationId });

  const {
    isLoading: loadingProductCurrencies,
    isError: isErrorLoadingProductCurrencies,
    data: { data: productCurrenciesData } = {}
  } = useFetchCurrenciesQuery();

  const isLoading = loadingAccounts || loadingProductCurrencies;
  const isError = isErrorLoadingAccounts || isErrorLoadingProductCurrencies;

  if (isError) {
    console.error(TRANSLATIONS.ERROR_MISSING_OPTIONS);
  }

  const payOptions = useMemo(
    () =>
      virtualAccountsData
        ?.filter(
          (value, index, array) =>
            array.findIndex((v) => v.currency_code === value.currency_code) ===
            index
        )
        .map((account) => account.currency_code) ?? [],
    [virtualAccountsData]
  );

  const buyOptions = useMemo(
    () =>
      productCurrenciesData
        ?.filter(
          (value, index, array) =>
            array.findIndex((v) => v.iso === value.iso) === index
        )
        .map((productCurrency) => productCurrency.iso) ?? [],
    [productCurrenciesData]
  );

  // NOTE (LTJ): Whether we are missing either buy/pay options
  const missingOptions = payOptions.length === 0 || buyOptions.length === 0;
  // NOTE (LTJ): Whether we only have 1:1 identical options
  const onlyIdenticalOptions =
    payOptions.length === 1 &&
    buyOptions.length === 1 &&
    payOptions[0] === buyOptions[0];

  // NOTE (LTJ): These eventualities are not necessarily errors, but missing org capabilities
  if (!isLoading && (missingOptions || onlyIdenticalOptions)) return null;

  return (
    <section
      aria-label="Exchange Rates"
      className="flex w-full flex-col gap-2 rounded-lg bg-white p-4 shadow-plain-lg"
    >
      <h3 className="text-md font-bold">{TRANSLATIONS.WIDGET_TITLE}</h3>
      {isLoading ? (
        <RollingLoader ariaLabel={TRANSLATIONS.LOADING_OPTIONS} />
      ) : isError ? (
        <ErrorPanel message={TRANSLATIONS.ERROR_LOADING} />
      ) : (
        <FXRateCalculator
          organisationId={organisationId}
          payCurrencies={payOptions}
          buyCurrencies={buyOptions}
        />
      )}
    </section>
  );
};
