import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';

interface MeterProps {
  label?: string;
  value: number;
  minValue?: number;
  maxValue?: number;
  highLevel?: number;
  lowLevel?: number;
  className?: string;
  barClassName?: string;
}

export const Meter = (props: MeterProps) => {
  const {
    value,
    label,
    className = '',
    barClassName = '',
    lowLevel = 1,
    highLevel = 2,
    minValue = 0,
    maxValue = 3
  } = props;

  const percentage = (value - minValue) / (maxValue - minValue);
  const barWidth = `${Math.round(percentage * 100)}%`;
  const id = nanoid();
  return (
    <div
      aria-valuenow={value}
      aria-valuemax={maxValue}
      aria-valuemin={minValue}
      aria-labelledby={id}
      role="meter"
      className={cn('flex items-center space-x-1', className)}
    >
      {label && (
        <span
          id={id}
          className={cn('text-xs', {
            'text-inpay-green-primary-mixes-60%-secondary': value > highLevel,
            'text-inpay-orange-800': value > lowLevel && value <= highLevel,
            'text-inpay-red-1000': value <= lowLevel
          })}
        >
          {label}
        </span>
      )}
      <div
        className={cn(
          'h-2 overflow-hidden rounded bg-inpay-gray-primary-800',
          barClassName
        )}
      >
        <div
          className={cn('h-2', {
            'bg-inpay-green-primary-mixes-60%-secondary': value > highLevel,
            'bg-inpay-orange-800': value > lowLevel && value <= highLevel,
            'bg-inpay-red-1000': value <= lowLevel
          })}
          style={{ width: barWidth }}
        />
      </div>
    </div>
  );
};
