import cn from 'classnames';
import { MouseEvent } from 'react';

interface Props {
  label: string;
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

export const Badge = ({ label, className, onClick }: Props) => {
  return (
    <div
      onClick={onClick}
      className={cn(
        className,
        'w-fit max-w-full overflow-hidden rounded-lg px-2 py-1 text-sm'
      )}
    >
      {label}
    </div>
  );
};
