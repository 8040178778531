import { MouseEventHandler } from 'react';
import { toast } from 'react-toastify';

import { useForgotPasswordMutation } from 'redux/api/authenticationApi';

import { TextButton } from '@atoms/buttons/TextButton';

interface Props {
  email: string;
}
const getErrorMessage = (payload: any) => {
  if (typeof payload.data === 'string') {
    return payload.data;
  } else if (payload.data && 'payloads' in payload.data) {
    return payload.data.errors
      .map(({ title }: { title: string }) => title)
      .join('. ');
  } else {
    return 'Something went wrong.';
  }
};
export const ResetPasswordButton = ({ email }: Props) => {
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const handleResetPassword: MouseEventHandler<HTMLButtonElement> = async (
    event: any
  ) => {
    event.preventDefault();

    try {
      await forgotPassword({ email: email });

      toast.success('Reset password email has been sent', {
        position: 'bottom-center',
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    } catch (error: any) {
      toast.error(getErrorMessage(error), {
        position: 'bottom-center',
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
      });
    }
  };
  return (
    <TextButton
      className="hover:bg-transparent"
      onClick={handleResetPassword}
      disabled={isLoading}
    >
      Reset Password
    </TextButton>
  );
};
