import { ComponentPropsWithRef, ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

interface LabelProps extends ComponentPropsWithRef<'label'> {
  children?: ReactElement;
  text: string;
  htmlFor?: string;
  disabled?: boolean;
}

export const Label = ({
  children,
  text,
  className,
  disabled,
  ...props
}: LabelProps) => {
  return (
    <label
      className={twMerge(
        'flex cursor-pointer flex-col gap-0.5',
        disabled && 'cursor-default text-inpay-gray-primary-1000',
        className
      )}
      {...props}
    >
      <span className="pl-2 text-xs">{text}</span>
      {children}
    </label>
  );
};
