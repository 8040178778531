import { IReasonCode } from 'models/reasonCode';
import { ReasonCodesReport, StatesReport } from 'models/stats';

import { customerApi } from '../customerApi';

type PayoutsStatsKeys = 'categories' | 'created_after' | 'created_before';

export type PayoutsStatsParams = Partial<Record<PayoutsStatsKeys, string>>;

export interface StateReasonCodeStats {
  payout_count: number;
  reason_codes: Array<ReasonCodeStats>;
}

export interface ReasonCodeStats {
  reason_code_id: number;
  category: string;
  kind: string;
  symbol: string;
  payout_count: number;
  external_message: string;
}

export type ReasonCodesByStates = Record<string, StateReasonCodeStats>;

export const PayoutsStatsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    getStatsPerReasonCodes: builder.query<
      ReasonCodesReport,
      PayoutsStatsParams
    >({
      query: (params) =>
        `/v1/payouts/payout_requests/stats/reason_codes_reports?${new URLSearchParams(
          params
        )}`,
      providesTags: ['Stats']
    }),

    getCategoryCountsStats: builder.query<StatesReport, PayoutsStatsParams>({
      query: (params) =>
        `/v1/payouts/payout_requests/stats/state_category_counts_reports?${new URLSearchParams(
          params
        )}`,
      providesTags: ['Stats']
    }),
    getReasonCodes: builder.query<{ data: Array<IReasonCode> }, any>({
      query: (params) =>
        `/v1/payouts/payout_request_reason_codes?${new URLSearchParams(params)}`,
      providesTags: ['Stats']
    }),
    getReasonCodesByStates: builder.query<{ data: ReasonCodesByStates }, any>({
      query: (params) =>
        `/v1/payouts/payout_requests/stats/state_category_reason_code_counters?${new URLSearchParams(
          params
        )}`,
      providesTags: ['Stats']
    })
  })
});

export const {
  useGetStatsPerReasonCodesQuery,
  useGetCategoryCountsStatsQuery,
  useGetReasonCodesQuery,
  useGetReasonCodesByStatesQuery
} = PayoutsStatsApi;
