import { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchTransactionsQuery } from 'redux/api/accounts/transactionApi';

import { TransactionData } from 'models/transaction';

import { TABLE_NAME } from 'components/pages/Search/constants';

import { TRANSACTIONS_SEARCH_RESULTS_TEST_ID } from './constants';
import { useTransactionsColumns } from './transactionsColumns';

import { DataTable } from '../DataTable';

interface Props {
  onRowClick?: (
    event: MouseEvent<HTMLElement>,
    rowData: TransactionData
  ) => void;
  onSuccess: (transaction: TransactionData | undefined) => void;
  searchParams: any;
}

export const TransactionsSearchResults = ({
  onRowClick,
  searchParams,
  onSuccess
}: Props) => {
  const columnDefinitions = useTransactionsColumns();

  const { organisationId } = useParams();
  const { data, error, isFetching, isSuccess } = useFetchTransactionsQuery({
    organisationId,
    ...searchParams
  });

  useEffect(() => {
    if (!isFetching && isSuccess) {
      onSuccess(data?.data[0]);
    }
  }, [isFetching, isSuccess, data]);

  const transactions = data?.data || [];

  return (
    <>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center">
          <div>{error.error}</div>
        </div>
      )}
      {transactions.length > 0 && (
        <DataTable
          name={TABLE_NAME.ACCOUNT_TRANSACTIONS}
          topBar="name-with-count"
          total={data?.meta?.count}
          onRowClick={onRowClick}
          className="mb-4 h-auto"
          columns={columnDefinitions}
          data={transactions}
          data-testid={TRANSACTIONS_SEARCH_RESULTS_TEST_ID}
        />
      )}
      {isFetching && (
        <div
          role="progressbar"
          aria-label="searching transactions"
          className="flex h-40 w-full items-center justify-center"
        >
          Searching transactions
        </div>
      )}
    </>
  );
};
