import * as yup from 'yup';

export const userDetailsSchema = yup
  .object({
    first_name: yup.string().label('First Name').required(),
    last_name: yup.string().label('Last Name').required(),
    email: yup.string().email().label('Email'),
    role: yup.string().label('Role').required()
  })
  .required();
