import { merge as objectMapper } from 'object-mapper';

import { BatchesRequestParams } from 'redux/api/payouts/batchesApi';

import { LocalState } from './localState';

export type Params = LocalState & { organisationId: string };

export const apiParams = function (params: Params): BatchesRequestParams {
  // eslint-disable-next-line prefer-const
  let request: BatchesRequestParams = {
    organisationId: '',
    filter: {
      kind: 'csv'
    },
    page: {
      size: 20
    }
  };

  const map = {
    'filters.status': 'filter.state',
    organisationId: 'organisationId',
    cursorAfter: 'page.cursor_after',
    cursorBefore: 'page.cursor_before',
    includeCursor: 'page.include_cursor'
  };

  objectMapper<BatchesRequestParams>(params, request, map);

  return request;
};
