import { useParams } from 'react-router-dom';

import { CopyButton } from '@organisms/AccountDetails/CopyButton';

import { useFetchFundingInformationQuery } from 'redux/api/accounts/virtualAccountApi';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

export const WireFundingInformation = ({
  virtualAccountNumber
}: {
  virtualAccountNumber: string;
}) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const {
    data: { data } = {},
    isFetching,
    isError
  } = useFetchFundingInformationQuery({ organisationId, virtualAccountNumber });

  const { wire: wireInformation } = data || {};

  const instructionsField = (label: string, value: string): JSX.Element => {
    return (
      <div className="flex items-center justify-between gap-2">
        <div className="shrink-0 text-sm">{label}</div>
        <div
          className="grow-1 w-full truncate text-right font-bold"
          title={value}
        >
          {value}
        </div>
        <CopyButton textToCopy={value} />
      </div>
    );
  };

  const loadingText = 'Loading account funding information data';

  return (
    <div>
      {isFetching && (
        <div className="flex flex-col items-center gap-y-4 p-4">
          <RollingLoader ariaLabel={loadingText} />
          <div className="text-xs">
            <span className="invisible">...</span>
            <span>{loadingText}</span>
            <BlindingDots />
          </div>
        </div>
      )}

      {isError && (
        <div className="rounded-md bg-inpay-gray-primary-400 p-3 text-sm">
          Funding information not available for this account.
        </div>
      )}
      {!isFetching && !isError && wireInformation && (
        <>
          <div className="mb-3 font-bold">Funding instructions</div>
          {wireInformation && (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <div className="text-sm">
                  Please go to your online bank and make a funds transfer using
                  the payment details below:
                </div>
              </div>
              <div className="flex flex-col gap-4 rounded-md bg-inpay-gray-primary-200 p-3">
                {instructionsField(
                  'Beneficiary Name',
                  wireInformation.beneficiary.full_name
                )}
                {instructionsField(
                  'Beneficiary IBAN',
                  wireInformation.beneficiary.iban
                )}
                {instructionsField(
                  'Beneficiary Bank',
                  wireInformation.beneficiary.bank_name
                )}
              </div>
              <div className="flex items-center justify-between gap-4 rounded-md bg-inpay-blue-200 p-3">
                <div className="text-sm">
                  Please make sure to include your funding reference in payment
                  details.
                </div>
                <div className="flex gap-2">
                  <span className="font-bold">
                    {wireInformation.funding_reference}
                  </span>
                  <CopyButton textToCopy={wireInformation.funding_reference} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
