import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal
} from '@radix-ui/react-alert-dialog';
import { useParams } from 'react-router-dom';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { useFetchPayoutQuery } from 'redux/api/payouts/payoutsApi';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import { CheckCircleIcon } from 'components/ui/atoms/Icon';

import { PAYOUT_NEEDS_APPROVAL } from '../constants';

interface Props {
  onClose: () => void;
  reference: string;
}

const formatter = new MoneyFormatter();

export const AcceptanceDialog = ({ onClose, reference }: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { data, isLoading, isSuccess } = useFetchPayoutQuery({ reference });
  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const isOrgApprovalEnabled = !!orgSettings?.manual_payout_number_of_approvers;

  // temporary until the feature flag is completely removed
  const isManualPayoutApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-2001-approval-workflow-manual-payouts'];

  return (
    <AlertDialog open>
      <AlertDialogPortal>
        <AlertDialogOverlay
          className="fixed inset-0 bg-inpay-green-primary-1000/90"
          onClick={(e) => e.stopPropagation()}
        />

        {data && data.data && (
          <AlertDialogContent className="fixed left-1/2 top-1/2 w-116 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-inpay-gray-secondary-600 shadow-[0_0_6px_#155C56]">
            <AlertDialogDescription className="p-6">
              {isLoading && (
                <div className="flex flex-col items-center gap-y-4 rounded-b-lg bg-inpay-gray-primary-200 p-4">
                  <RollingLoader ariaLabel="Processing" />
                  <div className="text-xs">
                    <span>Processing</span>
                    <BlindingDots />
                  </div>
                </div>
              )}
              {isSuccess && (
                <div className="flex flex-col gap-4">
                  <div className="flex flex-col items-center gap-4">
                    <CheckCircleIcon className="mr-1 w-12 stroke-inpay-gray-primary-1000" />
                    <div className="text-lg font-bold">
                      Payout request successfully created
                    </div>
                    <div className="text-3xl font-bold">
                      {data.data.currency} {formatter.format(data.data.amount)}
                    </div>
                  </div>
                  <div className="bg flex flex-col rounded-md border bg-inpay-gray-primary-200 p-4">
                    <div>
                      End-to-end ID:
                      <span className="ml-1 font-semibold">
                        {data.data.end_to_end_id}
                      </span>
                    </div>
                    <div>
                      Inpay reference number:
                      <span className="ml-1 font-semibold">{reference} </span>
                    </div>
                  </div>
                  {isManualPayoutApprovalFlowEnabled && isOrgApprovalEnabled ? (
                    <div className="rounded bg-inpay-yellow-600 p-4">
                      {PAYOUT_NEEDS_APPROVAL}
                    </div>
                  ) : (
                    <div className="pt-4">
                      For more information and tracking of this request please
                      check payout requests with the provided End-to-end ID
                    </div>
                  )}
                </div>
              )}
            </AlertDialogDescription>
            <div className="flex w-full flex-col gap-4 px-6 pb-6">
              <SecondaryButton
                className="w-full"
                size="large"
                disabled={isLoading}
                onClick={onClose}
              >
                View payout requests
              </SecondaryButton>
              <PrimaryButton
                className="w-full"
                size="large"
                disabled={isLoading}
                onClick={onClose}
              >
                Finish and close
              </PrimaryButton>
            </div>
          </AlertDialogContent>
        )}
      </AlertDialogPortal>
    </AlertDialog>
  );
};
