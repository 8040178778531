import AppStoreBadge from 'assets/images/app-store-badge.svg';
import GooglePlayBadge from 'assets/images/google-play-badge.svg';

import { useRedirectTo } from 'hooks/useRedirectTo';

import { MfaSetupForm } from 'components/ui/organisms/MfaSetupForm';

export const MfaSetup = () => {
  const { navigateWithRedirectTo } = useRedirectTo();
  const onSuccess = () => {
    navigateWithRedirectTo('/mfa-verify');
  };

  return (
    <div className="flex size-full flex-col justify-center">
      <div className="flex flex-col self-center bg-inpay-gray-secondary-1000 p-10 md:size-fit md:rounded-lg md:px-40 md:py-14">
        <MfaSetupForm onSuccess={onSuccess} />
        <div className="mt-10 text-center text-xs md:text-sm">
          <span>Do not have the Authy App? Get it here:</span>
          <div className="mt-1 flex flex-row justify-center md:mt-4">
            <a
              href="https://play.google.com/store/apps/details?id=com.authy.authy"
              className="mr-2"
              target="_blank"
              rel="noreferrer"
            >
              <GooglePlayBadge className="h-10" />
            </a>
            <a
              href="https://itunes.apple.com/us/app/authy/id494168017"
              className="ml-2"
              target="_blank"
              rel="noreferrer"
            >
              <AppStoreBadge className="h-10" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
