// TODO: There is a lot of duplication related to query params to api params
// in the code base. We need to define a good way to reuse this and standarise.
// Att. @dmuneras
import dayjs from 'dayjs';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { API_DATE_FORMAT, DATE_FORMAT } from 'utils/dayjs';

import { TransactionsRequest } from 'redux/api/accounts/virtualAccountApi';

export type PaginationState = {
  sort: string;
  includeCursor?: boolean;
  cursorBefore?: string;
  cursorAfter?: string;
};

export type LocalState = PaginationState;

type Action =
  | { type: 'reset' }
  | { type: 'setSort'; value: string }
  | { type: 'setCursorBefore'; value?: string }
  | { type: 'setCursorAfter'; value?: string };

function reducer(state: LocalState, action: Action): LocalState {
  switch (action.type) {
    case 'setSort':
      return {
        ...state,
        sort: action.value,
        includeCursor: undefined,
        cursorBefore: undefined,
        cursorAfter: undefined
      };
    case 'setCursorBefore':
      return {
        ...state,
        cursorBefore: action.value,
        cursorAfter: undefined,
        includeCursor: undefined
      };
    case 'setCursorAfter':
      return {
        ...state,
        cursorAfter: action.value,
        cursorBefore: undefined,
        includeCursor: undefined
      };
    case 'reset':
      return {
        sort: '-date',
        cursorAfter: undefined,
        cursorBefore: undefined,
        includeCursor: undefined
      };
  }
}

const reset = (): Action => ({ type: 'reset' });
const setSort = (value: string): Action => ({ type: 'setSort', value });
const setCursorBefore = (value: string): Action => ({
  type: 'setCursorBefore',
  value
});
const setCursorAfter = (value: string): Action => ({
  type: 'setCursorAfter',
  value
});

export function accountsStateToParams(state: any) {
  const { accountNumber, organisationId } = state;
  const params = {
    sort: state.sort,
    accountNumber,
    organisationId
  } as TransactionsRequest;

  if (state.cursorAfter) params.cursor_after = state.cursorAfter;
  if (state.cursorBefore) params.cursor_before = state.cursorBefore;
  if (state.includeCursor) {
    params.include_cursor = state.includeCursor.toString();
  }

  if (state.dateRange.from) {
    params.created_after = formatDate(state.dateRange.from);
  }

  if (state.dateRange.to) {
    params.created_before = formatDate(state.dateRange.to);
  }

  return params;
}

export function useLocalState(defaultState: Partial<LocalState>) {
  const [searchParams] = useSearchParams();
  const [initialState] = useState<LocalState>(() => {
    const initialCursorBefore =
      defaultState.cursorBefore ||
      searchParams.get('cursorBefore') ||
      undefined;
    const initialCursorAfter =
      defaultState.cursorAfter || searchParams.get('cursorAfter') || undefined;

    return {
      sort: defaultState.sort || searchParams.get('sort') || '-date',
      includeCursor: defaultState.includeCursor || undefined,
      cursorBefore:
        initialCursorAfter !== undefined ? undefined : initialCursorBefore,
      cursorAfter: initialCursorAfter
    };
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const queryParams = useMemo(() => {
    return {
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore,
      sort: state.sort
    };
  }, [state]);

  useEffect(() => () => dispatch(reset()), []);

  return {
    state,
    queryParams,
    dispatch,
    reset,
    setSort,
    setCursorBefore,
    setCursorAfter
  };
}

function formatDate(stringDate: string) {
  return dayjs(stringDate, DATE_FORMAT).format(API_DATE_FORMAT);
}
