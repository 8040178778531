import { useState } from 'react';

import { WithdrawalAccount } from 'models/withdrawal';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { NoWithdrawalRequestsIcon } from '@atoms/Icon/NoWithdrawalRequestsIcon';
import { WithdrawFundsIcon } from '@atoms/Icon/WithdrawFundsIcon';

import { WithdrawalRequestDialog } from './WithdrawalRequestDialog';

interface Props {
  withdrawalAccounts: WithdrawalAccount[];
  isWithdrawalAccountsError: boolean;
  isWithdrawalAccountsLoading: boolean;
  organisationId: number;
}

export const NoWithdrawalRequests = ({ ...props }: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex size-full flex-col items-center overflow-auto p-8">
      <NoWithdrawalRequestsIcon />
      <h3 className="mt-7 text-xl font-medium">
        Your withdrawal requests will appear here once they've been generated.
      </h3>
      <div className="mt-7">
        <PrimaryButton
          className="flex items-center gap-2"
          onClick={() => setOpen(true)}
        >
          <WithdrawFundsIcon className="size-5" stroke="white" />
          Withdraw funds
        </PrimaryButton>
        <WithdrawalRequestDialog open={open} setOpen={setOpen} {...props} />
      </div>
    </div>
  );
};
