import { EnvelopeIcon as ReadEmailIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';

interface Props {
  email: string;
}

export const ForgotPasswordSent = ({ email }: Props) => {
  return (
    <div className="flex w-80 flex-col text-inpay-black-primary-1000">
      <ReadEmailIcon
        width="40"
        height="40"
        className="mb-5 flex size-10 self-center stroke-inpay-black-primary-1000 stroke-1.5"
      />
      <h1 className="mb-6 text-center text-[2rem] font-medium tracking-[0.01rem]">
        Check your email
      </h1>
      <p className="mb-6 text-center">
        We sent a password reset link to
        <br />
        <span className="font-medium text-inpay-blue-1000">{email}</span>
      </p>
      <p className="text-center">
        Didn&apos;t receive the email?{' '}
        <Link
          className="font-medium text-inpay-blue-1000 hover:text-inpay-green-primary-1000"
          to="/passwords/forgot"
        >
          Click to resend
        </Link>
      </p>
    </div>
  );
};
