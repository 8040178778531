import { useEffect } from 'react';

import {
  SelectChoice,
  Selector,
  SelectorClassNames
} from '@molecules/Selector/Selector';

interface Props {
  onChange: (value: string) => void;
  classNames?: SelectorClassNames;
  name?: string;
  value: string;
  options: SelectChoice[];
  isActive?: boolean;
}

export const CorridorSelector = ({
  onChange,
  classNames,
  name,
  value,
  options,
  isActive
}: Props) => {
  const isDisabled = isActive || options.length === 0 || options.length === 1;

  /*
    If there is only one available option this hook set it automatically since
    the field will be disabled.
  */
  useEffect(() => {
    if (!value && options.length === 1) {
      onChange(options[0].value);
    }
  }, [onChange, options, value]);

  return (
    <>
      <label className="mb-0.5 pl-2 text-xs">Corridor</label>
      <Selector
        classNames={classNames}
        selectChoices={options}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
        value={value}
      />
    </>
  );
};
