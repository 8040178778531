import { SVGProps } from 'react';

export const SharedAccountIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      cx="14"
      cy="14"
      r="13"
      className="fill-inpay-gray-primary-400"
      stroke="white"
      strokeWidth="1.7142857142857142"
      d="M23.143 12A11.143 11.143 0 0 1 12 23.143A11.143 11.143 0 0 1 0.857 12A11.143 11.143 0 0 1 23.143 12z"
    />
    <path
      d="M9.429 5.871A3.557 3.557 0 0 1 12.986 9.429v3.557H9.429a3.557 3.557 0 1 1 0 -7.114Z"
      className="stroke-inpay-green-primary-800"
      strokeWidth="2.142857142857143"
    />
    <path
      d="M14.571 18.129A3.557 3.557 0 0 1 11.014 14.571v-3.557H14.571a3.557 3.557 0 1 1 0 7.114Z"
      className="stroke-inpay-green-primary-mixes-80%-secondary"
      strokeWidth="2.142857142857143"
    />
  </svg>
);
