// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HELfwJHcChzIN_lDwH1c{position:relative}.HELfwJHcChzIN_lDwH1c:after,.HELfwJHcChzIN_lDwH1c:before{border:8px solid transparent;border-bottom-color:#f6e0e0;content:\"\";left:16px;position:absolute;top:-16px}", "",{"version":3,"sources":["webpack://./src/components/ui/atoms/InputError/styles.module.css"],"names":[],"mappings":"AAAA,sBACE,iBACF,CACA,yDASE,4BAAkC,CAAlC,2BAAkC,CAPlC,UAAW,CAEX,SAAU,CADV,iBAAkB,CAElB,SAKF","sourcesContent":[".input-error {\n  position: relative;\n}\n.input-error:after,\n.input-error:before {\n  content: '';\n  position: absolute;\n  left: 16px;\n  top: -16px;\n  border-top: 8px solid transparent;\n  border-right: 8px solid transparent;\n  border-bottom: 8px solid #f6e0e0;\n  border-left: 8px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input-error": "HELfwJHcChzIN_lDwH1c"
};
export default ___CSS_LOADER_EXPORT___;
