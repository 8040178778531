import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  Select,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectPortal,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport
} from '@radix-ui/react-select';
import cn from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useElementSize } from 'usehooks-ts';

import { InputError } from '@atoms/InputError/InputError';

export interface SelectorClassNames {
  loadingClassName?: string;
  formButtonClassName?: string;
  selectedItemClassName?: string;
  selectContentClassName?: string;
  selectItemListClassName?: string;
}
export interface SelectChoice {
  value: string;
  label: string | [string, string];
}

interface Props {
  defaultValue?: string;
  onChange?: (value: string) => void;
  classNames?: SelectorClassNames;
  selectChoices: SelectChoice[];
  name?: string;
  disabled?: boolean;
  value: string;
  errorMessage?: string;
  ['data-testid']?: string;
}

export const Selector = ({
  onChange,
  classNames,
  selectChoices,
  errorMessage,
  name,
  disabled,
  value,
  ['data-testid']: dataTestId
}: Props) => {
  const [currentItem, setCurrentItem] = useState<SelectChoice | undefined>(
    undefined
  );
  const [availableList, setAvailableList] = useState<SelectChoice[]>([]);
  const [isOpen, onOpenChange] = useState<boolean>(false);
  const selectTriggerProps = {
    className: twMerge(
      cn(
        'group flex items-center h-12 select-none rounded-lg bg-white px-4 text-left shadow-plain justify-between',
        classNames?.formButtonClassName,
        {
          'group hover:shadow-plain-lg cursor-pointer': !disabled,
          'stroke-inpay-gray-primary-1000 stroke-1.5 group hover:none text-inpay-black-primary-1000':
            disabled,
          'bg-inpay-gray-primary-200':
            (disabled && currentItem?.value) || (!currentItem && disabled)
        }
      )
    )
  };

  const selectedItemProps = {
    className: twMerge(
      cn(
        'group flex h-12 cursor-pointer items-center px-4 rounded-t-lg outline-offset-[-1px] hover:bg-inpay-green-secondary-light-200 hover:text-inpay-green-primary-1000 border-none focus:outline-none',
        classNames?.selectedItemClassName,
        {
          'px-4': disabled
        }
      )
    )
  };

  const selectItemListProps = {
    className: twMerge(
      cn(
        'group h-12 flex flex-1 items-center cursor-pointer px-4 outline-offset-[-1px] first:rounded-t-lg last:rounded-b-lg hover:bg-inpay-green-secondary-light-200 hover:text-inpay-green-primary-1000 border-none focus:outline-none w-full',
        classNames?.selectItemListClassName
      )
    )
  };

  const selectContentProps = {
    className: twMerge(
      cn(
        classNames?.selectContentClassName,
        'relative select-none overflow-hidden rounded-lg bg-white shadow-plain-lg'
      )
    )
  };
  const onValueChange = useCallback(
    (value: string, callOnChange = true) => {
      if (selectChoices) {
        const matchingValue = selectChoices.find(
          (element) => element.value == value
        );
        if (matchingValue) {
          setCurrentItem({ ...matchingValue });
          setAvailableList(
            selectChoices.filter((i) => i.value != matchingValue.value)
          );
          callOnChange && onChange && onChange(value);
        }
      }
    },
    [onChange, selectChoices]
  );

  useEffect(() => {
    if (value) {
      onValueChange(value);
    } else {
      setAvailableList(selectChoices);
      setCurrentItem(undefined);
    }
    // NOTE (LTJ): Warning! Be wary of introducing circular dependency here...
  }, [selectChoices]);

  useEffect(() => {
    onValueChange(value, false);
  }, [onValueChange, value]);

  const [containerRef, { width }] = useElementSize<any>();

  return (
    <div
      className="flex w-full flex-col"
      ref={containerRef}
      data-testid={dataTestId}
    >
      <Select
        onValueChange={onValueChange}
        onOpenChange={onOpenChange}
        open={isOpen}
        name={name}
        disabled={disabled}
        value={value}
      >
        <SelectTrigger {...selectTriggerProps}>
          <SelectValue />
          <SelectIcon asChild>
            <ChevronDownIcon
              className={cn(
                'w-6 shrink-0 stroke-inpay-gray-primary-1000 stroke-1.5',
                {
                  'group-hover:stroke-inpay-black-primary-1000': !disabled
                }
              )}
            />
          </SelectIcon>
        </SelectTrigger>
        <SelectPortal>
          <SelectContent style={{ width }} {...selectContentProps}>
            <SelectScrollUpButton className="flex justify-center">
              <ChevronUpIcon className="h-10 w-6 stroke-1.5" />
            </SelectScrollUpButton>
            <SelectViewport>
              {currentItem && (
                <SelectItem
                  key={currentItem.value}
                  value={currentItem.value}
                  {...selectedItemProps}
                >
                  <SelectItemText asChild>
                    <div className="flex w-full">
                      <div className="flex-1">
                        {Array.isArray(currentItem.label) ? (
                          <>
                            <div className="truncate">
                              {currentItem.label[0]}
                            </div>
                            <div className="-mt-1 text-xs text-inpay-gray-primary-1000 group-hover:text-inpay-green-primary-400">
                              {currentItem.label[1]}
                            </div>
                          </>
                        ) : (
                          currentItem.label
                        )}
                      </div>
                      {isOpen && (
                        <ChevronUpIcon className="w-6 stroke-inpay-black-primary-1000 stroke-1.5" />
                      )}
                    </div>
                  </SelectItemText>
                </SelectItem>
              )}
              {availableList.map((item, index) => (
                <SelectItem
                  key={item?.value}
                  value={item.value}
                  {...selectItemListProps}
                >
                  <SelectItemText asChild>
                    <div className="flex w-full">
                      <div className="flex-1">
                        {Array.isArray(item.label) ? (
                          <>
                            <div className="truncate">{item.label[0]}</div>
                            <div className="text-xs text-inpay-gray-primary-1000 group-hover:text-inpay-green-primary-400">
                              {item.label[1]}
                            </div>
                          </>
                        ) : (
                          item.label
                        )}
                      </div>
                      {index == 0 && !currentItem && (
                        <ChevronUpIcon className="w-6 stroke-inpay-black-primary-1000 stroke-1.5" />
                      )}
                    </div>
                  </SelectItemText>
                </SelectItem>
              ))}
            </SelectViewport>
            <SelectScrollDownButton className="flex justify-center">
              <ChevronDownIcon className="h-10 w-6 stroke-1.5" />
            </SelectScrollDownButton>
          </SelectContent>
        </SelectPortal>
      </Select>

      <InputError errorMessage={errorMessage} />
    </div>
  );
};
