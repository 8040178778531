import {
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';

import { API_URL } from 'constants/environment';

import { setCredentials, expired } from 'redux/slices/authSlice';
import { RootState } from 'redux/store';

import { AuthResponse } from './authenticationApi';

export const baseQuery = fetchBaseQuery({
  baseUrl: `${API_URL}/api`,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  }
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    const refreshResult = await baseQuery(
      {
        url: '/v1/authentication/token/refresh',
        method: 'POST',
        credentials: 'include'
      },
      api,
      extraOptions
    );
    if (refreshResult.data) {
      api.dispatch(setCredentials(refreshResult.data as AuthResponse));

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(expired());
    }
  }
  return result;
};
