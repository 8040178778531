import { useEffect, useReducer } from 'react';

import dayjs, { DATE_FORMAT, Dayjs } from 'utils/dayjs';

import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { DateField } from '@atoms/DateField/DateField';

import { DateRangePicker } from 'components/ui/molecules/DateRangePicker';

export interface CustomFiltersProps {
  onSelect: (value: Required<DateRangeState>) => void;
  defaultValue: {
    from: Dayjs | undefined;
    to: Dayjs | undefined;
  };
}

export interface DateRangeState {
  from?: Dayjs;
  to?: Dayjs;
}

const initialState: DateRangeState = {
  from: undefined,
  to: undefined
};

export function reducer(state: DateRangeState, action: any) {
  switch (action.type) {
    case 'select':
      if (state.from && state.to) {
        return {
          ...initialState,
          from: action.payload.date
        };
      } else if (state.from || state.to) {
        if (state.from && !state.to) {
          if (state.from < action.payload.date) {
            return {
              ...state,
              to: action.payload.date
            };
          } else {
            return {
              from: action.payload.date,
              to: state.from
            };
          }
        } else {
          if (state.to && state.to < action.payload.date) {
            return {
              ...state,
              from: action.payload.date
            };
          } else {
            return {
              to: action.payload.date,
              from: state.to
            };
          }
        }
      } else {
        return {
          ...state,
          from: action.payload.date
        };
      }
    case 'fill': {
      const date = dayjs(action.payload.value, DATE_FORMAT);
      return {
        ...state,
        [action.payload.prefix]: date
      };
    }
    case 'init':
      return {
        ...action.payload
      };
    case 'reset':
      return initialState;
  }
}

export const CustomFilters = ({
  onSelect,
  defaultValue
}: CustomFiltersProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onDateSelected = (date: Date) => {
    dispatch({ type: 'select', payload: { date: dayjs(date) } });
  };
  const completeDateInput = (value: any, mask: any) => {
    const prefix = mask.el.input.name == 'fromDate' ? 'from' : 'to';
    dispatch({ type: 'fill', payload: { value, prefix } });
  };

  const selectAndClose = () => {
    onSelect(state);
    dispatch({ type: 'reset' });
  };

  const isFinished = !!state.from && !!state.to;

  useEffect(() => {
    if (defaultValue.from && defaultValue.to) {
      dispatch({
        type: 'init',
        payload: {
          from: defaultValue.from,
          to: defaultValue.to,
          dateFormat: DATE_FORMAT
        }
      });
    } else {
      dispatch({ type: 'reset' });
    }
  }, [defaultValue]);

  return (
    <div onClick={(e) => e.preventDefault()}>
      <DateRangePicker
        onDateSelected={onDateSelected}
        selectedRange={{ from: state.from?.toDate(), to: state.to?.toDate() }}
      />
      <div className="mt-4 grid grid-cols-2 gap-x-4">
        <label htmlFor="fromDate" className="mb-0.5 pl-2 text-xs">
          From
        </label>
        <label htmlFor="toDate" className="mb-0.5 pl-2 text-xs">
          To
        </label>
        <DateField
          name="fromDate"
          aria-label="From date"
          onComplete={completeDateInput}
          value={state.from && state.from.format(DATE_FORMAT)}
          className="rounded-lg px-4 py-1.5 text-sm font-medium shadow-plain"
        />
        <DateField
          name="toDate"
          aria-label="To date"
          onComplete={completeDateInput}
          value={state.to && state.to.format(DATE_FORMAT)}
          className="rounded-lg px-4 py-1.5 text-sm font-medium shadow-plain"
        />
      </div>
      <SecondaryButton
        className="mt-6 w-full"
        disabled={!isFinished}
        onClick={() => selectAndClose()}
      >
        Select date range
      </SecondaryButton>
    </div>
  );
};
