import { TransactionData } from 'models/transaction';

import { customerApi } from '../customerApi';

type TransactionsKeys =
  | 'unique_identifier'
  | 'page_size'
  | 'sort'
  | 'include_cursor'
  | 'cursor_before'
  | 'created_before'
  | 'cursor_after'
  | 'account_number';

export interface VirtualAccountsResponse {
  data: TransactionData[];
}

export type TransactionsRequest = {
  [key in TransactionsKeys]?: string;
} & {
  organisationId: string;
};

export const transactionApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    // Search transactions endpoint
    fetchTransactions: builder.query<
      { data: Array<TransactionData>; meta: { count: number } },
      TransactionsRequest
    >({
      query: ({ organisationId, ...params }) => {
        return `v2/virtual_bank/organisations/${organisationId}/movements/search?${new URLSearchParams(params)}`;
      },
      providesTags: ['Transactions']
    })
  })
});

export const { useFetchTransactionsQuery } = transactionApi;
