import { hotjar } from 'react-hotjar';

export type LoggingArea = 'create-manual-payout' | 'dashboard';

// TODO (LÞJ): Automate some of these actions during navigation/dialog actions
export type LoggingAction =
  | 'dialog-open'
  | 'dialog-close'
  | 'page-open'
  | 'form-submit';

// TODO (LÞJ): Limit the appropriate actions to the appropriate areas (fx. no `dashboard:dialog-open)
export type LoggingId = `${LoggingArea}:${LoggingAction}`;

export const logEventToHotjar = (eventName: LoggingId): void => {
  try {
    if (hotjar.initialized()) {
      hotjar.event(eventName);
    }
  } catch (loggingError) {
    // TODO: Improve handling/ignoring logging errors
    /* eslint-disable no-console */
    console.error('Error logging event to hotjar.', loggingError);
  }
};
