import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import cn from 'classnames';
import { MouseEvent, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';

import { PayoutDraftData, PayoutState } from 'models/payout';

import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';
import { FormattedIdentifier } from '@atoms/FormattedIdentifier/FormattedIdentifier';
import { TransactionIconMinus } from '@atoms/Icon/TransactionIconMinus';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

export const usePayoutDraftColumns = () => {
  const { organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  const { data: { data: accountsInfo = [] } = {} } =
    useFetchVirtualAccountsQuery({ organisationId });

  const accountsList = useMemo(
    () =>
      accountsInfo?.map((account) => ({
        value: account.account_number,
        ...account
      })),
    [accountsInfo]
  );

  const columnHelper = createColumnHelper<PayoutDraftData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'amount_icon',
        cell: () => (
          <div className="ml-6 mr-8 w-6">
            <TransactionIconMinus height="32" width="32" />
          </div>
        ),
        meta: {
          className: 'w-28 !p-0'
        }
      }),
      columnHelper.accessor('created_at', {
        id: 'date',
        header: 'Date',
        cell: (info) => {
          const dateTime = info.getValue<string>();

          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-30'
        }
      }),
      columnHelper.accessor(
        (row) => ({
          id: row.end_to_end_id
        }),
        {
          id: 'idReference',
          header: 'ID',
          cell: (info) => {
            const { id } = info.getValue<{
              id: string;
            }>();

            return (
              <div className="uppercase">
                <FormattedIdentifier value={id} className="text-xs" />
              </div>
            );
          }
        }
      ),
      columnHelper.accessor('debtor_account', {
        id: 'accountNumber',
        cell: (info) => {
          const value = info.getValue<string>();
          const account = accountsList?.find(
            (el) => el.account_number === value
          );
          return (
            <>
              <div className="whitespace-nowrap text-xs">
                {virtualAccountNumberFormatter(value)}
              </div>
              <div className="truncate text-base font-medium">
                {account?.label ? account.label : account?.currency_name}
              </div>
            </>
          );
        },
        header: (props: any) => {
          const currentFilter = props?.column?.getFilterValue();

          return (
            <span>
              Account:
              <b className="ml-1 truncate font-bold">
                {currentFilter
                  ? virtualAccountNumberFormatter(currentFilter)
                  : 'All'}
              </b>
            </span>
          );
        },
        enableColumnFilter: true,
        meta: {
          alignment: 'right',
          className: 'w-48 pl-2',
          values:
            accountsList?.map((a) => ({ ...a, id: a.account_number })) || [],
          renderValueFilter: (
            value: any,
            selected: boolean,
            onClick: (e: MouseEvent<Element>) => void
          ) => (
            <div
              className={cn(
                'w-fit max-w-full overflow-hidden truncate rounded-lg px-2 py-1 text-right',
                {
                  'hover:none cursor-default bg-inpay-green-secondary-light-200 text-inpay-green-primary-400':
                    selected,
                  'cursor-pointer hover:bg-inpay-green-primary-1000 hover:text-white':
                    !selected
                }
              )}
              onClick={onClick}
            >
              {value ? (
                <>
                  <div className="truncate text-right text-xs">
                    {value?.label ? value.label : value?.currency_name}
                  </div>
                  <div className="whitespace-nowrap text-right font-medium">
                    {virtualAccountNumberFormatter(value.account_number)}
                  </div>
                </>
              ) : (
                <div className="whitespace-nowrap font-medium">All</div>
              )}
            </div>
          )
        }
      }),
      columnHelper.accessor(({ amount, currency }) => ({ amount, currency }), {
        id: 'amount',
        header: 'Amount',
        meta: {
          alignment: 'right',
          className: 'mx-2'
        },
        cell: (info) => {
          const value = info.getValue<{ amount: number; currency: string }>();
          return (
            <>
              <div className="text-xs font-normal uppercase">
                {value.currency}
              </div>
              <div className="whitespace-nowrap text-base font-medium">
                {new MoneyFormatter({ currency: value.currency }).format(
                  value.amount
                )}
              </div>
            </>
          );
        }
      }),
      columnHelper.accessor('state', {
        id: 'state',
        meta: {
          alignment: 'right',
          className: 'w-52 pr-6'
        },
        cell: (info) => <PayoutBadge state={info.getValue<PayoutState>()} />,
        header: 'Status'
      }),
      columnHelper.display({
        id: 'column_params',
        meta: {
          className: 'w-12 pr-3.5 pl-1'
        },
        cell: () => (
          <ChevronRightIcon className="h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        )
      })
    ],
    [accountsList]
  );

  return columns;
};
