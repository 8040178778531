import { skipToken } from '@reduxjs/toolkit/dist/query';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { WithdrawalsSearchResults } from '@organisms/WithdrawalsSearchResults';

import { Order } from 'models/order';
import { Payment } from 'models/payment';
import { PayoutData } from 'models/payout';
import { TransactionData } from 'models/transaction';
import { WithdrawalRequestsData } from 'models/withdrawal';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { PayoutDetails } from 'components/pages/Payouts/Details/PayoutDetails';
import { OrdersSearchResults } from 'components/ui/organisms/OrdersSearchResults';
import { PaymentsSearchResults } from 'components/ui/organisms/PaymentsSearchResults';
import { PayoutsSearchResults } from 'components/ui/organisms/PayoutsSearchResults';
import { TransactionsSearchResults } from 'components/ui/organisms/TransactionsSearchResult';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';
import { TableLayout } from 'components/ui/templates/TableLayout';

import { searchStateToParams, useLocalState } from './localState';
import { NoSearchResult } from './NoSearchResult';
import { SearchHeader } from './SearchHeader';

import { OrderSearchDetails } from '../Orders/OrderDetails/OrderSearchDetails';
import { PaymentDetails } from '../PaymentDetails';
import { TransactionDetails } from '../TransactionDetails';

export const Search = () => {
  const routes = [
    {
      path: '/transactions/:transactionId',
      element: <TransactionDetails />
    },
    {
      path: '/payouts/:payoutId',
      element: <PayoutDetails />
    },
    {
      path: '/orders/:orderId',
      element: <OrderSearchDetails />
    },
    {
      path: '/payments/:paymentId',
      element: <PaymentDetails />
    }
  ];

  const {
    state: { q },
    queryParams
  } = useLocalState();

  const navigate = useNavigate();
  const { '*': childRoute } = useParams();
  const { filters, queryParams: globalQueryParams } = useGlobalFilters();

  const [statuses, setStatuses] = useState<{
    [key: string]: { loaded: boolean; id?: string };
  }>({
    payouts: {
      loaded: false,
      id: undefined
    },
    transactions: {
      loaded: false,
      id: undefined
    },
    orders: {
      loaded: false,
      id: undefined
    },
    payments: {
      loaded: false,
      id: undefined
    },
    withdrawals: {
      loaded: false,
      id: undefined
    }
  });

  const onPayoutClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    navigate(
      `payouts/${rowData.inpay_unique_reference}?${prepareSearchParams(
        location.search,
        {
          ...queryParams,
          ...globalQueryParams
        }
      )}`
    );
  };

  const onTransactionClick = (
    _event: MouseEvent<HTMLElement>,
    rowData: any
  ) => {
    if (rowData.reference) {
      const queryParams = prepareSearchParams(location.search, {
        reference: rowData.reference,
        type: rowData.entry_type,
        ...globalQueryParams
      });
      navigate(`transactions/${rowData.id}?${queryParams}`);
    }
  };

  const onOrderClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    navigate(
      `orders/${rowData.uuid}?${prepareSearchParams(location.search, {
        ...queryParams,
        ...globalQueryParams
      })}`
    );
  };

  const onPaymentClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    navigate(
      `payments/${rowData.uuid}?${prepareSearchParams(location.search, {
        ...queryParams,
        ...globalQueryParams
      })}`
    );
  };

  const onPayoutSuccess = (payout: PayoutData | undefined) => {
    setStatuses((statuses) => ({
      ...statuses,
      payouts: {
        loaded: true,
        id: payout?.inpay_unique_reference
      }
    }));
  };

  const onTransactionSuccess = (transaction: TransactionData | undefined) => {
    setStatuses((statuses) => ({
      ...statuses,
      transactions: {
        loaded: true,
        id: transaction?.reference
      }
    }));
  };

  const onOrderSuccess = (order: Order | undefined) => {
    setStatuses((statuses) => ({
      ...statuses,
      orders: {
        loaded: true,
        id: order?.uuid
      }
    }));
  };

  const onPaymentSuccess = (payment: Payment | undefined) => {
    setStatuses((statuses) => ({
      ...statuses,
      payments: {
        loaded: true,
        id: payment?.uuid
      }
    }));
  };

  const onWithdrawalSuccess = (
    withdrawal: WithdrawalRequestsData | undefined
  ) => {
    setStatuses((statuses) => ({
      ...statuses,
      withdrawals: {
        loaded: true,
        id: withdrawal?.reference
      }
    }));
  };

  const searchParams = useMemo(
    () => (q ? searchStateToParams({ q, ...filters }) : skipToken),
    [q, filters]
  );

  useEffect(() => {
    if (!childRoute) {
      updateUrl(
        `${location.pathname}?${prepareSearchParams(location.search, {
          ...queryParams,
          ...globalQueryParams
        }).toString()}`
      );
    }
  }, [queryParams, globalQueryParams, childRoute]);

  useEffect(() => {
    if (statuses.transactions.id) {
      navigate(
        `transactions/${statuses.transactions.id}?${prepareSearchParams(
          location.search,
          queryParams
        )}`
      );
    } else if (statuses.payouts.id) {
      navigate(
        `payouts/${statuses.payouts.id}?${prepareSearchParams(
          location.search,
          queryParams
        )}`
      );
    } else if (statuses.orders.id) {
      navigate(
        `orders/${statuses.orders.id}?${prepareSearchParams(
          location.search,
          queryParams
        )}`
      );
    } else if (statuses.payments.id) {
      navigate(
        `payments/${statuses.payments.id}?${prepareSearchParams(
          location.search,
          queryParams
        )}`
      );
    } else if (statuses.withdrawals.id) {
      navigate(
        `withdrawals/${statuses.withdrawals.id}?${prepareSearchParams(
          location.search,
          queryParams
        )}`
      );
    }
  }, [statuses]);

  useEffect(() => {
    setStatuses({
      payouts: {
        loaded: false,
        id: undefined
      },
      transactions: {
        loaded: false,
        id: undefined
      },
      orders: {
        loaded: false,
        id: undefined
      },
      payments: {
        loaded: false,
        id: undefined
      },
      withdrawals: {
        loaded: false,
        id: undefined
      }
    });
  }, [q, filters]);

  const statusEntries = Object.entries(statuses);
  const allSearchesFinished = statusEntries.every(
    ([_key, { loaded }]) => loaded
  );
  const noResults = !statusEntries.some(([_key, { id }]) => !!id);

  if (!q) return <NoSearchResult />;
  if (allSearchesFinished && noResults) return <NoSearchResult />;

  return (
    <TableLayout header={<SearchHeader />}>
      <DetailedLayout routes={routes}>
        <section aria-label="search results" className="flex flex-col gap-4">
          <TransactionsSearchResults
            onRowClick={onTransactionClick}
            searchParams={searchParams}
            onSuccess={onTransactionSuccess}
          />
          <PayoutsSearchResults
            onRowClick={onPayoutClick}
            searchParams={searchParams}
            onSuccess={onPayoutSuccess}
          />
          <OrdersSearchResults
            onRowClick={onOrderClick}
            searchParams={searchParams}
            onSuccess={onOrderSuccess}
          />
          <PaymentsSearchResults
            onRowClick={onPaymentClick}
            searchParams={searchParams}
            onSuccess={onPaymentSuccess}
          />
          <WithdrawalsSearchResults
            searchParams={searchParams}
            onSuccess={onWithdrawalSuccess}
          />
        </section>
      </DetailedLayout>
    </TableLayout>
  );
};
