import { ForwardedRef, forwardRef } from 'react';

import { InputField, InputFieldProps } from '@molecules/InputField';

export const MfaCodeField = forwardRef<HTMLInputElement, InputFieldProps>(
  (props, ref: ForwardedRef<HTMLInputElement>) => {
    return <InputField {...props} type="number" autoComplete="off" ref={ref} />;
  }
);
MfaCodeField.displayName = 'MfaCodeField';
