import { configureStore, createAction, PreloadedState } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AnyAction, combineReducers } from 'redux';

import { customerApi } from './api/customerApi';
import accountsReducer from './slices/accountsSlice';
import authReducer from './slices/authSlice';
import globalFiltersSlice from './slices/globalFiltersSlice';
import pagesPayoutsSlice from './slices/pages.payoutsSlice';

type AppReducer = ReturnType<typeof combinedReducer>;

export const resetState = createAction('resetState');

const combinedReducer = combineReducers({
  auth: authReducer,
  accounts: accountsReducer,
  globalFilters: globalFiltersSlice,
  pagesPayouts: pagesPayoutsSlice,
  [customerApi.reducerPath]: customerApi.reducer
});

const rootReducer = (rootState: AppReducer | undefined, action: AnyAction) => {
  if (action.type == resetState.type) {
    rootState = undefined;
  }
  return combinedReducer(rootState, action);
};

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([customerApi.middleware])
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch = () => useDispatch<AppDispatch>();
