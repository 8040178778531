import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

import { ReasonCodeStats, StateReasonCodesStats } from 'models/stats';

import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { StatsTable } from 'components/ui/organisms/StatsTable';

export interface DrilldownTableProps {
  stateId: string;
  title: string;
  data: StateReasonCodesStats;
  className?: string;
  onHeaderClick?: () => void | undefined;
}

export const DrilldownTable = ({
  stateId,
  title,
  data,
  className,
  onHeaderClick
}: DrilldownTableProps) => {
  const total = data ? data?.meta.count : 'N/A';
  const orgNavigate = useOrgNavigate();

  const onReasonCodeClick = (reasonCode: string) => {
    orgNavigate(`/payouts?reasonCode=${reasonCode}&state=${stateId}`);
  };

  const columns = useMemo<ColumnDef<ReasonCodeStats>[]>(
    () => [
      {
        id: 'name',
        header: '',
        accessorFn: (row) => ({
          id: row.id,
          label: row.name
        }),
        className: 'min-w-64 w-64 !text-left',
        cell: (info) => {
          const value = info.getValue<{ id: string; label: string }>();
          return (
            <a
              className="cursor-pointer hover:text-inpay-green-primary-800"
              onClick={() => onReasonCodeClick(value.id)}
              role="link"
            >
              {value.label}
            </a>
          );
        }
      },
      {
        id: 'count',
        header: `Count: ${total}`,
        accessorKey: 'count',
        className: 'border-l',
        cell: (info) => {
          const value = info.getValue<number>();
          return <div className="text-center font-black">{value}</div>;
        }
      },
      {
        id: 'percentage',
        header: 'Total',
        accessorKey: 'percentage',
        className: 'border-l',
        cell: (info) => {
          const value = info.getValue<number>();
          return <div className="text-center font-black">{value}%</div>;
        }
      }
    ],
    [data]
  );

  return (
    <>
      {data?.items && (
        <StatsTable
          columns={columns}
          data={data.items}
          title={title}
          onHeaderClick={onHeaderClick}
          totalPercentage={data?.meta.percentage}
          className={className}
        />
      )}
    </>
  );
};
