import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogClose, DialogPortal } from '@radix-ui/react-dialog';
import IMask from 'imask';
import { FormEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';

import dayjs from 'utils/dayjs';
import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { setFormErrors } from 'utils/formHelpers';

import { Content, Overlay, Title } from '@organisms/Dialog';
import { addAccountFundingDialogSchema as schema } from 'validators/addAccountFundingDialog';

import { useCreateAccountFundingMutation } from 'redux/api/accounts/virtualAccountApi';

import { VirtualAccount } from 'models/virtualAccount';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { CopyToClipboardButton } from '@atoms/CopyToClipboardButton/CopyToClipboardButton';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import { AccountSelector } from '@molecules/AccountSelector';
import { InputField } from '@molecules/InputField';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
}

// CBP-2466-account-funding: temporary until the feature flag is completely removed
export const AddAccountFundingDialog = ({ open, onClose }: Props) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [
    createFunding,
    {
      isSuccess: creationSuccess,
      data: accountFunding,
      reset: resetFundingDialog
    }
  ] = useCreateAccountFundingMutation();

  const {
    register,
    handleSubmit,
    setError,
    formState,
    setValue,
    getValues,
    trigger,
    reset: resetForm
  } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      await handleSubmit(async (data) => {
        setShowLoading(true);

        const timeoutPromise = new Promise((res) =>
          setTimeout(() => res(''), 2000)
        );

        const creationPromise = createFunding({
          virtual_account_number: data.virtual_account_number,
          params: {
            end_to_end_id: data.end_to_end_id,
            remittance_description: data.remittance_description,
            currency: data.currency,
            amount: data.amount
          }
        }).unwrap();

        await Promise.all([timeoutPromise, creationPromise]);
      })(event);
    } catch (error: any) {
      // fixes rendering backend failures if form was submitted
      // with valid fields.
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    } finally {
      setShowLoading(false);
    }
  };
  const formFailed = () => Object.keys(formState.errors).length > 0;

  const onAccountChange = (account: VirtualAccount) => {
    setValue('virtual_account_number', account.account_number);
    setValue('currency', account.currency_code);
    formFailed() && trigger();
  };

  const onDialogClose = (value: boolean) => {
    setShowLoading(false);
    resetForm();
    resetFundingDialog();
    onClose(value);
  };

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content className="w-84">
            <Title>
              {!showLoading && !creationSuccess && <span>Add funds</span>}
              {showLoading && (
                <span>
                  Creating your account funding
                  <BlindingDots />
                </span>
              )}
              {!showLoading && creationSuccess && (
                <div className="text-sm leading-4">
                  <p>Reference created for</p>
                  <p className="font-medium">
                    {getValues('currency')} account (No.{' '}
                    {IMask.pipe(getValues('virtual_account_number') || '', {
                      mask: '*** **** [**********]'
                    })}
                    )
                  </p>
                </div>
              )}
            </Title>
            <div className="rounded-b-lg bg-inpay-gray-primary-200 p-6">
              {!showLoading && creationSuccess && (
                <div className="flex flex-col gap-4">
                  <div className="mt-4 text-xl font-medium">
                    Payment request
                  </div>
                  <div className="flex justify-between">
                    <div className="align-left">
                      <p className="text-xs leading-4">Own reference:</p>
                      <p className="font-medium">
                        {accountFunding.order_reference}
                      </p>
                    </div>
                    <div className="align-right text-right">
                      <p className="text-xs leading-4">Order date</p>
                      <p className="font-medium">
                        {dayjs
                          .utc(accountFunding.created_at)
                          .format('DD-MM-YY')}
                      </p>
                      <p className="text-xs font-medium">
                        {dayjs
                          .utc(accountFunding.created_at)
                          .format('HH:mm UTC')}
                      </p>
                    </div>
                  </div>
                  {accountFunding.order_text && (
                    <div className="align-left">
                      <p className="text-xs leading-4">Description:</p>
                      <p className="font-medium">{accountFunding.order_text}</p>
                    </div>
                  )}
                  <div className="flex justify-between">
                    <div className="align-left">
                      <p className="text-xs leading-4">Amount</p>
                      <p className="font-medium">
                        {new MoneyFormatter({
                          currency: getValues('currency')
                        }).format(accountFunding.amount)}
                      </p>
                    </div>
                    <div className="align-right text-right">
                      <p className="text-xs leading-4">Status</p>
                      <p className="font-medium capitalize">
                        {accountFunding.status}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4 text-xl font-medium">
                    Payment instructions
                  </div>
                  <div className="text-sm">
                    <span className="text-break">
                      Please make sure you include Inpay reference;{' '}
                      <CopyToClipboardButton
                        value={accountFunding.reference}
                        className="inline-block font-medium text-inpay-blue-1000 hover:text-inpay-green-primary-1000"
                        messageClassName="text-inpay-green-primary-1000"
                      />{' '}
                      in payment details when making the funds transfer.
                    </span>
                  </div>
                  <DialogClose asChild>
                    <PrimaryButton
                      size="large"
                      aria-label="Done, close window"
                      className="mt-4 w-full"
                    >
                      Done, close window
                    </PrimaryButton>
                  </DialogClose>
                </div>
              )}
              {showLoading && (
                <div className="flex flex-col items-center gap-y-4 p-4">
                  <RollingLoader ariaLabel="Submitting" />
                  <div className="text-xs">
                    <span className="invisible">...</span>
                    <span>Processing</span>
                    <BlindingDots />
                  </div>
                </div>
              )}
              {!showLoading && !creationSuccess && (
                <form onSubmit={onSubmit} autoComplete="off">
                  <div className="mb-4 flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">Account</label>
                    <AccountSelector
                      defaultAccountNumber={getValues('virtual_account_number')}
                      onChange={onAccountChange}
                    />
                    {formState.errors.virtual_account_number && (
                      <div
                        role="alert"
                        id="virtual_account_number"
                        className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm drop-shadow-sm"
                      >
                        {formState.errors.virtual_account_number.message}
                      </div>
                    )}
                  </div>
                  <InputField
                    {...register('end_to_end_id')}
                    className="mb-4"
                    label="Own reference"
                    name="end_to_end_id"
                    errorMessage={formState.errors.end_to_end_id?.message}
                  />
                  <InputField
                    {...register('remittance_description')}
                    className="mb-4"
                    label="Description (optional)"
                    name="remittance_description"
                    errorMessage={
                      formState.errors.remittance_description?.message
                    }
                  />
                  <InputField
                    {...register('amount')}
                    className="mb-8"
                    label="Amount"
                    name="amount"
                    errorMessage={formState.errors.amount?.message}
                  />
                  <PrimaryButton
                    type="submit"
                    className="w-full"
                    disabled={formFailed()}
                  >
                    Create manual funding
                  </PrimaryButton>
                  {formState.errors.base && (
                    <div
                      role="alert"
                      className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
                      style={{
                        filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
                      }}
                    >
                      {formState.errors.base.message}
                    </div>
                  )}
                </form>
              )}
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
