import { encodeQueryParams } from 'utils/url';

import { type ApprovalAction } from 'models/ApprovalWorkFlow';
import { type Batch, type BatchDetail } from 'models/batches';
import { PaginationMeta } from 'models/paginationMeta';

import { customerApi } from '../customerApi';

export interface BatchesRequestParams {
  organisationId: string;
  filter: { kind: 'csv'; state?: string };
  page?: {
    size?: number;
    cursor_after?: string;
    cursor_before?: string;
    include_cursor?: boolean;
  };
}

export interface BatchesResponse {
  data: Batch[];
  meta: PaginationMeta;
}
export interface BatchDetailsResponse {
  data: BatchDetail;
}

export type BatchReviewResponse =
  | { data: { success: boolean } }
  | { errors: Error[] };

export const batchesApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    // fetch batches for batches page
    fetchBatches: builder.query<BatchesResponse, BatchesRequestParams>({
      query: ({ organisationId, ...params }) =>
        `/v1/payouts/organisations/${organisationId}/batches?${encodeQueryParams(
          params
        )}`,
      providesTags: ['Batches']
    }),
    // fetch batch approval information
    fetchBatch: builder.query<BatchDetailsResponse, any>({
      query: ({ id, organisationId }) =>
        `/v1/payouts/organisations/${organisationId}/batches/${id}`,
      providesTags: ['Batches']
    }),
    // post approve or decline batch
    approveOrDeclineBatch: builder.mutation<
      BatchReviewResponse,
      { organisationId: string; batchId: string; action: ApprovalAction }
    >({
      query: ({ organisationId, batchId, action }) => {
        return {
          url: `/v1/payouts/organisations/${organisationId}/batches/${batchId}/${action}`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Batches']
    })
  })
});

export default batchesApi;
export const {
  useFetchBatchesQuery,
  useFetchBatchQuery,
  useApproveOrDeclineBatchMutation
} = batchesApi;
