import { Navigate, useParams } from 'react-router-dom';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';

import { Loading } from 'components/pages/Loading';

export const AccountsRedirection = () => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { data, isLoading } = useFetchVirtualAccountsQuery({ organisationId });

  if (isLoading) return <Loading text="Loading accounts" />;
  const defaultAccount = data?.data[0].account_number;

  if (!defaultAccount) return <Navigate to={`/org/${organisationId}`} />;

  return <Navigate to={`/org/${organisationId}/accounts/${defaultAccount}`} />;
};
