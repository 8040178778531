import { MouseEvent, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { hasValue } from 'utils/hasValue';

import { CreateManualPayoutDialog } from '@organisms/CreateManualPayoutDialog/CreateManualPayoutDialog';

interface Props {
  'data-testid'?: string;
}

// CBP-2001-approval-workflow-manual-payouts: temporary until the feature flag is completely removed
export const CreateManualPayoutItem = ({
  'data-testid': dataTestId
}: Props) => {
  const triggerRef = useRef<HTMLAnchorElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const dialogStep = searchParams.get('createManualPayout');

  const closeDialog = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('createManualPayout');
    setSearchParams(newSearchParams);
    triggerRef.current?.focus();
  };

  const openDialog = (e: MouseEvent) => {
    e.stopPropagation();
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.set('createManualPayout', 'selectProduct');
    setSearchParams(newSearchParams);
  };

  return (
    <>
      <a
        className="flex h-12 items-center whitespace-nowrap pl-6 text-sm hover:bg-inpay-dark-green-1000"
        onClick={openDialog}
        ref={triggerRef}
        data-testid={dataTestId}
      >
        Create manual payout
      </a>
      {hasValue(dialogStep) ? (
        <CreateManualPayoutDialog onClose={closeDialog} />
      ) : null}
    </>
  );
};
