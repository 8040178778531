import { SVGProps } from 'react';

export const AccountsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="#1e1e1e"
    {...props}
  >
    <g fill="none" strokeWidth=".8">
      <path d="M14.558 17.758h-3.2v3.2" />
      <path d="M21.1 18.384a4.754 4.754 0 0 1-9.163-.626m6.462-2.559h3.2V12" />
      <path d="M11.857 14.574a4.754 4.754 0 0 1 9.164.625" />
      <path d="M19.04 8.8V3.68a1.28 1.28 0 0 0-1.28-1.28H3.68A1.28 1.28 0 0 0 2.4 3.68v10.24a1.28 1.28 0 0 0 1.28 1.28H8.8M2.4 6.24h16.64M4.96 9.44h5.12M4.96 12h2.56" />
    </g>
  </svg>
);
