import Logo from 'assets/images/inpay-logo-white.svg';

import { OrgLink } from '@atoms/OrgLink';

import { SidebarItem } from './SidebarItem';
import { useSidebarMenu } from './useSidebarMenu';

export const Sidebar = () => {
  const { sidebarItems, settingsItems } = useSidebarMenu();

  return (
    <nav
      id="sidebar"
      aria-label="Sidebar"
      role="navigation"
      data-testid="sidebar"
    >
      <div className="relative h-full w-60 cursor-pointer overflow-hidden bg-inpay-green-primary-1000 px-3.5 text-white drop-shadow-gray-lg hover:overflow-auto">
        <div className="flex h-full flex-col justify-between pb-4">
          <nav className="mb-10 flex flex-col gap-y-5">
            <div className="p-4">
              <OrgLink to="/">
                <Logo className="mr-4 h-9" />
              </OrgLink>
            </div>

            {sidebarItems.map((item) => (
              <SidebarItem key={item.id} data-testid={item.id} item={item} />
            ))}
          </nav>

          <nav className="flex flex-col gap-y-4">
            {settingsItems.map((item) => (
              <SidebarItem key={item.id} data-testid={item.id} item={item} />
            ))}
          </nav>
        </div>
      </div>
    </nav>
  );
};
