import { ArrowLeftOnRectangleIcon as LogoutIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { AuthenticationContainer } from 'components/ui/atoms/AuthenticationContainer';

export const Logout = () => {
  const navigate = useNavigate();

  return (
    <AuthenticationContainer>
      <div className="w-80">
        <LogoutIcon className="mx-auto w-10 overflow-visible stroke-inpay-black-primary-1000 stroke-1.5" />
        <p className="mt-5 text-center text-4xl font-medium">Thanks for now</p>
        <p className="mt-5 text-center text-base font-[350]">
          You have been logged out.
        </p>

        <PrimaryButton
          size="large"
          className="mt-16 block w-full text-center font-medium leading-8"
          aria-label="Login"
          title="Login"
          onClick={() => navigate('/login')}
        >
          Log in again
        </PrimaryButton>
      </div>
    </AuthenticationContainer>
  );
};
