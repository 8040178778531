import { DateRangeOptions } from 'models/dateRange';

export const dateRangeOptions = {
  today: {
    label: 'Today',
    value: 'today'
  },
  yesterday: {
    label: 'Yesterday',
    value: 'yesterday'
  },
  d30: {
    label: 'Past 30 days',
    value: 'd30'
  },
  d7: {
    label: 'Past 7 days',
    value: 'd7'
  },
  last_week: {
    label: 'The last week',
    value: 'last_week'
  },
  last_month: {
    label: 'The last month',
    value: 'last_month'
  },
  last_year: {
    label: 'The last year',
    value: 'last_year'
  },
  this_week: {
    label: 'This week',
    value: 'this_week'
  },
  this_month: {
    label: 'This month',
    value: 'this_month'
  },
  this_year: {
    label: 'This year',
    value: 'this_year'
  },
  custom: {
    label: 'Custom range',
    value: 'custom'
  }
} as DateRangeOptions;
