import { Link, LinkProps, useParams } from 'react-router-dom';

export const OrgLink = ({ children, to, ...props }: LinkProps) => {
  const { organisationId } = useParams();

  const pathname = (to as string).startsWith(`/org/${organisationId}`)
    ? to
    : `/org/${organisationId}${to}`;

  return (
    <Link to={pathname} {...props}>
      {children}
    </Link>
  );
};
