import { Table } from 'antd/es';
import { useState } from 'react';

import { WithdrawalRequestsData } from 'models/withdrawal';

import { getColumns as getWithdrawalColumns } from './columns';
import { WithdrawalDetails } from './Details/WithdrawalDetails';

interface Props {
  withdrawalRequestsData: WithdrawalRequestsData[];
}

export const WithdrawalsTable = ({ withdrawalRequestsData }: Props) => {
  const withdrawalRequestColumns = getWithdrawalColumns(withdrawalRequestsData);

  const [open, setOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] =
    useState<WithdrawalRequestsData>();

  const handleRowClick = (record: WithdrawalRequestsData) => {
    setSelectedRowData(record);
    setOpen(true);
  };

  return (
    <div className="flex gap-6">
      <div
        className="overflow-auto rounded-xl bg-white shadow-lg"
        style={{ flex: open ? 0.75 : 1, zIndex: 0 }}
      >
        <Table
          className="withdrawals-table"
          title={() => (
            <div className="text-1xl p-3">
              <span className="font-semibold">Withdrawal requests:</span>{' '}
              <span>{withdrawalRequestsData.length}</span>
            </div>
          )}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record),
              style: { cursor: 'pointer' }
            };
          }}
          columns={withdrawalRequestColumns}
          dataSource={withdrawalRequestsData}
          scroll={{ x: 500, y: 800 }}
          size="middle"
          style={{ borderRight: 'none' }}
          pagination={{ pageSize: 8 }}
        />
      </div>
      {open && selectedRowData && (
        <WithdrawalDetails
          setOpen={setOpen}
          selectedRowData={selectedRowData}
        />
      )}
    </div>
  );
};
