import { InformationCircleIcon } from '@heroicons/react/24/outline';

import { BatchDetail } from 'models/batches';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

type ApprovalStatusProps = {
  batchDetails: BatchDetail;
  userId: number;
  requiredNumberOfApprovals: number;
};

export const ApprovalStatus = ({
  batchDetails,
  userId,
  requiredNumberOfApprovals
}: ApprovalStatusProps) => {
  const getApprovalMessage = () => {
    const approvedBy = batchDetails?.approvals || [];
    const needsApproval = requiredNumberOfApprovals - approvedBy.length;

    if (approvedBy.length === 0) {
      return `Needs to be approved by ${needsApproval} ${
        needsApproval === 1 ? 'person' : 'people'
      }.`;
    }

    const approvedText = approvedBy.map((approval) => (
      <span key={approval.user_id} className="flex flex-row items-center gap-1">
        <span>{approval.user_id === userId ? 'You' : approval.user_name}</span>
        <div className="group relative cursor-pointer">
          <InformationCircleIcon className="m-auto size-4" />
          <div className="invisible absolute -left-2 bottom-8 min-w-max rounded-lg bg-inpay-black-primary-800 px-2 py-1.5 text-white group-hover:visible">
            <FormattedDateTime value={approval.created_at} />
            <svg
              viewBox="0 0 100 100"
              className="invisible absolute size-4 fill-inpay-black-primary-800 group-hover:visible"
            >
              <polygon points="0,0 100,0 50,100" />
            </svg>
          </div>
        </div>
      </span>
    ));

    const remainingApprovalText =
      needsApproval === 1 ? 'one person.' : `${needsApproval} more people.`;

    return (
      <div className="flex">
        <span className="mr-2 font-bold">Approved by:</span>

        {batchDetails?.status === 'waiting_review' && (
          <>
            {approvedText}
            <span> / Needs to be approved by {remainingApprovalText}</span>
          </>
        )}
        {batchDetails?.status === 'completed' && approvedText}
      </div>
    );
  };

  return (
    <div className="w-max rounded-md bg-inpay-gray-primary-400 px-4 py-2 text-sm">
      {getApprovalMessage()}
    </div>
  );
};
