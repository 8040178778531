import { LinkProps, useLocation, useResolvedPath } from 'react-router-dom';

import { OrgLink } from './OrgLink';

export const LinkWithQuery = ({ children, to, ...props }: LinkProps) => {
  const { search } = useLocation();
  const { pathname } = useResolvedPath(to);

  return (
    <OrgLink to={`${pathname}${search}`} {...props}>
      {children}
    </OrgLink>
  );
};
