import { useBoolean } from 'usehooks-ts';

import { FundingInformationDialog } from '@organisms/AccountFundingDialog';
import { CryptoWalletFundingInformation } from '@organisms/AccountFundingDialog/CryptoWalletFundingInformation';

export const CryptoAccountFundingItem = () => {
  const { value, toggle, setValue } = useBoolean(false);

  return (
    <>
      <a
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
        className="group flex h-12 items-center whitespace-nowrap pl-6 text-sm hover:bg-inpay-dark-green-1000"
      >
        Crypto funding
      </a>
      <FundingInformationDialog
        FundingDetailsComponent={CryptoWalletFundingInformation}
        open={value}
        onClose={setValue}
      />
    </>
  );
};
