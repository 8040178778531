import { ColumnFilter } from '@tanstack/table-core';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffectOnce } from 'usehooks-ts';

import { PayoutsRequest } from 'redux/api/payouts/payoutsApi';
import { GlobalFiltersState } from 'redux/slices/globalFiltersSlice';
import {
  updateFilters as updateStoreFilters,
  selectState,
  reset,
  setCursorAfter,
  setCursorBefore,
  setSort,
  setIncludeCursor,
  LocalState
} from 'redux/slices/pages.payoutsSlice';

export type MergedState = LocalState & GlobalFiltersState & { batchId: string };

export function useLocalState(defaultState?: Partial<LocalState>) {
  const state = useSelector(selectState);
  const dispatch = useDispatch();

  const queryParams = useMemo(
    () => ({
      cursorAfter: state.pagination?.cursorAfter,
      cursorBefore: state.pagination?.cursorBefore,
      sort: state.sort,
      accountNumber: state.filters.accountNumber,
      state: state.filters.state,
      currency: state.filters.currency,
      amountFrom: state.filters.amountFrom,
      amountTo: state.filters.amountTo
    }),
    [state]
  );

  const tableFilters = useMemo<ColumnFilter[]>(() => {
    const result: ColumnFilter[] = [];
    for (const [key, value] of Object.entries(state.filters)) {
      if (value) {
        result.push({ id: key, value: value.toString() });
      }
    }

    return result;
  }, [state.filters]);

  const resetState = () => dispatch(reset());
  const updateSort = (value: string) => dispatch(setSort(value));
  const updateFilters = (value: LocalState['filters']) =>
    dispatch(updateStoreFilters(value));

  const updateCursorBefore = (value: string | undefined) =>
    dispatch(setCursorBefore(value));
  const updateCursorAfter = (value: string | undefined) =>
    dispatch(setCursorAfter(value));
  const updateIncludeCursor = (value: boolean) =>
    dispatch(setIncludeCursor(value));
  useEffectOnce(() => {
    if (!defaultState) return;
  });

  return {
    state,
    tableFilters,
    queryParams,
    resetState,
    updateSort,
    updateFilters,
    updateCursorBefore,
    updateCursorAfter,
    updateIncludeCursor
  };
}

export const stateToRequestParams = (
  state: Partial<MergedState>
): PayoutsRequest => {
  const params = {} as PayoutsRequest;
  if (state?.sort) params.sort = state.sort;
  const pagination = state.pagination;
  if (pagination) {
    if (pagination.cursorAfter) params.cursor_after = pagination.cursorAfter;
    if (pagination.cursorBefore) params.cursor_before = pagination.cursorBefore;
    if (pagination.includeCursor)
      params.include_cursor = pagination.includeCursor.toString();
  }

  if (state?.filters?.state) params.state_category = state.filters.state;
  if (state?.filters?.accountNumber)
    params.virtual_account_number = state.filters.accountNumber;
  if (state?.filters?.currency) params.currency_code = state.filters.currency;
  if (state?.filters?.amountFrom && state.filters.amountTo) {
    params.amount_from = state.filters.amountFrom;
    params.amount_to = state.filters.amountTo;
  }
  if (state?.batchId) params.batch_id = state.batchId;
  return params;
};
