import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getCountryName } from 'utils/formatters/getCountryName';

import { useFetchPayoutQuery } from 'redux/api/payouts/payoutsApi';

import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

import { Loading } from 'components/pages/Loading';
import { DetailsBox } from 'components/ui/organisms/DetailsBox';

interface Props {
  onTransactionSourceLoaded?: (data: any) => void;
  reference?: string;
}

export const PayoutTransactionDetails = ({
  onTransactionSourceLoaded,
  reference
}: Props) => {
  const {
    data: { data: payoutData } = {},
    isLoading,
    isError,
    isSuccess
  } = useFetchPayoutQuery({ reference });

  useEffect(() => {
    if (payoutData && onTransactionSourceLoaded) {
      onTransactionSourceLoaded(payoutData);
    }
  }, [isSuccess, isError]);

  const {
    ultimate_debtor: ultimateDebtor,
    creditor,
    creditor_account: creditorAccount
  } = payoutData || {};

  const data = useMemo(() => {
    return !isSuccess
      ? []
      : [
          {
            header: 'Sender',
            key: 'sender',
            data: [
              { key: 'Full Name:', value: ultimateDebtor.full_name },
              {
                key: 'Date of Birth:',
                value: (
                  <FormattedDate
                    value={ultimateDebtor.birthdate}
                    className="font-medium"
                  />
                )
              },
              { key: 'Address:', value: ultimateDebtor.address },
              { key: 'Postal Code:', value: ultimateDebtor.postal_code },
              { key: 'City:', value: ultimateDebtor.city },
              {
                key: 'Country:',
                value: getCountryName(ultimateDebtor.country_code)
              }
            ]
          },
          {
            header: 'Receiver',
            key: 'receiver',
            data: [
              { key: 'Full Name:', value: creditor.full_name },
              {
                key: 'Date of Birth:',
                value: (
                  <FormattedDate
                    value={creditor.birthdate}
                    className="font-medium"
                  />
                )
              },
              { key: 'Address:', value: creditor.address },
              { key: 'Postal Code:', value: creditor.postal_code },
              { key: 'City:', value: creditor.city },
              {
                key: 'Country:',
                value: getCountryName(creditorAccount.country_code)
              }
            ]
          },
          {
            header: 'Receiver Bank',
            key: 'receiver-bank',
            data: [
              { key: 'IBAN:', value: creditorAccount.iban },
              {
                key: 'Country:',
                value: getCountryName(creditorAccount.country_code)
              }
            ]
          }
        ];
  }, [payoutData, isSuccess]);

  return (
    <div className="relative max-h-full w-full flex-col overflow-auto rounded-lg bg-white shadow-plain-lg">
      <div className="payout-overview__header sticky top-0">
        <div className="flex items-center justify-between bg-white px-6 py-3.5">
          <span>Details</span>
          <Link to=".." className="cursor-pointer" aria-label="Close">
            <XMarkIcon className="size-6" />
          </Link>
        </div>
        <div className="border-inpay-gray-primary-300 h-10 border-y bg-inpay-green-secondary-light-200"></div>
      </div>
      {isLoading && <Loading className="bg-white" />}
      {isSuccess && <DetailsBox data={data} defaultValue="sender" />}
      {isError && <div>error</div>}
    </div>
  );
};
