import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useFetchOrganisationsQuery } from 'redux/api/organisations/organisationsApi';

import { Organisation } from 'models/organisations';

import { Loading } from 'components/pages/Loading';
import { NoOrganisation } from 'components/pages/NoOrganisation';

export const RedirectToDefaultOrganisation = () => {
  const { data, isFetching } = useFetchOrganisationsQuery();

  const defaultOrganisation = useMemo<Organisation | undefined>(
    () =>
      data?.organisations?.find((o) => o.default) || data?.organisations?.[0],
    [data]
  );

  if (isFetching) return <Loading />;

  if (defaultOrganisation)
    return <Navigate to={`/org/${defaultOrganisation.id}`} replace />;

  return <NoOrganisation />;
};
