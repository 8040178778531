import { createSearchParams } from 'react-router-dom';

export function prepareSearchParams(
  searchParams: URLSearchParams | string,
  queryParams: Record<string, any>
) {
  const params = createSearchParams(searchParams);

  for (const key in queryParams) {
    if (!queryParams[key]) {
      params.delete(key);
    } else {
      params.set(key, queryParams[key]);
    }
  }

  return params;
}

/**
 * Encodes a nested object of key/value pairs as URL query params
 * Example { filter: { status: 'rejected } } => "filter%5Bstatus%5D=rejected"
 * @param queryParams Query params. Can be a nested object.
 */
export const encodeQueryParams = (queryParams: Record<string, any>) => {
  const params = Object.entries(queryParams).reduce(
    (pairs: string[], [key, value]) => {
      if (typeof value === 'object') {
        for (const nestedKey in value) {
          pairs.push(`${key}%5B${nestedKey}%5D=${value[nestedKey]}`);
        }
      } else {
        pairs.push(`${key}=${value}`);
      }
      return pairs;
    },
    []
  );
  return params.join('&');
};

export const updateUrl = (url: string) => history.replaceState(null, '', url);

export function updateURLSearchParams(key: string, newValue: string) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // if the key doesn't exist, return
  if (!searchParams.has(key)) return url;

  // Set the search parameter
  searchParams.set(key, newValue);

  url.search = searchParams.toString();
  return url;
}

export function appendURLSearchParams(key: string, value: string) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  if (searchParams.has(key)) return url;

  // Append the search parameter
  searchParams.append(key, value);

  url.search = searchParams.toString();
  return url;
}

export function removeURLSearchParams(key: string) {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  // Delete the search parameter
  searchParams.delete(key);

  url.search = searchParams.toString();
  return url;
}

export function updateHistoryState(url: URL) {
  window.history.pushState({}, '', url);
}
