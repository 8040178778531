import * as TooltipRadix from '@radix-ui/react-tooltip';
import { type ReactNode } from 'react';

export const Tooltip = ({
  children,
  content
}: {
  children: ReactNode;
  content: ReactNode;
}) => {
  return (
    <TooltipRadix.Provider delayDuration={200}>
      <TooltipRadix.Root>
        <TooltipRadix.Trigger className="cursor-pointer" asChild>
          {children}
        </TooltipRadix.Trigger>
        <TooltipRadix.Portal>
          <TooltipRadix.Content
            className="rounded-md bg-inpay-black-primary-800 p-2 text-white"
            sideOffset={5}
            asChild
          >
            {content}
          </TooltipRadix.Content>
        </TooltipRadix.Portal>
      </TooltipRadix.Root>
    </TooltipRadix.Provider>
  );
};
