import { useParams } from 'react-router-dom';

import { GlobalFilters } from '../GlobalFilters';

export const TopBar = () => {
  const { organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  return (
    <div
      id="topbar"
      className="z-[1] flex min-h-16 items-center justify-around bg-inpay-gray-primary-200 px-9 drop-shadow-gray-lg"
    >
      <GlobalFilters />
    </div>
  );
};

TopBar.displayName = 'TopBar';
