import { ChangeEvent, useState } from 'react';

import { InputError } from '@atoms/InputError/InputError';

const keys = ['day', 'month', 'year'] as const;

type Keys = (typeof keys)[number];

type State = {
  [key in Keys]?: string;
};

interface Props {
  onChange: (value: string) => void;
  defaultValue?: string;
  errorMessage?: string;
}

export const SeparatedDateField = ({
  onChange,
  errorMessage,
  defaultValue
}: Props) => {
  const [date, setDate] = useState<State>(
    defaultValue
      ? {
          day: defaultValue.slice(8, 10),
          month: defaultValue.slice(5, 7),
          year: defaultValue.slice(0, 4)
        }
      : {
          day: undefined,
          month: undefined,
          year: undefined
        }
  );

  const onFieldChange = (field: Keys, e: ChangeEvent<HTMLInputElement>) => {
    const updatedDate = { ...date, [field]: e.target?.value };
    setDate(updatedDate);

    if (updatedDate.day && updatedDate.month && updatedDate.year) {
      onChange(`${updatedDate.year}-${updatedDate.month}-${updatedDate.day}`);
    }
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-x-4">
        <input
          placeholder="DD"
          defaultValue={date.day}
          className="h-12 w-full rounded-lg p-4 shadow-plain focus:shadow-plain-lg group-hover:shadow-plain-lg"
          onChange={(e) => onFieldChange('day', e)}
        />
        <input
          placeholder="MM"
          defaultValue={date.month}
          className="h-12 w-full rounded-lg p-4 shadow-plain focus:shadow-plain-lg group-hover:shadow-plain-lg"
          onChange={(e) => onFieldChange('month', e)}
        />
        <input
          placeholder="YYYY"
          defaultValue={date.year}
          className="h-12 w-full rounded-lg p-4 shadow-plain focus:shadow-plain-lg group-hover:shadow-plain-lg"
          onChange={(e) => onFieldChange('year', e)}
        />
        <span className="pl-2 text-xs text-inpay-gray-primary-1000">Day</span>
        <span className="pl-2 text-xs text-inpay-gray-primary-1000">Month</span>
        <span className="pl-2 text-xs text-inpay-gray-primary-1000">Year</span>
      </div>

      <InputError errorMessage={errorMessage} />
    </>
  );
};
