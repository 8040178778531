import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Content, Overlay, Title } from '@organisms/Dialog';

import { VirtualAccount } from 'models/virtualAccount';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { AccountSelector } from '@molecules/AccountSelector';

interface FundingDetailsComponentProps {
  virtualAccountNumber: string;
}

interface Props {
  open: boolean;
  FundingDetailsComponent: ({
    virtualAccountNumber
  }: FundingDetailsComponentProps) => JSX.Element;
  onClose: (value: boolean) => void;
}

export const FundingInformationDialog = ({
  open,
  onClose,
  FundingDetailsComponent
}: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const [virtualAccountNumber, setVirtualAccountNumber] = useState<string>();

  const onAccountChange = (account: VirtualAccount) => {
    setVirtualAccountNumber(account.account_number);
  };

  const onDialogClose = (value: boolean) => {
    onClose(value);

    // Reset selected account everytime the dialog gets closed.
    setVirtualAccountNumber(undefined);
  };

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content className="w-104">
            <Title className="flex items-center">
              <PlusCircleIcon className="size-7 shrink-0 text-inpay-green-primary-mixes-20%-secondary" />
              <div className="grow text-lg font-medium">
                <span>Account funding</span>
              </div>
            </Title>

            <div className="overflow-auto rounded-b-lg bg-inpay-gray-secondary-600 p-6">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col">
                  <label className="mb-0.5 pl-2 text-xs">Account</label>
                  <AccountSelector
                    defaultAccountNumber={virtualAccountNumber}
                    onChange={onAccountChange}
                  />
                </div>
                {virtualAccountNumber ? (
                  <FundingDetailsComponent
                    virtualAccountNumber={virtualAccountNumber}
                  />
                ) : (
                  <div className="rounded-md bg-inpay-gray-primary-400 p-3 text-sm">
                    Select account to see the funding instructions.
                  </div>
                )}

                <PrimaryButton
                  className="w-full"
                  onClick={() => {
                    onDialogClose(false);
                  }}
                >
                  Done, close window
                </PrimaryButton>
              </div>
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
