import { ArrowUpTrayIcon, XCircleIcon } from '@heroicons/react/24/outline';

import { Title } from '@organisms/Dialog';

import { UploadBatchResponse } from 'redux/api/payouts/uploadBatchApi';

import { CheckCircleIcon } from '@atoms/Icon';

import { ErrorResponse } from './types';

interface Props {
  isLoading: boolean;
  isError: boolean;
  error?: ErrorResponse;
  data?: UploadBatchResponse;
}

export const RenderDialogTitle = ({
  isLoading,
  data,
  isError,
  error
}: Props) => {
  return (
    <Title>
      {!isLoading && !data && !isError && (
        <div className="flex items-center">
          <ArrowUpTrayIcon className="mr-1 w-6 stroke-1" />
          <span className="grow pl-2"> Upload batch file</span>
        </div>
      )}
      {isLoading && !data && !isError && (
        <div className="flex items-center">
          <ArrowUpTrayIcon className="mr-1 w-6 stroke-1" />
          Uploading batch file
        </div>
      )}
      {data && (
        <div className="flex items-center">
          <CheckCircleIcon className="mr-1 w-6" />
          Payout batch accepted
        </div>
      )}
      {error && (
        <div className="flex items-center">
          <XCircleIcon className="mr-1 w-8 fill-inpay-red-200 stroke-white stroke-2" />
          Payout batch failed
        </div>
      )}
    </Title>
  );
};
