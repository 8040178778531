import { ReasonCodesReport, ReasonCodeStats } from 'models/stats';

import { customerApi } from '../customerApi';

type OrdersStatsKeys = 'categories' | 'created_after' | 'created_before';

export type OrdersStatsParams = Partial<Record<OrdersStatsKeys, string>>;

export const OrdersStatsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrdersStatsPerReasonCodes: builder.query<
      ReasonCodesReport,
      OrdersStatsParams
    >({
      query: (params) =>
        `/v1/collection/stats/orders/reason_codes_reports?${new URLSearchParams(
          params
        )}`,
      providesTags: ['Stats']
    }),

    getOrdersCategoryCountsStats: builder.query<
      { data: Array<ReasonCodeStats>; meta: { orders_count: number } },
      OrdersStatsParams
    >({
      query: (params) =>
        `/v1/collection/stats/orders/status_counts_reports?${new URLSearchParams(
          params
        )}`,
      providesTags: ['Stats']
    })
  })
});

export const {
  useGetOrdersStatsPerReasonCodesQuery,
  useGetOrdersCategoryCountsStatsQuery
} = OrdersStatsApi;
