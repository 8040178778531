import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { hasValue } from 'utils/hasValue';

import { useFetchBusinessUnitsQuery } from 'redux/api/organisations/organisationsApi';

import { InputError } from '@atoms/InputError/InputError';

import { SelectChoice, Selector } from '@molecules/Selector/Selector';

interface Props {
  onChange: (value: string) => void;
  errorMessage?: string;
  value: string;
}

export const BusinessUnitSelector = ({
  onChange,
  errorMessage,
  value
}: Props) => {
  const { organisationId } = useParams();

  const { data: { data: businessUnits = [] } = {} } =
    useFetchBusinessUnitsQuery(organisationId ?? '', {
      skip: !hasValue(organisationId)
    });

  const businessOptions = useMemo<SelectChoice[]>(() => {
    return businessUnits.map((unit) => ({
      value: unit.uuid,
      label: unit.name
    }));
  }, [businessUnits]);

  useEffect(() => {
    if (businessUnits?.length === 1) onChange(businessUnits[0].uuid);
  }, [businessUnits, onChange]);

  return (
    <>
      <Selector
        value={value}
        selectChoices={businessOptions}
        onChange={onChange}
        disabled={businessOptions.length < 2}
      />

      <InputError errorMessage={errorMessage} />
    </>
  );
};
