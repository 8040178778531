import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';

import { Content, Overlay } from '@organisms/Dialog';
import { uploadBatchDialogSchema as schema } from 'validators/uploadBatchDialog';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import {
  UploadBatchResponse,
  useUploadBatchMutation
} from 'redux/api/payouts/uploadBatchApi';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { TextButton } from '@atoms/buttons/TextButton';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import { InputField } from '@molecules/InputField';

import { CorrectDataHandler } from './CorrectDataHandler';
import { DownloadErrorButton } from './DownloadErrorButton';
import { ErrorHandler } from './ErrorHandler';
import { FileInputField } from './FileInputField';
import { RenderDialogTitle } from './RenderDialogTitle';
import { ErrorResponse } from './types';

interface Inputs extends InferType<typeof schema> {
  base: null;
  file: FileList;
}

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  setIsCreateBatchDialogVisible: (value: boolean) => void;
}

export const UploadBatchDialog = ({
  open,
  onClose,
  setIsCreateBatchDialogVisible
}: Props) => {
  const {
    handleSubmit,
    register,
    setError,
    watch,
    formState,
    reset: resetForm
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  // this is needed to cause re-render on onChange value
  watch('file');

  const [
    uploadBatch,
    { isError, error, data, isLoading, reset: resetUploadRequest }
  ] = useUploadBatchMutation();

  const uploadError = error as ErrorResponse;

  // useOrgNavigate
  const navigate = useNavigate();
  const { organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  if (!orgSettings) return null;

  const isBatchesPageEnabled =
    !!featureFlagsData?.['CBP-1761-customer-app-batches'];

  const isApprovalEnabled = orgSettings.batch_number_of_approvers > 0;

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      await handleSubmit(async (data) => {
        const formData = new FormData();
        if (data.name) {
          formData.append('name', data.name);
        }
        formData.append('file', data.file[0]);
        await uploadBatch({ form: formData, organisationId: organisationId });
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    } finally {
      resetForm();
    }
  };

  const onDialogClose = (value: boolean) => {
    resetForm();
    onClose(value);
  };

  const openCreateBatchTemplate = () => {
    onDialogClose(false);
    setIsCreateBatchDialogVisible(true);
  };

  const submitDisabled =
    formState.isSubmitSuccessful ||
    !formState.dirtyFields.file ||
    !formState.isValid;

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content>
            <RenderDialogTitle
              isLoading={isLoading}
              data={data}
              isError={isError}
              error={uploadError}
            />
            {!isLoading && !data && !isError && (
              <div className="flex justify-center rounded-b-lg bg-inpay-gray-primary-200 p-6">
                <div className="w-72">
                  <form onSubmit={onSubmit} autoComplete="off">
                    <InputField
                      {...register('name')}
                      className="mb-4"
                      label="Name (if left blank the file name will be used)"
                    />
                    <FileInputField {...register('file')} />
                    <PrimaryButton
                      type="submit"
                      size="large"
                      className="mt-8 w-full"
                      disabled={submitDisabled}
                    >
                      Upload batch
                    </PrimaryButton>
                    {formState.errors.base && (
                      <div
                        role="alert"
                        className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
                        style={{
                          filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
                        }}
                      >
                        {formState.errors.base.message}
                      </div>
                    )}
                    <TextButton
                      onClick={openCreateBatchTemplate}
                      className="mt-4 w-full"
                    >
                      Create Batch template
                    </TextButton>
                  </form>
                </div>
              </div>
            )}
            {isLoading && (
              <div className="flex flex-col items-center gap-y-4 rounded-b-lg bg-inpay-gray-primary-200 p-4">
                <RollingLoader ariaLabel="Uploading batch" />
                <div className="text-xs">
                  <span className="invisible">...</span>
                  <span>Processing</span>
                  <BlindingDots />
                </div>
              </div>
            )}
            {data && data.data && (
              <div className="rounded-b-lg bg-white px-6 py-10">
                <CorrectDataHandler
                  data={data as UploadBatchResponse}
                  isApprovalEnabled={isApprovalEnabled}
                  isBatchesPageEnabled={isBatchesPageEnabled}
                />
                <PrimaryButton
                  className="mt-10 w-full"
                  size="large"
                  onClick={() => {
                    onDialogClose(false);
                    navigate(
                      `/org/${organisationId}/batches/${data.data.batch_id}?status=waiting_review`
                    );
                  }}
                >
                  View batch
                </PrimaryButton>
                <SecondaryButton
                  className="mt-4 w-full"
                  onClick={() => {
                    resetForm();
                    resetUploadRequest();
                  }}
                >
                  Upload another batch
                </SecondaryButton>
              </div>
            )}
            {isError && uploadError && 'data' in uploadError && (
              <div className="rounded-b-lg bg-white px-6 py-10" role="alert">
                <ErrorHandler error={uploadError} />
                <DownloadErrorButton
                  errorData={uploadError.data}
                  resetForm={resetForm}
                  resetUploadRequest={resetUploadRequest}
                />
                <PrimaryButton
                  type="submit"
                  size="large"
                  className="mt-4 w-full"
                  onClick={() => {
                    resetForm();
                    onDialogClose(false);
                  }}
                >
                  Close window
                </PrimaryButton>
              </div>
            )}
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
