import { useBoolean } from 'usehooks-ts';

import { ExportTransactionDialog } from '@organisms/ExportTransactionDialog';

import { VirtualAccount } from 'models/virtualAccount';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

interface Props {
  currentAccountData: VirtualAccount | undefined;
}

export const AccountTransactionsHeader = ({ currentAccountData }: Props) => {
  const {
    value: isExportTransactionDialogOpen,
    setTrue: openExportTransactionDialog,
    setFalse: closeExportTransactionDialog
  } = useBoolean(false);

  return (
    <div className="flex items-center justify-between">
      <div className="text-2xl font-medium">Account Transactions</div>
      {currentAccountData && (
        <>
          <PrimaryButton onClick={openExportTransactionDialog}>
            Export Transactions
          </PrimaryButton>
          <ExportTransactionDialog
            open={isExportTransactionDialogOpen}
            onClose={closeExportTransactionDialog}
            accountData={currentAccountData}
          />
        </>
      )}
    </div>
  );
};
