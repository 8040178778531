import { queryAllByRole, queryHelpers, render } from '@testing-library/react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

// (LÞJ) Shamelessly stolen from https://testing-library.com/docs/example-react-router/

/**
 * A wrapper function for react test rendering with simple react-router
 * @param ui The element passed for rendering
 * @param initialRouting The route we want to initialize the application at
 */
export const renderWithRouter = (
  ui: React.ReactElement,
  { route = '/' } = {}
) => {
  window.history.pushState({}, 'Test page', route);

  return render(ui, { wrapper: BrowserRouter });
};

/**
 * A custom query for loading/progress indicators
 * @param container The container. Target of the query.
 * @param name Progress indicator name. Fx. by `aria-label`
 */
export const getProgressIndicator = (
  container: HTMLElement,
  name: string
): HTMLElement => {
  const elements = queryAllByRole(container, 'progressbar', { name });
  if (!elements.length) {
    throw queryHelpers.getElementError(
      `Unable to find an element of role 'progressbar' with name: ${name}"`,
      container
    );
  }
  if (elements.length > 1) {
    throw queryHelpers.getElementError(
      `Found multiple elements of role 'progressbar' with name: ${name}"`,
      container
    );
  }
  return elements[0];
};

/**
 * Utility function checking if current runtime is inside Cypress test environment.
 * returns True when executed in Cypress, false otherwise.
 */
export function isRunningInsideCypress(): boolean {
  return typeof Cypress !== 'undefined';
}
