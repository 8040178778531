import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'redux/store';

interface AccountsState {
  transactionsParams: {
    page: number;
    sort: string;
  };
}

export const initialState: AccountsState = {
  transactionsParams: {
    page: 1,
    sort: '-date'
  }
};

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setTransactionsPage: (state, { payload }: PayloadAction<number>) => {
      state.transactionsParams.page = payload;
    },
    increnmentTransactionsPage: (state) => {
      state.transactionsParams.page += 1;
    },
    setTransactionsSort: (state, { payload }: PayloadAction<string>) => {
      state.transactionsParams.sort = payload;
      state.transactionsParams.page = 1;
    }
  }
});

export const {
  setTransactionsPage,
  increnmentTransactionsPage,
  setTransactionsSort
} = slice.actions;

export const selectTransactionsPage = (state: RootState) =>
  state.accounts.transactionsParams.page;
export const selectTransactionsSort = (state: RootState) =>
  state.accounts.transactionsParams.sort;
export const selectTransactionsParams = (state: RootState) =>
  state.accounts.transactionsParams;

export default slice.reducer;
