import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';

import {
  removeURLSearchParams,
  updateHistoryState,
  updateURLSearchParams
} from 'utils/url';

import { CreateManualPayoutDialog } from '@organisms/CreateManualPayoutDialog/CreateManualPayoutDialog';
import { Restricted } from 'containers/Restricted';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { TabValue } from 'redux/slices/pages.payoutsSlice';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import {
  CREATE_MANUAL_PAYOUT_TEST_ID,
  TITLE_PAYOUT_REQUESTS_TEST_ID
} from './constants';
import { useLocalState } from './localState';

import { TabButton } from '../Batches/TabButton';

interface Props {
  onTabClick?: (tabValue: TabValue) => void;
  currentTab?: TabValue;
}

export const PayoutsHeader = ({ onTabClick, currentTab }: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { updateTab } = useLocalState();
  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const {
    value: isCreateManualPayoutDialogVisible,
    toggle: toggleCreateManualPayoutDialog,
    setTrue: openCreateManualPayoutDialog
  } = useBoolean(false);

  const closeDialog = () => {
    const url = removeURLSearchParams('createManualPayout');
    updateHistoryState(url);
    toggleCreateManualPayoutDialog();
  };

  useEffect(() => {
    if (new URLSearchParams(window.location.search).has('createManualPayout')) {
      const url = updateURLSearchParams('createManualPayout', 'selectProduct');
      updateHistoryState(url);
      openCreateManualPayoutDialog();
    }
  }, []);

  const openDialog = () => {
    toggleCreateManualPayoutDialog();
  };

  const isManualPayoutEnabled =
    orgSettings?.manual_payouts_enabled !== undefined &&
    orgSettings.manual_payouts_enabled > 0;

  const isOrgApprovalEnabled =
    (orgSettings?.manual_payout_number_of_approvers || 0) > 0;

  // temporary until the feature flag is completely removed
  const isManualPayoutApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-2001-approval-workflow-manual-payouts'];

  const isTabEnabled =
    onTabClick !== undefined &&
    isManualPayoutApprovalFlowEnabled &&
    isOrgApprovalEnabled;

  useEffect(() => {
    if (isTabEnabled) updateTab('completed');
  }, [isTabEnabled]);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div
          className="text-2xl font-medium"
          data-testid={TITLE_PAYOUT_REQUESTS_TEST_ID}
        >
          Payout Requests
        </div>

        {isManualPayoutEnabled && isManualPayoutApprovalFlowEnabled && (
          <Restricted
            requiredPermission="payouts:create"
            key="create-manual-payout"
          >
            <PrimaryButton
              onClick={openDialog}
              className="flex items-center gap-2"
              data-testid={CREATE_MANUAL_PAYOUT_TEST_ID}
            >
              Create manual payout
            </PrimaryButton>
          </Restricted>
        )}

        {isCreateManualPayoutDialogVisible ? (
          <CreateManualPayoutDialog onClose={closeDialog} />
        ) : null}
      </div>
      {isTabEnabled && (
        <div className="flex gap-3 pb-4">
          <TabButton
            onClick={() => {
              onTabClick('completed');
            }}
            label="All payouts"
            isActive={currentTab === 'completed'}
          />
          <TabButton
            onClick={() => {
              onTabClick('waiting_review');
            }}
            label="Pending approval"
            isActive={currentTab === 'waiting_review'}
          />
          <TabButton
            onClick={() => {
              onTabClick('rejected');
            }}
            label="Declined"
            isActive={currentTab === 'rejected'}
          />
        </div>
      )}
    </div>
  );
};
