import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { CreatePasswordForm } from '@organisms/CreatePasswordForm';
import { UpdatePasswordSuccess } from '@organisms/UpdatePasswordSuccess';
import InpayLogo from 'assets/images/inpay-logo-white.svg';

import { useValidateInvitationTokenQuery } from 'redux/api/authenticationApi';

import { Loading } from 'components/pages/Loading';

export const InvitationAccept = () => {
  const { token } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sent = searchParams.get('sent') != null;

  const { isLoading, error } = useValidateInvitationTokenQuery(
    {
      token: token as string
    },
    { skip: !!sent }
  );

  const onSent = () => {
    setSearchParams('sent');
  };

  useEffect(() => {
    if (error == undefined) return;
    if ('status' in error) {
      navigate('/invitations/expired');
    }
  }, [error]);

  return (
    <div className="md:bg-inpay-auth flex h-screen">
      <div className="flex w-full flex-col items-center justify-center bg-inpay-gray-primary-200 md:w-144">
        {isLoading ? (
          <Loading />
        ) : sent ? (
          <UpdatePasswordSuccess />
        ) : (
          <CreatePasswordForm token={token as string} onSent={onSent} />
        )}
      </div>
      <InpayLogo className="absolute right-12 top-10 hidden h-auto w-24 md:block" />
    </div>
  );
};
