import { useState } from 'react';
import {
  NavigateOptions,
  useNavigate,
  useSearchParams
} from 'react-router-dom';

export const useRedirectTo = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const urlQueryParams = searchParams.get('redirect_url');

  if (redirectTo !== urlQueryParams && urlQueryParams) {
    setRedirectTo(urlQueryParams);
  }

  const navigateOrRedirectTo = (
    intendedUrl: string,
    options?: NavigateOptions
  ) => {
    const url =
      redirectTo !== null ? decodeURIComponent(redirectTo) : intendedUrl;

    navigate(url, options);
  };

  const navigateWithRedirectTo = (
    url: string,
    options?: NavigateOptions,
    redirectUrl: string | null = redirectTo
  ): void => {
    redirectUrl
      ? navigate(
          `${url}?redirect_url=${encodeURIComponent(redirectUrl)}`,
          options
        )
      : navigate(`${url}`, options);
  };

  return {
    navigateOrRedirectTo,
    navigateWithRedirectTo,
    redirectTo,
    setRedirectTo
  };
};
