import { CancelOrderErrorResponse } from 'redux/api/collections/orders/responses';

export function formatErrors(errors: CancelOrderErrorResponse): string {
  return (errors.data?.errors ?? [])
    .filter((error) => error?.title?.details)
    .map((error) => {
      const detailsString =
        typeof error.title?.details === 'string'
          ? error.title.details
          : Object.entries(error.title?.details || {})
              .map(([fieldName, fieldErrors]) =>
                (fieldErrors || [])
                  .map((message) => `${fieldName}: ${message}`)
                  .join(', ')
              )
              .join(', ');

      return detailsString.trim();
    })
    .join('');
}
