import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { SearchWidget } from '@organisms/SearchWidget';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { DateRangeWrapper } from '@molecules/DateRangeWrapper';

import { OrgDropDown } from './OrgDropDown';

// (LÞJ): Should match routes, but not query params
//  Expects a leading digit (organisation id)
const ENABLED_DATE_RANGE_PICKER_PAGES_PATTERN =
  /(\d+)(\/)(payouts|collections|accounts)/;

// (LÞJ): Pattern attempts to verify we are on Dashboard route
//  or Exported transactions route. Including query params
const DISABLED_DATE_RANGE_PICKER_PAGES_PATTERN = /(\/org\/(\d+)(\/)*(\?.*)*$)/;

export const GlobalFilters = () => {
  const { filters, setDateRange } = useGlobalFilters();
  const location = useLocation();

  const dateFilterPresent = useMemo(() => {
    return (
      ENABLED_DATE_RANGE_PICKER_PAGES_PATTERN.test(location.pathname) ||
      DISABLED_DATE_RANGE_PICKER_PAGES_PATTERN.test(location.pathname)
    );
  }, [location.pathname]);

  const dateFilterDisabled = useMemo(() => {
    return DISABLED_DATE_RANGE_PICKER_PAGES_PATTERN.test(location.pathname);
  }, [location.pathname]);

  const { organisationId } = useParams() as { organisationId: string };

  return (
    <div className="flex w-full justify-between gap-2">
      <div className="flex flex-row gap-2">
        <SearchWidget className="w-86 shrink-0" />
        {dateFilterPresent && (
          <DateRangeWrapper
            defaultFilters={filters.dateRange}
            onChange={setDateRange}
            disabled={dateFilterDisabled}
          />
        )}
      </div>
      <div className="flex w-64 flex-row">
        <OrgDropDown currentOrganisationId={organisationId} />
      </div>
    </div>
  );
};
