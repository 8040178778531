import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { hasValue } from 'utils/hasValue';

import { useFetchUserQuery } from 'redux/api/usersApi';

import { Loading } from 'components/pages/Loading';
import { LinkWithQuery } from 'components/ui/atoms/LinkWithQuery';

import { UserDetailsEdit } from './UserDetailsEdit';
import { UserDetailsShow } from './UserDetailsShow';

export const UserDetails = () => {
  const { userId, organisationId } = useParams();
  const [isEdit, setIsEdit] = useState(false);

  const {
    data: { data: userData } = {},
    isLoading,
    isError,
    isSuccess
  } = useFetchUserQuery({ organisationId, id: userId });

  const onEditClick = () => {
    setIsEdit(true);
  };

  const onFormClose = () => {
    setIsEdit(false);
  };

  return (
    <div
      role="complementary"
      aria-label="User details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="border-inpay-gray-primary-300 flex items-center justify-between border-b px-6 py-3.5 font-bold">
        <span>{isEdit ? 'Edit User' : 'User Details'}</span>
        <LinkWithQuery to=".." className="cursor-pointer" aria-label="Close">
          <XMarkIcon className="size-6 text-inpay-black-primary-400" />
        </LinkWithQuery>
      </div>
      <div className="overflow-auto">
        {isLoading && <Loading />}
        {isSuccess && hasValue(userData) && (
          <>
            {!isEdit ? (
              <UserDetailsShow data={userData} onEditClick={onEditClick} />
            ) : (
              <UserDetailsEdit
                data={userData}
                onCancelClick={onFormClose}
                onSuccess={onFormClose}
              />
            )}
          </>
        )}
        {isError && (
          <div className="flex h-full items-center justify-center">
            Something went wrong
          </div>
        )}
      </div>
    </div>
  );
};
