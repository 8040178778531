import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyFetchOrganisationsQuery } from 'redux/api/organisations/organisationsApi';

import { Organisation } from 'models/organisations';

import { useRedirectTo } from 'hooks/useRedirectTo';

import { LoggingIn } from 'components/ui/organisms/LoggingIn';

const getOrganisationIdFromLink = (link: string): string | undefined =>
  link.match(/^\/org\/(?<id>\d+)/)?.groups?.id;

export const OrganisationsLoadingTransition = () => {
  const { navigateWithRedirectTo, redirectTo, setRedirectTo } = useRedirectTo();
  const navigate = useNavigate();
  const [fetchOrganisations] = useLazyFetchOrganisationsQuery();

  useEffect(() => {
    Promise.all([fetchOrganisations().unwrap()]).then(
      ([organisationsResponse]) => {
        let { organisations }: { organisations: Array<Organisation> } =
          organisationsResponse;

        organisations = organisations || [];

        if (organisations.length === 0) {
          navigate('/no-organisation');

          return;
        }

        const organisationFromRedirect =
          redirectTo &&
          organisations.find(
            (o) => o.id.toString() == getOrganisationIdFromLink(redirectTo)
          );

        if (!organisationFromRedirect) setRedirectTo(null);

        const organisation =
          organisationFromRedirect ||
          organisations.find((o) => o.default) ||
          organisations[0];

        navigateWithRedirectTo(
          `/org/${organisation.id}/login_transition`,
          {
            state: { loggingIn: true }
          },
          organisationFromRedirect ? redirectTo : null
        );
      }
    );
  }, []);

  return <LoggingIn />;
};
