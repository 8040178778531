import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { FormEventHandler, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';

import { generateRangeFromType } from 'utils/dateRange';
import dayjs, { API_DATE_FORMAT, DATE_FORMAT, formatDate } from 'utils/dayjs';
import { setFormErrors } from 'utils/formHelpers';
import { hasValue } from 'utils/hasValue';

import { Content, Overlay, Title } from '@organisms/Dialog';
import { DownloadReportForm } from '@organisms/DownloadReportDialog/DownloadReportForm';
import { exportTransactionDialogSchema as schema } from 'validators/exportTransactionDialog';

import {
  GenerateReportResponse,
  useGenerateAccountReportMutation
} from 'redux/api/accounts/reportsApi';
import { useGetCurrentUserQuery } from 'redux/api/customerApi';

import { VirtualAccount } from 'models/virtualAccount';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { TRANSLATIONS } from './constants';
import { Form } from './Form';
import { Loading } from './Loading';
import { NewReportSuccess } from './NewReportSuccess';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
  accountData: VirtualAccount;
}

export const ExportTransactionDialog = ({
  open,
  onClose,
  accountData
}: Props) => {
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [alreadyGeneratedReport, setAlreadyGeneratedReport] =
    useState<GenerateReportResponse>();
  const { filters } = useGlobalFilters();

  const [generateReport, { isSuccess: generateSuccess, reset }] =
    useGenerateAccountReportMutation();

  const range = useMemo(() => {
    const dateRange = generateRangeFromType(filters.dateRange);
    // TODO (LTJ): Add a `includeToday` flag to `generateRangeFromType`
    // TODO: Add a jest unit test f or `generateRangeFromType`
    //  Ensuring the input is correct as early as possible.
    if (!hasValue(dateRange.to)) {
      return {
        ...dateRange,
        to: dayjs().format(DATE_FORMAT)
      };
    }
    return dateRange;
  }, [filters.dateRange]);

  const {
    handleSubmit,
    setError,
    formState,
    reset: resetForm
  } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      virtual_account_number: accountData.account_number,
      created_after: range.from,
      created_before: range.to
    }
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      await handleSubmit(
        async () => {
          setShowLoading(true);

          const after = formatDate(range.from, 'utc', 'iso');
          const before = range.to
            ? formatDate(range.to, 'utc', 'iso')
            : dayjs().format(API_DATE_FORMAT);

          try {
            const reportGenerationResults = await generateReport({
              // TODO (LTJ): We are reading from the component state
              //  because of a bug where the form data state is stale.
              // Reproduce by submitting with one account then another.
              virtualAccountNumber: accountData.account_number,
              created_after: after,
              created_before: before
            }).unwrap();
            if (reportGenerationResults)
              setAlreadyGeneratedReport(reportGenerationResults);
          } catch (error) {
            throw new Error(TRANSLATIONS.ERROR_GENERATING_REPORT);
          }
        },
        () => {
          throw new Error(TRANSLATIONS.ERROR_GENERATING_REPORT);
        }
      )(event);
    } catch (error: any) {
      // fixes rendering backend failures if form was submitted
      // with valid fields.
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    } finally {
      setShowLoading(false);
      resetForm();
    }
  };

  const onDialogClose = (value: boolean) => {
    reset();
    resetForm();
    onClose(value);
  };

  const { data } = useGetCurrentUserQuery();
  const currentUserEmail = data ? data.user.email : TRANSLATIONS.LOADING;

  const isNewReport = !alreadyGeneratedReport && generateSuccess;
  const isGenerated = !showLoading && generateSuccess;
  const isNotGenerated = !showLoading && !generateSuccess;

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content className="w-84">
            <Title>
              <span>
                {showLoading && TRANSLATIONS.GENERATING_REPORT}
                {isNotGenerated && (
                  <div className="flex items-center">
                    <DownloadIcon className="size-6 stroke-1 group-hover:stroke-1.5" />
                    <span className="grow pl-2">
                      {TRANSLATIONS.DIALOG_TITLE}
                    </span>
                  </div>
                )}
                {isGenerated && TRANSLATIONS.CLOSE_DIALOG}
              </span>
            </Title>
            <div className="rounded-b-lg bg-white p-6">
              {showLoading && <Loading email={currentUserEmail} />}
              {isGenerated && isNewReport && (
                <NewReportSuccess email={currentUserEmail} />
              )}
              {isGenerated && !isNewReport && alreadyGeneratedReport && (
                <DownloadReportForm
                  onClick={() => onDialogClose(false)}
                  dateRange={{
                    type: 'custom',
                    from: formatDate(alreadyGeneratedReport.date_from),
                    to: formatDate(alreadyGeneratedReport.date_to)
                  }}
                  uuid={alreadyGeneratedReport.uuid}
                  accountNumber={accountData.account_number}
                />
              )}
              {isNotGenerated && (
                <Form
                  onSubmit={onSubmit}
                  formState={formState}
                  dateRange={filters.dateRange}
                  accountData={accountData}
                />
              )}
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
