export const NoWithdrawalAccountsIcon = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 124C96.2417 124 124 96.2417 124 62C124 27.7583 96.2417 0 62 0C27.7583 0 0 27.7583 0 62C0 96.2417 27.7583 124 62 124Z"
        fill="#BDF5D5"
      />
      <path
        d="M99.1998 124H24.7998V43.8133C28.3065 43.8094 31.6685 42.4146 34.1481 39.935C36.6277 37.4554 38.0225 34.0934 38.0265 30.5867H85.9731C85.9694 32.3238 86.3102 34.0444 86.9759 35.649C87.6415 37.2536 88.6188 38.7102 89.851 39.9346C91.0754 41.1672 92.5321 42.1448 94.1368 42.8106C95.7416 43.4764 97.4624 43.8172 99.1998 43.8133V124Z"
        fill="white"
      />
      <path
        d="M62.0002 84.3199C72.9575 84.3199 81.8402 75.4372 81.8402 64.4799C81.8402 53.5226 72.9575 44.6399 62.0002 44.6399C51.0428 44.6399 42.1602 53.5226 42.1602 64.4799C42.1602 75.4372 51.0428 84.3199 62.0002 84.3199Z"
        fill="#49C786"
      />
      <path
        d="M69.0145 73.8329L62 66.8184L54.9855 73.8329L52.6473 71.4947L59.6618 64.4802L52.6473 57.4657L54.9855 55.1275L62 62.142L69.0145 55.1275L71.3527 57.4657L64.3382 64.4802L71.3527 71.4947L69.0145 73.8329Z"
        fill="white"
      />
      <path
        d="M72.7468 89.28H51.2534C49.8838 89.28 48.7734 90.3904 48.7734 91.76C48.7734 93.1297 49.8838 94.24 51.2534 94.24H72.7468C74.1164 94.24 75.2268 93.1297 75.2268 91.76C75.2268 90.3904 74.1164 89.28 72.7468 89.28Z"
        fill="#9DF0BF"
      />
      <path
        d="M80.1868 99.2H43.8135C42.4438 99.2 41.3335 100.31 41.3335 101.68C41.3335 103.05 42.4438 104.16 43.8135 104.16H80.1868C81.5565 104.16 82.6668 103.05 82.6668 101.68C82.6668 100.31 81.5565 99.2 80.1868 99.2Z"
        fill="#9DF0BF"
      />
    </svg>
  );
};
