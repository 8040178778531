import { SVGProps } from 'react';

export const PayoutsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="#1e1e1e"
    width="20"
    height="20"
    {...props}
  >
    <g fill="none" strokeWidth=".8">
      <path d="M2.4 13.918v7.68m0-1.281h14.08a2.56 2.56 0 0 0-2.56-2.56h-3.2a2.56 2.56 0 0 0-2.56-2.56H2.4m5.12 2.56h3.2m.638-12.797c0 1.414 2.292 2.56 5.12 2.56s5.12-1.146 5.12-2.56-2.292-2.56-5.12-2.56-5.12 1.146-5.12 2.56Z" />
      <path d="M11.358 4.96v6.4c0 1.413 2.292 2.56 5.12 2.56s5.12-1.146 5.12-2.56v-6.4" />
      <path d="M21.597 8.16c0 1.413-2.292 2.56-5.12 2.56s-5.12-1.146-5.12-2.56" />
    </g>
  </svg>
);
