// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cZFyNsiXZx9opl3tuAOS:after,.cZFyNsiXZx9opl3tuAOS:before{border:8px solid transparent;border-bottom-color:#e0f5e5;content:\"\";left:16px;position:absolute;top:-6px}", "",{"version":3,"sources":["webpack://./src/components/ui/molecules/DateRangeWrapper/styles.module.css"],"names":[],"mappings":"AAAA,yDASE,4BAAkC,CAAlC,2BAAkC,CAPlC,UAAW,CAEX,SAAU,CADV,iBAAkB,CAElB,QAKF","sourcesContent":[".notification:after,\n.notification:before {\n  content: '';\n  position: absolute;\n  left: 16px;\n  top: -6px;\n  border-top: 8px solid transparent;\n  border-right: 8px solid transparent;\n  border-bottom: 8px solid #e0f5e5;\n  border-left: 8px solid transparent;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": "cZFyNsiXZx9opl3tuAOS"
};
export default ___CSS_LOADER_EXPORT___;
