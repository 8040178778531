export const CREATE_MANUAL_PAYOUT_TEST_ID = 'create-manual-payout';
export const TITLE_PAYOUT_REQUESTS_TEST_ID = 'title-payout-requests';
export const PAYOUT_REQUEST_TABLE_TEST_ID = 'payout-requests-table';

export const TRANSLATIONS = {
  SUPER_EMPTY: 'We have yet to receive any payout requests.',
  EMPTY: 'No payout requests matching the filters.',
  RESET_FILTERS: 'Reset filters',
  EMPTY_PENDING: 'No pending payout requests',
  EMPTY_DECLINED: 'No declined payout requests'
};

export const APPROVAL_DETAILS_SECTION_ID = 'approval-details';
