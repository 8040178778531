import { BusinessUnit } from 'models/businessUnit';
import { Organisation } from 'models/organisations';

import { customerApi } from '../customerApi';

export interface OrganisationsResponse {
  organisations: Organisation[];
}

export interface ProductsResponse {
  meta: {
    payout_products_count: number;
    collection_products_count: number;
  };
}

export interface OrganisationsSettingResponse {
  data: {
    batch_number_of_approvers: number;
    manual_payout_number_of_approvers?: number;
    manual_payouts_enabled: number;
    batches_enabled: number;
    fx_widget_enabled?: number;
    virtual_bank_bookkeeping?: string;
    account_funding_enabled?: number;
  };
}

export interface BusinessUnitsResponse {
  data: BusinessUnit[];
}

export const organisationsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchOrganisationConfigurations: builder.query<
      OrganisationsSettingResponse,
      any
    >({
      query: (organisationId) => {
        return `/v2/customer/organisations/${organisationId}/configurations`;
      },
      providesTags: ['Organisations']
    }),
    fetchOrganisations: builder.query<OrganisationsResponse, void>({
      query: () => {
        return '/v1/customer/organisations';
      },
      providesTags: ['Organisations']
    }),
    setCurrentOrganisation: builder.mutation<Organisation, number>({
      query: (organisationId) => {
        return {
          url: `/v1/customer/organisations/${organisationId}/set_current`,
          method: 'POST',
          headers: {
            'X-Organisation-ID': organisationId.toString()
          }
        };
      },
      invalidatesTags: [
        'Payouts',
        'VirtualAccounts',
        'Orders',
        'Payments',
        'Stats',
        'Transactions',
        'Reports',
        'BatchTemplates',
        'Batches',
        'Currencies',
        'BusinessUnits',
        'Users'
      ]
    }),
    setDefaultOrganisation: builder.mutation<Organisation, number>({
      query: (organisationId) => {
        return {
          url: `/v1/customer/organisations/${organisationId}/set_default`,
          method: 'POST'
        };
      },
      invalidatesTags: ['Organisations', 'Stats']
    }),
    fetchContractedProducts: builder.query<ProductsResponse, string>({
      query: (organisationId) => {
        return `/v1/customer/organisations/${organisationId}/contracted_products`;
      }
    }),
    fetchBusinessUnits: builder.query<BusinessUnitsResponse, string>({
      query: (organisationId) => {
        return {
          url: '/v1/customer/business_units',
          method: 'GET',
          headers: {
            'X-Organisation-ID': organisationId
          }
        };
      },
      providesTags: ['BusinessUnits']
    })
  })
});

export const {
  useFetchOrganisationsQuery,
  useSetCurrentOrganisationMutation,
  useSetDefaultOrganisationMutation,
  useLazyFetchOrganisationsQuery,
  useFetchContractedProductsQuery,
  useFetchOrganisationConfigurationsQuery,
  useLazyFetchOrganisationConfigurationsQuery,
  useFetchBusinessUnitsQuery
} = organisationsApi;
