import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { type InferType } from 'yup';

import { hasValue } from 'utils/hasValue';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { CancelButton } from '../ConfirmCancelDialog';
import { SpecificField } from '../fields/SpecificField';
import { fieldToDisplayableName } from '../fieldToDisplayableName';
import { setFormErrors } from '../formHelpers';
import { StepProps } from '../steps';

export const CommonStep = ({
  onSubmit,
  onCancel,
  fields,
  defaultValues,
  responseField
}: StepProps) => {
  const validationObject = useMemo(() => {
    if (hasValue(fields)) {
      return Object.keys(fields).reduce<Record<string, yup.BaseSchema>>(
        (acc, fieldName: string) => {
          acc[fieldName] = yup
            .string()
            .label(fieldToDisplayableName(fieldName))
            .required();
          return acc;
        },
        {}
      );
    }
  }, [fields]);

  const schema = yup.object(validationObject).required();
  interface Inputs extends InferType<typeof schema> {
    base: null;
  }

  const form = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ...defaultValues }
  });

  if (!fields) return null;

  const onSubmitHandle: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      await form.handleSubmit(async (data) => {
        await onSubmit(data);
      })(e);
    } catch (error: any) {
      setFormErrors({
        errors: error.data.errors[responseField],
        setError: form.setError,
        fields: Object.keys(schema.fields)
      });
    }
  };

  return (
    <div className="flex justify-center pb-8">
      <form autoComplete="off" className="w-80" onSubmit={onSubmitHandle}>
        {Object.keys(fields).map((fieldName) => {
          return (
            <SpecificField
              key={fieldName}
              fieldName={fieldName}
              fieldDefinition={fields[fieldName]}
              form={form}
            />
          );
        })}
        <div className="mt-10 flex gap-4">
          <CancelButton onAction={onCancel} />
          <PrimaryButton size="large" type="submit" className="w-full">
            Next
          </PrimaryButton>
        </div>
        {form.formState.errors.base && (
          <div
            role="alert"
            className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
            style={{
              filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
            }}
          >
            {form.formState.errors.base.message}
          </div>
        )}
      </form>
    </div>
  );
};
