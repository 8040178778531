import { ReactElement, useState } from 'react';
import { useMatch, useParams, useResolvedPath } from 'react-router-dom';

import { Restricted } from 'containers/Restricted';

import { PermitAction } from 'hooks/usePermissions';

import { SidebarDropdownItem } from './SidebarDropdownItem';
import { SidebarSingleItem } from './SidebarSingleItem';

export type SidebarMenuSubItem = Omit<SidebarMenuItem, 'icon'>;

export interface SidebarMenuItem {
  id: string;
  ['data-testid']?: string;
  title: string;
  path: string;
  // Match full route? Default: false
  end?: boolean;
  requiredPermission?: PermitAction;
  // disabled?: boolean;
  icon: React.ElementType;
  subtitle?: string;
  // Apply active route styling?
  noActive?: boolean;
  subItems?: (SidebarMenuSubItem | ReactElement)[];
}

export interface SidebarItemProps {
  item: SidebarMenuItem;
  openActive?: boolean;
  'data-testid'?: string;
}

export const SidebarItem = ({
  item,
  openActive,
  'data-testid': dataTestId,
  ...props
}: SidebarItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { organisationId } = useParams();

  const resolved = useResolvedPath(`/org/${organisationId}${item.path}`);
  const active = !!useMatch({ path: resolved.pathname, end: !!item.end });

  const shouldBeExpanded = isExpanded || (!!openActive && active);

  return (
    <Restricted requiredPermission={item.requiredPermission}>
      {item.subItems?.length ? (
        <SidebarDropdownItem
          isExpanded={shouldBeExpanded}
          data-testid={dataTestId}
          item={item}
          onClick={() => setIsExpanded(!isExpanded)}
          isActive={active}
          {...props}
        />
      ) : (
        <SidebarSingleItem
          item={item}
          data-testid={dataTestId}
          {...props}
          isActive={active}
        />
      )}
    </Restricted>
  );
};
