export const NoWithdrawalRequestsIcon = () => {
  return (
    <svg
      width="124"
      height="136"
      viewBox="0 0 124 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62 133C96.2417 133 124 105.242 124 71C124 36.7583 96.2417 9 62 9C27.7583 9 0 36.7583 0 71C0 105.242 27.7583 133 62 133Z"
        fill="#BDF5D5"
      />
      <g filter="url(#filter0_d_4137_15932)">
        <path
          d="M97.5465 47.8533H26.4532C24.1704 47.8533 22.3198 49.5108 22.3198 51.5554V129.298C22.3198 131.343 24.1704 133 26.4532 133H97.5465C99.8293 133 101.68 131.343 101.68 129.298V51.5554C101.68 49.5108 99.8293 47.8533 97.5465 47.8533Z"
          fill="white"
        />
      </g>
      <path
        d="M53.7331 60.2533H32.2398C30.8701 60.2533 29.7598 61.3637 29.7598 62.7333C29.7598 64.103 30.8701 65.2133 32.2398 65.2133H53.7331C55.1028 65.2133 56.2131 64.103 56.2131 62.7333C56.2131 61.3637 55.1028 60.2533 53.7331 60.2533Z"
        fill="#9DF0BF"
      />
      <path
        d="M68.6131 71H32.2398C30.8701 71 29.7598 72.1103 29.7598 73.48C29.7598 74.8497 30.8701 75.96 32.2398 75.96H68.6131C69.9828 75.96 71.0931 74.8497 71.0931 73.48C71.0931 72.1103 69.9828 71 68.6131 71Z"
        fill="#DEFAEA"
      />
      <path
        d="M53.7331 82.5733H32.2398C30.8701 82.5733 29.7598 83.6837 29.7598 85.0533C29.7598 86.423 30.8701 87.5333 32.2398 87.5333H53.7331C55.1028 87.5333 56.2131 86.423 56.2131 85.0533C56.2131 83.6837 55.1028 82.5733 53.7331 82.5733Z"
        fill="#9DF0BF"
      />
      <path
        d="M68.6131 93.32H32.2398C30.8701 93.32 29.7598 94.4303 29.7598 95.8C29.7598 97.1697 30.8701 98.28 32.2398 98.28H68.6131C69.9828 98.28 71.0931 97.1697 71.0931 95.8C71.0931 94.4303 69.9828 93.32 68.6131 93.32Z"
        fill="#DEFAEA"
      />
      <path
        d="M53.7331 104.893H32.2398C30.8701 104.893 29.7598 106.004 29.7598 107.373C29.7598 108.743 30.8701 109.853 32.2398 109.853H53.7331C55.1028 109.853 56.2131 108.743 56.2131 107.373C56.2131 106.004 55.1028 104.893 53.7331 104.893Z"
        fill="#9DF0BF"
      />
      <path
        d="M68.6131 115.64H32.2398C30.8701 115.64 29.7598 116.75 29.7598 118.12C29.7598 119.49 30.8701 120.6 32.2398 120.6H68.6131C69.9828 120.6 71.0931 119.49 71.0931 118.12C71.0931 116.75 69.9828 115.64 68.6131 115.64Z"
        fill="#DEFAEA"
      />
      <g filter="url(#filter1_d_4137_15932)">
        <path
          d="M97.5465 9H26.4532C24.1704 9 22.3198 10.8506 22.3198 13.1333V37.9333C22.3198 40.2161 24.1704 42.0667 26.4532 42.0667H97.5465C99.8293 42.0667 101.68 40.2161 101.68 37.9333V13.1333C101.68 10.8506 99.8293 9 97.5465 9Z"
          fill="#49C786"
        />
      </g>
      <path
        d="M53.7331 18.0933H32.2398C30.8701 18.0933 29.7598 19.2037 29.7598 20.5733C29.7598 21.943 30.8701 23.0533 32.2398 23.0533H53.7331C55.1028 23.0533 56.2131 21.943 56.2131 20.5733C56.2131 19.2037 55.1028 18.0933 53.7331 18.0933Z"
        fill="#9DF0BF"
      />
      <path
        d="M68.6131 28.84H32.2398C30.8701 28.84 29.7598 29.9503 29.7598 31.32C29.7598 32.6897 30.8701 33.8 32.2398 33.8H68.6131C69.9828 33.8 71.0931 32.6897 71.0931 31.32C71.0931 29.9503 69.9828 28.84 68.6131 28.84Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4137_15932"
          x="16.3198"
          y="38.8533"
          width="91.3599"
          height="97.1467"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4137_15932"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4137_15932"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_4137_15932"
          x="16.3198"
          y="0"
          width="91.3599"
          height="45.0667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4137_15932"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4137_15932"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
