import { useStore } from 'react-redux';

import { baseQueryWithReauth } from 'redux/api/baseApi';

const FILENAME_REGEXP = /filename(?:=|\*=)[\w-]+''([^"';\n]+)/;

export const useDownloadFile = () => {
  const store = useStore();
  const downloadFile = async (url: string) => {
    const data = await baseQueryWithReauth(
      {
        url: `v1/${url}`,
        responseHandler: (response) => response.blob()
      },
      store as any, //strange ts errors. RTK docs say that it's enough interface and it works
      {}
    );
    const link = document.createElement('a');

    const contentDisposition = (data.meta as any).response.headers.get(
      'content-disposition'
    );

    const match = contentDisposition.match(FILENAME_REGEXP);

    link.setAttribute('href', URL.createObjectURL(data.data as Blob));
    link.setAttribute('download', match[1]);

    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return downloadFile;
};
