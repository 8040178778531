// Large search icon used when we have no search icon
// Sourced from figma (Inpay Customer Portal / Workflows 2024)
// https://www.figma.com/file/ExyiNmwMwOZlYM9ldbdWjN/Workflows-2024?type=design&node-id=1233%3A6244&mode=design&t=CV1OWt9Wx5qVxk23-1
export const SearchIcon = () => (
  <svg
    width="112"
    height="112"
    viewBox="0 0 112 112"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1350_25257)">
      <path
        d="M93.9985 86C90.6848 86 87.9985 83.3137 87.9985 80V74H21.9985V21.3521C21.9985 20.6054 22.604 20 23.3506 20H98.6463C99.3931 20 99.9985 20.6054 99.9985 21.3521V80C99.9985 83.3137 97.3122 86 93.9985 86Z"
        fill="#7CEBAA"
      />
      <path
        d="M92.0649 78.0684L87.9966 80H27.9966V27.3521C27.9966 26.6054 28.602 26 29.3486 26H87.9966L92.0649 27.9316V78.0684Z"
        fill="#F2FBFF"
      />
      <path
        d="M87.9961 26V80H92.644C93.3908 80 93.9961 79.3946 93.9961 78.6479V27.3521C93.9961 26.6052 93.3907 26 92.644 26H87.9961Z"
        fill="#DFF6FD"
      />
      <path
        d="M92.0679 85.807L88.2046 86.0001H21.9995C18.6858 86.0001 15.9995 83.3138 15.9995 80.0001V33.1699C15.9995 32.4231 16.6049 31.8179 17.3516 31.8179H44.7738C45.5572 31.8179 46.3074 32.1351 46.853 32.6974L51.1458 37.1206C51.6914 37.6827 52.4416 38.0001 53.225 38.0001H82.2046L86.0679 39.9317V80.0001C86.0679 83.3138 88.7542 85.807 92.0679 85.807Z"
        fill="#BDF5D5"
      />
      <path
        d="M87.9959 80V39.3521C87.9959 38.6052 87.3904 38 86.6438 38H82.2012V80C82.2012 83.3137 84.8875 86 88.2012 86H93.9959C90.6822 86 87.9959 83.3137 87.9959 80Z"
        fill="#9DF0BF"
      />
      <path
        d="M28.4287 72.27L31.6148 75.4561L26.8357 80.2351L23.6496 77.0491L28.4287 72.27Z"
        fill="#867885"
      />
      <path
        d="M40.2927 79.3493C49.001 79.3493 56.0604 72.2898 56.0604 63.5815C56.0604 54.8732 49.001 47.8137 40.2927 47.8137C31.5844 47.8137 24.5249 54.8732 24.5249 63.5815C24.5249 72.2898 31.5844 79.3493 40.2927 79.3493Z"
        fill="#918291"
      />
      <path
        d="M47.2372 70.5265C42.8381 74.9255 35.6349 74.6221 32.5377 71.3471C32.0263 70.8064 31.3429 70.4605 30.9425 69.8652C27.9966 65.4856 28.46 59.4946 32.3326 55.622C36.1818 51.7728 42.1236 51.2917 46.4958 54.1785C47.1205 54.5909 47.5337 55.2526 48.0578 55.8271C51.1235 59.188 51.6362 66.1275 47.2372 70.5265Z"
        fill="#F2FBFF"
      />
      <path
        d="M48.2627 55.6218C47.7364 55.0955 47.1709 54.6321 46.5756 54.2318C49.5216 58.6113 49.0582 64.6023 45.1856 68.4749C41.3129 72.3476 35.3219 72.811 30.9424 69.865C31.3428 70.4603 31.806 71.0257 32.3324 71.5521C36.7315 75.9511 43.8636 75.9511 48.2627 71.5521C52.6617 67.153 52.6617 60.0209 48.2627 55.6218Z"
        fill="#DFF6FD"
      />
      <path
        d="M27.0952 81.1102L18.5624 89.643C16.8033 91.4021 15.1808 92.2226 13.4217 90.4635C12.8637 89.9055 12.277 89.4419 12.0739 88.7342C11.6339 87.2089 12.0154 85.498 13.2165 84.297L18.8198 78.6936L20.6148 78.9503L20.3585 77.1551L22.057 75.4566C22.454 75.0596 23.0961 75.0597 23.493 75.4566L26.0695 78.0331L27.0952 81.1102Z"
        fill="#918291"
      />
      <path
        d="M26.0694 78.033L16.5112 87.5911C15.3096 88.7928 13.5981 89.1737 12.0731 88.7342C12.2772 89.4423 12.6583 90.1103 13.2162 90.6683C14.9758 92.4279 17.8287 92.4279 19.5883 90.6683L25.1911 85.0655L24.9347 83.2705L26.7297 83.5269L28.4284 81.8282C28.825 81.4316 28.8249 80.7887 28.4284 80.3923L26.0693 78.0331L26.0694 78.033Z"
        fill="#867885"
      />
      <path
        d="M24.9349 83.2706L22.1143 81.9883L18.8198 78.6938L20.3584 77.1553L23.6529 80.4497L24.9349 83.2706Z"
        fill="#F2FBFF"
      />
      <path
        d="M23.6523 80.45L26.7295 83.5271L25.1909 85.0656L22.1138 81.9885L23.6523 80.45Z"
        fill="#DFF6FD"
      />
      <path
        d="M9 16.5C9 18.9785 6.97846 21 4.5 21C2.02154 21 -8.83643e-08 18.9785 -1.96701e-07 16.5C-3.05038e-07 14.0215 2.02154 12 4.5 12C6.97846 12 9 14.0077 9 16.5ZM2.07692 16.5C2.07692 17.8292 3.17077 18.9231 4.5 18.9231C5.82923 18.9231 6.92308 17.8292 6.92308 16.5C6.92308 15.1708 5.82923 14.0769 4.5 14.0769C3.17077 14.0769 2.07692 15.1569 2.07692 16.5Z"
        fill="#E2E4E3"
      />
      <path
        d="M13.9185 23.125C13.9185 22.0895 13.079 21.25 12.0435 21.25C11.0079 21.25 10.1685 22.0895 10.1685 23.125C10.1685 24.1605 11.0079 25 12.0435 25C13.079 25 13.9185 24.1605 13.9185 23.125Z"
        fill="#9DF0BF"
      />
      <path
        d="M80 109C79.5284 109 79.1158 108.706 78.9537 108.25C78.1137 105.794 76.2126 103.897 73.7516 103.044C73.3095 102.897 73 102.471 73 102C73 101.529 73.2947 101.118 73.7516 100.956C76.2126 100.103 78.1137 98.2206 78.9537 95.75C79.101 95.3088 79.5284 95 80 95C80.4716 95 80.8842 95.2941 81.0463 95.75C81.9011 98.2059 83.7874 100.103 86.2484 100.956C86.6905 101.103 87 101.529 87 102C87 102.471 86.7053 102.882 86.2484 103.044C83.7874 103.897 81.8863 105.779 81.0463 108.25C80.8842 108.706 80.4716 109 80 109ZM76.6547 102.015C78.0105 102.853 79.1453 104 80 105.353C80.84 104 81.9895 102.868 83.3453 102.015C81.9895 101.176 80.8547 100.029 80 98.6765C79.16 100.029 78.0105 101.176 76.6547 102.015Z"
        fill="#BDF5D5"
      />
      <path
        d="M101.844 13.4868H97.9792C97.4439 13.4868 97 13.0429 97 12.5076C97 11.9722 97.4309 11.5283 97.9792 11.5283H101.844C102.379 11.5283 102.823 11.9722 102.823 12.5076C102.823 13.0429 102.379 13.4868 101.844 13.4868Z"
        fill="#E2E4E3"
      />
      <path
        d="M111.036 13.4868H107.171C106.636 13.4868 106.192 13.0429 106.192 12.5076C106.192 11.9722 106.623 11.5283 107.158 11.5283H111.023C111.558 11.5283 112.002 11.9722 112.002 12.5076C112.002 13.0429 111.571 13.4868 111.036 13.4868Z"
        fill="#BDF5D5"
      />
      <path
        d="M104.508 10.8232C103.972 10.8232 103.528 10.3793 103.528 9.84397V5.97924C103.528 5.44392 103.972 5 104.508 5C105.043 5 105.487 5.44392 105.487 5.97924V9.84397C105.487 10.3923 105.043 10.8232 104.508 10.8232Z"
        fill="#BDF5D5"
      />
      <path
        d="M104.508 20.0153C103.972 20.0153 103.528 19.5714 103.528 19.0361V15.1714C103.528 14.6361 103.972 14.1921 104.508 14.1921C105.043 14.1921 105.487 14.6361 105.487 15.1714V19.0361C105.487 19.5714 105.043 20.0153 104.508 20.0153Z"
        fill="#E2E4E3"
      />
    </g>
    <defs>
      <clipPath id="clip0_1350_25257">
        <rect width="112" height="112" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
