import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';

interface Props {
  className?: string;
  expanded: boolean;
}

export const ExpandIcon = ({ className, expanded }: Props) => (
  <ChevronDownIcon
    className={cn(className, {
      'scale-flip': expanded
    })}
  />
);
