import cn from 'classnames';
import { MouseEvent } from 'react';

import { PayoutState, payoutStates } from 'models/payout';

import { BadgeStyleParams } from '../badgeStyle';

interface Props {
  state: PayoutState | string;
  label?: string;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (e: MouseEvent) => void;
}

export const PayoutBadge = ({
  state,
  label,
  count,
  onClick,
  className,
  selected,
  disabled
}: Props) => {
  const payoutState = payoutStates.find((s) => s === state || s.id === state);
  const style: BadgeStyleParams = payoutState?.style || {
    color: 'bg-inpay-gray-primary-200',
    activeColor: 'bg-inpay-gray-secondary-600',
    hover: 'hover:bg-inpay-gray-secondary-600'
  };

  return (
    <span
      className={cn(
        'w-fit max-w-full gap-1 overflow-hidden truncate rounded-lg px-2 py-1 text-sm leading-4',
        className,
        {
          'hover:cursor-pointer': onClick && !disabled,
          'bg-transparent text-inpay-green-primary-400': disabled,
          [`${style.color} ${style.hover}`]: !disabled && !selected,
          [style.activeColor]: selected
        }
      )}
      onClick={onClick}
    >
      <>
        {Number.isInteger(count) && (
          <span className="mr-1 font-bold">{count}</span>
        )}
        {label || payoutState?.label || state}
      </>
    </span>
  );
};
