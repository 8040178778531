import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { isRunningInsideCypress } from 'utils/test-utils';

import { useRedirectTo } from 'hooks/useRedirectTo';

import { LoggingIn } from 'components/ui/organisms/LoggingIn';

export const LoginTransition = () => {
  const { navigateOrRedirectTo } = useRedirectTo();
  const { organisationId } = useParams();

  useEffect(() => {
    const timeoutPromise = new Promise((resolve) => {
      if (isRunningInsideCypress()) {
        resolve('');
      } else {
        setTimeout(() => {
          resolve('');
        }, 2000);
      }
    });

    Promise.all([timeoutPromise]).then(() => {
      navigateOrRedirectTo(`/org/${organisationId}`);
    });
  }, []);

  return <LoggingIn />;
};
