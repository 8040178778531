import { LockClosedIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';

import { userDetailsSchema as schema } from 'validators/userDetails';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { useGetRolesQuery, useUpdateUserMutation } from 'redux/api/usersApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { type User } from 'models/user';

import { useRolesOptions } from 'hooks/useRolesOptions';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { ResetPasswordButton } from '@atoms/buttons/ResetPasswordButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { InputField } from '@molecules/InputField';
import { Selector } from '@molecules/Selector/Selector';

import { Loading } from 'components/pages/Loading';

import { LockedPasswordField } from '../UserProfile/LockedPasswordField';

export interface Props {
  data: User;
  onCancelClick?: () => void;
  onSuccess?: () => void;
}

interface Inputs extends InferType<typeof schema> {
  base: null;
}

export const UserDetailsEdit = ({ data, onCancelClick, onSuccess }: Props) => {
  const organisationId = useSelector(selectOrganisationId);
  const [updateUserData, { isLoading }] = useUpdateUserMutation();

  const { data: { data: rolesData } = {} } = useGetRolesQuery();
  const roles = rolesData || [];

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const {
    isLoading: isLoadingOrgSettings,
    isSuccess: isSuccessOrgSettings,
    isError: isErrorOrgSettings,
    data: { data: orgSettings } = {}
  } = useFetchOrganisationConfigurationsQuery(organisationId);

  const isApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-1761-customer-app-batches'] &&
    (orgSettings?.batch_number_of_approvers || 0) > 0;

  const {
    handleSubmit,
    setError,
    register,
    control,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onSubmit',
    defaultValues: {
      first_name: data.first_name,
      last_name: data.last_name,
      role: data.role
    },
    resolver: yupResolver(schema)
  });

  const rolesOptions = useRolesOptions({ roles, isApprovalFlowEnabled });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event: any) => {
    try {
      if (!organisationId) {
        throw Error('[userDetailsEdit] - organisationId is not present');
      }

      await handleSubmit(async (formData) => {
        await updateUserData({
          data: { ...formData, id: data.id },
          organisationId
        }).unwrap();
        onSuccess && onSuccess();
        toast.success('Profile updated', {
          position: 'bottom-center',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  if (!data) return null;

  return (
    <>
      {isLoadingOrgSettings && <Loading />}
      {isErrorOrgSettings && (
        <div className="flex items-center justify-center bg-white p-20">
          Something went wrong, please try again.
        </div>
      )}
      {isSuccessOrgSettings && (
        <form onSubmit={onSubmit} autoComplete="off">
          <div className="flex flex-col gap-10 px-6 py-4">
            <div>
              <label className="mb-0.5 pl-2 text-xs">First Name</label>
              <InputField
                aria-label="First Name"
                {...register('first_name')}
                errorMessage={errors.first_name?.message}
              />
            </div>
            <div>
              <label className="mb-0.5 pl-2 text-xs">Last Name</label>
              <InputField
                {...register('last_name')}
                errorMessage={errors.last_name?.message}
              />
            </div>
            <div>
              <InputField
                disabled
                aria-label="Email"
                label="Email"
                defaultValue={data.email}
              >
                <LockClosedIcon className="absolute inset-y-0 right-2 h-12 w-6" />
              </InputField>
            </div>
            <div>
              <label className="mb-0.5 pl-2 text-xs text-inpay-gray-primary-1000">
                Password
              </label>
              <div className="flex items-center gap-3 text-inpay-gray-primary-1000">
                <LockedPasswordField
                  defaultValue="*********************"
                  type="password"
                  disabled
                />
                {data.email ? <ResetPasswordButton email={data.email} /> : null}
              </div>
            </div>
            <div className="flex flex-col">
              <label className="mb-0.5 pl-2 text-xs">Role</label>
              <Controller
                name="role"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Selector
                    onChange={onChange}
                    value={
                      isApprovalFlowEnabled
                        ? value
                        : value.replace('_reviewer', '')
                    }
                    selectChoices={rolesOptions}
                    errorMessage={errors.role?.message}
                  />
                )}
              />
            </div>
            <div className="border-inpay-gray-primary-300 flex justify-end gap-3 border-t">
              <SecondaryButton
                type="submit"
                size="large"
                onClick={onCancelClick}
                className="mt-4"
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                type="submit"
                size="large"
                disabled={isLoading}
                className="mt-4"
              >
                Save Changes
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
