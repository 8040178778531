import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';
import { logEventToHotjar } from 'utils/logging';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';
import { useFetchManualPayoutDraftQuery } from 'redux/api/payouts/payoutsApi';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { Loading } from 'components/pages/Loading';

import { CancelButton } from '../ConfirmCancelDialog';
import { fieldToDisplayableName } from '../fieldToDisplayableName';
import { ShowDataItem } from '../ShowDataItem';
import { StepProps } from '../steps';

type Props = Omit<StepProps, 'responseField'>;

export const SummaryStep = ({ onSubmit, onCancel }: Props) => {
  const { organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  const {
    data: { data } = {},
    isSuccess,
    isLoading
  } = useFetchManualPayoutDraftQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const [errors, setErrors] = useState<string[]>([]);

  const { data: { data: virtualAccounts } = {} } = useFetchVirtualAccountsQuery(
    { organisationId }
  );

  const account = useMemo(
    () =>
      data &&
      virtualAccounts?.find(
        (account) =>
          account.account_number ==
          data.payout_request?.virtual_account_number?.value
      ),
    [data, virtualAccounts]
  );

  const onSubmitHandler = async () => {
    logEventToHotjar('create-manual-payout:form-submit');

    try {
      setErrors([]);
      await onSubmit(data);
    } catch (error: any) {
      if (error.status == 422) {
        setErrors(
          error.data.errors.reduce(
            (
              acc: string[],
              error: { details: string[]; source: { pointer: string } }
            ) => {
              error.details.forEach((detail) => {
                if (typeof detail == 'string') {
                  acc.push(
                    `${fieldToDisplayableName(error.source.pointer)} ${detail}`
                  );
                } else if (typeof detail == 'object') {
                  Object.entries(detail).forEach(([_sectionKey, errors]) => {
                    if (typeof errors === 'object') {
                      Object.entries(
                        errors as Record<string, string[]>
                      ).forEach(([key, value]) => {
                        value.forEach((error: string) =>
                          acc.push(`${fieldToDisplayableName(key)} ${error}`)
                        );
                      });
                    }
                  });
                }
              });
              return acc;
            },
            []
          )
        );
      } else {
        console.error(error);
      }
    }
  };

  const formatter = new MoneyFormatter();

  return (
    <>
      {isSuccess && (
        <div className="px-10">
          <div className="flex flex-wrap gap-y-4">
            {account && (
              <ShowDataItem
                label="Account"
                className="w-1/2 grow even:text-right"
              >
                <p className="font-medium">
                  {account.label
                    ? account.label
                    : `${account.currency_name} account`}
                </p>
                <p className="text-xs">
                  No. {virtualAccountNumberFormatter(account?.account_number)}
                </p>
              </ShowDataItem>
            )}
            <ShowDataItem
              value={formatter.format(
                Number(data?.payout_request?.amount?.value)
              )}
              label={fieldToDisplayableName('amount')}
              className="w-1/2 grow even:text-right"
            />
            <ShowDataItem
              value={data?.payout_request?.end_to_end_id?.value?.toString()}
              label={fieldToDisplayableName('end_to_end_id')}
              className="w-1/2 grow even:text-right"
            />
            <ShowDataItem
              value={data?.product?.local_instrument?.toString()}
              label="Selected product"
              className="w-1/2 grow even:text-right"
            />
          </div>
          <hr className="border-inpay-gray-primary-300 my-6" />
          <div>
            <h3 className="mb-4 font-medium">Sender</h3>
            <div className="flex flex-wrap gap-y-4">
              {Object.keys(data?.ultimate_debtor || {}).map((field: string) => (
                <ShowDataItem
                  key={field}
                  value={(data?.ultimate_debtor[field]?.value || '').toString()}
                  label={fieldToDisplayableName(field)}
                  className="w-1/2 grow even:text-right"
                />
              ))}
            </div>
          </div>
          <hr className="border-inpay-gray-primary-300 my-6" />
          <div>
            <h3 className="mb-4 font-medium">Receiver</h3>
            <div className="flex flex-wrap gap-y-4">
              {Object.keys(data?.creditor || {}).map((field: string) => (
                <ShowDataItem
                  key={field}
                  value={(data?.creditor[field]?.value || '').toString()}
                  label={fieldToDisplayableName(field)}
                  className="w-1/2 grow even:text-right"
                />
              ))}
            </div>
          </div>
          <hr className="border-inpay-gray-primary-300 my-6" />
          <div>
            <h3 className="mb-4 font-medium">Receiver Bank</h3>
            <div className="flex flex-wrap gap-y-4">
              {Object.keys(data?.creditor_account || {}).map(
                (field: string) => (
                  <ShowDataItem
                    key={field}
                    value={(
                      data?.creditor_account[field]?.value || ''
                    ).toString()}
                    label={fieldToDisplayableName(field)}
                    className="w-1/2 grow even:text-right"
                  />
                )
              )}
            </div>
          </div>
          <div className="my-6 flex gap-4">
            <CancelButton onAction={onCancel} />
            <PrimaryButton
              size="large"
              className="w-full"
              onClick={onSubmitHandler}
            >
              Submit
            </PrimaryButton>
          </div>
          {errors && (
            <div className="my-6">
              {errors.map((error, index) => (
                <div
                  key={index}
                  className="font-semibold italic text-inpay-red-1000"
                >
                  {error}.
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      {isLoading && <Loading />}
    </>
  );
};
