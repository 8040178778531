import { SVGProps } from 'react';

export const DashboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="#fff"
    width="1em"
    height="1em"
    {...props}
  >
    <path
      fill="none"
      d="M10.72 12.444a.834.834 0 0 1-.832.836H3.232a.834.834 0 0 1-.832-.836V3.237a.834.834 0 0 1 .833-.836l6.656.01a.834.834 0 0 1 .831.835ZM21.6 7.339a.827.827 0 0 1-.832.821h-6.654a.826.826 0 0 1-.832-.82V3.231a.826.826 0 0 1 .832-.822l6.656-.01a.828.828 0 0 1 .833.82ZM10.72 20.78a.829.829 0 0 1-.834.823l-6.656-.011a.827.827 0 0 1-.83-.822v-4.104a.827.827 0 0 1 .832-.822h6.656a.828.828 0 0 1 .832.822Zm3.394.823a.834.834 0 0 1-.834-.834V11.56a.834.834 0 0 1 .832-.837h6.656a.834.834 0 0 1 .832.837v9.194a.835.835 0 0 1-.831.837Z"
      strokeWidth=".8"
    />
  </svg>
);
