import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import {
  useFetchOrganisationsQuery,
  useLazyFetchOrganisationConfigurationsQuery,
  useSetCurrentOrganisationMutation
} from 'redux/api/organisations/organisationsApi';
import { setOrganisationId, setRole } from 'redux/slices/authSlice';

import { Loading } from 'components/pages/Loading';
import { LoggingIn } from 'components/ui/organisms/LoggingIn';

export const RequireOrganisation = () => {
  const location: { state: any } = useLocation();
  const { organisationId } = useParams();
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  const {
    data: organisationsData,
    isSuccess: organisationsSuccess,
    isLoading: organisationsLoading,
    isError: organisationsError
  } = useFetchOrganisationsQuery();

  const [
    setCurrentOrganisation,
    {
      isLoading: currentOrganisationLoading,
      isSuccess: currentOrganisationSuccess,
      isError: currentOrganisationError
    }
  ] = useSetCurrentOrganisationMutation();

  const dispatch = useDispatch();

  const [
    fetchSettings,
    {
      isLoading: settingsLoading,
      isSuccess: settingsSuccess,
      isError: settingsError
    }
  ] = useLazyFetchOrganisationConfigurationsQuery();

  useEffect(() => {
    if (organisationsSuccess && !currentOrganisationLoading) {
      const currentOrganisation = organisationsData.organisations.find(
        (o) => o.id.toString() == organisationId
      );
      if (organisationId && currentOrganisation) {
        setCurrentOrganisation(organisationId as unknown as number);
        dispatch(setOrganisationId(organisationId as unknown as number));
        dispatch(setRole(currentOrganisation.role as unknown as string));
      } else {
        setIsUnauthorized(true);
      }
    }
  }, [organisationsSuccess, organisationId]);

  useEffect(() => {
    if (currentOrganisationSuccess) {
      fetchSettings(organisationId);
    }
  }, [currentOrganisationSuccess]);

  const isLoading =
    currentOrganisationLoading || organisationsLoading || settingsLoading;
  const isSuccess =
    currentOrganisationSuccess && organisationsSuccess && settingsSuccess;

  const noOrganisation =
    currentOrganisationError ||
    organisationsError ||
    settingsError ||
    organisationsData?.organisations.length == 0 ||
    isUnauthorized;

  return (
    <>
      {isLoading &&
        (location.state?.loggingIn ? (
          <LoggingIn />
        ) : (
          <Loading text="Loading Organisation..." />
        ))}
      {noOrganisation && <Navigate to="/no-organisation" replace />}
      {isSuccess && <Outlet />}
    </>
  );
};
