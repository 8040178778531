import { XMarkIcon } from '@heroicons/react/24/outline';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { formatDate, formatHours } from 'utils/dayjs';
import { getCountryName } from 'utils/formatters/getCountryName';
import { hasValue } from 'utils/hasValue';

import { convertTypeToLabel } from 'constants/paymentType';

import { paymentsApi } from 'redux/api/collections/payments';

import { PaymentStatusTransition } from 'models/payment';

import { PaymentBadge } from '@atoms/badges/PaymentBadge/PaymentBadge';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

import { Loading } from 'components/pages/Loading';
import { DetailsBox, Item } from 'components/ui/organisms/DetailsBox';

export const PaymentDetails = () => {
  const { paymentId } = useParams();
  const {
    data: { data: paymentData } = {},
    isLoading,
    isError,
    isSuccess
  } = paymentsApi.useFetchPaymentQuery(
    hasValue(paymentId) ? { reference: paymentId } : skipToken
  );
  const data = useMemo<Array<Item>>(() => {
    const sections: Array<Item> = [];
    if (!paymentData || !isSuccess) return sections;

    sections.push({
      header: 'Payment information',
      key: 'info',
      data: [
        {
          key: 'Payment type',
          value: convertTypeToLabel(paymentData.type)
        },
        {
          key: 'Debtor country',
          value: getCountryName(paymentData.details.debtor_country || '') || ''
        },
        {
          key: 'Debtor bank',
          value: paymentData.details.debtor_bank_name || ''
        }
      ]
    });

    // TODO:We need to improve naming to pass items to the sections.
    sections.push({
      header: 'Debtor information',
      key: 'debtor',
      data: [
        {
          key: 'Name',
          value: paymentData.order.debtor.full_name
        },
        {
          key: 'Date of birth',
          value: formatDate(paymentData.order.debtor.date_of_birth)
        },
        {
          key: 'Email',
          value: paymentData.order.debtor.email
        },
        {
          key: 'Country of Residence',
          value:
            getCountryName(paymentData.order.debtor.country_of_residence) ||
            paymentData.order.debtor.country_of_residence
        }
      ]
    });
    sections.push({
      header: 'Status history',
      key: 'history',
      data: [],
      customChildren: paymentData.status_history.map(
        (transition: PaymentStatusTransition, index: number) => (
          <div
            className="flex justify-between pt-6 last-of-type:pb-6"
            key={index}
          >
            <div>
              <div className="text-xs">Status</div>
              <PaymentBadge type={transition.status} />
            </div>
            <div className="text-right">
              <FormattedDate
                className="text-xs"
                value={transition.created_at}
              />
              <div className="font-semibold">
                {formatHours(transition.created_at, 'utc')}
              </div>
            </div>
          </div>
        )
      )
    });

    return sections;
  }, [paymentData, isSuccess]);

  if (!paymentId) return null;

  return (
    <div
      role="complementary"
      aria-label="Payment details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="payment-overview__header">
        <div className="flex items-center justify-between px-6 py-3.5">
          <span>Details</span>
          <Link to=".." className="cursor-pointer" aria-label="Close">
            <XMarkIcon className="size-6" />
          </Link>
        </div>
        <div className="border-inpay-gray-primary-300 border-y bg-inpay-green-secondary-light-200 py-2 text-sm">
          &nbsp;
        </div>
      </div>
      <div className="overflow-auto">
        {isLoading && <Loading />}
        {isSuccess && <DetailsBox data={data} defaultValue="info" />}
        {isError && (
          <div className="flex h-full items-center justify-center">
            Something went wrong
          </div>
        )}
      </div>
    </div>
  );
};
