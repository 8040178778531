import { SVGProps } from 'react';

export const CollectionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    stroke="#1e1e1e"
    {...props}
  >
    <g fill="none" strokeWidth=".8">
      <path d="M20.198 17.52v1.262a2.522 2.522 0 0 1-2.522 2.522H5.063a2.522 2.522 0 0 1-2.521-2.522V9.955a2.522 2.522 0 0 1 2.521-2.523h12.612a2.522 2.522 0 0 1 2.521 2.522v1.26m-2.52-3.784-1.96-3.913a1.26 1.26 0 0 0-1.692-.565l-8.96 4.48" />
      <path d="M20.198 17.52a1.262 1.262 0 0 0 1.26-1.26v-3.784a1.262 1.262 0 0 0-1.26-1.26h-3.154a3.154 3.154 0 0 0 0 6.305Z" />
      <path d="M17.044 14.054a.315.315 0 1 1-.315.315.315.315 0 0 1 .315-.315" />
    </g>
  </svg>
);
