import { useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { TabValue } from 'redux/slices/pages.payoutsSlice';
import { resetState } from 'redux/store';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { TableLayout } from 'components/ui/templates/TableLayout';

import { useLocalState } from './localState';
import { ManualPayoutsDraftList } from './ManualPayoutsList/ManualPayoutsDraftList';
import { ManualPayoutsList } from './ManualPayoutsList/ManualPayoutsList';
import { PayoutsHeader } from './PayoutsHeader';

const clearedFilters = {
  state: undefined,
  accountNumber: undefined,
  reasonCode: undefined,
  currency: undefined
};

export const NewPayoutsContent = () => {
  const navigate = useNavigate();
  const { '*': payoutId } = useParams();

  const {
    state,
    queryParams: localQueryParams,
    updateTab,
    updateCursorAfter,
    updateCursorBefore,
    updateFilters
  } = useLocalState();

  const { queryParams: globalQueryParams } = useGlobalFilters();

  useUpdateEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...localQueryParams,
      ...globalQueryParams
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [payoutId, localQueryParams, state]);

  useUpdateEffect(() => {
    resetState();
    if (payoutId) {
      navigate('.');
    }
  }, [globalQueryParams]);

  const onTabClick = (tabValue: TabValue) => {
    updateFilters({ ...clearedFilters });
    updateTab(tabValue);
    updateCursorAfter(undefined);
    updateCursorBefore(undefined);
    if (payoutId) {
      navigate({ pathname: '.' });
    }
  };
  return (
    <TableLayout
      header={<PayoutsHeader onTabClick={onTabClick} currentTab={state.tab} />}
    >
      {state.tab === 'completed' || state.tab === undefined ? (
        <ManualPayoutsList />
      ) : (
        <ManualPayoutsDraftList />
      )}
    </TableLayout>
  );
};
