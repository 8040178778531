import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  useFetchWithdrawalAccountsQuery,
  useFetchWithdrawalRequestsQuery
} from 'redux/api/accounts/withdrawalsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { AntdTableLayout } from 'components/ui/templates/AntdTableLayout';
import { TableContent } from 'components/ui/templates/AntdTableLayout/TableContent';
import { TableHeader } from 'components/ui/templates/AntdTableLayout/TableHeader';

import { apiParams } from './apiAdapter';
import { useLocalState } from './localState';
import { WithdrawalsContent } from './WithdrawalsContent';
import { WithdrawalsHeader } from './WithdrawalsHeader';

export const Withdrawals = () => {
  const organisationId = useSelector(selectOrganisationId);
  if (!organisationId) throw new Error('Missing organisation id');

  const { '*': withdrawalRequestId } = useParams();

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const {
    data: { data: withdrawalAccounts = [] } = {},
    isError: isWithdrawalAccountsError,
    isLoading: isWithdrawalAccountsLoading
  } = useFetchWithdrawalAccountsQuery(organisationId);

  const { state } = useLocalState({
    cursorAfter: withdrawalRequestId,
    includeCursor: !!withdrawalRequestId
  });

  const { data: { data: withdrawalRequestsData = [] } = {} } =
    useFetchWithdrawalRequestsQuery(
      apiParams({
        organisationId: organisationId.toString(),
        filters: {
          ...state.filters
        },
        sorting: state.sorting,
        cursorAfter: state.cursorAfter,
        cursorBefore: state.cursorBefore,
        includeCursor: state.includeCursor
      })
    );

  const withdrawalsEnabled =
    orgSettings?.virtual_bank_bookkeeping === 'dual' ||
    orgSettings?.virtual_bank_bookkeeping === 'v2';

  const withdrawalsArePossible =
    withdrawalsEnabled && withdrawalAccounts.length > 0;

  const withdrawalProps = {
    withdrawalAccounts,
    withdrawalRequestsData,
    withdrawalsArePossible,
    isWithdrawalAccountsError,
    isWithdrawalAccountsLoading,
    organisationId
  };

  return (
    <AntdTableLayout>
      <TableHeader title="Withdrawals">
        <WithdrawalsHeader {...withdrawalProps} />
      </TableHeader>
      <TableContent>
        <WithdrawalsContent {...withdrawalProps} />
      </TableContent>
    </AntdTableLayout>
  );
};
