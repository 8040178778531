import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';
import { hasValue } from 'utils/hasValue';

import { userProfileEditSchema as schema } from 'validators/userProfileEdit';

import { useUpdateUserMutation } from 'redux/api/usersApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { type User } from 'models/user';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { ResetPasswordButton } from '@atoms/buttons/ResetPasswordButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { InputField } from 'components/ui/molecules/InputField';

import { LockedPasswordField } from './LockedPasswordField';

interface Props {
  data: User;
  onCancelClick?: () => void;
  onSuccess?: () => void;
}

interface Inputs extends InferType<typeof schema> {
  base: null;
}

export const UserProfileEdit = ({ data, onCancelClick, onSuccess }: Props) => {
  const [updateUserData, { isLoading }] = useUpdateUserMutation();
  const organisationId = useSelector(selectOrganisationId);
  const {
    handleSubmit,
    setError,
    register,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onSubmit',
    defaultValues: {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email
    },
    resolver: yupResolver(schema)
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event: any) => {
    try {
      if (!organisationId) {
        throw Error('[userProfileEdit] - organisationId is not present');
      }

      await handleSubmit(async (formData) => {
        await updateUserData({
          data: { ...formData, id: data.id },
          organisationId
        }).unwrap();
        onSuccess && onSuccess();
        toast.success('Profile updated', {
          position: 'bottom-center',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="flex flex-col px-6 py-4">
        <div className="flex w-1/4 flex-col gap-10">
          <div>
            <label htmlFor="first_name" className="mb-0.5 pl-2 text-xs">
              First Name
            </label>
            <InputField
              {...register('first_name')}
              errorMessage={errors.first_name?.message}
              id="first_name"
            />
          </div>
          <div>
            <label htmlFor="last_name" className="mb-0.5 pl-2 text-xs">
              Last Name
            </label>
            <InputField
              {...register('last_name')}
              errorMessage={errors.last_name?.message}
              id="last_name"
            />
          </div>

          <div>
            <label htmlFor="email" className="mb-0.5 pl-2 text-xs">
              Email
            </label>
            <InputField
              {...register('email')}
              errorMessage={errors.email?.message}
              id="email"
            />
          </div>
          <div>
            <label
              htmlFor="password"
              className="mb-0.5 pl-2 text-xs text-inpay-gray-primary-1000"
            >
              Password
            </label>
            <div className="flex items-center gap-3">
              <LockedPasswordField
                defaultValue="*********************"
                type="password"
                disabled
              />
              {hasValue(data.email) ? (
                <ResetPasswordButton email={data.email} />
              ) : null}
            </div>
          </div>
        </div>

        <div className="flex justify-end gap-3">
          <SecondaryButton size="large" onClick={onCancelClick}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" type="submit" disabled={isLoading}>
            Save Changes
          </PrimaryButton>
        </div>
      </div>
    </form>
  );
};
