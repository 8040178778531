import { DialogContent } from '@radix-ui/react-dialog';
import { ComponentProps } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<typeof DialogContent>;

export const Content = ({ children, className, ...props }: Props) => (
  <DialogContent
    onClick={(e) => e.stopPropagation()}
    className={twMerge(
      'fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
      'flex max-h-screen flex-col rounded-lg drop-shadow-plain-lg',
      className
    )}
    {...props}
  >
    {children}
  </DialogContent>
);
