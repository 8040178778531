import cn from 'classnames';
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { twMerge } from 'tailwind-merge';

import { CopyIcon } from '../Icon/CopyIcon';

interface Props {
  value: string;
  className?: string;
  messageClassName?: string;
  iconClassName?: string;
}

export const CopyToClipboardButton = ({
  value,
  className,
  messageClassName,
  iconClassName
}: Props) => {
  const [copied, setCopied] = useState<boolean>(false);

  const buttonProps = {
    className: twMerge(cn('cursor-pointer', className))
  };

  const iconProps = {
    width: '20',
    height: '20',
    stroke: 'auto',
    className: twMerge(cn('pl-1 inline-block font-normal', iconClassName))
  };

  const messageProps = {
    className: twMerge(cn('pl-1', !copied && 'hidden', messageClassName))
  };

  useEffect(() => {
    const timer = setTimeout(() => setCopied(false), 3000);
    return () => clearTimeout(timer);
  }, [copied]);

  return (
    <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
      <span {...buttonProps}>
        {value}
        <CopyIcon {...iconProps} />
        <span {...messageProps}>Copied.</span>
      </span>
    </CopyToClipboard>
  );
};
