import { skipToken } from '@reduxjs/toolkit/dist/query';
import { MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { hasValue } from 'utils/hasValue';

import { DataTable } from '@organisms/DataTable';

import { useFetchUsersQuery } from 'redux/api/usersApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { UserDetails } from 'components/pages/UserDetails';
import { LoadingOverlay } from 'components/ui/molecules/LoadingOverlay';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';
import { TableLayout } from 'components/ui/templates/TableLayout';

import { USER_MANAGEMENT_TABLE_TEST_ID } from './constants';
import { useUserColumns } from './userColumns';
import { UserManagementHeader } from './UserManagementHeader';

const routes = [{ path: ':userId', element: <UserDetails /> }];

export const UserManagement = () => {
  const organisationId = useSelector(selectOrganisationId);

  const navigate = useNavigate();

  const {
    data: { data: users = [], meta } = {},
    isFetching,
    error
  } = useFetchUsersQuery(
    hasValue(organisationId) ? { organisationId } : skipToken
  );

  const userColumnDefinitions = useUserColumns();
  const { '*': userId } = useParams();

  const onRowClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    if (rowData.id === userId) {
      navigate('.');
    } else {
      navigate({
        pathname: rowData.id.toString(),
        search: location.search
      });
    }
  };

  if (!organisationId) {
    navigate('/');
  }

  return (
    <TableLayout header={<UserManagementHeader />}>
      <DetailedLayout routes={routes}>
        {error && 'error' in error && (
          <div
            className="flex h-screen items-center justify-center"
            role="alert"
          >
            <div>{error.error}</div>
          </div>
        )}

        <DataTable
          data-testid={USER_MANAGEMENT_TABLE_TEST_ID}
          columns={userColumnDefinitions}
          isFetching={isFetching}
          name="Users"
          topBar="name-with-count"
          currentRow={{
            value: userId,
            field: 'id'
          }}
          total={meta?.total}
          data={users.filter((user) => user.active)}
          onRowClick={onRowClick}
        />
        {isFetching && <LoadingOverlay />}
      </DetailedLayout>
    </TableLayout>
  );
};
