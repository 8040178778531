import { SVGProps } from 'react';

export const CopyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    stroke="currentColor"
    {...props}
  >
    <path d="M18.579 2.727v1.509h2.529a2.978 2.978 0 0 1 2.045.801A2.644 2.644 0 0 1 24 6.959v14.314a2.651 2.651 0 0 1-.85 1.926 2.979 2.979 0 0 1-2.035.795H8.313a2.982 2.982 0 0 1-2.045-.801 2.644 2.644 0 0 1-.843-1.918V18.07H2.892a2.982 2.982 0 0 1-2.045-.801A2.644 2.644 0 0 1 0 15.345V2.727A2.651 2.651 0 0 1 .853.8 2.979 2.979 0 0 1 2.891 0h12.797a2.978 2.978 0 0 1 2.045.801 2.644 2.644 0 0 1 .843 1.918Zm-2.193 1.509V2.723a.639.639 0 0 0-.21-.464.722.722 0 0 0-.492-.195H2.892a.718.718 0 0 0-.492.198.642.642 0 0 0-.207.464v12.62a.639.639 0 0 0 .21.464.722.722 0 0 0 .492.195h2.527V6.963a2.651 2.651 0 0 1 .85-1.926 2.979 2.979 0 0 1 2.035-.795h8.073Zm5.42 17.037V6.959a.639.639 0 0 0-.21-.464.722.722 0 0 0-.492-.195H8.312a.718.718 0 0 0-.492.198.642.642 0 0 0-.207.464v14.314a.639.639 0 0 0 .21.464.722.722 0 0 0 .492.195h12.797a.718.718 0 0 0 .492-.198.642.642 0 0 0 .207-.464Z" />
  </svg>
);
