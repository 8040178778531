import cn from 'classnames';
import React from 'react';

import { orderStates } from 'models/order';

interface Props {
  state: string;
  label?: string;
  count?: number;
  selected?: boolean;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const OrderBadge = ({
  state,
  label,
  count,
  onClick,
  className,
  selected,
  disabled
}: Props) => {
  const orderState = orderStates.find((s) => s.id === state);
  const style = orderState?.style || {
    color: 'bg-inpay-gray-primary-200',
    activeColor: 'bg-inpay-gray-secondary-600',
    hover: 'hover:bg-inpay-gray-secondary-600'
  };

  const labelText = label || orderState?.label;

  return (
    <span
      className={cn(
        'w-fit max-w-full gap-1 overflow-hidden truncate rounded-lg px-2 py-1 text-sm leading-4',
        className,
        {
          'hover:cursor-pointer': onClick && !disabled,
          'bg-transparent text-inpay-green-primary-400': disabled,
          [`${style.color} ${style.hover}`]: !disabled && !selected,
          [style.activeColor]: selected
        }
      )}
      onClick={onClick}
    >
      {Number.isInteger(count) && (
        <span className="mr-1 font-bold">{count}</span>
      )}
      {labelText}
    </span>
  );
};
