import { NoWithdrawalAccountsIcon } from '@atoms/Icon/NoWithdrawalAccountsIcon';

export const NoWithdrawalAccounts = () => {
  return (
    <div className="flex size-full flex-col items-center overflow-auto p-8">
      <NoWithdrawalAccountsIcon />
      <h3 className="mt-7 text-xl font-medium">
        Withdrawal options are currently unavailable for your organisation's
        account setup.
      </h3>
    </div>
  );
};
