export const withdrawalRequestStatuses: Array<WithdrawalRequestStatus> = [
  {
    id: 'received',
    value: 'received',
    order: 1,
    label: 'Received',
    style: {
      background: 'bg-inpay-gray-primary-400',
      hover: 'hover:bg-inpay-gray-primary-600'
    }
  },
  {
    id: 'processing',
    value: 'processing',
    order: 2,
    label: 'Processing',
    style: {
      background: 'bg-inpay-blue-400',
      hover: 'hover:bg-inpay-blue-600'
    }
  },
  {
    id: 'completed',
    value: 'completed',
    order: 3,
    label: 'Completed',
    style: {
      background: 'bg-inpay-green-secondary-light-600',
      hover: 'hover:bg-inpay-green-secondary-light-800'
    }
  },
  {
    id: 'cancelled',
    value: 'cancelled',
    order: 4,
    label: 'Cancelled',
    style: {
      background: 'bg-inpay-red-400',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'returned',
    value: 'returned',
    order: 5,
    label: 'Returned',
    style: {
      background: 'bg-inpay-red-400',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'rejected',
    value: 'rejected',
    order: 6,
    label: 'Rejected',
    style: {
      background: 'bg-inpay-red-400',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'pending_approval',
    value: 'Pending_approval',
    order: 7,
    label: 'Pending Approval',
    style: {
      background: 'bg-inpay-purple-400',
      hover: 'hover:bg-inpay-purple-600'
    }
  }
];
export interface TagStyleParams {
  color?: string;
  background?: string;
  hover?: string;
}

export type WithdrawalRequestStatus = {
  id: string;
  value: string;
  order: number;
  label: string;
  style: TagStyleParams;
};

interface WithdrawalStatusTransition {
  id: string;
  created_at?: string;
  status: string;
}

interface CreditorAccount {
  id?: string;
  uuid?: string;
  label?: string;
  organisation_id?: number;
  account_number?: string;
  account_holder_type?: string;
  type?: string;
  bank_bic?: string;
  bank_branch_name?: string;
  bank_clearing_system_code?: string;
  bank_name?: string;
  country_code?: string;
  creditor_address?: string;
  creditor_city?: string;
  creditor_country?: string;
  creditor_name?: string;
  creditor_postcode?: string;
  currency?: string;
  iban?: string;
  local_instrument?: string;
  bank_branch_id?: string;
  bank_clearing_system_id?: string;
  status?: string;
}

// Index endpoint. No withdrawal account data
export interface WithdrawalRequestsData {
  id: string;
  amount: number;
  creditor_account_uuid: string;
  currency_code: string;
  debtor_account_number: string;
  external_reference: string;
  reference: string;
  provider_capability_id: string;
  created_at?: string;
  status: string;
}

// Show endpoint. Does contain creditor account info from withdrawal account
export interface WithdrawalRequestData {
  id: string;
  amount: number;
  creditor_account_uuid: string;
  currency_code: string;
  debtor_account_number: string;
  external_reference: string;
  reference: string;
  provider_capability_id: string;
  created_at?: string;
  status: string;
  transfer_amount: number;
  transfer_currency_code: string;
  status_transitions: Array<WithdrawalStatusTransition>;
  creditor_account: Partial<CreditorAccount>;
}

export interface GeneratedWithdrawalData {
  amount: string;
  created_at: string;
  creditor_account_uuid: string;
  currency_code: string;
  debtor_account_number: string;
  external_reference: string;
  id: number;
  reference: string;
  status: string;
  transfer_currency_code: string;
}

export type WithdrawalAccountStatus =
  | 'created'
  | 'pending_approval'
  | 'active'
  | 'inactive'
  | 'rejected';

export type AccountHolderType = 'organisation' | 'individual';
export interface WithdrawalAccount {
  id: number;
  uuid: string;
  label: string;
  organisation_id: number;
  account_number: string;
  bank_bic: string;
  bank_branch_name: string;
  bank_clearing_system_code: string;
  bank_name: string;
  country_code: string;
  creditor_address: string;
  creditor_city: string;
  creditor_country: string;
  creditor_name: string;
  creditor_postcode: string;
  currency: string;
  iban: string;
  local_instrument: string;
  bank_branch_id: string;
  bank_clearing_system_id: string;
  type: string;
  account_holder_type: AccountHolderType;
  status: WithdrawalAccountStatus;
}
