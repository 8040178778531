import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';

import { useFetchPayoutDraftsQuery } from 'redux/api/payouts/payoutsApi';
import { LocalState } from 'redux/slices/pages.payoutsSlice';

import { useGlobalFilters } from 'hooks/useGlobalFilters';
import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { usePayoutDraftColumns } from 'components/pages/Payouts/payoutsDraftsColumn';
import { LoadingOverlay } from 'components/ui/molecules/LoadingOverlay';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';

import { BatchPayoutDraftDetails } from '../BatchPayoutDraftDetails';
import { useLocalState } from '../localState';

const routes = [{ path: ':payoutId', element: <BatchPayoutDraftDetails /> }];

export const BatchDraftPayoutsList = () => {
  const location = useLocation();
  const { batchId, organisationId, '*': payoutId } = useParams();
  const {
    state,
    tableFilters,
    queryParams: localQueryParams,
    resetState,
    updateSort,
    updateFilters,
    updateCursorAfter,
    updateCursorBefore
  } = useLocalState();

  const navigate = useOrgNavigate();
  const { queryParams: globalQueryParams } = useGlobalFilters();

  const {
    data: { data, meta } = {},
    isFetching,
    error
  } = useFetchPayoutDraftsQuery({
    organisation_id: organisationId ? organisationId : '',
    'filter[batch_id]': batchId ? batchId : '',
    'page[size]': '20'
  });

  const [pagination, setPagination] = useState<PaginatorProps>();

  const sortPayouts = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');

      if (sortingParams != state.sort) {
        updateSort(sortingParams);
      }
    },
    [state.sort]
  );
  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);

    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_prev_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  useUpdateEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...localQueryParams,
      ...globalQueryParams
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [payoutId, localQueryParams, state]);

  useUpdateEffect(() => {
    resetState();
    if (payoutId) {
      navigate('.');
    }
  }, [globalQueryParams]);

  const batchPayoutsColumnDefinitions = usePayoutDraftColumns();

  if (!batchId) return null;

  const payoutRequests = data || [];

  const onRowClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    if (rowData.end_to_end_id == payoutId) {
      navigate({ pathname: '.', search: location.search });
    } else {
      navigate({
        pathname: rowData.id.toString(),
        search: location.search
      });
    }
  };

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const prefilledFilters = {
      state: undefined,
      accountNumber: undefined,
      currency: undefined
    };

    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      prefilledFilters
    );

    updateFilters(updatedFilters);
  };

  const nextPage = () => {
    updateCursorAfter(meta?.cursor_next);
  };

  const prevPage = () => {
    updateCursorBefore(meta?.cursor_prev);
  };

  return (
    <DetailedLayout routes={routes}>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{error.error}</div>
        </div>
      )}
      <DataTable
        columns={batchPayoutsColumnDefinitions}
        isFetching={isFetching}
        name="Batch payout requests"
        topBar="name-with-count"
        total={meta?.total}
        currentRow={{
          value: payoutId,
          field: 'id'
        }}
        data={payoutRequests}
        onSortingChange={sortPayouts}
        onFilteringChange={onFilteringChange}
        initialSorting={sorting}
        filters={tableFilters}
        onRowClick={onRowClick}
        pagination={{
          ...pagination,
          onClickNext: nextPage,
          onClickPrev: prevPage
        }}
      />
      {isFetching && <LoadingOverlay />}
    </DetailedLayout>
  );
};
