export const passwordValidationRules: {
  label: string;
  match: (value: string | undefined) => boolean;
}[] = [
  {
    match: (value) => !!value && value.length >= 8,
    label: 'Minimum of 8 characters'
  },
  {
    match: (value) => !!value && /(?=.*[A-Z])(?=.*[a-z])/.test(value),
    label: 'Mixed upper and lowercase'
  },
  {
    match: (value) => !!value && /(?=.*\W)(?=.*\d)/.test(value),
    label: 'Minimum 1 number and 1 symbol'
  }
];

export const isPasswordValid = (password?: string) =>
  passwordValidationRules.every((r) => r.match(password));
export const countPasswordMatchedValidations = (password?: string) =>
  passwordValidationRules.filter((r) => r.match(password)).length;
