import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { FINISHED_CHECKMARK } from './constants';
import { Steps } from './steps';

interface Props {
  step: Steps;
  value: string;
  isFinished?: boolean;
}

export const StepStatusCircle = ({ step, value, isFinished }: Props) => {
  return isFinished ? (
    <CheckCircleIcon className="w-9" data-testid={FINISHED_CHECKMARK} />
  ) : (
    <div
      key={step}
      className="border-inpay-gray-primary-300 flex aspect-square w-8 items-center justify-center rounded-full border bg-white text-sm font-medium"
    >
      {value}
    </div>
  );
};
