import { KeyIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { type InferType } from 'yup';

import { forgotPasswordFormSchema as schema } from 'validators/forgotPasswordForm';

import { useForgotPasswordMutation } from 'redux/api/authenticationApi';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { InputField } from 'components/ui/molecules/InputField';

interface Props {
  onSent: (email: string) => void;
  expired: boolean;
}

interface Inputs extends InferType<typeof schema> {
  base: null;
}

export const ForgotPasswordForm = ({ onSent, expired }: Props) => {
  const [forgotPassword] = useForgotPasswordMutation();

  const { register, handleSubmit, setError, formState } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    try {
      await handleSubmit(async (data) => {
        await forgotPassword(data).unwrap();
        onSent(data.email);
      })(event);
    } catch (error: any) {
      if (error.data && 'errors' in error.data) {
        const errorMessage = error.data.errors
          .map(({ title }: { title: string }) => title)
          .join('. ');
        setError(
          'email',
          { type: 'custom', message: errorMessage },
          { shouldFocus: true }
        );
      } else {
        const errorMessage =
          typeof error.data == 'string' ? error.data : 'Something went wrong.';
        toast.error(errorMessage, {
          position: 'bottom-center',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      }
    }
  };

  return (
    <div className="w-80">
      <KeyIcon width="40" height="40" className="mx-auto mb-4 flex size-10" />
      <h1 className="mb-5 whitespace-nowrap text-center text-[2rem] font-medium tracking-[0.01rem]">
        {expired ? 'Your link expired' : 'Forgot password'}
      </h1>
      {expired ? (
        <p className="mb-10 whitespace-nowrap text-left">
          Sorry, your reset password link is no longer valid!
          <br />
          Please request another one below.
          <br />
          Then we&apos;ll send you new reset instructions.
        </p>
      ) : (
        <p className="mb-10 text-center tracking-tight">
          No worries, we’ll send you reset instructions.
        </p>
      )}
      <form className="flex flex-col" onSubmit={onSubmit}>
        <InputField
          {...register('email')}
          label="Email"
          name="email"
          errorMessage={formState.errors.email?.message}
        />
        <PrimaryButton
          size="large"
          className="mt-6 font-medium"
          type="submit"
          value="Submit"
          disabled={formState.isSubmitSuccessful || !formState.isValid}
        >
          Reset my password
        </PrimaryButton>
      </form>
    </div>
  );
};
