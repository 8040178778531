import { Navigate, Outlet, useParams } from 'react-router-dom';

import { PermitAction, usePermissions } from 'hooks/usePermissions';

interface Props {
  requiredPermission: PermitAction;
}

export const RequirePermission = ({ requiredPermission }: Props) => {
  const { organisationId } = useParams();
  const { isPermitted } = usePermissions();

  if (isPermitted(requiredPermission)) {
    return <Outlet />;
  } else {
    return <Navigate to={`/org/${organisationId}`} />;
  }
};
