import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { DateRangeType } from 'models/dateRange';

export function generateRangeFromType(
  value: { type: string; from?: string; to?: string },
  infiniteTo = false
) {
  // need conditional type that dependent on infiniteTo
  const returnValue = { type: value.type } as {
    type: DateRangeType;
    from: string;
    to: string | undefined;
  };
  if (value.type == 'custom') {
    returnValue.to = dayjs(value.to, DATE_FORMAT)
      .endOf('day')
      .format(DATE_FORMAT);
    returnValue.from = dayjs(value.from, DATE_FORMAT)
      .startOf('day')
      .format(DATE_FORMAT);
  } else {
    const rangeToToday = infiniteTo
      ? undefined
      : dayjs().subtract(1, 'days').endOf('day').format(DATE_FORMAT);

    switch (value.type) {
      case 'today':
        returnValue.from = dayjs().startOf('day').format(DATE_FORMAT);
        returnValue.to = undefined;
        break;
      case 'yesterday':
        returnValue.from = dayjs()
          .subtract(1, 'days')
          .startOf('day')
          .format(DATE_FORMAT);
        returnValue.to = dayjs()
          .subtract(1, 'days')
          .endOf('day')
          .format(DATE_FORMAT);
        break;
      case 'd7':
        returnValue.from = dayjs()
          .subtract(7, 'days')
          .startOf('day')
          .format(DATE_FORMAT);
        returnValue.to = rangeToToday;
        break;
      case 'd30':
        returnValue.from = dayjs()
          .subtract(30, 'days')
          .startOf('day')
          .format(DATE_FORMAT);
        returnValue.to = rangeToToday;
        break;
      case 'last_week':
        returnValue.from = dayjs()
          .subtract(7, 'days')
          .startOf('week')
          .format(DATE_FORMAT);
        returnValue.to = dayjs()
          .subtract(7, 'days')
          .endOf('week')
          .format(DATE_FORMAT);
        break;
      case 'last_month':
        returnValue.from = dayjs()
          .subtract(1, 'months')
          .startOf('month')
          .format(DATE_FORMAT);
        returnValue.to = dayjs()
          .subtract(1, 'months')
          .endOf('month')
          .format(DATE_FORMAT);
        break;
      case 'last_year':
        returnValue.from = dayjs()
          .subtract(1, 'year')
          .startOf('year')
          .format(DATE_FORMAT);
        returnValue.to = dayjs()
          .subtract(1, 'year')
          .endOf('year')
          .format(DATE_FORMAT);
        break;
      case 'this_week':
        returnValue.from = dayjs().startOf('week').format(DATE_FORMAT);
        returnValue.to = rangeToToday;
        break;
      case 'this_month':
        returnValue.from = dayjs().startOf('month').format(DATE_FORMAT);
        returnValue.to = rangeToToday;
        break;
      case 'this_year':
        returnValue.from = dayjs().startOf('year').format(DATE_FORMAT);
        returnValue.to = rangeToToday;
        break;
      default:
        break;
    }
  }
  return returnValue;
}
