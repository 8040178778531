import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { convertRoleToLabel } from 'constants/roles';

import { User } from 'models/user';

export const useUserColumns = () => {
  const columnHelper = createColumnHelper<User>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ email, first_name, last_name }) => ({
          email,
          first_name,
          last_name
        }),
        {
          id: 'username',
          header: 'Email/Name',
          cell: (info) => {
            const {
              email,
              first_name: firstName,
              last_name: lastName
            } = info.getValue<{
              first_name: string;
              last_name: string;
              email: string;
            }>();
            return (
              <>
                <div className="text-xs">{email}</div>
                <div className="font-medium">
                  {firstName} {lastName}
                </div>
              </>
            );
          },
          meta: {
            className: 'w-30 pl-6'
          }
        }
      ),
      columnHelper.accessor('role', {
        id: 'role',
        header: 'Role',
        cell: (info) => {
          const roleName = info.getValue();
          const role = convertRoleToLabel(roleName as string);
          return <div className="text-base font-medium capitalize">{role}</div>;
        }
      }),
      columnHelper.display({
        id: 'column_params',
        cell: () => (
          <ChevronRightIcon className="h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        ),
        meta: {
          className: 'w-12 pr-3.5 pl-1'
        }
      })
    ],
    []
  );

  return columns;
};
