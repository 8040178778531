import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  Select,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectPortal,
  SelectScrollDownButton,
  SelectScrollUpButton,
  SelectTrigger,
  SelectValue,
  SelectViewport
} from '@radix-ui/react-select';
import cn from 'classnames';
import IMask from 'imask';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useElementSize } from 'usehooks-ts';

import { VirtualAccount } from 'models/virtualAccount';

import { InputError } from '@atoms/InputError/InputError';

import { virtualAccountLabel } from './utils';

export interface AccountSelectorClassNames {
  loadingClassName?: string;
  formButtonClassName?: string;
  selectedItemClassName?: string;
  selectContentClassName?: string;
  selectItemListClassName?: string;
  className?: string;
}

export interface Props {
  defaultAccountNumber?: string;
  onChange?: (account: VirtualAccount) => void;
  classNames?: AccountSelectorClassNames;
  disabled?: boolean;
  errorMessage?: string;
  data: VirtualAccount[];
}

export const Selector = ({
  classNames,
  data,
  defaultAccountNumber,
  onChange,
  errorMessage,
  disabled = false
}: Props) => {
  const selectTriggerProps = {
    className: twMerge(
      cn(
        'group max-w-full truncate flex items-center h-12 select-none rounded-lg bg-white px-4 text-left shadow-plain justify-between',
        classNames?.formButtonClassName,
        {
          'group hover:shadow-plain-lg cursor-pointer': !disabled,
          'stroke-inpay-gray-primary-1000 stroke-1.5 group hover:none text-inpay-black-primary-1000':
            disabled
        }
      )
    )
  };

  const selectedItemProps = {
    className: twMerge(
      cn(
        'group flex h-12 cursor-pointer items-center px-4 rounded-t-lg px-4 outline-offset-[-1px] hover:bg-inpay-green-secondary-light-200 hover:text-inpay-green-primary-1000 border-none focus:outline-none',
        classNames?.selectedItemClassName
      )
    )
  };

  const selectItemListProps = {
    className: twMerge(
      cn(
        'group h-12 flex flex-1 items-center cursor-pointer px-4 outline-offset-[-1px] first:rounded-t-lg last:rounded-b-lg hover:bg-inpay-green-secondary-light-200 hover:text-inpay-green-primary-1000 border-none focus:outline-none w-full',
        classNames?.selectItemListClassName
      )
    )
  };

  const selectContentProps = {
    className: twMerge(
      cn(
        'relative select-none overflow-hidden rounded-lg bg-white shadow-plain-lg',
        classNames?.selectContentClassName
      )
    )
  };
  const [currentItem, setCurrentItem] = useState<VirtualAccount>();
  const [availableList, setAvailableList] = useState<VirtualAccount[]>([]);
  const [opened, onOpenChange] = useState<boolean>(false);
  const [containerRef, { width }] = useElementSize<any>();

  useEffect(() => {
    if (data?.length) {
      setAvailableList(data);
      defaultAccountNumber && onValueChange(defaultAccountNumber);
    }
  }, [data]);

  const onValueChange = (value: string) => {
    if (data) {
      const virtualAccountItemData = data.find(
        (i) => i.account_number == value
      );

      setCurrentItem(virtualAccountItemData as VirtualAccount);

      setAvailableList(data.filter((i) => i.account_number != value));

      onChange && onChange(virtualAccountItemData as VirtualAccount);
    }
  };

  return (
    <div className="flex w-full flex-col" ref={containerRef}>
      <Select
        onValueChange={onValueChange}
        onOpenChange={onOpenChange}
        defaultValue={defaultAccountNumber}
        disabled={disabled}
      >
        <SelectTrigger {...selectTriggerProps}>
          <SelectValue
            asChild
            placeholder={
              <div className="placeholder flex">
                <div className="flex-1">Select account</div>
              </div>
            }
          >
            {currentItem && (
              <div className="flex-1 flex-col overflow-hidden">
                <div className="truncate">
                  {virtualAccountLabel(currentItem)}
                </div>
                <div className="-mt-1 text-xs text-inpay-gray-primary-1000 group-hover:text-inpay-green-primary-400">
                  No.{' '}
                  {IMask.pipe(currentItem.account_number, {
                    mask: '***[ ]****[ ][****][ ][********]'
                  })}
                </div>
              </div>
            )}
          </SelectValue>
          <SelectIcon asChild>
            <ChevronDownIcon
              className={cn(
                'w-6 shrink-0 stroke-inpay-gray-primary-1000 stroke-1.5',
                {
                  'group-hover:stroke-inpay-black-primary-1000': !disabled
                }
              )}
            />
          </SelectIcon>
        </SelectTrigger>
        <SelectPortal>
          <SelectContent style={{ width: width }} {...selectContentProps}>
            <SelectScrollUpButton className="flex justify-center">
              <ChevronUpIcon className="h-10 w-6 stroke-1.5" />
            </SelectScrollUpButton>
            <SelectViewport>
              {currentItem && (
                <SelectItem
                  key={currentItem.account_number}
                  value={currentItem.account_number}
                  {...selectedItemProps}
                >
                  <SelectItemText asChild>
                    <div className="flex w-full">
                      <div className="flex-1 flex-col overflow-hidden">
                        <div className="truncate">
                          {virtualAccountLabel(currentItem)}
                        </div>
                        <div className="-mt-1 text-xs text-inpay-gray-primary-1000 group-hover:text-inpay-green-primary-400">
                          No.{' '}
                          {IMask.pipe(currentItem.account_number, {
                            mask: '***[ ]****[ ][****][ ][********]'
                          })}
                        </div>
                      </div>
                      {opened && (
                        <ChevronUpIcon className="w-6 stroke-inpay-black-primary-1000 stroke-1.5" />
                      )}
                    </div>
                  </SelectItemText>
                </SelectItem>
              )}
              {availableList.map((item) => (
                <SelectItem
                  key={item.account_number}
                  value={item.account_number}
                  {...selectItemListProps}
                >
                  <SelectItemText asChild>
                    <div className="flex w-full flex-col">
                      <div className="truncate">
                        {virtualAccountLabel(item)}
                      </div>
                      <div className="text-xs text-inpay-gray-primary-1000 group-hover:text-inpay-green-primary-400">
                        No.{' '}
                        {IMask.pipe(item.account_number, {
                          mask: '***[ ]****[ ][****][ ][********]'
                        })}
                      </div>
                    </div>
                  </SelectItemText>
                </SelectItem>
              ))}
            </SelectViewport>
            <SelectScrollDownButton className="flex justify-center">
              <ChevronDownIcon className="h-10 w-6 stroke-1.5" />
            </SelectScrollDownButton>
          </SelectContent>
        </SelectPortal>
      </Select>

      <InputError errorMessage={errorMessage} />
    </div>
  );
};
