import { ReactNode } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

interface Props {
  children: ReactNode;
  routes: any; // Custom page routes
}

// NOTE (LTJ): Layouts a table (`children`) on the left, and "details" (`detailComponent`) on the right
//  Special asterisk: Payouts & Orders pages use this to render filter panel
export const DetailedLayout = ({ children, routes }: Props) => {
  const location = useLocation();
  const detailComponent = useRoutes(routes, location);

  return (
    <div className="flex h-[75vh] space-x-4">
      <div className="w-2/3 grow">{children}</div>
      {detailComponent?.props?.children ? (
        <div className="z-0 w-1/3">
          <div>{detailComponent}</div>
        </div>
      ) : null}
    </div>
  );
};
