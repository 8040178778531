export const EXPORTED_TRANSACTIONS_SIDEBAR_ITEM_TEST_ID =
  'exported-transactions-side-bar-item';
export const PAYOUT_REQUESTS_SIDEBAR_ITEM_TEST_ID =
  'payout-requests-side-bar-item';
export const CREATE_MANUAL_PAYOUT_SIDEBAR_ITEM_TEST_ID =
  'create-manual-payout-sidebar-item';
export const ACCOUNTS_SIDEBAR_ITEM_TEST_ID = 'accounts';
export const PAYOUTS_SIDEBAR_ITEM_TEST_ID = 'payouts';
export const BATCHES_SIDEBAR_ITEM_TEST_ID = 'batches';
export const USER_MANAGEMENT_SIDEBAR_ITEM_TEST_ID = 'user-management';
export const COLLECTION_SIDEBAR_ITEM_TEST_ID = 'collections';
export const ORDERS_SIDEBAR_ITEM_TEST_ID = 'orders';
export const ACCOUNT_WITHDRAWALS_ITEM_TEST_ID = 'withdrawals';
