import { RequestQuery } from 'msw';

import {
  OrderByUuidOrInpayExternalReferenceRequest,
  OrdersRequest
} from './requests';
import {
  CancelOrderResponse,
  OrderByInpayExternalReferenceResponse,
  OrderByUuidOrInpayExternalReferenceResponse,
  OrdersDataResponse,
  OrdersMetaResponse
} from './responses';

import { customerApi } from '../../customerApi';

export const ordersApi = customerApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    fetchOrder: builder.query<
      { data: OrderByInpayExternalReferenceResponse },
      RequestQuery
    >({
      query: ({ reference }) => `/v1/collection/orders/${reference}`,
      providesTags: ['Orders']
    }),
    fetchOrderByUuidOrInpayExternalReference: builder.query<
      { data: OrderByUuidOrInpayExternalReferenceResponse },
      OrderByUuidOrInpayExternalReferenceRequest
    >({
      query: (params) => {
        const urlParams = new URLSearchParams(params);
        return `/v1/collection/orders/find_by_uuid_or_inpay_external_reference?${urlParams}`;
      },
      providesTags: ['Orders']
    }),
    fetchOrders: builder.query<
      { data: OrdersDataResponse; meta: OrdersMetaResponse },
      OrdersRequest
    >({
      query: (params) => {
        // Filter out 'meta' key
        // eslint-disable-next-line
        const { meta, ...baseParams } = params;
        const urlParams = new URLSearchParams(baseParams);
        if (params.meta.sort) {
          urlParams.set('meta[sort]', params.meta.sort);
        }
        if (params.meta.cursor_after) {
          urlParams.set('meta[cursor_after]', params.meta.cursor_after);
        }
        if (params.meta.cursor_before) {
          urlParams.set('meta[cursor_before]', params.meta.cursor_before);
        }
        if (params.meta.page_size) {
          urlParams.set('meta[page_size]', params.meta.page_size);
        }
        return `/v1/collection/orders?${urlParams}`;
      },
      providesTags: ['Orders']
    }),
    cancelOrder: builder.mutation<
      CancelOrderResponse,
      { organisationId: string; orderId: string }
    >({
      query: (params) => {
        const { organisationId, orderId } = params;
        return {
          url: `/v1/collection/organisations/${organisationId}/orders/${orderId}`,
          method: 'DELETE',
          credentials: 'include'
        };
      },
      invalidatesTags: ['Orders']
    })
  })
});

export const {
  useFetchOrderQuery,
  useFetchOrderByUuidOrInpayExternalReferenceQuery,
  useFetchOrdersQuery,
  useCancelOrderMutation
} = ordersApi;
