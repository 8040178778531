import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { FormEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';

import { Content, Overlay, Title } from '@organisms/Dialog';
import { newUserDialogSchema as schema } from 'validators/newUserDialog';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';
import { useCreateUserMutation, useGetRolesQuery } from 'redux/api/usersApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { useRolesOptions } from 'hooks/useRolesOptions';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { InputField } from '@molecules/InputField';
import { Selector } from '@molecules/Selector/Selector';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

interface Props {
  open: boolean;
  onClose: (value: boolean) => void;
}

export const NewUserDialog = ({ open, onClose }: Props) => {
  const {
    reset: resetForm,
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError
  } = useForm<Inputs>({
    mode: 'onSubmit',
    resolver: yupResolver(schema)
  });

  const onDialogClose = (value: boolean) => {
    resetForm();
    onClose(value);
  };

  const [createUser, { isLoading }] = useCreateUserMutation();

  const { data: { data: rolesData } = {} } = useGetRolesQuery();
  const roles = rolesData || [];

  const organisationId = useSelector(selectOrganisationId);

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const {
    isSuccess: isSuccessOrgSettings,
    isError: isErrorOrgSettings,
    data: { data: orgSettings } = {}
  } = useFetchOrganisationConfigurationsQuery(organisationId);

  const isApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-1761-customer-app-batches'] &&
    (orgSettings?.batch_number_of_approvers || 0) > 0;

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event: any) => {
    try {
      if (!organisationId) {
        throw Error('[NewUserDialog] - organisationId is not present');
      }
      await handleSubmit(async (formData) => {
        await createUser({
          data: { ...formData },
          organisationId
        });
        onDialogClose(false);
        toast.success('profile created', {
          position: 'bottom-center',
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        });
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  const rolesOptions = useRolesOptions({ roles, isApprovalFlowEnabled });

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content className="w-84">
            <Title className="bg-inpay-green-primary-1000 px-6 text-white">
              <span>Add New User</span>
            </Title>
            {isErrorOrgSettings && (
              <div className="flex items-center justify-center rounded-b-lg bg-white p-20">
                Something went wrong, please try again.
              </div>
            )}
            {isSuccessOrgSettings && (
              <form onSubmit={onSubmit} autoComplete="off">
                <div className="rounded-b-lg bg-white">
                  <div className="flex flex-col gap-10 px-6 py-4">
                    <InputField
                      {...register('first_name')}
                      label="First Name"
                      errorMessage={errors.first_name?.message}
                    />
                    <InputField
                      {...register('last_name')}
                      label="Last Name"
                      errorMessage={errors.last_name?.message}
                    />
                    <InputField
                      {...register('email')}
                      label="E-mail"
                      errorMessage={errors.email?.message}
                    />
                    <div className="flex flex-col">
                      <label htmlFor="role" className="mb-0.5 pl-2 text-xs">
                        Role
                      </label>
                      <Controller
                        name="role"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <Selector
                            onChange={onChange}
                            value={value}
                            selectChoices={rolesOptions}
                            errorMessage={errors.role?.message}
                          />
                        )}
                      />
                    </div>
                    <div className="flex justify-end gap-3">
                      <SecondaryButton
                        size="large"
                        type="submit"
                        onClick={() => onDialogClose(false)}
                      >
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton
                        size="large"
                        type="submit"
                        disabled={isLoading}
                      >
                        Save Changes
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </form>
            )}
            {isErrorOrgSettings && (
              <div className="flex items-center justify-center rounded-b-lg bg-white p-20">
                Something went wrong, please try again.
              </div>
            )}
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
