import * as yup from 'yup';

// THIS COMMENTED UNTIL WE DECIDE TO GO WITH DECIMAL, MIRO DECISIONS.

// const MASK = [
//   {
//     mask: Number, // enable number mask
//     // other options are optional with defaults below
//     scale: 2, // digits after point, 0 for integers
//     signed: false, // disallow negative
//     thousandsSeparator: ',', // any single char
//     padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
//     normalizeZeros: true, // appends or removes zeros at ends
//     radix: '.', // fractional delimiter
//     mapToRadix: ['.'] // symbols to process as radix
//     // additional number interval options (e.g.) MAYBE WE MIGHT NEED IT.
//     // min: -10000,
//     // max: 10000
//   }
// ];

// const IMaskInput = IMaskMixin(({ inputRef, ...props }) => (
//   <InputField
//     ref={inputRef as Ref<HTMLInputElement>}
//     inputClassName="h-8"
//     {...props}
//   />
// ));

// const schema = yup.object({
//   from: yup
//     .string()
//     .required()
//     .label('"From"')
//     .test('to', '"From" should be less than "To".', (value, context) => {
//       return (
//         parseInt(context.parent.to?.replaceAll(',', '') || '') >=
//         parseInt((value || '')?.replaceAll(',', '') || '')
//       );
//     }),
//   to: yup.string().required().label('"To"').notOneOf(['0.00'], 'To cannot be 0')
// });

export const amountFilterSchema = yup
  .object({
    from: yup
      .number()
      .required()
      .typeError(({ label }) => `${label} must be a number.`)
      .label('"From"')
      .max(9999999, ({ label }) => `${label} must be max 7 digits.`)
      .lessThan(yup.ref('to'), ({ label }) => `${label} should be < than "To".`)
      .test(
        'noEOrSign', // type of the validator (should be unique)
        "Number had an 'e' or sign.", // error message
        (value) => {
          return typeof value === 'number' && !/[eE+-]/.test(value.toString());
        }
      )
      .test(
        'positive',
        '"From" must be a positive number.',
        (value) => typeof value === 'number' && value >= 0
      ),
    to: yup
      .number()
      .required()
      .typeError(({ label }) => `${label} must be a number.`)
      .label('"To"')
      .positive(({ label }) => `${label} must be a positive number.`)
      .max(9999999, ({ label }) => `${label} must be max 7 digits.`)
      .moreThan(
        yup.ref('from'),
        ({ label }) => `${label} should be > than "From".`
      )
      .test(
        'noEOrSign', // type of the validator (should be unique)
        "Number had an 'e' or sign.", // error message
        (value) => typeof value === 'number' && !/[eE+-]/.test(value.toString())
      )
  })
  .required();
