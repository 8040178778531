import { Item } from '@radix-ui/react-toggle-group';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof Item>;

export const FilterToggle = ({ ...props }: Props) => (
  <Item
    className="h-10 w-full rounded-lg bg-white
    shadow-[0px_0px_4px_rgba(212,215,213,1)] hover:bg-inpay-green-primary-1000 hover:text-white
    radix-disabled:!bg-white radix-disabled:text-inpay-gray-primary-1000 radix-disabled:shadow-none
    radix-state-on:bg-inpay-green-primary-1000 radix-state-on:text-white"
    {...props}
  />
);
