import { XMarkIcon } from '@heroicons/react/24/outline';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getCountryName } from 'utils/formatters/getCountryName';
import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { hasValue } from 'utils/hasValue';

import { DetailsBox, Item } from '@organisms/DetailsBox';

import { useFetchPayoutDraftQuery } from 'redux/api/payouts/payoutsApi';

import { LinkWithQuery } from '@atoms/LinkWithQuery';

import { Loading } from 'components/pages/Loading';

const amountFormatter = new MoneyFormatter();

export const BatchPayoutDraftDetails = () => {
  const { payoutId } = useParams();
  const { organisationId } = useParams();

  const {
    data: { data: payoutData } = {},
    isLoading,
    isError,
    isSuccess
  } = useFetchPayoutDraftQuery(
    hasValue(payoutId) && hasValue(organisationId)
      ? {
          id: payoutId,
          organisationId: organisationId
        }
      : skipToken
  );

  const [activeSection, setActiveSection] = useState('payout-request');

  const data = useMemo(() => {
    if (isSuccess && payoutData) {
      const sections: Item[] = [];

      // TODO:We need to improve naming to pass items to the sections.
      // ATT. @dmuneras
      sections.push(
        {
          header: 'Payout Request',
          key: 'payout-request',
          data: [
            {
              key: 'Account:',
              value: payoutData.currency_code,
              subValue: payoutData.debtor_account.id
            },
            {
              key: 'End-to-end ID:',
              value: payoutData.end_to_end_id
            },
            {
              key: 'Amount:',
              value: amountFormatter.format(
                Number.parseFloat(payoutData.amount)
              )
            },
            {
              key: 'Destination Country:',
              value: getCountryName(payoutData.creditor_account.country_code)
            },
            {
              key: 'Selected Product:',
              value: payoutData.local_instrument
            }
          ]
        },
        {
          header: 'Sender',
          key: 'sender',
          data: [
            {
              key: 'Name:',
              value: payoutData.ultimate_debtor.name
            },
            {
              key: 'Address lines:',
              value: payoutData.ultimate_debtor.address_lines
            },
            {
              key: 'City:',
              value: payoutData.ultimate_debtor.city
            },
            {
              key: 'Post code:',
              value: payoutData.ultimate_debtor.postcode
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.ultimate_debtor.country_code)
            }
          ]
        },
        {
          header: 'Receiver Bank',
          key: 'receiver-bank',
          data: [
            {
              key: 'IBAN:',
              value: payoutData.creditor_account.iban
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.creditor_account.country_code)
            },
            {
              key: 'Account type:',
              value: payoutData.creditor_account.type
            },
            {
              key: 'Account IBAN:',
              value: payoutData.creditor_account.iban
            }
          ]
        },
        {
          header: 'Receiver',
          key: 'receiver',
          data: [
            {
              key: 'Name:',
              value: payoutData.creditor.name
            },
            {
              key: 'Date of Birth:',
              value: payoutData.creditor.birthdate
            },
            {
              key: 'Address:',
              value: payoutData.creditor.address_lines
            },
            {
              key: 'Postal Code:',
              value: payoutData.creditor.postcode
            },
            {
              key: 'City:',
              value: payoutData.creditor.city
            },
            {
              key: 'Country:',
              value: getCountryName(payoutData.creditor.country_code)
            }
          ]
        }
      );
      return sections;
    }

    return [];
  }, [payoutData, isSuccess]);

  const updateActiveSection = (section: string) => {
    if (section !== '' && section !== activeSection)
      return setActiveSection(section);
    setActiveSection('payout-request');
  };

  if (!payoutId || !organisationId) return null;

  return (
    <div
      role="complementary"
      aria-label="Payout details"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="payout-overview__header">
        <div className="flex items-center justify-between px-6 py-3.5">
          <span>Details</span>
          <LinkWithQuery to=".." className="cursor-pointer" aria-label="Close">
            <XMarkIcon className="size-6" />
          </LinkWithQuery>
        </div>
        <div className="border-inpay-gray-primary-300 border-y bg-inpay-green-secondary-light-200 py-2 text-sm">
          &nbsp;
        </div>
      </div>
      <div className="overflow-auto">
        {isLoading && <Loading />}
        {isSuccess && (
          <DetailsBox
            data={data}
            activeSection={activeSection}
            setActiveSection={updateActiveSection}
          />
        )}
        {isError && (
          <div className="flex h-full items-center justify-center">
            Something went wrong
          </div>
        )}
      </div>
    </div>
  );
};
