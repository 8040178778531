import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { AuthenticationContainer } from 'components/ui/atoms/AuthenticationContainer';
import { ForgotPasswordForm } from 'components/ui/organisms/ForgotPasswordForm';
import { ForgotPasswordSent } from 'components/ui/organisms/ForgotPasswordSent';

export const ForgotPassword = () => {
  const [email, setEmail] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();

  const sent = searchParams.get('sent') != null;

  const expired = searchParams.get('expired') != null;

  const onSent = (email: string) => {
    setEmail(email);
    setSearchParams('sent');
  };

  return (
    <AuthenticationContainer>
      {sent && email ? (
        <ForgotPasswordSent email={email} />
      ) : (
        <>
          <ForgotPasswordForm onSent={onSent} expired={expired} />
          <Link
            to="/login"
            className="inpay-link mt-24 self-center font-medium"
          >
            Back to login page
          </Link>
        </>
      )}
    </AuthenticationContainer>
  );
};
