import { encodeQueryParams } from 'utils/url';

import { type ApprovalAction } from 'models/ApprovalWorkFlow';
import {
  PayoutData,
  PayoutDraft,
  PayoutDraftData,
  PayoutDraftDetailData,
  PayoutFieldsDefinition
} from 'models/payout';

import { customerApi } from '../customerApi';

type PayoutsRequestKeys =
  | 'unique_identifier'
  | 'page_size'
  | 'sort'
  | 'include_cursor'
  | 'cursor_before'
  | 'created_after'
  | 'created_before'
  | 'cursor_after'
  | 'state_category'
  | 'virtual_account_number'
  | 'reason_code_id'
  | 'currency_code'
  | 'batch_id'
  | 'organisation_id'
  | 'amount_from'
  | 'amount_to';

export type PayoutsRequest = {
  [key in PayoutsRequestKeys]?: string;
};

type PayoutDraftsRequestKeys =
  | 'organisation_id'
  | 'filter[batch_id]'
  | 'filter[state]'
  | 'page[size]'
  | 'page[cursor_after]'
  | 'page[cursor_before]'
  | 'page[include_cursor]';

export type PayoutDraftsRequest = {
  [key in PayoutDraftsRequestKeys]?: string;
};

export interface PayoutsResponse {
  data: Array<PayoutData>;
  meta: PayoutsResponseMeta;
}

export interface PayoutDraftsResponse {
  data: Array<PayoutDraftData>;
  meta: PayoutDraftsResponseMeta;
}

interface CreateDraftRequest {
  product_id: string;
  schema_id: string;
  user_id: string;
}

export interface CreateDraftResponse {
  data: PayoutFieldsDefinition;
}

export interface PayoutDraftResponse {
  data: PayoutDraft;
}

interface PayoutsResponseMeta {
  has_previous_page: boolean;
  has_next_page: boolean;
  cursor_next: string;
  cursor_prev: string;
  total: number;
  has_payouts: boolean;
}
export type PayoutReviewResponse =
  | { data: { success: boolean } }
  | { errors: Error[] };

interface PayoutDraftsResponseMeta {
  has_prev_page: boolean;
  has_next_page: boolean;
  cursor_next: string;
  cursor_prev: string;
  total: number;
}

export const payoutsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchPayout: builder.query({
      query: ({ reference }) => `/v1/payouts/payout_requests/${reference}`,
      providesTags: (_result, _error, id) => [{ type: 'Payouts', id }]
    }),
    fetchPayouts: builder.query<PayoutsResponse, PayoutsRequest>({
      query: (params) => {
        return `/v1/payouts/payout_requests?${new URLSearchParams(params)}`;
      },
      providesTags: ['Payouts']
    }),
    fetchPayoutDrafts: builder.query<PayoutDraftsResponse, PayoutDraftsRequest>(
      {
        query: (params) => {
          return `/v1/payouts/organisations/${
            params.organisation_id
          }/payout_drafts?${encodeQueryParams(params)}`;
        },
        // TODO (LTJ): We shuld consider if we want to share cache tag with payouts
        providesTags: ['Payouts']
      }
    ),
    fetchPayoutDraft: builder.query<
      { data: PayoutDraftDetailData },
      { id: string; organisationId: string }
    >({
      query: ({ id, organisationId }) =>
        `/v1/payouts/organisations/${organisationId}/payout_drafts/${id}`,
      providesTags: (_result, _error, args) => [
        { type: 'PayoutDraft', id: args.id }
      ]
    }),
    // This is referring to the manual payout creation and not the approval flow.
    // This mutation creates the state of the manual payout called draft.
    createManualPayoutDraft: builder.mutation<
      CreateDraftResponse,
      CreateDraftRequest
    >({
      query: (params) => {
        return {
          url: '/v1/payouts/payout_request_draft',
          method: 'POST',
          credentials: 'include',
          body: params
        };
      }
    }),
    // This is referring to the manual payout creation and not the approval.
    // This loads an existing draft of a manual payout.
    fetchManualPayoutDraft: builder.query<PayoutDraftResponse, void>({
      query: () => '/v1/payouts/payout_request_draft',
      providesTags: ['PayoutDraft']
    }),
    // This is referring to the manual payout creation and not the approval.
    // This mutation updates the manual payout draft.
    updateManualPayoutDraft: builder.mutation<CreateDraftResponse, any>({
      query: (params) => {
        return {
          url: '/v1/payouts/payout_request_draft',
          method: 'PATCH',
          credentials: 'include',
          body: params
        };
      }
    }),
    // This is referring to the manual payout creation and not the approval.
    // This mutation post the manual payout (from the draft state).
    publishManualPayoutDraft: builder.mutation<any, void>({
      query: () => {
        return {
          url: '/v1/payouts/payout_request_draft/publish',
          method: 'POST',
          credentials: 'include'
        };
      },
      invalidatesTags: ['PayoutDraft', 'Payouts']
    }),
    // This is referring to the manual payout creation and not the approval.
    deleteManualPayoutDraft: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/v1/payouts/payout_request_draft',
          method: 'DELETE',
          credentials: 'include'
        };
      },
      invalidatesTags: ['PayoutDraft', 'Payouts']
    }),
    // post approve or decline payouts
    approveOrDeclinePayout: builder.mutation<
      PayoutReviewResponse,
      { organisationId: string; payoutId: string; action: ApprovalAction }
    >({
      query: ({ organisationId, payoutId, action }) => {
        return {
          url: `/v1/payouts/organisations/${organisationId}/payouts/${payoutId}/${action}`,
          method: 'POST'
        };
      },
      invalidatesTags: ['PayoutDraft']
    })
  })
});

export const {
  useFetchPayoutQuery,
  useLazyFetchPayoutQuery,
  useFetchPayoutsQuery,
  useFetchPayoutDraftsQuery,
  useFetchPayoutDraftQuery,
  useLazyFetchPayoutsQuery,
  useCreateManualPayoutDraftMutation,
  useUpdateManualPayoutDraftMutation,
  useFetchManualPayoutDraftQuery,
  useDeleteManualPayoutDraftMutation,
  usePublishManualPayoutDraftMutation,
  useApproveOrDeclinePayoutMutation
} = payoutsApi;
