import { useParams } from 'react-router-dom';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';

/**
 *
 * This checks which features are enabled.
 */
export const useFeatureAccessControl = () => {
  const { organisationId } = useParams();
  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const isManualPayoutEnabled =
    orgSettings?.manual_payouts_enabled !== undefined &&
    orgSettings.manual_payouts_enabled > 0;

  const isBatchesPageEnabled =
    !!featureFlagsData?.['CBP-1761-customer-app-batches'] &&
    orgSettings?.batches_enabled !== undefined &&
    orgSettings.batches_enabled > 0;

  const isAccountFundingInfoEnabled =
    !!featureFlagsData?.['CBP-2466-account-funding'] &&
    orgSettings?.account_funding_enabled !== undefined &&
    orgSettings.account_funding_enabled > 0;

  const isManualPayoutApprovalFlowEnabled =
    !!featureFlagsData?.['CBP-2001-approval-workflow-manual-payouts'];

  const isWithdrawalRequestsEnabled =
    !!featureFlagsData?.['CBP-243-account-withrawals'] &&
    !!featureFlagsData?.['CBP-2870-withdrawal-accounts-enabled'] &&
    orgSettings?.virtual_bank_bookkeeping === 'v2';

  return {
    isManualPayoutEnabled,
    isBatchesPageEnabled,
    isAccountFundingInfoEnabled,
    isManualPayoutApprovalFlowEnabled,
    isWithdrawalRequestsEnabled
  };
};
