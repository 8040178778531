import { NavLink, NavLinkProps, useParams } from 'react-router-dom';

export const OrgNavLink = ({ children, to, ...props }: NavLinkProps) => {
  const { organisationId } = useParams();

  const pathname = (to as string).startsWith(`/org/${organisationId}`)
    ? to
    : `/org/${organisationId}${to}`;

  return (
    <NavLink to={pathname} {...props}>
      {children}
    </NavLink>
  );
};
