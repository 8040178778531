import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useLazyGetCurrentUserQuery } from 'redux/api/customerApi';
import {
  selectAuthScopes,
  selectAuthToken,
  selectLogoutState
} from 'redux/slices/authSlice';
import { resetState, useAppDispatch } from 'redux/store';

import { AuthScope } from 'models/authJwt';

import { useRedirectTo } from 'hooks/useRedirectTo';

import { Loading } from 'components/pages/Loading';
import { MessageCodes } from 'components/pages/Login';
import { LoggingIn } from 'components/ui/organisms/LoggingIn';

interface Props {
  allowedScopes?: AuthScope[];
  redirectionPath?: string;
}

export const RequireAuth = ({ allowedScopes }: Props) => {
  const location: { state: any; pathname: string; search: string } =
    useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', false);

  const scopes = useSelector(selectAuthScopes);
  const authToken = useSelector(selectAuthToken);
  const logoutState = useSelector(selectLogoutState);

  const [getCurrentUser, { isFetching, isSuccess, isError }] =
    useLazyGetCurrentUserQuery();

  const searchParams = useSearchParams()[0];
  const { navigateWithRedirectTo } = useRedirectTo();

  useEffect(() => {
    if ((!authToken && !isLoggedIn) || logoutState || isError) {
      const didUserComeFromDocs = searchParams.get('fromDocs') !== null;
      const didUserLogoutFromApp = searchParams.get('user_logout') !== null;

      if (didUserComeFromDocs) {
        navigate('login?fromDocs=true');
        return;
      }

      setIsLoggedIn(false);
      dispatch(resetState);

      if (!didUserLogoutFromApp) {
        navigateWithRedirectTo(
          'login',
          {
            replace: true,
            state: {
              reason: logoutState
            }
          },
          `${location.pathname}${location.search}`
        );
      } else {
        navigate('logout');
      }
    } else {
      getCurrentUser();
    }
  }, [logoutState, isError]);

  return (
    <>
      {isFetching &&
        (location.state?.loggingIn ? (
          <LoggingIn />
        ) : (
          <Loading text="Loading Current User" />
        ))}
      {isSuccess &&
        (allowedScopes &&
        !scopes?.find((scope) => allowedScopes?.includes(scope)) ? (
          <Navigate
            to={`/login?redirect_code=${MessageCodes.MissingScope}`}
            replace
          />
        ) : (
          <Outlet />
        ))}
    </>
  );
};
