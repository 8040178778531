import { formatDate } from 'utils/dayjs';

import { type ReportsRequest } from 'redux/api/accounts/reportsApi';

import { FILTER_PARAM_NAMES } from './constants';
import { type LocalState } from './localState';

/**
 * adapter function that takes in the state and prepares the values to be put in a url as query params
 */
export function mapStateToParams(state: LocalState): ReportsRequest {
  const { sort } = state;
  const { cursorAfter, cursorBefore, includeCursor } = state.pagination;
  const { from, to } = state.dateRange;

  const params: ReportsRequest = {
    sort,
    ...(cursorAfter && { cursor_after: cursorAfter }),
    ...(cursorBefore && { cursor_before: cursorBefore }),
    ...(includeCursor && { include_cursor: includeCursor.toString() }),
    ...(from && { created_after: formatDate(from, 'utc', 'iso') }),
    ...(to && { created_before: formatDate(to, 'utc', 'iso') })
  };

  const accountFilter = state.filters.find(
    (filter) => filter.id === FILTER_PARAM_NAMES.accountNumber
  );

  if (accountFilter && accountFilter.value)
    params.virtual_account_number = accountFilter.value.toString();

  return params;
}
