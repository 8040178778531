import { useMemo, useState } from 'react';

import { CopyButton } from '@organisms/AccountDetails/CopyButton';

import { CryptoFundingInformation } from 'models/fundingInformation';

import { Selector } from '@molecules/Selector/Selector';

interface Props {
  instructions: CryptoFundingInformation[];
}
const CryptoFundingInstructions = ({ instructions }: Props) => {
  const [currentInstruction, setInstruction] = useState<string>(
    instructions[0].currency
  );

  const instructionMap = useMemo(() => {
    const result = {};
    instructions.forEach(
      (instruction) => (result[instruction.currency] = instruction)
    );
    return result;
  }, [instructions]);

  return (
    <div className="flex flex-col gap-6">
      <Selector
        selectChoices={instructions.map((info: CryptoFundingInformation) => ({
          value: info.currency,
          label: info.currency
        }))}
        value={currentInstruction}
        onChange={setInstruction}
      />
      <div className="flex-col items-center justify-between gap-2">
        <div className="shrink-0 text-sm">Address</div>
        <div className="flex">
          <div className="grow-1 w-full break-all font-bold">
            {instructionMap[currentInstruction].address}
          </div>
          <CopyButton textToCopy={instructionMap[currentInstruction].address} />
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 rounded-md bg-inpay-blue-200 p-3">
        <div className="text-sm">
          Please{' '}
          <span className="font-bold">
            only send {instructionMap[currentInstruction].currency} via the{' '}
            {instructionMap[currentInstruction].protocol} protocol.
          </span>
          <br />
          Funds sent via other protocols will not be received and can not be
          recovered.
        </div>
      </div>
    </div>
  );
};

export default CryptoFundingInstructions;
