import { ChevronDownIcon } from '@heroicons/react/24/outline';

export const CsvSelector = () => {
  return (
    <div className="hover:none group flex h-12 select-none items-center rounded-lg stroke-inpay-gray-primary-1000 stroke-1.5 px-4 text-left text-inpay-black-primary-1000 shadow-plain">
      <span className="pointer-events-none">
        <div className="flex justify-end"></div>
        <div className="flex">
          <div className="flex-1">CSV</div>
          <ChevronDownIcon className="w-1/12 stroke-inpay-gray-primary-1000 stroke-1.5" />
        </div>
      </span>
    </div>
  );
};
