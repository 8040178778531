import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { KeyIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';

import { allFieldsDirty, setFormErrors } from 'utils/formHelpers';

import { createPasswordFormSchema as schema } from 'validators/createPasswordForm';
import { isPasswordValid } from 'validators/password';

import { useCreatePasswordMutation } from 'redux/api/authenticationApi';

import { NewPasswordField } from 'components/ui/molecules/NewPasswordField';
import { PasswordField } from 'components/ui/molecules/PasswordField';

interface Props {
  token: string;
  onSent: () => void;
}

interface Inputs extends InferType<typeof schema> {
  token: string;
  base: null;
}

export const CreatePasswordForm = ({ token, onSent }: Props) => {
  const { register, handleSubmit, setError, formState, watch } =
    useForm<Inputs>({
      mode: 'onSubmit',
      resolver: yupResolver(schema),
      defaultValues: {
        password: '',
        password_confirmation: ''
      }
    });

  const [createPassword] = useCreatePasswordMutation();
  register('base', { disabled: true });

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      try {
        await handleSubmit(async (data) => {
          await createPassword({ ...data, token }).unwrap();
          onSent();
        })(event);
      } catch (error: any) {
        setFormErrors({ error, setError, fields: Object.keys(schema.fields) });
      }
    },
    []
  );
  const fields = watch(['password', 'password_confirmation']);
  const isConfirmationValid =
    fields[0] != '' && fields[0] == fields[1] && isPasswordValid(fields[0]);

  return (
    <div className="flex w-82 flex-col text-inpay-black-primary-1000">
      <KeyIcon className="mb-4 flex size-10 self-center stroke-inpay-black-primary-1000 stroke-1.5" />
      <h1 className="mb-5 text-center text-[2rem] font-medium tracking-[0.01rem]">
        Create new password
      </h1>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <NewPasswordField
          {...register('password')}
          label="Enter new password"
          errorMessage={formState.errors.password?.message}
        />
        <PasswordField
          {...register('password_confirmation')}
          label="Repeat password"
          className="mt-12"
          autoComplete="new-password"
          errorMessage={formState.errors.password_confirmation?.message}
        >
          {isConfirmationValid && (
            <CheckCircleIcon className="absolute right-4 inline h-12 w-7 align-middle" />
          )}
        </PasswordField>
        <button
          className="mt-10 h-12 rounded-lg bg-inpay-black-primary-1000 p-2
            text-base font-medium text-white hover:bg-inpay-green-primary-1000 disabled:bg-inpay-black-primary-400"
          type="submit"
          value="Submit"
          disabled={
            formState.isSubmitSuccessful ||
            !allFieldsDirty(formState.dirtyFields, Object.keys(schema.fields))
          }
        >
          Create new password
        </button>
        {formState.errors.base && (
          <div
            role="alert"
            className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
            style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
          >
            {formState.errors.base.message}
          </div>
        )}
      </form>
    </div>
  );
};
