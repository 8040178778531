import { FormEventHandler } from 'react';
import { FormState } from 'react-hook-form';

import { dateRangeOptions } from '@organisms/DateRangeFilter/dateRangeOptions';

import { VirtualAccount } from 'models/virtualAccount';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { DateRangeDefaultFilters } from 'components/ui/organisms/DateRangeFilter';

interface Props {
  formState: FormState<any>;
  dateRange: DateRangeDefaultFilters;
  onSubmit: FormEventHandler<HTMLFormElement>;
  accountData: VirtualAccount;
}

export const Form = ({
  formState,
  onSubmit,
  dateRange,
  accountData
}: Props) => {
  return (
    <div className="w-72">
      <form onSubmit={onSubmit}>
        <div className="border-inpay-gray-primary-300 mb-6 flex flex-col gap-6 rounded-lg border-b bg-inpay-gray-primary-200 p-3 last-of-type:border-0">
          <div>
            <div className="text-xs">Format:</div>
            <div className="font-semibold">CSV</div>
          </div>
          <div>
            <div className="text-xs">Account name:</div>
            <div className="font-semibold">
              {accountData.label || accountData.currency_name}
            </div>
          </div>
          <div>
            <div className="text-xs">Account number:</div>
            <div className="font-semibold">{accountData.account_number}</div>
          </div>
          <div>
            <div className="text-xs">Interval:</div>
            <div className="font-semibold">
              {dateRange.type === 'custom'
                ? `${dateRange.from} - ${dateRange.to}`
                : dateRangeOptions[dateRange.type].label}
            </div>
          </div>
        </div>
        <PrimaryButton
          type="submit"
          size="large"
          className="w-full"
          disabled={formState.isSubmitSuccessful}
        >
          Export account transactions
        </PrimaryButton>
        {typeof formState.errors.base?.message == 'string' && (
          <div
            role="alert"
            className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
            style={{
              filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
            }}
          >
            {formState.errors.base.message}
          </div>
        )}
      </form>
    </div>
  );
};
