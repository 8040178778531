import { KeyIcon } from '@heroicons/react/24/outline';

import { AuthenticationContainer } from 'components/ui/atoms/AuthenticationContainer';

export const InvitationExpired = () => {
  return (
    <AuthenticationContainer>
      <div className="flex w-96	 flex-col ">
        <KeyIcon
          width="40"
          height="40"
          className="mb-4 flex size-10 self-center stroke-inpay-black-primary-1000 stroke-1.5"
        />
        <h1 className="mb-5 text-center text-[2rem] font-medium tracking-[0.01rem]">
          Your link is expired
        </h1>
        <p className="mb-5">
          Sorry, your create password link is no longer valid! Please reach out
          to us at
          <a
            className="mx-1 text-inpay-blue-1000"
            href="mailto:support@inpay.com"
          >
            support@inpay.com
          </a>
          and request a new invitation.
        </p>
        <p>Then we will send you a new invitation.</p>
      </div>
    </AuthenticationContainer>
  );
};
