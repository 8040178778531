import { useParams } from 'react-router-dom';

import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';

import { Selector, Props as SelectorProps } from './Selector';

type Props = Omit<SelectorProps, 'data'> & {
  productId?: number;
};

export const AccountSelector = ({ productId, ...props }: Props) => {
  const { organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  const { data: { data } = {}, isLoading } = useFetchVirtualAccountsQuery({
    organisationId,
    product_id: productId
  });

  return isLoading ? (
    <div className="relative h-12 w-full cursor-pointer select-none rounded-lg bg-white px-4 text-left shadow-plain hover:shadow-plain-lg">
      <div className="my-1 h-4 w-1/3 rounded bg-inpay-gray-primary-400"></div>
      <div className="my-1 h-3 w-1/2 rounded bg-inpay-gray-primary-400"></div>
    </div>
  ) : (
    <Selector data={data || []} {...props} />
  );
};
