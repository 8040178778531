import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { generateRangeFromType } from 'utils/dateRange';
import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { RootState } from 'redux/store';

import { DateRangeType } from 'models/dateRange';

export interface GlobalFiltersState {
  dateRange: DateRangeFilter;
}

export interface DateRangeFilter {
  type: DateRangeType;
  to: string | undefined;
  from: string | undefined;
}

export const initialState: GlobalFiltersState = {
  dateRange: {
    type: 'd30',
    from: dayjs().subtract(30, 'days').startOf('day').format(DATE_FORMAT),
    to: undefined
  }
};

const slice = createSlice({
  name: 'globalFilters',
  initialState,
  reducers: {
    setCustomRange: (
      state,
      { payload }: PayloadAction<{ to: string; from: string }>
    ) => {
      state.dateRange.type = 'custom';
      state.dateRange.to = dayjs(payload.to, DATE_FORMAT)
        .endOf('day')
        .format(DATE_FORMAT);
      state.dateRange.from = dayjs(payload.from, DATE_FORMAT)
        .startOf('day')
        .format(DATE_FORMAT);
    },
    setDateRangeType: (
      state,
      { payload }: PayloadAction<Exclude<DateRangeType, 'custom'>>
    ) => {
      state.dateRange = generateRangeFromType({ type: payload }, true);
    }
  }
});

export const { setCustomRange, setDateRangeType } = slice.actions;

export const selectDateRange = (state: RootState) =>
  state.globalFilters.dateRange;

export default slice.reducer;
