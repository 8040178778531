import cn from 'classnames';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { SearchIcon } from '@atoms/Icon/SearchIcon';

interface Props {
  title?: string;
  className: string;
  children: ReactNode;
}

// This Component is only intended for as `noDataMessage` slot for `<DataTable />`
export const NoDataFound = ({ title, children, className }: Props) => {
  return (
    <div
      className={twMerge(
        cn(
          'flex w-full flex-col items-center bg-white p-8 shadow-plain-lg',
          className
        )
      )}
    >
      {title ? <h2 className="text-xl font-medium">{title}</h2> : ''}
      <SearchIcon />
      {children}
    </div>
  );
};
