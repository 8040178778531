import { XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useSearchParams } from 'react-router-dom';

import { OrderTransactionDetails } from 'components/ui/organisms/OrderTransactionDetails';
import { PayoutTransactionDetails } from 'components/ui/organisms/PayoutTransactionDetails';

interface Props {
  onTransactionSourceLoaded?: (data: any) => any;
}

export const TransactionDetails = ({ onTransactionSourceLoaded }: Props) => {
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  const transactionType = searchParams.get('type');

  if (transactionType === 'withdrawal' && reference) {
    return (
      <PayoutTransactionDetails
        reference={reference}
        onTransactionSourceLoaded={onTransactionSourceLoaded}
      />
    );
  } else if (transactionType === 'invoice' && reference) {
    return (
      <OrderTransactionDetails
        reference={reference}
        onTransactionSourceLoaded={onTransactionSourceLoaded}
      />
    );
  } else {
    return (
      <div className="relative max-h-full w-full flex-col overflow-auto rounded-lg bg-white shadow-plain-lg">
        <div className="payout-overview__header sticky top-0">
          <div className="flex items-center justify-between bg-white px-6 py-3.5">
            <span>Details</span>
            <Link to=".." className="cursor-pointer" aria-label="Close">
              <XMarkIcon className="size-6" />
            </Link>
          </div>
          <div className="border-inpay-gray-primary-300 h-10 border-y bg-inpay-green-secondary-light-200"></div>
        </div>
        <p className="px-6 py-4">
          Transaction source details not supported for this type of transaction.
        </p>
      </div>
    );
  }
};
