export const ANTD_INPAY_THEME = {
  components: {
    Select: {
      colorPrimary: 'rgb(87, 130, 65)',
      optionSelectedColor: 'rgba(0, 0, 0, 0.88)',
      optionSelectedBg: 'rgba(234, 234, 234, 0.27)',
      optionHeight: 45,
      optionLineHeight: 3
    },
    Collapse: {
      headerPadding: '22px 24px',
      contentPadding: '16px 24px',
      fontSizeIcon: 18
    },
    Dropdown: {
      controlItemBgActiveHover: 'rgb(197, 206, 204)',
      controlItemBgActive: 'rgba(197, 206, 204, 0.6)'
    },
    Table: {
      headerBg: 'rgb(222, 250, 234)',
      rowHoverBg: 'rgb(248, 247, 246)',
      headerSortHoverBg: 'rgb(222, 250, 234)'
    },
    Tag: {
      defaultBg: 'rgb(162, 205, 254)',
      borderRadiusSM: 8
    }
  },
  token: {
    colorPrimary: '#0b4b4b',
    colorInfo: '#0b4b4b'
  }
};
