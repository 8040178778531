import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger
} from '@radix-ui/react-accordion';
import { ReactNode } from 'react';

import { Steps } from './steps';
import { StepStatusCircle } from './StepStatusCircle';

interface Props {
  children: ReactNode;
  name: Steps;
  header: string;
  index: string;
  isFinished: boolean;
}

export const StepItem = ({
  children,
  name,
  header,
  index,
  isFinished
}: Props) => {
  return (
    <AccordionItem
      value={name}
      className="rounded-lg border border-inpay-gray-primary-800 bg-inpay-gray-secondary-600"
    >
      <AccordionHeader>
        <AccordionTrigger className="group flex w-full cursor-pointer items-center justify-start gap-2 px-6 py-4 outline-offset-[-1px] group-last:radix-state-closed:rounded-b-lg">
          <StepStatusCircle step={name} value={index} isFinished={isFinished} />
          <h2 className="font-semibold">{header}</h2>
          <ChevronDownIcon className="group-radix-state-open:scale-flip ml-auto w-7 transition-all duration-300 group-hover:!stroke-inpay-black-primary-1000 group-radix-state-closed:stroke-inpay-black-primary-400" />
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent>{children}</AccordionContent>
    </AccordionItem>
  );
};
