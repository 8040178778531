import { Outlet } from 'react-router-dom';

import { Sidebar } from '@organisms/Sidebar/Sidebar';
import { TopBar } from '@organisms/TopBar';

import { TopBarMutationObserver } from './TopBarMutationObserver';

export const AppLayout = () => {
  return (
    <div className="flex h-full text-black">
      {/*This mutation observer can be removed when /src/components/ui/organisms/DataTable/DataTable.tsx is removed*/}
      <TopBarMutationObserver />
      <Sidebar />
      <div className="flex flex-1 flex-col">
        <TopBar />
        <main
          className="size-full overflow-auto bg-inpay-gray-primary-200"
          id="main"
        >
          <Outlet />
        </main>
      </div>
    </div>
  );
};
