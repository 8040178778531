import { Navigate, Outlet, useParams } from 'react-router-dom';

import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';

import { Loading } from 'components/pages/Loading';

interface Props {
  featureFlag: string;
}

export const RequireFeatureFlag = ({ featureFlag }: Props) => {
  const {
    data: { data: featureFlagsData } = {},
    isSuccess: featureFlagsSuccess,
    isLoading: featureFlagsLoading,
    isError: featureFlagsError
  } = useFetchFeatureFlagsQuery();

  const { organisationId } = useParams();

  const isFeatureFlagsEnabled = !!featureFlagsData?.[featureFlag];
  return (
    <>
      {featureFlagsLoading && <Loading text="Loading data..." />}
      {featureFlagsError && <Navigate to={`/org/${organisationId}`} replace />}
      {featureFlagsSuccess &&
        (isFeatureFlagsEnabled ? (
          <Outlet />
        ) : (
          <Navigate to={`/org/${organisationId}`} replace />
        ))}
    </>
  );
};
