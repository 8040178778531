import { BatchPayoutsContent } from './BatchPayoutsContent';
import { StateGate } from './StateGate';

export const BatchPayouts = () => {
  return (
    <StateGate>
      <BatchPayoutsContent />
    </StateGate>
  );
};
