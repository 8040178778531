import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { KeyIcon } from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { type InferType } from 'yup';

import {
  allFieldsDirty,
  modifyFormError,
  setFormErrors
} from 'utils/formHelpers';
import { prepareSearchParams } from 'utils/url';

import { isPasswordValid } from 'validators/password';
import { updatePasswordFormSchema as schema } from 'validators/updatePasswordForm';

import { useResetPasswordMutation } from 'redux/api/authenticationApi';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { NewPasswordField } from '@molecules/NewPasswordField';
import { PasswordField } from '@molecules/PasswordField';

prepareSearchParams('qwe', { x: 'qwe' });

interface Props {
  token: string;
  onSent: () => void;
}

interface Inputs extends InferType<typeof schema> {
  token: string;
  base: null;
}

export const UpdatePasswordForm = ({ token, onSent }: Props) => {
  const { register, handleSubmit, setError, formState, watch } =
    useForm<Inputs>({
      mode: 'onSubmit',
      resolver: yupResolver(schema),
      defaultValues: {
        password: '',
        password_confirmation: ''
      }
    });

  const [resetPassword] = useResetPasswordMutation();

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      try {
        await handleSubmit(async (data) => {
          await resetPassword({ ...data, token }).unwrap();
          onSent();
        })(event);
      } catch (error: any) {
        const newError = modifyFormError({
          error,
          replacement: {
            status: 403,
            pointer: 'reset_password_token',
            messages: [
              <>
                This page has expired, please request a{' '}
                <Link to="/passwords/forgot?expired" className="font-medium">
                  new reset password link here
                </Link>
              </>
            ]
          }
        });
        setFormErrors({
          error: newError,
          setError,
          fields: Object.keys(schema.fields)
        });
      }
    },
    []
  );

  const fields = watch(['password', 'password_confirmation']);
  const isConfirmationValid =
    fields[0] != '' && fields[0] == fields[1] && isPasswordValid(fields[0]);

  register('base', { disabled: true });

  return (
    <div className="w-80">
      <KeyIcon className="mx-auto mb-4 flex size-10 stroke-inpay-black-primary-1000 stroke-1.5" />
      <h1 className="mb-10 text-center text-[2rem] font-medium tracking-[0.01rem]">
        Create new password
      </h1>
      <form className="flex flex-col" onSubmit={onSubmit}>
        <input hidden autoComplete="username" />
        <NewPasswordField
          {...register('password')}
          label="Enter new password"
          autoComplete="new-password"
          errorMessage={formState.errors.password?.message}
        />
        <PasswordField
          {...register('password_confirmation')}
          label="Repeat password"
          className="mt-12"
          autoComplete="new-password"
          errorMessage={formState.errors.password_confirmation?.message}
        >
          {isConfirmationValid && (
            <CheckCircleIcon className="absolute right-4 inline h-12 w-7 align-middle" />
          )}
        </PasswordField>
        <PrimaryButton
          size="large"
          className="mt-6 font-medium"
          type="submit"
          value="Submit"
          disabled={
            formState.isSubmitSuccessful ||
            !allFieldsDirty(formState.dirtyFields, Object.keys(schema.fields))
          }
        >
          Create new password
        </PrimaryButton>
        {formState.errors.base && (
          <div
            role="alert"
            className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
            style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
          >
            {formState.errors.base.message}
          </div>
        )}
      </form>
    </div>
  );
};
