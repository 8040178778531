import { ErrorResponse } from './types';

interface Props {
  error: ErrorResponse;
}

export const ErrorHandler = ({ error }: Props) => {
  return (
    error && (
      <div className="flex flex-col gap-3.5">
        <div>
          Format:<span className="ml-1 font-semibold">CSV</span>
        </div>
        <div>
          Name:
          <span className="ml-1 font-semibold">{error.data.data.filename}</span>
        </div>
        {error.data.meta && (
          <>
            <div>
              Type:
              <span className="ml-1 font-semibold">
                {error.data.meta.local_instrument}
              </span>
            </div>
            <div>
              Corridor:
              <span className="ml-1 font-semibold">
                {error.data.meta.currency.iso} ({error.data.meta.currency.name})
                - {error.data.meta.country.iso} ({error.data.meta.country.name})
              </span>
            </div>
          </>
        )}

        <div>
          {error.data.errors &&
            error.data.errors.map((error: { details: any }) =>
              error.details.map(
                (detail: boolean | null | undefined, index: number) => (
                  <div
                    key={index}
                    className="font-semibold italic text-inpay-red-1000"
                  >
                    {detail}.
                  </div>
                )
              )
            )}
        </div>

        {error.data.data.content ? (
          <div className="pb-10">
            Please download the error report,
            <br /> correct the data and try again.
          </div>
        ) : (
          <div className="pb-10">
            Please upload the batch with correct data, <br /> and try again.
          </div>
        )}
      </div>
    )
  );
};
