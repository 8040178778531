export const NoSearchResult = () => {
  return (
    <div className="size-full rounded-lg bg-white p-8 text-center shadow-plain-lg">
      <h2 className="text-xl font-medium">No search result</h2>
      <p className="mt-6">
        We could not find any results matching the applied search criteria.
        <br />
        Please adjust your search criteria and try again.
      </p>
    </div>
  );
};
