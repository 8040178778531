import { LocalInstrument, ProductSchema } from 'models/batchTemplate';

import { customerApi } from '../customerApi';

export interface LocalInstrumentsResponse {
  data: Array<LocalInstrument>;
}

export interface ProductSchemasResponse {
  data: Array<ProductSchema>;
}

export const batchTemplateApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchBatchTemplate: builder.query<any, any>({
      query: ({ id }) => {
        return `/v1/payouts/schemas/${id}/csv_template`;
      },
      providesTags: ['BatchTemplates']
    }),
    getLocalInstruments: builder.query<LocalInstrumentsResponse, void>({
      query: () => {
        return '/v1/payouts/local_instruments';
      },
      providesTags: ['BatchTemplates']
    }),
    getProductSchemas: builder.query<ProductSchemasResponse, any>({
      query: (localInstrumentId) => {
        return `/v1/payouts/product_schemas?product_id=${localInstrumentId}`;
      },
      providesTags: ['BatchTemplates']
    })
  })
});

export const {
  useFetchBatchTemplateQuery,
  useLazyFetchBatchTemplateQuery,
  useGetLocalInstrumentsQuery,
  useGetProductSchemasQuery,
  useLazyGetProductSchemasQuery
} = batchTemplateApi;
