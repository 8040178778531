import cn from 'classnames';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import { useFetchCurrenciesQuery } from 'redux/api/currenciesApi';

import { ExpandIcon } from 'components/ui/atoms/Icon';

interface Props {
  onCurrencyChange: (value?: string) => void;
  value?: string;
}

export const CurrencyFilter = ({ onCurrencyChange, value }: Props) => {
  const { data } = useFetchCurrenciesQuery();

  const [filterVisible, setFilterVisible] = useState<boolean>(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setFilterVisible(false);
  });

  return data ? (
    <div
      ref={ref}
      className="relative flex h-full cursor-pointer items-center gap-1 whitespace-nowrap px-4 text-sm font-normal"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setFilterVisible(!filterVisible);
      }}
    >
      <span
        className={cn('hover:text-inpay-green-primary-1000', {
          'text-inpay-green-primary-1000': filterVisible
        })}
      >
        Currency:
        <b className="ml-1 truncate font-bold">{value || 'All'}</b>
      </span>
      <ExpandIcon
        className="w-4 shrink-0 stroke-2 duration-200"
        expanded={filterVisible}
      />
      {filterVisible && (
        <div className="absolute left-0 top-full flex max-w-42 origin-top transform-gpu flex-col gap-2 rounded-b-lg bg-inpay-green-secondary-light-200 p-6 shadow-plain-bottom duration-200 ease-in-out hover:cursor-auto">
          <div
            className={cn(
              'w-fit max-w-full truncate rounded-lg px-2 py-1 text-right text-sm font-normal',
              {
                'hover:none cursor-default text-inpay-green-primary-400': !value
              },
              {
                'cursor-pointer hover:bg-inpay-green-primary-1000  hover:text-white':
                  value
              }
            )}
            onClick={() => onCurrencyChange(undefined)}
          >
            All
          </div>
          {data &&
            data.data.map((currency) => (
              <div
                className={cn(
                  'w-fit max-w-full cursor-pointer overflow-hidden truncate rounded-lg px-2 py-1 text-right text-sm font-normal',
                  {
                    'cursor-pointer hover:bg-inpay-green-primary-1000  hover:text-white':
                      value !== currency.iso
                  },
                  {
                    'hover:none cursor-default text-inpay-green-primary-400':
                      value === currency.iso
                  }
                )}
                key={currency.iso}
                onClick={() => {
                  onCurrencyChange(currency.iso);
                }}
              >
                {currency.name}
              </div>
            ))}
        </div>
      )}
    </div>
  ) : null;
};
