import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

interface Props {
  email: string;
}

export const Loading = ({ email }: Props) => {
  return (
    <div className="flex flex-col items-center">
      <RollingLoader ariaLabel="Generating report" className="my-3" />
      <div className="mt-4">
        When the report is ready you will receive an email (
        <span className="text-inpay-blue-1000">{email}</span>) with a link where
        you can download your report.
      </div>
    </div>
  );
};
