import { DialogClose } from '@radix-ui/react-dialog';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

interface Props {
  email: string;
}

export const NewReportSuccess = ({ email }: Props) => {
  return (
    <div className="flex flex-col items-center gap-6">
      <h3 className="pt-4 text-center text-lg font-medium">
        Your account statement
        <br />
        is being generated
      </h3>
      <div>
        When the report is ready you will receive an email (
        <span className="text-inpay-blue-1000">{email}</span>) with a link where
        you can download your report.
      </div>
      <DialogClose asChild>
        <PrimaryButton aria-label="Close window" className="w-full">
          Close window
        </PrimaryButton>
      </DialogClose>
    </div>
  );
};
