import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonProps } from './Button';

export const TextButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, size = 'small', ...props },
    ref: ForwardedRef<HTMLButtonElement>
  ) => (
    <button
      ref={ref}
      onClick={props.onClick}
      className={twMerge(
        cn(
          (!size || size === 'large') && 'h-11',
          size === 'small' && 'h-9 text-sm',
          'font-medium leading-[22px]',
          'text-inpay-green-primary-1000 hover:bg-inpay-gray-primary-200 hover:text-inpay-dark-green-1000 disabled:text-inpay-black-primary-400',
          'outline-offset-2 outline-inpay-blue-1000',
          'cursor-pointer disabled:cursor-default',
          className
        )
      )}
      {...props}
    />
  )
);

TextButton.displayName = 'TextButton';
