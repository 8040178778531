import * as yup from 'yup';

export const uploadBatchDialogSchema = yup
  .object({
    name: yup.string().optional(),
    file: yup
      .mixed()
      .label('file')
      .required('File required')
      .test('FileList', 'File is not present', (value) => {
        return value?.length > 0;
      })
      .test('fileSize', 'The file is too large', (value) => {
        if (!value?.length) return true;
        return value[0].size <= 2 * 1024 * 1024;
      })
  })
  .required();
