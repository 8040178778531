import { XMarkIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { payoutStates } from 'models/payout';
import { IReasonCode } from 'models/reasonCode';

import { Badge } from '@atoms/badges/Badge/Badge';
import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';

interface AcceptedFilters {
  state?: string | null | undefined;
  accountNumber?: string | null | undefined;
  reasonCode?: string | null | undefined;
}

interface Props {
  filters: AcceptedFilters;
  reasonCodes?: IReasonCode[];
  className?: string;
  onRemoveClick?: ({ type }: { type: string }) => void;
}

export const PayoutsFiltersLabels = ({
  filters,
  reasonCodes,
  className,
  onRemoveClick
}: Props) => {
  const selectedReasonCode = useMemo<IReasonCode | undefined>(() => {
    return reasonCodes?.find((rc: IReasonCode) => rc.id == filters.reasonCode);
  }, [reasonCodes, filters.reasonCode]);

  const stateStyling = useMemo(() => {
    return payoutStates.find((s) => s.id === filters.state)?.style;
  }, [selectedReasonCode]);

  return (
    <div
      className={cn('flex gap-2', className)}
      role="region"
      aria-label="Active filters"
    >
      {selectedReasonCode ? (
        <div
          className="relative cursor-pointer"
          onClick={() => onRemoveClick && onRemoveClick({ type: 'reasonCode' })}
        >
          <Badge
            label={selectedReasonCode.reason}
            className={twMerge(
              'inline leading-4',
              stateStyling?.hover,
              stateStyling?.color
            )}
          />
          <XMarkIcon className="absolute -right-1.5 -top-1.5 box-content h-2.5 rounded-full border-3 border-inpay-black-primary-1000 bg-inpay-black-primary-1000 fill-white" />
        </div>
      ) : null}
      {filters.state ? (
        <div
          className="relative cursor-pointer"
          onClick={() => onRemoveClick && onRemoveClick({ type: 'state' })}
        >
          <PayoutBadge state={filters.state} />
          <XMarkIcon className="absolute -right-1.5 -top-1.5 box-content h-2.5 rounded-full border-3 border-inpay-black-primary-1000 bg-inpay-black-primary-1000 fill-white" />
        </div>
      ) : null}
    </div>
  );
};
