import cn from 'classnames';

import { PaymentState } from 'models/payment';

export interface PaymentStateBadge {
  class: string;
  text: string;
}

export type PaymentStateBadges = {
  [key in PaymentState]: PaymentStateBadge;
};

interface PaymentBadgeProps {
  type: PaymentState;
}

export const badges: PaymentStateBadges = {
  created: {
    class: 'bg-inpay-blue-200',
    text: 'Created'
  },
  instructions_provided: {
    class: 'bg-inpay-orange-400',
    text: 'Instructions provided'
  },
  consent_given: {
    class: 'bg-inpay-green-secondary-light-600',
    text: 'Consent Given'
  },
  extra_info_required: {
    class: 'bg-inpay-yellow-400',
    text: 'Extra Info Required'
  },
  bank_info_collected: {
    class: 'bg-inpay-green-secondary-light-600',
    text: 'Bank Info Collected'
  },
  external_request_created: {
    class: 'bg-inpay-blue-200',
    text: 'External Request Created'
  },
  bank_authorised: {
    class: 'bg-inpay-blue-200',
    text: 'Bank Authorised'
  },
  bank_processing: {
    class: 'bg-inpay-blue-200',
    text: 'Bank Processing'
  },
  completed: {
    class: 'bg-inpay-green-secondary-light-600',
    text: 'Completed'
  },
  extra_bank_info_required: {
    class: 'bg-inpay-yellow-400',
    text: 'Extra Info Required'
  },
  external_transfer_required: {
    class: 'bg-inpay-yellow-400',
    text: 'External Transfer Required'
  },
  redirect_to_bank: {
    class: 'bg-inpay-orange-400',
    text: 'Redirect To Bank'
  },
  redirected_to_inpay: {
    class: 'bg-inpay-orange-400',
    text: 'Redirect To Inpay'
  },
  bank_rejected: {
    class: 'bg-inpay-red-400',
    text: 'Rejected'
  },
  user_cancelled: {
    class: 'bg-inpay-red-400',
    text: 'User Cancelled'
  },
  redirect_to_inpay: {
    class: 'bg-inpay-red-400',
    text: 'User Cancelled'
  }
};

export const PaymentBadge = ({ type }: PaymentBadgeProps) => {
  const badge = badges[type];

  if (!badge) {
    console.error(`invalid badge from type ${type}`);
    return null;
  }

  return (
    <span
      className={cn(
        badge.class,
        'inline-block w-fit overflow-hidden truncate rounded-lg px-2 py-1 text-sm'
      )}
    >
      {badge.text}
    </span>
  );
};
