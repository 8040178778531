import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

interface ButtonProps {
  errorData: any;
  resetForm?: any;
  resetUploadRequest: () => void;
}

export const DownloadErrorButton = ({
  errorData,
  resetForm,
  resetUploadRequest
}: ButtonProps) => {
  const filePrefix = `data:text/csv;charset=utf-8,${errorData.data.content}`;
  const encodedURI = encodeURI(filePrefix);

  return errorData.data.content ? (
    <a
      className="flex h-12 w-full cursor-pointer items-center justify-center rounded-lg bg-inpay-red-1000 p-2 text-center text-white hover:bg-inpay-red-200 focus-visible:bg-inpay-red-200 disabled:bg-inpay-black-primary-400"
      href={encodedURI}
      download={errorData.data.filename}
    >
      Download CSV error report
    </a>
  ) : (
    <PrimaryButton
      size="large"
      className="mt-4 w-full"
      onClick={() => {
        resetForm();
        resetUploadRequest();
      }}
    >
      Try again
    </PrimaryButton>
  );
};
