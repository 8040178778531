import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';
import { PayoutsFiltersLabels } from '@organisms/PayoutsFiltersLabels/PayoutsFiltersLabels';

import { useFetchPayoutsQuery } from 'redux/api/payouts/payoutsApi';
import { LocalState } from 'redux/slices/pages.payoutsSlice';

import { useGlobalFilters } from 'hooks/useGlobalFilters';
import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { PayoutDetails } from 'components/pages/Payouts/Details/PayoutDetails';
import { usePayoutColumns } from 'components/pages/Payouts/payoutColumns';
import { LoadingOverlay } from 'components/ui/molecules/LoadingOverlay';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';

import { stateToRequestParams, useLocalState } from '../localState';

const routes = [{ path: ':payoutId', element: <PayoutDetails /> }];

export const BatchPayoutsList = () => {
  const location = useLocation();
  const navigate = useOrgNavigate();
  const { batchId, '*': payoutId } = useParams();
  const [pagination, setPagination] = useState<PaginatorProps>();
  const batchPayoutsColumnDefinitions = usePayoutColumns();

  const {
    state,
    tableFilters,
    queryParams: localQueryParams,
    resetState,
    updateSort,
    updateFilters,
    updateCursorAfter,
    updateCursorBefore
  } = useLocalState();

  const { filters: globalFilters, queryParams: globalQueryParams } =
    useGlobalFilters();

  const {
    data: { data, meta } = {},
    isFetching,
    error
  } = useFetchPayoutsQuery(
    stateToRequestParams({ ...state, ...globalFilters, batchId })
  );

  const sortPayouts = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');

      if (sortingParams != state.sort) {
        updateSort(sortingParams);
      }
    },
    [state.sort]
  );

  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);

    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  useUpdateEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...localQueryParams,
      ...globalQueryParams
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [payoutId, localQueryParams, state]);

  useUpdateEffect(() => {
    resetState();
    if (payoutId) {
      navigate('.');
    }
  }, [globalQueryParams]);

  if (!batchId) return null;

  const removeFilterLabel = ({ type }: { type: string }) => {
    switch (type) {
      case 'state':
        updateFilters({
          state: undefined
        });
        break;
    }
  };

  const onRowClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    if (rowData.inpay_unique_reference == payoutId) {
      navigate({ pathname: '.', search: location.search });
    } else {
      navigate({
        pathname: rowData.inpay_unique_reference,
        search: location.search
      });
    }
  };

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const prefilledFilters = {
      state: undefined,
      accountNumber: undefined,
      currency: undefined
    };

    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      prefilledFilters
    );

    updateFilters(updatedFilters);
  };

  const nextPage = () => {
    updateCursorAfter(meta?.cursor_next);
  };

  const prevPage = () => {
    updateCursorBefore(meta?.cursor_prev);
  };

  const payoutRequests = data || [];

  return (
    <DetailedLayout routes={routes}>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{error.error}</div>
        </div>
      )}
      <DataTable
        columns={batchPayoutsColumnDefinitions}
        isFetching={isFetching}
        name="Batch payout requests"
        topBar="name-with-count"
        total={meta?.total}
        hasData={meta?.has_payouts}
        data={payoutRequests}
        onSortingChange={sortPayouts}
        onFilteringChange={onFilteringChange}
        initialSorting={sorting}
        filters={tableFilters}
        onRowClick={onRowClick}
        pagination={{
          ...pagination,
          onClickNext: nextPage,
          onClickPrev: prevPage
        }}
        noDataMessage={
          <NoDataFound title="No payout requests found" className="mt-1">
            Payout requests not found for this specific batch.
          </NoDataFound>
        }
      />
      <PayoutsFiltersLabels
        filters={state.filters}
        onRemoveClick={removeFilterLabel}
        className="absolute right-6 top-3.5"
      />
      {isFetching && <LoadingOverlay />}
    </DetailedLayout>
  );
};
