export const AccountWidgetSkeleton = () => {
  return (
    <div
      role="progressbar"
      aria-label="Account widgets loading"
      className="grid h-14 w-full animate-pulse grid-cols-[3rem_3fr_1fr_2rem] items-center gap-2 rounded-lg bg-white px-4 shadow-plain-lg lg:gap-4"
    >
      <div className="size-8 rounded-full bg-inpay-gray-primary-800" />
      <div className="flex h-full flex-col items-center justify-center gap-2">
        <div className="h-4 w-full rounded bg-inpay-gray-primary-800" />
        <div className="h-4 w-full rounded bg-inpay-gray-primary-800" />
      </div>
      <div className="flex justify-between gap-2">
        <div className="size-8 rounded bg-inpay-gray-primary-800" />
        <div className="h-8 w-full rounded bg-inpay-gray-primary-800" />
      </div>
    </div>
  );
};
