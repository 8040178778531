import { SVGProps } from 'react';

export const CheckCircleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    stroke="#fff"
    className="fill-inpay-green-primary-1000"
    viewBox="0 0 48 48"
    {...props}
  >
    <rect width="48" height="48" rx="24" stroke="none" />
    <path
      fill="none"
      strokeLinecap="round"
      strokeWidth="4"
      d="m14.771 26.324 8.887 7.702m9.183-19.255-9.183 19.255"
    />
  </svg>
);
