import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';

import { useFetchOrdersQuery } from 'redux/api/collections/orders';

import { OrderError } from 'models/errors';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { TableLayout } from 'components/ui/templates/TableLayout';

import { apiParams } from './apiAdapter';
import { COLLECTION_ORDERS_TABLE_TEST_ID } from './constants';
import { FiltersState, LocalState, useLocalState } from './localState';
import { useOrderColumnDefinitions } from './orderColumns';
import { OrderDetails } from './OrderDetails';
import { OrdersFilters } from './OrdersFilters';
import { OrdersHeader } from './OrdersHeader';

export const Orders = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { columnDefinitions } = useOrderColumnDefinitions();

  const [pagination, setPagination] = useState<PaginatorProps>();

  const {
    state,
    queryParams: localQueryParams,
    dispatch,
    setSort,
    updateCursorAfter,
    updateCursorBefore,
    updateFilters
  } = useLocalState();

  const { filters, queryParams: globalQueryParams } = useGlobalFilters();

  // Sync (local & global) query params to URL
  useUpdateEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...localQueryParams,
      ...globalQueryParams
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [orderId, globalQueryParams, state]);

  const { currentData, isFetching, error } = useFetchOrdersQuery(
    apiParams({ ...state, ...filters })
  );

  const orders = currentData?.data || [];
  const errorResponse: OrderError[] = (error as any)?.data.errors;

  const sortOrders = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');
      if (sortingParams != state.sort) {
        dispatch(setSort(sortingParams));
      }
    },
    [state.sort]
  );

  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);
    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  const nextPage = () => {
    currentData && dispatch(updateCursorAfter(currentData.meta.cursor_after));
  };

  const prevPage = () => {
    currentData && dispatch(updateCursorBefore(currentData.meta.cursor_before));
  };

  const onRowClick = (_event: React.MouseEvent<HTMLElement>, rowData: any) => {
    if (orderId) {
      if (rowData.uuid === orderId) {
        navigate('..');
      } else {
        navigate(`../${rowData.uuid}`);
      }
    } else {
      navigate(rowData.uuid);
    }
  };

  useEffect(() => {
    if (currentData?.meta) {
      setPagination({
        showPrev: currentData.meta.cursor_before?.length > 0,
        showNext: currentData.meta.cursor_after?.length > 0
      });
    }
  }, [currentData?.meta]);

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const prefilledFilters = {
      status: undefined,
      accountNumber: undefined
    };

    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      prefilledFilters
    );

    dispatch(updateFilters(updatedFilters));
  };

  return (
    <TableLayout header={<OrdersHeader />}>
      <div className="flex h-full gap-4">
        {errorResponse ? (
          <div className="flex h-full items-center justify-center" role="alert">
            {errorResponse.map((error: OrderError) => (
              <div key={error.title}>{error.title}</div>
            ))}
          </div>
        ) : (
          <div className="w-2/3 grow">
            <DataTable
              data-testid={COLLECTION_ORDERS_TABLE_TEST_ID}
              columns={columnDefinitions}
              name="Collection orders"
              topBar="name-with-count"
              total={orders.length}
              data={orders}
              hasData={currentData?.meta.has_orders}
              noDataMessage={
                <NoDataFound title="No orders found" className="mt-1">
                  We have yet to receive any collection orders.
                </NoDataFound>
              }
              currentRow={{
                value: orderId,
                field: 'uuid'
              }}
              isFetching={isFetching}
              onSortingChange={sortOrders}
              filters={Object.keys(state.filters).map((id) => ({
                id,
                value: state.filters[id]
              }))}
              onFilteringChange={onFilteringChange}
              initialSorting={sorting}
              onRowClick={onRowClick}
              pagination={{
                ...pagination,
                onClickNext: nextPage,
                onClickPrev: prevPage
              }}
            />
          </div>
        )}
        {isFetching && <LoadingOverlay />}
        <div className="relative w-1/3">
          {orderId ? (
            <OrderDetails orderId={orderId} />
          ) : (
            <OrdersFilters
              filters={state.filters}
              onFilterChange={(filterChange: FiltersState) =>
                dispatch(updateFilters(filterChange))
              }
            />
          )}
        </div>
      </div>
    </TableLayout>
  );
};
