import { useMemo, useReducer } from 'react';

export type PaginationState = {
  sort: string;
  includeCursor?: boolean;
  cursorBefore?: string;
  cursorAfter?: string;
};

export type FiltersState = {
  status?: string;
  accountNumber?: string;
};

export type LocalState = PaginationState & { filters: FiltersState };

type Action =
  | { type: 'reset' }
  | { type: 'setSort'; value: string }
  | { type: 'updateCursorBefore'; value?: string }
  | { type: 'updateCursorAfter'; value?: string }
  | { type: 'updateFilters'; value: LocalState['filters'] };

function reducer(status: LocalState, action: Action): LocalState {
  switch (action.type) {
    case 'setSort':
      return {
        ...status,
        sort: action.value,
        includeCursor: undefined,
        cursorBefore: undefined,
        cursorAfter: undefined
      };
    case 'updateCursorBefore':
      return {
        ...status,
        cursorBefore: action.value,
        cursorAfter: undefined,
        includeCursor: undefined
      };
    case 'updateCursorAfter':
      return {
        ...status,
        cursorAfter: action.value,
        cursorBefore: undefined,
        includeCursor: undefined
      };
    case 'updateFilters':
      return {
        ...status,
        filters: action.value
      };
    case 'reset':
      return {
        filters: { status: undefined, accountNumber: undefined },
        sort: '-date',
        cursorAfter: undefined,
        cursorBefore: undefined,
        includeCursor: undefined
      };
  }
}

const reset = (): Action => ({ type: 'reset' });
const setSort = (value: string): Action => ({ type: 'setSort', value });
const updateCursorBefore = (value?: string): Action => ({
  type: 'updateCursorBefore',
  value
});
const updateCursorAfter = (value?: string): Action => ({
  type: 'updateCursorAfter',
  value
});
const updateFilters = (value: LocalState['filters']): Action => ({
  type: 'updateFilters',
  value
});

const initialState: LocalState = {
  filters: {
    status: undefined,
    accountNumber: undefined
  },
  sort: '-date',
  includeCursor: undefined,
  cursorBefore: undefined,
  cursorAfter: undefined
};

export function useLocalState() {
  const searchParams = new URL(document.location.toString()).searchParams;

  // Merge values from query params with initialState
  const mergedInitialState: LocalState = {
    filters: {
      status: searchParams.get('status') || initialState.filters.status,
      accountNumber:
        searchParams.get('accountNumber') || initialState.filters.accountNumber
    },
    sort: searchParams.get('sort') || initialState.sort,
    includeCursor: initialState.includeCursor,
    cursorBefore: searchParams.get('cursorBefore') || initialState.cursorBefore,
    cursorAfter: searchParams.get('cursorAfter') || initialState.cursorAfter
  };

  const [state, dispatch] = useReducer(reducer, mergedInitialState);

  const queryParams = useMemo(() => {
    return {
      accountNumber: state.filters.accountNumber,
      status: state.filters.status,
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore,
      sort: state.sort
    };
  }, [state]);

  return {
    state,
    queryParams,
    dispatch,
    reset,
    setSort,
    updateCursorBefore,
    updateCursorAfter,
    updateFilters
  };
}
