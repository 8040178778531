import { formatDate } from 'utils/dayjs';

import { payoutStates } from './payout';

export interface IReasonCode {
  id: string;
  symbol: string;
  reason: string;
  kind: string;
  group: string;
  external_message: string;
  protected: boolean;
}

export type SupportedMetaAttrs = 'requestedExecutionDate';

export type Metadata = { [key in SupportedMetaAttrs]?: string };
export const WAITING_FOR_FUTURE_EXECUTION_SYMBOL = 'waiting_future_execution';

export class ReasonCode {
  public id: string;
  public symbol: string;
  public reason: string;
  public kind: string;
  public group: string;
  public external_message: string;
  public protected: boolean;
  public payoutStatus?: string;
  public meta?: Metadata;

  static DEFAULT_THEME = 'bg-inpay-red-200';

  constructor(
    attributes: IReasonCode,
    payoutStatus?: string,
    meta: Metadata = {}
  ) {
    this.id = attributes.id;
    this.payoutStatus = payoutStatus;
    this.symbol = attributes.symbol;
    this.reason = attributes.reason;
    this.kind = attributes.kind;
    this.group = attributes.group;
    this.external_message = attributes.external_message;
    this.protected = attributes.protected;
    this.meta = meta;
  }

  /**
   * Returns the reason code label. In the case of the waiting for pending
   * execution the label might change since it can contain the date of the
   * payout execution in it if it is provided as meta data.
   */
  label(): string {
    if (!this.meta) return this.external_message;

    const { requestedExecutionDate } = this.meta;

    if (this.hasWaitingForFutureExecutionSymbol() && requestedExecutionDate) {
      return `Payment will be executed on ${
        (formatDate(requestedExecutionDate), 'utc')
      }`;
    }

    // Leaking case, identical to first short-circuit
    return this.external_message;
  }

  hasWaitingForFutureExecutionSymbol(): boolean {
    return this.symbol === WAITING_FOR_FUTURE_EXECUTION_SYMBOL;
  }

  visualTheme(): string {
    return (
      this.symbolSpecificTheme() ||
      this.statusSpecificTheme() ||
      ReasonCode.DEFAULT_THEME
    );
  }

  private symbolSpecificTheme(): string | undefined {
    return payoutStates.find((s) => this.symbol === s.id)?.style.color;
  }

  private statusSpecificTheme(): string | void {
    if (this.payoutStatus) {
      return payoutStates.find((s) => this.payoutStatus === s.id)?.style.color;
    }
  }
}
