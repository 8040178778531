import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';
import { PayoutsFiltersLabels } from '@organisms/PayoutsFiltersLabels/PayoutsFiltersLabels';

import { useFetchPayoutsQuery } from 'redux/api/payouts/payoutsApi';
import { useGetReasonCodesQuery } from 'redux/api/payouts/payoutsStatsApi';
import { LocalState } from 'redux/slices/pages.payoutsSlice';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { PayoutDetails } from 'components/pages/Payouts/Details/PayoutDetails';
import { FiltersSection } from 'components/pages/Payouts/FiltersSection';
import { PayoutsHeader } from 'components/pages/Payouts/PayoutsHeader';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';
import { TableLayout } from 'components/ui/templates/TableLayout';

import { PAYOUT_REQUEST_TABLE_TEST_ID } from './constants';
import { stateToRequestParams, useLocalState } from './localState';
import { usePayoutColumns } from './payoutColumns';

const routes = [
  { index: true, element: <FiltersSection /> },
  { path: ':payoutId', element: <PayoutDetails /> }
];

export const PayoutsContent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { '*': payoutId } = useParams();
  const [pagination, setPagination] = useState<PaginatorProps>();
  const payoutColumnDefinitions = usePayoutColumns();

  const {
    state,
    tableFilters,
    queryParams: localQueryParams,
    resetState,
    updateSort,
    updateFilters,
    updateCursorAfter,
    updateCursorBefore
  } = useLocalState();

  const removeFilterLabel = ({ type }: { type: string }) => {
    switch (type) {
      case 'state':
        updateFilters({
          state: undefined,
          reasonCode: undefined
        });
        break;
      case 'reasonCode':
        updateFilters({
          reasonCode: undefined
        });
        break;
    }
  };

  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);

    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  const { filters: globalFilters, queryParams: globalQueryParams } =
    useGlobalFilters();

  const {
    data: { data, meta } = {},
    isFetching,
    error
  } = useFetchPayoutsQuery(
    stateToRequestParams({ ...state, ...globalFilters })
  );

  const { data: { data: reasonCodes } = {}, isFetching: fetchingReasonCodes } =
    useGetReasonCodesQuery(
      stateToRequestParams({ ...state, ...globalFilters })
    );

  const payoutRequests = data || [];

  const sortPayouts = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');

      if (sortingParams != state.sort) {
        updateSort(sortingParams);
      }
    },
    [state.sort]
  );

  useUpdateEffect(() => {
    const queryParams = prepareSearchParams(location.search, {
      ...localQueryParams,
      ...globalQueryParams
    }).toString();
    const url = `${location.pathname}${queryParams ? `?${queryParams}` : ''}`;
    updateUrl(url);
  }, [payoutId, localQueryParams, state]);

  useUpdateEffect(() => {
    resetState();
    if (payoutId) {
      navigate('.');
    }
  }, [globalQueryParams]);

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  const nextPage = () => {
    updateCursorAfter(meta?.cursor_next);
  };

  const prevPage = () => {
    updateCursorBefore(meta?.cursor_prev);
  };

  const onRowClick = (_event: MouseEvent<HTMLElement>, rowData: any) => {
    if (rowData.inpay_unique_reference == payoutId) {
      navigate({ pathname: '.', search: location.search });
    } else {
      navigate({
        pathname: rowData.inpay_unique_reference,
        search: location.search
      });
    }
  };

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const prefilledFilters = {
      state: undefined,
      accountNumber: undefined,
      reasonCode: undefined,
      currency: undefined
    };

    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      prefilledFilters
    );

    updateFilters(updatedFilters);
  };

  return (
    <TableLayout header={<PayoutsHeader />}>
      <DetailedLayout routes={routes}>
        {error && 'error' in error && (
          <div
            className="flex h-screen items-center justify-center"
            role="alert"
          >
            <div>{error.error}</div>
          </div>
        )}
        <DataTable
          columns={payoutColumnDefinitions}
          isFetching={isFetching || fetchingReasonCodes}
          name="Payout requests"
          topBar="name-with-count"
          currentRow={{
            value: payoutId,
            field: 'inpay_unique_reference'
          }}
          total={meta?.total}
          hasData={meta?.has_payouts}
          data={payoutRequests}
          onSortingChange={sortPayouts}
          onFilteringChange={onFilteringChange}
          initialSorting={sorting}
          filters={tableFilters}
          onRowClick={onRowClick}
          pagination={{
            ...pagination,
            onClickNext: nextPage,
            onClickPrev: prevPage
          }}
          noDataMessage={
            <NoDataFound title="No payout requests found" className="mt-1">
              We have yet to receive any withdrawals requests.
            </NoDataFound>
          }
          data-testid={PAYOUT_REQUEST_TABLE_TEST_ID}
        />
        <PayoutsFiltersLabels
          filters={state.filters}
          reasonCodes={reasonCodes}
          onRemoveClick={removeFilterLabel}
          className="absolute right-6 top-3.5"
        />
        {isFetching && <LoadingOverlay />}
      </DetailedLayout>
    </TableLayout>
  );
};
