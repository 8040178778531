import { encodeQueryParams } from 'utils/url';

import { customerApi } from '../customerApi';

export interface FXRateRequest {
  organisationId: string;
  buyCurrency: string;
  payCurrency: string;
}

export interface FXRateResponse {
  data: FXRate;
}

export interface FXRate {
  buy_currency: string;
  pay_currency: string;
  exchange_rate: string;
  utc_time: string;
}

// NOTE (LTJ): This api gives us pricing related information
//  Aggregating external currency exchange rates with out markup.
export const fxApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFXRate: builder.query<FXRateResponse, FXRateRequest>({
      query: (params) =>
        `/v1/fx/organisations/${
          params.organisationId
        }/exchange_rate?${encodeQueryParams({
          buy_currency: params.buyCurrency,
          pay_currency: params.payCurrency
        })}`
    })
  })
});

export const { useFetchFXRateQuery } = fxApi;
