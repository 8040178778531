import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle
} from '@radix-ui/react-alert-dialog';

import { ButtonProps } from '@atoms/buttons/Button';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  content: string;
  title: string;
  yesLabel: string;
  noLabel: string;
  loading: boolean;
  option?: ButtonProps['option'];
}

export const ConfirmationDialog = ({
  isOpen,
  onCancel,
  onConfirm,
  title,
  content,
  yesLabel,
  noLabel,
  loading,
  option
}: Props) => {
  return (
    <AlertDialog open={isOpen}>
      <AlertDialogPortal>
        <AlertDialogOverlay
          className="fixed inset-0 bg-inpay-green-primary-1000/90"
          onClick={(e) => e.stopPropagation()}
        />
        <AlertDialogContent className="fixed left-[50%] top-[50%] w-80 translate-x-[-50%] translate-y-[-50%] rounded-lg bg-inpay-gray-secondary-600 shadow-[0_0_6px_#155C56]">
          <AlertDialogTitle className="flex h-12 items-center rounded-t-lg bg-white px-6 text-sm font-medium shadow-plain-bottom-sm">
            {title}
          </AlertDialogTitle>
          <AlertDialogDescription className="p-6">
            {content}
          </AlertDialogDescription>
          {loading ? (
            <div className="flex justify-center rounded-b-lg bg-white p-4">
              <RollingLoader ariaLabel="confirmation-dialog-loading" />
            </div>
          ) : (
            <div className="flex w-full gap-4 px-6 pb-6">
              <AlertDialogCancel asChild>
                <SecondaryButton
                  size="large"
                  className="w-full shadow-plain"
                  onClick={() => onCancel()}
                >
                  {noLabel}
                </SecondaryButton>
              </AlertDialogCancel>
              <AlertDialogAction asChild>
                <PrimaryButton
                  size="large"
                  className="w-full shadow-plain"
                  onClick={() => {
                    onConfirm();
                  }}
                  option={option}
                >
                  {yesLabel}
                </PrimaryButton>
              </AlertDialogAction>
            </div>
          )}
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
