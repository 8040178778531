import { BadgeStyleParams } from 'components/ui/atoms/badges/badgeStyle';

import { type Reviewer } from './ApprovalWorkFlow';
import { IReasonCode } from './reasonCode';

// @mai: TODO: use PayoutStatus when available as type and rename const to PAYOUT_STATUSES
export const payoutStates: Array<PayoutState> = [
  {
    id: 'received',
    value: 'received',
    order: 1,
    label: 'Received',
    style: {
      color: 'bg-inpay-gray-primary-400',
      activeColor: 'bg-inpay-gray-primary-600',
      hover: 'hover:bg-inpay-gray-primary-600'
    }
  },
  {
    id: 'processing',
    value: 'processing',
    order: 2,
    label: 'Processing',
    style: {
      color: 'bg-inpay-blue-400',
      activeColor: 'bg-inpay-blue-600',
      hover: 'hover:bg-inpay-blue-600'
    }
  },
  {
    id: 'rejected',
    value: 'rejected',
    order: 3,
    label: 'Rejected',
    style: {
      color: 'bg-inpay-red-400',
      activeColor: 'bg-inpay-red-600',
      hover: 'hover:bg-inpay-red-600'
    }
  },
  {
    id: 'pending',
    value: 'pending',
    order: 4,
    label: 'Pending',
    style: {
      color: 'bg-inpay-purple-400',
      activeColor: 'bg-inpay-purple-600',
      hover: 'hover:bg-inpay-purple-600'
    }
  },
  {
    id: 'action_required',
    value: 'action_required',
    order: 5,
    label: 'Action Required',
    style: {
      color: 'bg-inpay-yellow-400',
      activeColor: 'bg-inpay-yellow-600',
      hover: 'hover:bg-inpay-yellow-600'
    }
  },
  {
    id: 'completed',
    value: 'completed',
    order: 6,
    label: 'Completed',
    style: {
      color: 'bg-inpay-green-secondary-light-600',
      activeColor: 'bg-inpay-green-secondary-light-800',
      hover: 'hover:bg-inpay-green-secondary-light-800'
    }
  },
  {
    id: 'returned',
    value: 'returned',
    order: 7,
    label: 'Returned',
    style: {
      color: 'bg-inpay-red-400',
      activeColor: 'bg-inpay-red-600',
      hover: 'hover:bg-inpay-red-600'
    }
  }
];

// @mai: TODO: rename this to PayoutStatus
export type PayoutState = {
  id: string;
  value: string;
  order: number;
  label: string;
  style: BadgeStyleParams;
};

// @mai: TODO: rename this to PayoutStatusBadge
export interface PayoutStateBadge {
  class: string;
  text: string;
}

export interface PayoutData {
  id: number;
  created_at?: string;
  end_to_end_id?: string;
  inpay_unique_reference?: string;
  amount: number;
  currency: string;
  debtor_account: string;
  execution_date: null;
  state: PayoutState; // @mai: TODO: rename this to PayoutStatus - backend needs update
}

export type PayoutDraftData = Omit<
  PayoutData,
  'inpay_unique_reference' | 'state'
> & {
  created_by: string;
  approval_status: string;
  state: string;
};

export interface PayoutError {
  pointer: string;
  details: Array<string>;
}

export type PayoutErrors = Record<string, Array<PayoutError>>;

export interface PayoutDetailData {
  id: string;
  order?: number;
  created_at?: string;
  end_to_end_id?: string;
  inpay_unique_reference?: string;
  amount: number;
  currency: string;
  status: PayoutState; // @mai: TODO: use PayoutStatus when available
  ultimate_debtor: {
    full_name: string;
    birthdate: string;
    address: string;
    city: string;
    postal_code: string;
    email: string;
    mobile_number: string;
    country_code: string;
  };
  creditor: {
    full_name: string;
    birthdate: string;
    address: string;
    city: string;
    postal_code: string;
    email: string;
    mobile_number: string;
    country_code: string;
  };
  creditor_account: {
    iban: string;
    account_number: string;
    account_number_type: null;
    bank_bic: string;
    bank_name: string;
    bank_clearing_system_code: number;
    bank_clearing_system_id: number;
    bank_branch_id: number;
    bank_branch_name: string;
    country_code: string;
  };
  debtor_account: {
    currency: string;
    number: string;
  };
  local_instrument: string;
  purpose_code: string;
  status_transitions: Array<PayoutStatusTransition>;
  timestamp: string;
  reason_codes: Array<IReasonCode>;
  requested_execution_date: string;
  errors: PayoutErrors;
  // @mai: we are not sure how this will used
  payment_request_state_reason_codes?: Array<any>;
}

export interface PayoutDraftDetailData {
  approval_status: string;
  creator_id: number;
  amount: string;
  // optional because might come from different sources
  manual_payout_id?: string;
  batch_id?: string;
  id: string;
  creditor: {
    birthdate: string;
    address_lines: string;
    city: string;
    country_code: string;
    name: string;
    postcode: string;
  };
  creditor_account: {
    bank_bic: string;
    bank_branch_id: number;
    bank_clearing_system_code: number;
    country_code: string;
    iban: string;
    type: string;
  };
  currency_code: string;
  debtor: {
    name: string;
  };
  debtor_account: {
    id: string;
    scheme_name: string;
  };
  end_to_end_id?: string;
  local_instrument: string;
  state: string;
  ultimate_debtor: {
    address_lines: string;
    city: string;
    country_code: string;
    name: string;
    postcode: string;
  };
  approvals?: Reviewer[];
  rejections?: Reviewer[];
}

export interface PayoutStatusTransition {
  timestamp: string;
  name: string;
}

// Payout Creation draft types
export interface PayoutDraft {
  payout_request: SectionDefinition;
  ultimate_debtor: SectionDefinition;
  creditor: SectionDefinition;
  creditor_account: SectionDefinition;
  product: Record<string, FieldDefinition>;
}

export const draftSections = [
  'creditor',
  'creditor_account',
  'payout_request',
  'ultimate_debtor'
] as const;

export type DraftSection = (typeof draftSections)[number];

export interface FieldDefinition {
  ui_field: 'text' | 'datepicker';
  value: string | number;
  errors: string[];
}
export type SectionDefinition = Record<string, FieldDefinition>;
export type ProductSectionDefinition = {
  local_instrument: string;
  product_id: string | number;
  schema_id: string | number;
};

export type PayoutFieldsDefinition = Record<DraftSection, SectionDefinition> & {
  product: ProductSectionDefinition;
};

export type PayoutTableRow = PayoutData & {
  idReference?: {
    id?: string;
    reference?: string;
  };
  amountWithCurrency?: {
    amount: number;
    currency: string;
  };
};
