import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { isEmpty } from 'utils/isEmpty';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';
import { PayoutsFiltersLabels } from '@organisms/PayoutsFiltersLabels/PayoutsFiltersLabels';

import { useFetchPayoutsQuery } from 'redux/api/payouts/payoutsApi';
import { useGetReasonCodesQuery } from 'redux/api/payouts/payoutsStatsApi';
import { LocalState } from 'redux/slices/pages.payoutsSlice';

import { type PayoutData } from 'models/payout';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { PayoutDetails } from 'components/pages/Payouts/Details/PayoutDetails';
import { FiltersSection } from 'components/pages/Payouts/FiltersSection';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';

import { PAYOUT_REQUEST_TABLE_TEST_ID, TRANSLATIONS } from '../constants';
import { stateToRequestParams, useLocalState } from '../localState';
import { usePayoutColumns } from '../payoutColumns';

const routes = [
  { index: true, element: <FiltersSection /> },
  { path: ':payoutId', element: <PayoutDetails /> }
];
const clearedFilters = {
  state: undefined,
  accountNumber: undefined,
  reasonCode: undefined,
  currency: undefined
};

export const ManualPayoutsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { '*': payoutId } = useParams();
  const [pagination, setPagination] = useState<PaginatorProps>();
  const payoutColumnDefinitions = usePayoutColumns();

  const {
    state,
    tableFilters,
    updateSort,
    updateFilters,
    updateCursorAfter,
    updateCursorBefore
  } = useLocalState();

  const { filters: globalFilters, setDateRange } = useGlobalFilters();

  const {
    data: { data, meta } = {},
    isFetching,
    error
  } = useFetchPayoutsQuery(
    stateToRequestParams({ ...state, ...globalFilters })
  );

  const { data: { data: reasonCodes } = {}, isFetching: fetchingReasonCodes } =
    useGetReasonCodesQuery(
      stateToRequestParams({ ...state, ...globalFilters })
    );

  const sortPayouts = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');

      if (sortingParams != state.sort) {
        updateSort(sortingParams);
      }
    },
    [state.sort]
  );

  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);

    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  const isDefaultFilters = useMemo(
    () => !isEmpty(tableFilters) && globalFilters.dateRange.type === 'd30',
    [meta, tableFilters, globalFilters]
  );

  const onResetFilters = useCallback(() => {
    updateFilters({
      state: undefined,
      accountNumber: undefined,
      reasonCode: undefined,
      currency: undefined
    });
    setDateRange({ type: 'd30' });
  }, [updateFilters, setDateRange]);

  const removeFilterLabel = ({ type }: { type: string }) => {
    switch (type) {
      case 'state':
        updateFilters({
          state: undefined,
          reasonCode: undefined
        });
        break;
      case 'reasonCode':
        updateFilters({
          reasonCode: undefined
        });
        break;
    }
  };

  const onRowClick = (_event: MouseEvent<HTMLElement>, rowData: PayoutData) => {
    if (rowData.inpay_unique_reference == payoutId) {
      navigate({ pathname: '.', search: location.search });
    } else {
      navigate({
        pathname: rowData.inpay_unique_reference,
        search: location.search
      });
    }
  };

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      { ...clearedFilters }
    );
    updateFilters(updatedFilters);
  };

  const nextPage = () => {
    updateCursorAfter(meta?.cursor_next);
  };

  const prevPage = () => {
    updateCursorBefore(meta?.cursor_prev);
  };

  const payoutRequests = data || [];

  return (
    <DetailedLayout routes={routes}>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{error.error}</div>
        </div>
      )}
      <DataTable
        columns={payoutColumnDefinitions}
        isFetching={isFetching || fetchingReasonCodes}
        name="Payout requests"
        topBar="name-with-count"
        currentRow={{
          value: payoutId,
          field: 'inpay_unique_reference'
        }}
        total={meta?.total}
        hasData={(meta?.total ?? 0) > 0}
        data={payoutRequests}
        onSortingChange={sortPayouts}
        onFilteringChange={onFilteringChange}
        initialSorting={sorting}
        filters={tableFilters}
        onRowClick={onRowClick}
        pagination={{
          ...pagination,
          onClickNext: nextPage,
          onClickPrev: prevPage
        }}
        noDataMessage={
          <NoDataFound className="flex flex-col items-center gap-4">
            <p className="font-semibold">
              {meta?.has_payouts === false
                ? TRANSLATIONS.SUPER_EMPTY
                : TRANSLATIONS.EMPTY}
            </p>
            {meta?.has_payouts && !isDefaultFilters ? (
              <SecondaryButton onClick={onResetFilters}>
                {TRANSLATIONS.RESET_FILTERS}
              </SecondaryButton>
            ) : null}
          </NoDataFound>
        }
        data-testid={PAYOUT_REQUEST_TABLE_TEST_ID}
      />
      <PayoutsFiltersLabels
        filters={state.filters}
        reasonCodes={reasonCodes}
        onRemoveClick={removeFilterLabel}
        className="absolute right-6 top-3.5"
      />
      {isFetching && <LoadingOverlay />}
    </DetailedLayout>
  );
};
