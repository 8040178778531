import { ArrowRightOnRectangleIcon as LoginIcon } from '@heroicons/react/24/outline';

export const LoggingIn = () => {
  return (
    <div className="bg-inpay-gray-primary-200">
      <div className="flex h-screen w-full flex-col items-center justify-center bg-inpay-gray-primary-200 text-black">
        <div className="mb-4 flex self-center">
          <LoginIcon className="stroke-black" width="40" height="40" />
        </div>
        <h1 className="mb-6 text-3xl font-medium">Logging in to Inpay</h1>
        <p className="italic">Preparing your data...</p>
      </div>
    </div>
  );
};
