import { yupResolver } from '@hookform/resolvers/yup';
import { FormEventHandler, useCallback, useEffect, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { type InferType } from 'yup';

import { setFormErrors } from 'utils/formHelpers';
import { hasValue } from 'utils/hasValue';

import { selectProductStepSchema as schema } from 'validators/selectProductStep';

import { useGetLocalInstrumentsQuery } from 'redux/api/payouts/batchTemplateApi';

import { type Corridor, type LocalInstrument } from 'models/batchTemplate';
import { type ProductSectionDefinition } from 'models/payout';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { type SelectChoice, Selector } from '@molecules/Selector/Selector';

import { CancelButton } from '../ConfirmCancelDialog';
import { NEXT } from '../constants';
import { CorridorSelector } from '../fields/CorridorSelector';
import { ProductSchemaSelector } from '../fields/ProductSchemaSelector';
import { type StepProps } from '../steps';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

type Props = Omit<StepProps, 'fields'> & {
  fields?: ProductSectionDefinition;
  isFinished: boolean;
};

type FieldName = 'localInstrumentName' | 'corridorId' | 'productSchemaId';

export const SelectProductStep = ({
  onSubmit,
  fields,
  onCancel,
  isFinished
}: Props) => {
  // Data hooks
  const { data: { data: localInstruments } = {} } = useGetLocalInstrumentsQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true
    }
  );

  // Memos
  const stepData = useMemo(() => {
    if (hasValue(fields)) {
      return {
        corridorId: fields.product_id.toString(),
        localInstrument: fields.local_instrument,
        productSchemaId: fields.schema_id.toString()
      };
    }
  }, [fields]);

  // Form watchers
  const { handleSubmit, setError, formState, setValue, control } =
    useForm<Inputs>({
      mode: 'onSubmit',
      resolver: yupResolver(schema),
      defaultValues: stepData
    });

  const localInstrumentName = useWatch({
    control,
    name: 'localInstrumentName'
  });

  const corridorId = useWatch({
    control,
    name: 'corridorId'
  });

  const productSchemaId = useWatch({
    control,
    name: 'productSchemaId'
  });

  const localInstrumentsOptions: SelectChoice[] = useMemo(() => {
    return Array.isArray(localInstruments)
      ? localInstruments.map((localInstrument) => ({
          label: localInstrument.name,
          value: localInstrument.name
        }))
      : [];
  }, [localInstruments]);

  const localInstrument = useMemo(() => {
    return localInstruments?.find(
      (i: LocalInstrument) => i.name === localInstrumentName
    );
  }, [localInstruments, localInstrumentName]);

  const corridorOptions: SelectChoice[] = useMemo(() => {
    if (!localInstrument) {
      return [];
    }

    return localInstrument.products.map((i: Corridor) => ({
      label: i.label,
      value: i.id.toString()
    }));
  }, [localInstrument]);

  const isLocalInstrumentOptionsDisabled = useMemo(
    () =>
      isFinished ||
      localInstrumentsOptions.length === 0 ||
      localInstrumentsOptions.length === 1,
    [isFinished, localInstrumentsOptions.length]
  );

  // Callbacks
  const setFieldValue = useCallback(
    (fieldName: FieldName, value: string): void => {
      setValue(fieldName, value, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
    },
    [setValue]
  );

  const onLocalInstrumentChange = useCallback(
    (localInstrument: string) => {
      setFieldValue('localInstrumentName', localInstrument);
      setFieldValue('corridorId', '');
      setFieldValue('productSchemaId', '');
    },
    [setFieldValue]
  );

  const onCorridorChange = useCallback(
    (corridorId: string) => {
      setFieldValue('corridorId', corridorId);
    },
    [setFieldValue]
  );

  const onProductSchemaChange = useCallback(
    (productSchemaId: string) => {
      setFieldValue('productSchemaId', productSchemaId);
    },
    [setFieldValue]
  );

  const onError = useCallback(
    (error: any, fields: string[]) => {
      setFormErrors({ error, setError, fields });
    },
    [setError]
  );

  // Effects
  useEffect(() => {
    if (!localInstrumentName && localInstrumentsOptions?.length === 1) {
      setFieldValue('localInstrumentName', localInstrumentsOptions[0].value);
    }
  }, [localInstrumentsOptions, localInstrumentName, setFieldValue]);

  // Handlers
  const onSubmitHandle: FormEventHandler<HTMLFormElement> = async (
    event: any
  ) => {
    try {
      await handleSubmit(async (data) => {
        await onSubmit(data);
      })(event);
    } catch (error: any) {
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  return (
    <div className="flex justify-center pb-8">
      <form onSubmit={onSubmitHandle} autoComplete="off" className="w-80">
        <div className="mb-4 flex flex-col">
          <label className="mb-0.5 pl-2 text-xs">Transfer type</label>
          <Selector
            selectChoices={localInstrumentsOptions}
            onChange={onLocalInstrumentChange}
            name="localInstrument"
            value={localInstrumentName}
            disabled={isLocalInstrumentOptionsDisabled}
          />
        </div>
        <div className="mb-4 flex flex-col">
          <CorridorSelector
            options={corridorOptions}
            onChange={onCorridorChange}
            name="corridorId"
            isActive={isFinished}
            value={corridorId}
          />
        </div>
        <div className="mb-10 flex flex-col">
          <ProductSchemaSelector
            corridorId={corridorId}
            name="productSchemaId"
            value={productSchemaId}
            isActive={isFinished}
            onError={onError}
            onChange={onProductSchemaChange}
          />
        </div>
        <div className="flex gap-4">
          <CancelButton onAction={onCancel} />
          <PrimaryButton
            size="large"
            type="submit"
            className="w-full"
            disabled={
              isFinished || !formState.isValid || formState.isSubmitSuccessful
            }
          >
            {NEXT}
          </PrimaryButton>
        </div>
        {formState.errors.base && (
          <div
            role="alert"
            className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm first-letter:capitalize"
            style={{
              filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
            }}
          >
            {formState.errors.base.message}
          </div>
        )}
      </form>
    </div>
  );
};
