import { Switch, SwitchThumb } from '@radix-ui/react-switch';
import { ForwardedRef, forwardRef } from 'react';

export interface SwitchFieldProps {
  disabled?: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const SwitchField = forwardRef<HTMLButtonElement, SwitchFieldProps>(
  ({ onChange, ...props }, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Switch
        ref={ref}
        className="relative h-8 w-16 overflow-hidden rounded-full bg-white drop-shadow-plain"
        onCheckedChange={onChange}
        {...props}
      >
        <SwitchThumb className="group m-1 flex h-6 items-center text-xs text-inpay-green-primary-1000 transition-transform radix-disabled:text-inpay-gray-primary-1000 radix-state-checked:translate-x-0 radix-state-unchecked:-translate-x-8">
          <span className="w-8 shrink-0">YES</span>
          <span className="block size-6 shrink-0 rounded-full bg-inpay-green-primary-1000 group-radix-disabled:bg-inpay-gray-primary-1000" />
          <span className="w-8 shrink-0">NO</span>
        </SwitchThumb>
      </Switch>
    );
  }
);

SwitchField.displayName = 'SwitchField';
