import { GeneratedWithdrawalData } from 'models/withdrawal';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { CheckCircleIcon } from '@atoms/Icon';

interface Props {
  withdrawalRequestData: GeneratedWithdrawalData;
  onDialogClose: () => void;
}
export const WithdrawalSuccess = ({
  withdrawalRequestData,
  onDialogClose
}: Props) => {
  return (
    <div className="rounded-b-lg bg-white px-6 py-10">
      <div className="flex flex-col items-center gap-6">
        <CheckCircleIcon className="w-8" />
        <div className="text-lg font-medium">
          Withdraw funds request has been sent
        </div>
        <div className="text-lg font-medium">
          Reference: {withdrawalRequestData.reference}
        </div>
        <div className="text-sm">
          The request will be sent for processing after review.
        </div>
      </div>
      <PrimaryButton
        className="mt-10 w-full"
        onClick={() => {
          onDialogClose();
        }}
      >
        Done
      </PrimaryButton>
    </div>
  );
};
