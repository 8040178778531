import { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  value?: string;
  label: string;
  className?: string;
}

export const ShowDataItem = ({ value, label, className, children }: Props) => {
  return (
    <div className={className}>
      <p className="text-xs">{label}</p>
      {children ? children : <p className="font-medium">{value}</p>}
    </div>
  );
};
