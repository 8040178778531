import cn from 'classnames';

import { ApprovalStatus } from 'models/ApprovalWorkFlow';

export interface BadgeStyleParams {
  color: string;
}

interface Badge {
  label: string;
  style: BadgeStyleParams;
}

export const approvalStatuses: Record<ApprovalStatus, Badge> = {
  completed: {
    label: 'Approved',
    style: {
      color: 'bg-inpay-green-secondary-light-600'
    }
  },
  waiting_review: {
    label: 'Pending Approval',
    style: {
      color: 'bg-inpay-orange-400'
    }
  },
  rejected: {
    label: 'Declined',
    style: {
      color: 'bg-inpay-red-400'
    }
  }
};

export interface ApprovalBadgeProps {
  status: ApprovalStatus;
}

export const ApprovalBadge = ({ status }: ApprovalBadgeProps) => {
  const approvalStatus = approvalStatuses[status];

  return (
    <span
      className={cn(
        'w-fit max-w-full gap-1 overflow-hidden truncate rounded-lg px-2 py-1 text-sm leading-4',
        `${approvalStatus?.style.color}`
      )}
    >
      {approvalStatus?.label ?? status}
    </span>
  );
};
