import {
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
  ArrowRightStartOnRectangleIcon
} from '@heroicons/react/24/outline';
import Select from 'antd/es/select';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { useLogoutMutation } from 'redux/api/authenticationApi';
import {
  useFetchOrganisationsQuery,
  useSetDefaultOrganisationMutation
} from 'redux/api/organisations/organisationsApi';
import { logout } from 'redux/slices/authSlice';

export const OrgDropDown = ({
  currentOrganisationId
}: {
  currentOrganisationId?: string;
}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [logoutQuery] = useLogoutMutation();
  const setSearchParams = useSearchParams()[1];

  const handleLogout = async () => {
    await logoutQuery().unwrap();
    setSearchParams('user_logout');
    dispatch(logout());
  };

  const { data: organisationsData, isSuccess } = useFetchOrganisationsQuery();

  const organisationOptions = useMemo(() => {
    return (
      organisationsData?.organisations?.map((org) => {
        return { value: org.id, label: org.name, type: 'org' };
      }) ?? []
    );
  }, [organisationsData?.organisations]);

  const [setDefaultQuery] = useSetDefaultOrganisationMutation();

  if (!isSuccess) {
    return null;
  }

  if (organisationsData.organisations.length < 1) {
    return null;
  }

  return (
    <Select
      className="min-h-[42px] w-full min-w-[200px] rounded-3xl border-inpay-green-secondary-light-1000"
      size="large"
      options={organisationOptions}
      defaultValue={organisationOptions.find(
        (org) => org.value.toString() === currentOrganisationId
      )}
      onSelect={(value, option) => {
        navigate(`/org/${value}`);
        setDefaultQuery(option.value);
      }}
      listHeight={400}
      suffixIcon={<ChevronDownIcon width={20} height={20} />}
      dropdownRender={(menu) => (
        <>
          {menu}
          <div className="flex flex-col">
            <Link to={`${window.location.origin}/docs`} target="_blank">
              <div className="flex flex-row justify-between rounded-lg p-3 hover:cursor-pointer hover:bg-inpay-gray-primary-200">
                API Reference
                <ArrowTopRightOnSquareIcon height={20} width={20} />
              </div>
            </Link>
            <div
              className="flex flex-row justify-between rounded-lg p-3 hover:cursor-pointer hover:bg-inpay-gray-primary-200"
              onClick={handleLogout}
            >
              Log out
              <ArrowRightStartOnRectangleIcon height={20} width={20} />
            </div>
          </div>
        </>
      )}
    />
  );
};
