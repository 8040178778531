import { Square2StackIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

interface Props {
  textToCopy: string;
}

export const CopyButton = ({ textToCopy }: Props) => {
  const [copyStatus, setCopyStatus] = useState<string | null>(null);

  const copyToClipboard = async (textToCopy: string) => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setCopyStatus('Copied!');
      setTimeout(() => {
        setCopyStatus(null);
      }, 2000);
    } catch (error) {
      setCopyStatus('Error copying text to clipboard');
      console.error('Error copying text to clipboard:', error);
    }
  };

  return (
    <div
      className="group relative flex cursor-pointer items-center justify-between"
      onClick={() => copyToClipboard(textToCopy)}
    >
      <button className="size-6 text-inpay-green-primary-800">
        <Square2StackIcon />
      </button>
      <div className="invisible absolute bottom-full right-0 flex grow items-center justify-end whitespace-nowrap rounded-md bg-inpay-black-primary-1000 p-2 text-xs text-white group-hover:visible">
        {copyStatus ? copyStatus : 'Click to copy'}
      </div>
    </div>
  );
};
