import { type ColumnFiltersState } from '@tanstack/react-table';
import { useEffect, useMemo, useReducer, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { type DateRangeType } from 'models/dateRange';

import { FILTER_PARAM_NAMES } from './constants';

export type PaginationState = {
  includeCursor?: boolean;
  cursorBefore?: string;
  cursorAfter?: string;
};

type DateRange = {
  from?: string;
  to?: string;
  type?: DateRangeType;
};

export type LocalState = {
  pagination: PaginationState;
  sort: string;
  filters: ColumnFiltersState;
  dateRange: DateRange;
};

type Action =
  | { type: 'reset' }
  | { type: 'setSort'; value: string }
  | { type: 'setFilters'; value: ColumnFiltersState }
  | { type: 'setCursorBefore'; value?: string }
  | { type: 'setCursorAfter'; value?: string };

function reducer(state: LocalState, action: Action): LocalState {
  switch (action.type) {
    case 'setFilters':
      return {
        ...state,
        filters: action.value,
        pagination: {}
      };
    case 'setSort':
      return {
        ...state,
        sort: action.value,
        pagination: {}
      };
    case 'setCursorBefore':
      return {
        ...state,
        pagination: {
          cursorBefore: action.value,
          cursorAfter: undefined,
          includeCursor: undefined
        }
      };
    case 'setCursorAfter':
      return {
        ...state,
        pagination: {
          cursorAfter: action.value,
          cursorBefore: undefined,
          includeCursor: undefined
        }
      };
    case 'reset':
      return {
        sort: '-date',
        pagination: {},
        filters: [],
        dateRange: {}
      };
  }
}

const reset = (): Action => ({ type: 'reset' });
const setFilters = (value: ColumnFiltersState): Action => ({
  type: 'setFilters',
  value
});
const setCursorBefore = (value: string): Action => ({
  type: 'setCursorBefore',
  value
});
const setCursorAfter = (value: string): Action => ({
  type: 'setCursorAfter',
  value
});

export function useLocalState(defaultState: Partial<LocalState>) {
  const [searchParams] = useSearchParams();
  const [initialState] = useState<LocalState>({
    sort: defaultState.sort || searchParams.get('sort') || '-date',
    filters: defaultState.filters || [],
    dateRange: defaultState.dateRange || {},
    pagination: {
      includeCursor: defaultState.pagination?.includeCursor || undefined,
      cursorBefore:
        defaultState.pagination?.cursorBefore ||
        searchParams.get('cursorBefore') ||
        undefined,
      cursorAfter:
        defaultState.pagination?.cursorAfter ||
        searchParams.get('cursorAfter') ||
        undefined
    }
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const queryParams = useMemo(() => {
    return {
      cursorAfter: state.pagination?.cursorAfter,
      cursorBefore: state.pagination?.cursorBefore,
      sort: state.sort,
      account_number: state.filters.find(
        (filter) => filter.id === FILTER_PARAM_NAMES.accountNumber
      )?.value
    };
  }, [state]);

  useEffect(() => () => dispatch(reset()), []);

  return {
    state,
    queryParams,
    dispatch,
    reset,
    setFilters,
    setCursorBefore,
    setCursorAfter
  };
}
