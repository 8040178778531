export const ROLENAMES: Record<string, string> = {
  operations_member_reviewer: 'Operations member / Approver',
  admin_reviewer: 'Admin / Approver'
};

export const convertRoleToLabel = (name: string) => {
  switch (name) {
    case 'admin_reviewer':
      return 'Admin / Approver';
    case 'operations_member_reviewer':
      return 'Operations member / Approver';
    case 'admin':
      return 'Admin';
    case 'operations_member':
      return 'Operations member';
    default:
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
};

export const REVIEWER_ROLES = ['admin_reviewer', 'operations_member_reviewer'];
