import { InformationCircleIcon } from '@heroicons/react/24/outline';

export interface ErrorPanelProps {
  message: string;
}

// TODO (LTJ): I added information circle icon for https://inpay.atlassian.net/browse/CBP-4086
//  But we should have different panels, icons, colors based on info/error/warning
export const ErrorPanel = ({ message }: ErrorPanelProps) => {
  return (
    <div role="alert" className="rounded-lg bg-white p-4 shadow-plain-lg">
      <div className="flex gap-2">
        <InformationCircleIcon className="w-16 text-inpay-green-primary-1000" />
        {message}
      </div>
    </div>
  );
};
