import { useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { useIsFirstRender, useUpdateEffect } from 'usehooks-ts';

import dayjs, { API_DATE_FORMAT, DATE_FORMAT } from 'utils/dayjs';
import { prepareSearchParams } from 'utils/url';

import { AccountDetails } from '@organisms/AccountDetails';
import { DataTable, PaginatorProps } from '@organisms/DataTable';

import {
  useFetchAccountStatementQuery,
  useFetchVirtualAccountsQuery
} from 'redux/api/accounts/virtualAccountApi';

import { PayoutDetailData } from 'models/payout';
import { TransactionData } from 'models/transaction';
import { VirtualAccount } from 'models/virtualAccount';

import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { ErrorPanel } from '@atoms/ErrorPanel';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { TransactionDetails } from 'components/pages/TransactionDetails';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';
import { TableLayout } from 'components/ui/templates/TableLayout';

import { AccountTransactionsHeader } from './AccountTransactionsHeader';
import { ACCOUNT_TRANSACTION_TABLE_TEST_ID, ERROR_MESSAGE } from './constants';
import { accountsStateToParams, useLocalState } from './localState';
import { useTransactionsColumns } from './transactionsColumns';

const updateUrl = (url: string) => history.replaceState(null, '', url);

export const AccountTransactions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const columnDefinitions = useTransactionsColumns();
  const {
    organisationId,
    virtual_account_number: accountNumber,
    '*': transactionPath
  } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');
  if (!accountNumber) throw new Error('Missing account number');

  const [pagination, setPagination] = useState<PaginatorProps>();
  const [goingToIndividualTransaction, setGoingToIndividualTransaction] =
    useState<boolean>(false);

  const SINGLE_TRANSACTION_PATH_PREFIX = 'transactions/';

  // TODO: This one feels fragile, just respecting what I found, but, we could
  // investigate if we can just rely on the normal routing. ATT. @dmuneras
  const transactionId = transactionPath?.replace(
    SINGLE_TRANSACTION_PATH_PREFIX,
    ''
  );

  const currentRowId = [transactionId, searchParams.get('type')].join();

  const isFirst = useIsFirstRender();

  useEffect(() => {
    setGoingToIndividualTransaction(!!transactionId);
  }, [isFirst]);

  const {
    state,
    queryParams,
    dispatch,
    reset,
    setCursorAfter,
    setCursorBefore
  } = useLocalState({
    cursorAfter: transactionId,
    includeCursor: !!transactionId
  });

  const {
    filters,
    queryParams: globalQueryParams,
    setDateRange
  } = useGlobalFilters();

  useUpdateEffect(() => {
    updateUrl(
      `${location.pathname}?${prepareSearchParams(location.search, {
        ...queryParams,
        ...globalQueryParams
      }).toString()}`
    );
  }, [transactionId, queryParams]);

  useUpdateEffect(() => dispatch(reset()), [globalQueryParams]);

  const requestParams = accountsStateToParams({
    accountNumber,
    organisationId,
    ...state,
    ...filters
  });

  const { data, isFetching, error } = useFetchAccountStatementQuery({
    ...requestParams
  });

  const { data: { data: virtualAccountsData } = {} } =
    useFetchVirtualAccountsQuery({ organisationId });

  const nextPage = () => {
    if (data?.meta.cursor_next.toString())
      dispatch(setCursorAfter(data?.meta.cursor_next.toString()));
  };

  const prevPage = () => {
    if (data?.meta.cursor_prev.toString())
      dispatch(setCursorBefore(data.meta.cursor_prev.toString()));
  };

  const onRowClick = (_event: React.MouseEvent<HTMLElement>, rowData: any) => {
    if (rowData.reference) {
      if ([rowData.id, rowData.entry_type].join() == currentRowId) {
        navigate('.');
      } else {
        const queryParams = prepareSearchParams('', {
          reference: rowData.reference,
          type: rowData.entry_type,
          ...globalQueryParams
        });

        navigate(
          `${SINGLE_TRANSACTION_PATH_PREFIX}${rowData.id}?${queryParams}`
        );
      }
    }
  };

  useEffect(() => {
    if (data?.meta) {
      setPagination({
        showPrev: data.meta.has_previous_page,
        showNext: data.meta.has_next_page
      });
    }
  }, [data?.meta]);

  const onTransactionSourceLoaded = (
    transactionSourceData: PayoutDetailData
  ) => {
    const isIncludedInPage: boolean =
      data?.data?.some((transaction: TransactionData) => {
        if (transactionId) {
          return transaction.id === parseInt(transactionId, 10);
        }

        return false;
      }) || false;

    if (!goingToIndividualTransaction || isIncludedInPage) {
      return;
    }

    const fromDate = dayjs(transactionSourceData.timestamp);
    const toDate = fromDate.clone().add(30, 'day');

    setDateRange({
      type: 'custom',
      to: toDate.format(DATE_FORMAT),
      from: fromDate.format(DATE_FORMAT)
    });

    setGoingToIndividualTransaction(false);
  };

  const currentAccountData = virtualAccountsData?.find(
    (accountInfo) => accountInfo.account_number === accountNumber
  );

  const fromDate =
    requestParams.created_after || dayjs().format(API_DATE_FORMAT);
  const toDate =
    requestParams.created_before || dayjs().format(API_DATE_FORMAT);
  const currencyCode = currentAccountData?.currency_code;

  const routes = [
    {
      index: true,
      element: (
        <AccountDetails
          virtualAccountNumber={accountNumber}
          fromDate={fromDate}
          toDate={toDate}
          currencyCode={currencyCode}
        />
      )
    },
    {
      path: `${SINGLE_TRANSACTION_PATH_PREFIX}:transaction_id`,
      element: (
        <TransactionDetails
          onTransactionSourceLoaded={onTransactionSourceLoaded}
        />
      )
    }
  ];

  const virtualAccountLabel = (item: VirtualAccount) =>
    item.label || item.currency_name;

  return (
    <TableLayout
      header={
        <AccountTransactionsHeader currentAccountData={currentAccountData} />
      }
    >
      {error ? (
        <div className="flex w-full items-center justify-center">
          <ErrorPanel message={ERROR_MESSAGE} />
        </div>
      ) : (
        <DetailedLayout routes={routes}>
          <DataTable
            data-testid={ACCOUNT_TRANSACTION_TABLE_TEST_ID}
            name={
              currentAccountData
                ? virtualAccountLabel(currentAccountData)
                : 'Account Transactions'
            }
            hasData={currentAccountData?.has_transactions}
            total={data?.meta?.total}
            data={data?.data || []}
            topBar="name-with-count"
            columns={columnDefinitions}
            pagination={{
              ...pagination,
              onClickNext: nextPage,
              onClickPrev: prevPage
            }}
            onRowClick={onRowClick}
            currentRow={{
              value: currentRowId,
              field: 'uniqId'
            }}
          />
          {isFetching && <LoadingOverlay />}
        </DetailedLayout>
      )}
    </TableLayout>
  );
};
