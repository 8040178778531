import { ReactNode, createElement } from 'react';

interface Props {
  if?: boolean;
  with: (typeof createElement.arguments)[0];
  wrapperProps?: (typeof createElement.arguments)[1];
  children: NonNullable<ReactNode>;
}

export const Wrap = ({
  if: condition,
  with: wrapper,
  wrapperProps,
  children
}: Props) =>
  condition ? (
    createElement(wrapper, wrapperProps, [children])
  ) : (
    <>{children}</>
  );
