import { Navigate, Outlet, useParams } from 'react-router-dom';

import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';

import { Loading } from 'components/pages/Loading';

export const RequireBatchesEnabled = () => {
  const { organisationId } = useParams();

  const {
    data: { data: orgSettingsData } = {},
    isSuccess: orgSettingsSuccess,
    isLoading: orgSettingsLoading,
    isError: orgSettingsError
  } = useFetchOrganisationConfigurationsQuery(organisationId);

  const isBatchesPageEnabled =
    orgSettingsData?.batches_enabled !== undefined &&
    orgSettingsData.batches_enabled > 0;

  return (
    <>
      {orgSettingsLoading && <Loading text="Loading data..." />}
      {orgSettingsError && <Navigate to={`/org/${organisationId}`} replace />}
      {orgSettingsSuccess &&
        (isBatchesPageEnabled ? (
          <Outlet />
        ) : (
          <Navigate to={`/org/${organisationId}`} replace />
        ))}
    </>
  );
};
