import classNames from 'classnames';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { LoginForm } from '@organisms/LoginForm';
import { API_URL, AUTHY_DISABLED } from 'constants/environment';

import { useRedirectTo } from 'hooks/useRedirectTo';

import { AuthenticationContainer } from '@atoms/AuthenticationContainer';

export enum MessageCodes {
  MissingScope = 'MissingScope'
}

type Messages = { [code in MessageCodes]: string };

const Messages: Messages = {
  MissingScope:
    'User is missing the required scope to access the page. Please ask your administrator to review your access'
};

export const Login = () => {
  const { navigateWithRedirectTo } = useRedirectTo();
  const { state } = useLocation() as { state: { reason?: string } };
  const [searchParams] = useSearchParams();
  const [_, setIsLoggedIn] = useLocalStorage('isLoggedIn', false);

  const redirectCode = searchParams.get('redirect_code') ?? '';

  const fromDocsFlag = searchParams.get('fromDocs') ?? null;

  const onLoginSuccess = (currentUser: any) => {
    setIsLoggedIn(true);

    if (fromDocsFlag) {
      window.location.replace(`${API_URL}/docs`);
    }

    if (currentUser?.skip_mfa || AUTHY_DISABLED) {
      navigateWithRedirectTo('/login_transition', {
        state: { loggingIn: true }
      });

      return;
    }

    if (currentUser?.mfa_enabled || currentUser?.mfa_configured) {
      navigateWithRedirectTo('/mfa-verify');
    } else {
      navigateWithRedirectTo('/mfa-setup');
    }
  };

  return (
    <AuthenticationContainer>
      <div className="w-80">
        <LoginForm
          onLoginSuccess={onLoginSuccess}
          loginExpired={state?.reason === 'expired'}
        />
        {redirectCode in MessageCodes && (
          <div
            role="alert"
            className={classNames(
              'mt-2 rounded-md bg-inpay-red-200 p-3 text-sm'
            )}
            style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
          >
            {Messages[searchParams.get('redirect_code') as MessageCodes]}
          </div>
        )}
      </div>
      <Link to="/passwords/forgot" className="inpay-link mt-24 font-medium">
        Forgot Password?
      </Link>
    </AuthenticationContainer>
  );
};
