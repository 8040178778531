import { Tag } from 'antd/es';
import { ColumnType } from 'antd/es/table';

import { formatDate, formatHours } from 'utils/dayjs';
import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import {
  WithdrawalRequestsData,
  withdrawalRequestStatuses
} from 'models/withdrawal';

import { WithdrawalRequestsIcon } from '@atoms/Icon/WithdrawalRequestsIcon';

const formatter = new MoneyFormatter();

export const getColumns = (
  withdrawalRequests: WithdrawalRequestsData[]
): ColumnType<WithdrawalRequestsData>[] => {
  return [
    {
      title: '',
      dataIndex: 'icon',
      key: 'icon',
      width: 100,
      render: () => (
        <div className="flex justify-center">
          <WithdrawalRequestsIcon />
        </div>
      )
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a, b) =>
        a.created_at && b.created_at
          ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
          : 0,
      render: (createdAt: string) => {
        return (
          <div>
            {formatDate(createdAt)}
            <br />
            <strong>{formatHours(createdAt)}</strong>
          </div>
        );
      }
    },
    {
      title: 'ID/Reference',
      dataIndex: 'reference',
      key: 'reference',
      render: (reference: string, record: WithdrawalRequestsData) => (
        <div>
          {record.external_reference}
          <br />
          <strong>{reference}</strong>
        </div>
      )
    },
    {
      title: 'Account',
      dataIndex: 'debtor_account_number',
      key: 'debtor_account_number',
      align: 'right',
      render: (debtorAccountNumber, record: WithdrawalRequestsData) => (
        <div>
          {debtorAccountNumber}
          <br />
          <strong>{record.currency_code}</strong>
        </div>
      ),
      filters: withdrawalRequests
        // remove duplicates
        .filter((withdrawalRequest, index, self) => {
          return (
            index ===
            self.findIndex(
              (r) =>
                r.debtor_account_number ===
                withdrawalRequest.debtor_account_number
            )
          );
        })
        // map them to the table filters
        .map((withdrawalRequest) => {
          return {
            text: withdrawalRequest.debtor_account_number,
            value: withdrawalRequest.debtor_account_number
          };
        }),
      onFilter: (value, record) => record.debtor_account_number === value,
      filterSearch: true
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      sorter: (a, b) => a.amount - b.amount,
      render: (amount, record: WithdrawalRequestsData) => {
        const formattedAmount = formatter.format(amount);
        return (
          <div>
            {record.currency_code}
            <br />
            <strong>{formattedAmount}</strong>
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status: string) => {
        const style = withdrawalRequestStatuses.find(
          (s) => s.value === status
        )?.style;

        const tagClassName = `w-fit max-w-full px-2 py-1 ${style?.background} ${style?.hover}`;

        return (
          <Tag bordered={false} className={tagClassName}>
            {status[0].toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
      filters: withdrawalRequests
        // remove duplicates
        .filter((withdrawalRequest, index, self) => {
          return (
            index ===
            self.findIndex((r) => r.status === withdrawalRequest.status)
          );
        })
        // map them to the table filters
        .map((withdrawalRequest) => {
          return {
            text:
              withdrawalRequest.status[0].toUpperCase() +
              withdrawalRequest.status.slice(1),
            value: withdrawalRequest.status
          };
        }),
      onFilter: (value, record) => record.status === value,
      filterSearch: true
    }
  ];
};
