import produce from 'immer';

import { ErrorResponse } from 'models/errors';

export const processFormErrors = (error: ErrorResponse, fields: string[]) => {
  if (typeof error.data == 'string') {
    return {
      base: [error.data]
    };
  } else if (error.data && 'errors' in error.data) {
    const errors = {} as { [key: string]: string[] };
    error.data.errors.forEach(({ title, details, source }) => {
      const pointer =
        source?.pointer && fields.includes(source.pointer)
          ? source.pointer
          : 'base';
      errors[pointer] = errors[pointer] ?? [];
      if (details && details.length > 0) {
        errors[pointer].push(...details);
      } else {
        errors[pointer].push(title);
      }
    });
    return errors;
  } else {
    return {
      base: ['Something went wrong.']
    };
  }
};

export const modifyFormError = ({
  error,
  replacement
}: {
  error: ErrorResponse;
  replacement: {
    status?: number;
    pointer: string;
    messages: any[];
  };
}) => {
  return produce(error, (draft) => {
    if (typeof draft.data == 'string') {
      return;
    }

    if (
      draft.status == replacement.status &&
      draft.data &&
      'errors' in draft.data
    ) {
      const errorForReplace = draft.data.errors.find(
        (err) => err?.source?.pointer == replacement.pointer
      );
      if (errorForReplace) errorForReplace.details = replacement.messages;
    }
  });
};

export const setFormErrors = ({
  error,
  setError,
  fields
}: {
  error: any;
  setError: any;
  fields: string[];
}) => {
  const errors = processFormErrors(error, fields);

  Object.entries(errors).forEach(([pointer, errors]) => {
    if (fields.concat(['base']).includes(pointer)) {
      setError(
        pointer as any,
        { type: 'custom', message: errors.join('. ') },
        { shouldFocus: true }
      );
    }
  });
};

export const allFieldsDirty = (
  dirtyFields: Record<string, boolean>,
  allFields: string[]
) => allFields.every((fieldName: string) => !!dirtyFields[fieldName]);
