import { useCallback, useEffect, useState } from 'react';

import { useLazyGetProductSchemasQuery } from 'redux/api/payouts/batchTemplateApi';

import { ProductSchema } from 'models/batchTemplate';

import {
  SelectChoice,
  Selector,
  SelectorClassNames
} from '@molecules/Selector/Selector';

import { DATA_REQUIRED_SELECTOR_TEST_ID } from '../constants';

interface Props {
  onChange: (value: string) => void;
  onError?: (error: any, fields: string[]) => void;
  classNames?: SelectorClassNames;
  name?: string;
  isActive?: boolean;
  value: string;
  corridorId: string;
}

export const ProductSchemaSelector = ({
  onChange,
  classNames,
  onError,
  name,
  value,
  corridorId,
  isActive
}: Props) => {
  const [options, setOptions] = useState<SelectChoice[]>([]);
  const [getProductSchemas] = useLazyGetProductSchemasQuery();

  const fetchingProductSchemas = useCallback(async () => {
    try {
      const { data: productSchemasApiResponse } =
        await getProductSchemas(corridorId);

      return productSchemasApiResponse?.data;
    } catch (error: any) {
      if (onError) {
        onError(error, ['productSchema']);
      }

      return [];
    }
  }, [corridorId, getProductSchemas, onError]);

  useEffect(() => {
    if (!corridorId) {
      return;
    }

    fetchingProductSchemas().then((data) => {
      const productSchemaOptions = data?.map((productSchema: ProductSchema) => {
        return {
          label: productSchema.label,
          value: productSchema.id.toString()
        };
      });
      if (productSchemaOptions) {
        setOptions(productSchemaOptions);

        if (productSchemaOptions.length === 1) {
          onChange(productSchemaOptions[0].value);
        }
      }
    });
  }, [corridorId, fetchingProductSchemas, onChange]);

  const isDisabled = isActive || options.length === 0 || options.length === 1;

  return (
    <>
      <label className="mb-0.5 pl-2 text-xs">Data required</label>
      <Selector
        data-testid={DATA_REQUIRED_SELECTOR_TEST_ID}
        classNames={classNames}
        selectChoices={options}
        onChange={onChange}
        name={name}
        disabled={isDisabled}
        value={value}
      />
    </>
  );
};
