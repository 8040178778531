import { FXRate } from 'redux/api/organisations/fxApi';

import { TRANSLATIONS } from './constants';

export const formatExchangeRate = (exchangeRateData: FXRate | undefined) => {
  if (!exchangeRateData) {
    return '';
  }
  const { buy_currency: buyCurrency, pay_currency: payCurrency } =
    exchangeRateData;

  const rate = Number(exchangeRateData.exchange_rate);
  const isValidRate = !isNaN(rate) || rate !== 0;

  if (!buyCurrency || !payCurrency || !isValidRate) {
    return TRANSLATIONS.ERROR_LOADING;
  }

  return `1 ${buyCurrency} = ${rate?.toFixed(4)} ${payCurrency}`;
};
