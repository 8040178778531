import { useEffect } from 'react';

/**
 * TODO: remove this when
 * /src/components/ui/organisms/DataTable/DataTable.tsx
 * is deprecated
 * The reason is that the radix-dialog will be at z-index 0 and the topbar would appear.
 * This is a hack that fixes the issue.
 *
 *
 * TODO: Remove radix-ui dialogs if possible as well
 *
 * @description: The issue is that the datatable has filters that are fixed.
 * When the topbar has a dropdown expanded, the filter will be in front of the topbar.
 * In this case, the solution is to set the topbar's z-index to 1.
 * However doing so, when we give it a z-index of 1, this will create problems with radix dialogs.
 * What happens is that the TopBar will be not be behind the overlay of the dialog, and this will cause problems.
 *
 * Additionally, this might cause other issues when we need to control a z-index in a more controlled way.
 */
export const TopBarMutationObserver = () => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach(() => {
        const dialogElement = document.getElementById('dialog');
        const topBarElement = document.getElementById('topbar');

        if (topBarElement) {
          if (dialogElement) {
            topBarElement.style.zIndex = '0';
          } else {
            topBarElement.style.zIndex = '1';
          }
        }
      });
    });

    // Observe changes to the body (or any other appropriate container)
    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup the observer and event listener on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};
