import { Accordion, AccordionItem } from '@radix-ui/react-accordion';

import { orderStates } from 'models/order';

import { OrderBadge } from '@atoms/badges/OrderBadge/OrderBadge';

import { FiltersState } from './localState';
import { ListItem } from './OrderFilterListItem';

export interface OrdersSidebarProps {
  filters: FiltersState;
  onFilterChange: (update: FiltersState) => void;
}

export const OrdersFilters = ({
  filters,
  onFilterChange
}: OrdersSidebarProps) => {
  return (
    <section
      aria-label="Filters"
      className="flex max-h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-plain-lg"
    >
      <div className="mx-6 flex h-13 shrink-0 items-center justify-between">
        <div className="shrink-0 font-bold">Filter</div>
      </div>
      <div className="border-inpay-gray-primary-300 grid h-10 shrink-0 grid-cols-2 border-y bg-inpay-green-secondary-light-200"></div>
      <div className="overflow-auto">
        <Accordion
          type="single"
          collapsible
          value={filters?.status || ''}
          onValueChange={(value) => {
            onFilterChange({
              status: value,
              accountNumber: undefined
            });
          }}
        >
          <ul>
            <ListItem
              className="cursor-pointer"
              isSelected={!filters?.status}
              onClick={() =>
                onFilterChange({
                  status: undefined,
                  accountNumber: undefined
                })
              }
            >
              <OrderBadge label="All" state="All" selected={!filters?.status} />
            </ListItem>
            {orderStates.map((status, i) => {
              const isSelected = filters?.status === status.value;

              return (
                <AccordionItem key={status.value + i} value={status.value}>
                  <ListItem
                    className="cursor-pointer"
                    isSelected={isSelected}
                    onClick={() => {
                      // If we are selecting already active filter, remove it
                      const newValue =
                        status.id != filters?.status ? status.id : undefined;
                      onFilterChange({
                        status: newValue,
                        accountNumber: undefined
                      });
                    }}
                  >
                    <OrderBadge
                      label={status.label}
                      state={status.value}
                      selected={isSelected}
                    />
                  </ListItem>
                </AccordionItem>
              );
            })}
          </ul>
        </Accordion>
      </div>
    </section>
  );
};
