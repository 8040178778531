import cn from 'classnames';
import { ReactNode } from 'react';

export interface ListItemProps {
  isSelected: boolean;
  className?: string;
  onClick?: () => void;
  children: ReactNode;
}

export const ListItem = ({
  isSelected,
  className,
  onClick,
  children
}: ListItemProps) => {
  return (
    <li
      className={cn(
        'flex h-14 flex-row items-center justify-between px-6 hover:bg-inpay-gray-primary-200 group-radix-state-open:bg-inpay-gray-primary-200',
        className,
        {
          'bg-inpay-gray-primary-200': isSelected
        }
      )}
      onClick={onClick}
    >
      {children}
    </li>
  );
};
