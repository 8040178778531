import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger
} from '@radix-ui/react-alert-dialog';
import { MouseEventHandler, ReactNode, useState } from 'react';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import {
  ARE_YOU_SURE,
  CANCEL_TEXT,
  EXIT_UNFINISHED_MANUAL_PAYOUT_REQUEST,
  IT_WILL_BE_DELETED,
  NO_TEXT,
  YES_TEXT
} from './constants';

interface Props {
  children?: ReactNode;
  onAction: MouseEventHandler<HTMLButtonElement>;
}

export const CancelButton = ({ onAction, children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger asChild>
        {children ? (
          children
        ) : (
          <SecondaryButton size="large" className="w-full">
            {CANCEL_TEXT}
          </SecondaryButton>
        )}
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay
          className="fixed inset-0 bg-inpay-green-primary-1000/90"
          onClick={(e) => e.stopPropagation()}
        />

        <AlertDialogContent className="fixed left-[50%] top-[50%] w-80 translate-x-[-50%] translate-y-[-50%] rounded-lg bg-inpay-gray-secondary-600 shadow-[0_0_6px_#155C56]">
          <AlertDialogTitle className="flex h-12 items-center rounded-t-lg bg-white px-6 text-sm font-medium shadow-plain-bottom-sm">
            {EXIT_UNFINISHED_MANUAL_PAYOUT_REQUEST}
          </AlertDialogTitle>
          <AlertDialogDescription className="p-6">
            {ARE_YOU_SURE}
            <br />
            {IT_WILL_BE_DELETED}
          </AlertDialogDescription>
          <div className="flex w-full gap-4 px-6 pb-6">
            <AlertDialogCancel asChild>
              <SecondaryButton size="large" className="w-full shadow-plain">
                {NO_TEXT}
              </SecondaryButton>
            </AlertDialogCancel>
            <AlertDialogAction asChild>
              <PrimaryButton
                size="large"
                className="w-full shadow-plain"
                onClick={(e) => {
                  onAction(e);
                  setIsOpen(false);
                }}
              >
                {YES_TEXT}
              </PrimaryButton>
            </AlertDialogAction>
          </div>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
};
