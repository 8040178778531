import { ReactNode } from 'react';

import { CookieBanner } from './CookieBanner';

interface Props {
  children: ReactNode;
}

export const AuthenticationContainer = ({ children }: Props) => {
  return (
    <div className="flex h-auto min-h-screen w-full flex-col bg-inpay-gray-primary-200 md:w-144">
      <div className="my-28 flex grow flex-col items-center justify-center">
        {children}
      </div>
      <CookieBanner />
    </div>
  );
};
