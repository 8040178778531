import { ColumnFiltersState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';

import { prepareSearchParams, updateUrl } from 'utils/url';

import { DataTable, PaginatorProps } from '@organisms/DataTable';

import { useFetchReportsQuery } from 'redux/api/accounts/reportsApi';
import { useFetchVirtualAccountsQuery } from 'redux/api/accounts/virtualAccountApi';

import { ExportedTransactionsData } from 'models/exportedTransaction';

import { useDownloadFile } from 'hooks/useDownloadFile';
import { useGlobalFilters } from 'hooks/useGlobalFilters';

import { ErrorPanel } from '@atoms/ErrorPanel';
import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { LoadingOverlay } from '@molecules/LoadingOverlay';

import { DownloadReportDialog } from 'components/ui/organisms/DownloadReportDialog';
import { TableLayout } from 'components/ui/templates/TableLayout';

import {
  EXPORTED_TRANSACTIONS_TABLE_TEST_ID,
  FILTER_PARAM_NAMES
} from './constants';
import { ExportedTransactionsHeader } from './ExportedTransactionsHeader';
import { useLocalState } from './localState';
import { mapStateToParams } from './map-state-to-params';
import { useExportsColumns, VirtualAccountOption } from './useExportsColumns';

export const ExportedStatements = () => {
  const [pagination, setPagination] = useState<PaginatorProps>({});
  const [virtualAccountOptions, setVirtualAccountOptions] = useState<
    VirtualAccountOption[]
  >([]);
  const { filters, queryParams: globalQueryParams } = useGlobalFilters();
  const [searchParams] = useSearchParams();
  const { '*': exportedAccountId, organisationId } = useParams();

  if (!organisationId) throw new Error('Missing organisation id');

  const downloadReport = searchParams.get('download_report');
  const { value: dialogOpen, setValue: setDialogOpen } =
    useBoolean(!!downloadReport);

  const columnDefinitions = useExportsColumns(virtualAccountOptions);

  const accountNumber = searchParams.get(FILTER_PARAM_NAMES.accountNumber);

  const {
    state,
    queryParams,
    dispatch,
    reset,
    setFilters,
    setCursorAfter,
    setCursorBefore
  } = useLocalState({
    pagination: {
      cursorAfter: exportedAccountId,
      includeCursor: !!exportedAccountId
    },
    filters: accountNumber
      ? [{ id: FILTER_PARAM_NAMES.accountNumber, value: accountNumber }]
      : []
  });

  const {
    data: { data: exportedTransactionReports, meta } = {},
    isFetching,
    error,
    isSuccess
  } = useFetchReportsQuery(mapStateToParams({ ...state, ...filters }));

  const {
    data: { data: virtualAccounts } = {},
    isFetching: isFetchingAccounts,
    isSuccess: isSuccessVirtualAccounts,
    error: errorVirtualAccounts
  } = useFetchVirtualAccountsQuery({ organisationId });

  // Resetting pagination, sorting and local filtering to initial value if global date range changed.
  // TODO: Need to be reviewed and might be limited to pagination resetting only
  useUpdateEffect(() => dispatch(reset()), [globalQueryParams]);

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  useUpdateEffect(() => {
    if (!exportedAccountId) {
      const url = `${location.pathname}?${prepareSearchParams(location.search, {
        ...queryParams,
        ...globalQueryParams
      }).toString()}`;
      updateUrl(url);
    }
  }, [exportedAccountId, queryParams]);

  useUpdateEffect(() => {
    dispatch(
      setFilters(
        accountNumber
          ? [{ id: FILTER_PARAM_NAMES.accountNumber, value: accountNumber }]
          : []
      )
    );
  }, [accountNumber]);

  useEffect(() => {
    if (isSuccessVirtualAccounts) {
      setVirtualAccountOptions(
        virtualAccounts
          ? virtualAccounts.map((info, index) => {
              return { order: index, value: info.account_number, item: info };
            })
          : []
      );
    }
  }, [isSuccessVirtualAccounts, errorVirtualAccounts]);

  const updateFilters = (filteringState: ColumnFiltersState) => {
    dispatch(setFilters(filteringState));
  };

  const downloadFile = useDownloadFile();

  const callDownloadReport = (props: ExportedTransactionsData) => {
    downloadFile(
      `reconciliation/accounts/${props.virtual_account_number}/reports/${props.uuid}/download`
    );
  };

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_previous_page,
        showNext: meta.has_next_page,
        onClickPrev: () => dispatch(setCursorBefore(meta.cursor_prev)),
        onClickNext: () => dispatch(setCursorAfter(meta.cursor_next))
      });
    } else {
      setPagination({
        showPrev: false,
        showNext: false
      });
    }
  }, [meta]);

  return (
    <TableLayout header={<ExportedTransactionsHeader />}>
      <div className="flex size-full flex-col items-center gap-2">
        {error && 'error' in error && (
          <div className="flex h-screen items-center justify-center">
            <ErrorPanel message={error.error} />
          </div>
        )}
        {isFetching && <LoadingOverlay />}
        {isSuccess && (
          <DataTable
            name="Transactions"
            columns={columnDefinitions}
            isFetching={isFetching || isFetchingAccounts}
            topBar="name-with-count"
            data-testid={EXPORTED_TRANSACTIONS_TABLE_TEST_ID}
            total={exportedTransactionReports?.length}
            data={exportedTransactionReports || []}
            onRowClick={(_e, data) => callDownloadReport(data)}
            pagination={pagination}
            filters={state.filters}
            onFilteringChange={updateFilters}
            noDataMessage={
              <NoDataFound title="No account exports" className="mt-1">
                We have yet to generate any account exports.
              </NoDataFound>
            }
          />
        )}

        {downloadReport && (
          <DownloadReportDialog open={dialogOpen} onClose={setDialogOpen} />
        )}
      </div>
    </TableLayout>
  );
};
