import { Navigate, useParams, useSearchParams } from 'react-router-dom';

export const ExportedStatementsRedirection = () => {
  const { organisationId } = useParams();
  const [queryParams] = useSearchParams();

  if (queryParams)
    return (
      <Navigate
        to={`/org/${organisationId}/accounts/exported_transactions?${queryParams.toString()}`}
      />
    );
  return (
    <Navigate to={`/org/${organisationId}/accounts/exported_transactions`} />
  );
};
