import {
  ColumnFiltersState,
  ColumnSort,
  SortingState
} from '@tanstack/react-table';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { DataTable, PaginatorProps, sortingRegexp } from '@organisms/DataTable';

import { useFetchPayoutDraftsQuery } from 'redux/api/payouts/payoutsApi';
import { LocalState } from 'redux/slices/pages.payoutsSlice';

import { type PayoutDraftData } from 'models/payout';

import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { LoadingOverlay } from 'components/ui/molecules/LoadingOverlay';
import { DetailedLayout } from 'components/ui/templates/DetailedLayout';

import { PAYOUT_REQUEST_TABLE_TEST_ID, TRANSLATIONS } from '../constants';
import { PayoutDraftDetails } from '../Details/PayoutDraftDetails';
import { useLocalState } from '../localState';
import { usePayoutDraftColumns } from '../payoutsDraftsColumn';

const routes = [{ path: ':payoutId', element: <PayoutDraftDetails /> }];

export const ManualPayoutsDraftList = () => {
  const { organisationId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();
  const { '*': payoutId } = useParams();
  const [pagination, setPagination] = useState<PaginatorProps>();

  const {
    state,
    tableFilters,
    updateSort,
    updateFilters,
    updateCursorAfter,
    updateCursorBefore
  } = useLocalState();

  const payoutColumnDefinitions = usePayoutDraftColumns();

  const sorting = useMemo(() => {
    const matches = state.sort.match(sortingRegexp);

    return matches
      ? [
          {
            id: matches[2],
            desc: matches[1] == '-'
          }
        ]
      : undefined;
  }, [state.sort]);

  const {
    data: { data, meta } = {},
    isFetching,
    error
  } = useFetchPayoutDraftsQuery({
    organisation_id: organisationId ? organisationId : '',
    'filter[state]': state.tab,
    'page[size]': '20'
  });
  const payoutRequests = data || [];

  const sortPayouts = useCallback(
    (sortingState: SortingState) => {
      const sortingParams = sortingState
        .map((column: ColumnSort) => `${column.desc ? '-' : ''}${column.id}`)
        .join(',');

      if (sortingParams != state.sort) {
        updateSort(sortingParams);
      }
    },
    [state.sort]
  );

  useEffect(() => {
    if (meta) {
      setPagination({
        showPrev: meta.has_prev_page,
        showNext: meta.has_next_page
      });
    }
  }, [meta]);

  const nextPage = () => {
    updateCursorAfter(meta?.cursor_next);
  };

  const prevPage = () => {
    updateCursorBefore(meta?.cursor_prev);
  };

  const onRowClick = (
    _event: MouseEvent<HTMLElement>,
    rowData: PayoutDraftData
  ) => {
    if (rowData.id.toString() == payoutId) {
      navigate({ pathname: '.', search: location.search });
    } else {
      navigate({
        pathname: rowData.id.toString(),
        search: location.search
      });
    }
  };

  const onFilteringChange = (filters: ColumnFiltersState) => {
    const prefilledFilters = {
      state: undefined,
      accountNumber: undefined,
      currency: undefined
    };

    const updatedFilters = filters.reduce(
      (accumulator: LocalState['filters'], filter) => (
        (accumulator[filter.id as keyof LocalState['filters']] =
          filter.value as string),
        accumulator
      ),
      prefilledFilters
    );

    updateFilters(updatedFilters);
  };

  return (
    <DetailedLayout routes={routes}>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center" role="alert">
          <div>{error.error}</div>
        </div>
      )}
      <DataTable
        columns={payoutColumnDefinitions}
        isFetching={isFetching}
        name="Payout requests"
        topBar="name-with-count"
        currentRow={{
          value: payoutId,
          field: 'inpay_unique_reference'
        }}
        total={meta?.total}
        data={payoutRequests}
        onSortingChange={sortPayouts}
        onFilteringChange={onFilteringChange}
        initialSorting={sorting}
        filters={tableFilters}
        onRowClick={onRowClick}
        pagination={{
          ...pagination,
          onClickNext: nextPage,
          onClickPrev: prevPage
        }}
        hasData={(meta?.total ?? 0) > 0}
        noDataMessage={
          <NoDataFound className="flex flex-col items-center gap-4">
            <p className="font-semibold">
              {state.tab === 'rejected'
                ? TRANSLATIONS.EMPTY_DECLINED
                : TRANSLATIONS.EMPTY_PENDING}
            </p>
          </NoDataFound>
        }
        data-testid={PAYOUT_REQUEST_TABLE_TEST_ID}
      />
      {isFetching && <LoadingOverlay />}
    </DetailedLayout>
  );
};
