import { yupResolver } from '@hookform/resolvers/yup';
import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { type InferType } from 'yup';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { setFormErrors } from 'utils/formHelpers';

import { Content, Overlay, Title } from '@organisms/Dialog';
import { withdrawalRequestDialogSchema as schema } from 'validators/withdrawalRequestDialog';

import { useGenerateWithdrawalMutation } from 'redux/api/accounts/withdrawalsApi';

import { VirtualAccount } from 'models/virtualAccount';
import { WithdrawalAccount } from 'models/withdrawal';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { WithdrawFundsIcon } from '@atoms/Icon/WithdrawFundsIcon';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import { AccountSelector } from '@molecules/AccountSelector';
import { InputField } from '@molecules/InputField';
import { Selector } from '@molecules/Selector/Selector';

import { InfoRow } from './InfoRow';
import { WithdrawalSuccess } from './WithdrawalSuccess';

interface Inputs extends InferType<typeof schema> {
  base: null;
}

interface Props {
  withdrawalAccounts: WithdrawalAccount[];
  isWithdrawalAccountsError: boolean;
  isWithdrawalAccountsLoading: boolean;
  organisationId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const amountFormatter = new MoneyFormatter();

export const WithdrawalRequestDialog = ({
  withdrawalAccounts,
  isWithdrawalAccountsError,
  isWithdrawalAccountsLoading,
  organisationId,
  open,
  setOpen
}: Props) => {
  const [confirmationPrompt, setConfirmationPrompt] = useState(false);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [
    createWithdrawal,
    {
      isSuccess: creationSuccess,
      isError: creationError,
      data: { data: withdrawalRequestData = undefined } = {},
      isLoading: isWithdrawalDataLoading,
      reset: resetDialog
    }
  ] = useGenerateWithdrawalMutation();

  const {
    trigger,
    setError,
    formState,
    getValues,
    setValue,
    register,
    watch,
    formState: { errors },
    reset: resetForm
  } = useForm<Inputs>({
    mode: 'onChange',
    resolver: yupResolver(schema)
  });

  const withdrawalAccountsOptions = useMemo(() => {
    return withdrawalAccounts.map((i) => ({
      label: [i.bank_name, i.iban ? i.iban : i.account_number] as [
        string,
        string
      ],
      value: i.id.toString()
    }));
  }, [withdrawalAccounts]);

  const onWithdrawRequest = async (event) => {
    event.preventDefault();
    setConfirmationPrompt(true);
  };

  const onConfirmWithdrawal = async (event) => {
    event.preventDefault();
    try {
      const data = getValues();
      await createWithdrawal({
        params: {
          organisation_id: organisationId.toString(),
          end_to_end_id: data.end_to_end_id,
          amount: Number.parseFloat(data.amount),
          currency_code: data.currency_code ?? 'EUR',
          creditor_account: {
            id: data.withdrawal_account.uuid
          },
          debtor_account: {
            scheme_name: 'VirtualAccount',
            id: data.virtual_account_number
          }
        }
      }).unwrap();
      setConfirmationPrompt(false);
    } catch (error: any) {
      const title = error?.data?.error?.title;
      const status = error?.status;

      if (status === 422) {
        switch (title) {
          case 'Validation Error':
            setErrorMessage('Reference must be unique');
            break;
          case 'Missing capability Error':
            setErrorMessage(
              'Your capability setup is incomplete.. Payout was automatically rejected'
            );
            break;
          default:
            setErrorMessage('Something went wrong');
        }
      } else if (status === 'PARSING_ERROR') {
        setErrorMessage('Amount is too large');
      } else {
        setErrorMessage('Something went wrong');
      }
      setTimeout(
        () =>
          setFormErrors({
            error,
            setError,
            fields: Object.keys(schema.fields)
          }),
        50
      );
    }
  };

  const onDialogClose = () => {
    resetForm();
    resetDialog();
    setOpen(false);
    setConfirmationPrompt(false);
  };

  const submitDisabled = formState.isSubmitSuccessful || !formState.isValid;

  const formErrors = () => Object.keys(formState.errors).length > 0;

  const onVirtualAccountChange = (account: VirtualAccount) => {
    setValue('virtual_account_number', account.account_number);
    setValue('currency_code', account.currency_code);
    setIsAccountSelected(true);
    formErrors() && trigger();
  };

  const onWithdrawalAccountsChange = (accountId: string) => {
    const account = withdrawalAccounts.find(
      (account) => account.id.toString() === accountId
    );
    setValue('withdrawal_account', account);
    formErrors() && trigger();
  };

  const showForm =
    !isWithdrawalDataLoading &&
    !isWithdrawalAccountsError &&
    !creationSuccess &&
    !confirmationPrompt &&
    !creationError;

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content className="w-84">
            <Title>
              <div className="flex items-center">
                <WithdrawFundsIcon stroke="black" className="size-6" />
                <span className="grow pl-2">Withdraw funds</span>
              </div>
            </Title>
            {showForm && (
              <div className="flex justify-center rounded-b-lg bg-inpay-gray-primary-200 p-6">
                <form autoComplete="off" className="flex w-full flex-col gap-4">
                  <div className="flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">
                      Virtual Bank Account
                    </label>
                    <AccountSelector
                      defaultAccountNumber={getValues('virtual_account_number')}
                      onChange={onVirtualAccountChange}
                      data-testid="account-select"
                    />
                  </div>
                  {formState.errors.virtual_account_number && (
                    <div
                      role="alert"
                      id="virtual_account_number"
                      className="mt-2 rounded-b-lg bg-inpay-red-200 p-3 text-sm drop-shadow-sm"
                    >
                      {formState.errors.virtual_account_number.message}
                    </div>
                  )}
                  <div className="flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">
                      Withdrawal Account
                    </label>
                    <Selector
                      selectChoices={
                        !isAccountSelected
                          ? [
                              {
                                label: '',
                                value: 'Select an account first'
                              }
                            ]
                          : withdrawalAccountsOptions
                      }
                      onChange={onWithdrawalAccountsChange}
                      value={
                        withdrawalAccounts.length === 1
                          ? withdrawalAccounts[0].id.toString()
                          : getValues('withdrawal_account')?.id.toString()
                      }
                      name="withdrawalAccountNumber"
                      disabled={
                        !isAccountSelected ||
                        !withdrawalAccountsOptions ||
                        withdrawalAccountsOptions.length === 0
                      }
                      data-testid="bank-account-select"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">
                      Amount in {watch('currency_code') ?? ''}
                    </label>
                    <InputField
                      {...register('amount')}
                      errorMessage={errors.amount?.message}
                    />
                  </div>
                  <div className="flex flex-col">
                    <label className="mb-0.5 pl-2 text-xs">End-to-end ID</label>
                    <InputField
                      {...register('end_to_end_id')}
                      errorMessage={errors.end_to_end_id?.message}
                    />
                  </div>
                  <PrimaryButton
                    size="large"
                    className="mt-8 w-full"
                    onClick={onWithdrawRequest}
                    disabled={!formState.isValid}
                  >
                    Request withdrawal
                  </PrimaryButton>
                  {formState.errors.base && (
                    <div
                      role="alert"
                      className="mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm"
                      style={{
                        filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
                      }}
                    >
                      {formState.errors.base.message}
                    </div>
                  )}
                </form>
              </div>
            )}
            {confirmationPrompt && !creationSuccess && !creationError && (
              <div className="rounded-b-lg bg-white p-6">
                <div className="flex flex-col gap-2 rounded-lg bg-inpay-gray-primary-200 p-6">
                  <InfoRow
                    label="Amount"
                    value={amountFormatter.format(
                      Number.parseFloat(getValues('amount').toString())
                    )}
                    subValue={getValues('currency_code')}
                  />
                  <InfoRow
                    label="Bank"
                    value={getValues('withdrawal_account')?.bank_name}
                  />
                  <InfoRow
                    label="IBAN"
                    value={getValues('withdrawal_account')?.iban}
                  />
                  <InfoRow
                    label="Beneficiary"
                    value={getValues('withdrawal_account')?.creditor_name}
                  />
                  <InfoRow
                    label="End-to-end ID"
                    value={getValues('end_to_end_id')}
                  />
                </div>
                <PrimaryButton
                  className="mt-10 w-full"
                  type="submit"
                  disabled={submitDisabled}
                  onClick={onConfirmWithdrawal}
                >
                  Confirm request
                </PrimaryButton>
                <SecondaryButton
                  className="mt-4 w-full"
                  onClick={() => {
                    setConfirmationPrompt(false);
                  }}
                >
                  Back
                </SecondaryButton>
              </div>
            )}
            {isWithdrawalDataLoading &&
              isWithdrawalAccountsLoading &&
              !creationSuccess && (
                <div className="flex size-72 items-center gap-4 rounded-b-lg bg-inpay-gray-primary-200 p-4">
                  <RollingLoader ariaLabel="Creating withdrawal" />
                  <div className="text-xs">
                    <span className="invisible">...</span>
                    <span>Processing</span>
                    <BlindingDots />
                  </div>
                </div>
              )}
            {creationSuccess &&
              withdrawalRequestData &&
              !isWithdrawalDataLoading && (
                <WithdrawalSuccess
                  onDialogClose={onDialogClose}
                  withdrawalRequestData={withdrawalRequestData}
                />
              )}
            {creationError && (
              <div className="rounded-b-lg bg-inpay-gray-primary-200 p-6">
                <div className="rounded-lg bg-inpay-red-200 p-3 text-inpay-red-1000">
                  {errorMessage}
                </div>
                <SecondaryButton
                  className="mt-6 w-full"
                  onClick={() => {
                    resetForm();
                    resetDialog();
                    setConfirmationPrompt(false);
                  }}
                >
                  Back
                </SecondaryButton>
              </div>
            )}
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
