import { Link } from 'react-router-dom';

export const NoOrganisation = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-inpay-gray-secondary-1000">
      <div className="font-bold">
        Your user is not attached to any organisation.
      </div>

      <Link to="/login" className="inpay-link mt-24 self-center font-medium">
        Back to login page
      </Link>
    </div>
  );
};
