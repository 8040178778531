import { Controller, UseFormReturn } from 'react-hook-form';

import { FieldDefinition } from 'models/payout';

import { Label } from '@atoms/Label';
import { Wrap } from '@atoms/Wrap';

import { AccountSelector } from '@molecules/AccountSelector';
import { BusinessUnitSelector } from '@molecules/BusinessUnitSelector';
import { InputField } from '@molecules/InputField';

import { SeparatedDateField } from './SeparatedDateField';

import { fieldToDisplayableName } from '../fieldToDisplayableName';

interface Props {
  fieldName: string;
  fieldDefinition: FieldDefinition;
  form: UseFormReturn<any, any>;
  accountProps?: {
    productId: number;
  };
  ['data-testid']?: string;
}

export const SpecificField = ({
  fieldName,
  fieldDefinition,
  form,
  accountProps,
  ['data-testid']: dataTestId
}: Props) => {
  const fieldDisplayableName = fieldToDisplayableName(fieldName);
  const { errors, value, ui_field: uiField } = fieldDefinition;

  const { control, formState } = form;
  const formError = formState.errors[fieldName]?.message as string;

  const errorMessage =
    formError ||
    errors?.map((error) => `${fieldDisplayableName} ${error}`).join('. ');

  const { onChange, ...inputProps } = form.register(fieldName, { value });

  const textField = (fieldName: string) => {
    switch (fieldName) {
      case 'virtual_account_number':
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange } }) => (
              <AccountSelector
                onChange={({ account_number: accountNumber }) =>
                  onChange(accountNumber)
                }
                defaultAccountNumber={
                  form.getValues(fieldName) ?? value?.toString()
                }
                errorMessage={errorMessage}
                productId={accountProps?.productId}
              />
            )}
          />
        );
      case 'iban':
        return (
          <>
            <InputField
              errorMessage={errorMessage}
              onChange={onChange}
              {...inputProps}
            />
            <span className="pl-2 text-xs text-inpay-gray-primary-1000">
              E.g.: GB29 NWBK 6016 1331 9268 19
            </span>
          </>
        );
      case 'business_unit':
        return (
          <Controller
            name={fieldName}
            control={control}
            render={({ field: { onChange } }) => (
              <BusinessUnitSelector
                onChange={(businessUnitId) => onChange(businessUnitId)}
                errorMessage={errorMessage}
                value={form.getValues(fieldName)}
              />
            )}
          />
        );

      default:
        return (
          <InputField
            errorMessage={errorMessage}
            onChange={onChange}
            {...inputProps}
          />
        );
    }
  };

  return (
    <div data-testid={dataTestId}>
      <Wrap
        if={!!fieldDisplayableName}
        with={Label}
        wrapperProps={{
          key: `pr-field-${fieldName}`,
          text: fieldDisplayableName,
          className: 'mb-4'
        }}
      >
        {uiField == 'datepicker' ? (
          <Controller
            name={fieldName}
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <SeparatedDateField
                errorMessage={errorMessage}
                onChange={onChange}
                defaultValue={value}
              />
            )}
          />
        ) : (
          textField(fieldName)
        )}
      </Wrap>
    </div>
  );
};
