import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useFetchWithdrawalRequestsQuery } from 'redux/api/accounts/withdrawalsApi';
import { selectOrganisationId } from 'redux/slices/authSlice';

import { WithdrawalRequestsData } from 'models/withdrawal';

import { Loading } from 'components/pages/Loading';
import { apiParams } from 'components/pages/Withdrawals/apiAdapter';
import { useLocalState } from 'components/pages/Withdrawals/localState';
import { WithdrawalsTable } from 'components/pages/Withdrawals/WithdrawalsTable';
import { TableContent } from 'components/ui/templates/AntdTableLayout/TableContent';

interface Props {
  onSuccess: (withdrawal: WithdrawalRequestsData | undefined) => void;
  searchParams: any;
}

export const WithdrawalsSearchResults = ({
  searchParams,
  onSuccess
}: Props) => {
  const organisationId = useSelector(selectOrganisationId);
  if (!organisationId) throw new Error('Missing organisation id');

  const { state } = useLocalState({
    filters: { reference: searchParams.unique_identifier }
  });

  const {
    data: { data: withdrawalRequestsData = [] } = {},
    isFetching,
    isSuccess
  } = useFetchWithdrawalRequestsQuery(
    apiParams({
      organisationId: organisationId.toString(),
      filters: {
        reference: searchParams.unique_identifier
      },
      sorting: state.sorting,
      cursorAfter: state.cursorAfter,
      cursorBefore: state.cursorBefore,
      includeCursor: state.includeCursor
    })
  );

  useEffect(() => {
    if (!isFetching && isSuccess) {
      onSuccess(withdrawalRequestsData[0]);
    }
  }, [isFetching, isSuccess]);

  if (isFetching) return <Loading text="Searching withdrawals" />;

  return (
    <>
      {withdrawalRequestsData.length > 0 && (
        <TableContent>
          <WithdrawalsTable withdrawalRequestsData={withdrawalRequestsData} />
        </TableContent>
      )}
    </>
  );
};
