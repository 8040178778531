import { ExportedTransactionsData } from 'models/exportedTransaction';

import { customerApi } from '../customerApi';

type ReportsRequestKeys =
  | 'unique_identifier'
  | 'page_size'
  | 'sort'
  | 'include_cursor'
  | 'cursor_before'
  | 'created_after'
  | 'created_before'
  | 'cursor_after'
  | 'virtual_account_number';
export type ReportsRequest = {
  [key in ReportsRequestKeys]?: string;
};

export type GenerateReportsRequest = {
  virtualAccountNumber: string;
  created_after: string;
  created_before: string;
};

export type GenerateReportResponse = null | {
  uuid: string;
  virtual_account_number: string;
  report_name: string;
  date_from: string;
  date_to: string;
  currency: string;
  number_of_transactions: number;
};

export interface ReportsResponse {
  data: ExportedTransactionsData[];
  meta: {
    has_previous_page: boolean;
    has_next_page: boolean;
    cursor_next: string;
    cursor_prev: string;
  };
}

export const reportsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchReport: builder.query<any, any>({
      query: ({ virtual_account_number, uuid }) => {
        // eslint-disable-next-line camelcase
        return `/v1/reconciliation/accounts/${virtual_account_number}/reports/${uuid}`;
      },
      providesTags: ['Reports']
    }),
    fetchReports: builder.query<ReportsResponse, ReportsRequest>({
      query: (params) => {
        return `/v1/reconciliation/account_statement_reports?${new URLSearchParams(
          params
        )}`;
      },
      providesTags: ['Reports'],
      keepUnusedDataFor: 0
    }),
    generateAccountReport: builder.mutation<
      GenerateReportResponse,
      GenerateReportsRequest
    >({
      query: ({ virtualAccountNumber, created_after, created_before }) => {
        return {
          url: `/v1/reconciliation/accounts/${virtualAccountNumber}/reports`,
          credentials: 'include',
          method: 'POST',
          body: {
            created_after,
            created_before
          }
        };
      }
    })
  })
});

export const {
  useFetchReportsQuery,
  useLazyFetchReportQuery,
  useGenerateAccountReportMutation
} = reportsApi;
