import { useMemo } from 'react';

import { REVIEWER_ROLES, ROLENAMES } from 'constants/roles';

import { Roles } from 'models/user';

export interface UserRolesProps {
  roles: Roles[];
  isApprovalFlowEnabled?: boolean;
}

export interface RoleOption {
  label: string;
  value: string;
}

export const useRolesOptions = (props: UserRolesProps): RoleOption[] => {
  const { roles, isApprovalFlowEnabled } = props;
  const rolesOptions = useMemo(() => {
    if (!isApprovalFlowEnabled) {
      return roles
        .filter((role: Roles) => !REVIEWER_ROLES.includes(role.name))
        .map((role: Roles) => ({
          label: role.name.charAt(0).toUpperCase() + role.name.slice(1),
          value: role.name
        }));
    } else {
      return roles.map((role: Roles) => ({
        label:
          ROLENAMES[role.name] ||
          role.name.charAt(0).toUpperCase() + role.name.slice(1),
        value: role.name
      }));
    }
  }, [roles, isApprovalFlowEnabled]);

  return rolesOptions;
};
