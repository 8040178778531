import { PlusIcon } from '@heroicons/react/24/outline';
import { useBoolean } from 'usehooks-ts';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';

import { NewUserDialog } from './NewUserDialog';

export const UserManagementHeader = () => {
  const {
    value: isNewUserDialogOpen,
    setTrue: openNewUserDialog,
    setFalse: closeNewUserDialog
  } = useBoolean(false);

  return (
    <div className="flex items-center justify-between">
      <div className="text-2xl font-medium">User Management</div>
      <PrimaryButton
        type="submit"
        onClick={openNewUserDialog}
        className="flex items-center gap-2"
      >
        <PlusIcon className="h-6" />
        Add New User
      </PrimaryButton>
      <NewUserDialog open={isNewUserDialogOpen} onClose={closeNewUserDialog} />
    </div>
  );
};
