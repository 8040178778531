// NOTE (LÞJ): Use this component while fetching for page content.
// If you're fetching dependency data critical for the page to render, consider using `<Loading />`
export const LoadingOverlay = () => {
  return (
    <div className="absolute inset-0 flex justify-center rounded-lg bg-inpay-black-primary-400/50 pt-[40vh]">
      <span
        data-testid="loading-overlay"
        role="progressbar"
        className="loader"
      />
    </div>
  );
};
