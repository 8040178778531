import { useEffect, useState } from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles.css';

interface Props {
  label: string;
  className?: string;
  inputClassName?: string;
  onChange: (countryCode: string, cellphone: string) => void;
}

export const CellPhoneField = ({
  className,
  label,
  inputClassName,
  onChange
}: Props) => {
  const [cellphone, setCellphone] = useState<string>('');
  const [fullCellphone, setFullCellphone] = useState<string>('');
  const [countryCode, setCountryCode] = useState<string>('');

  useEffect(() => {
    if (countryCode) {
      const regex = new RegExp(`^${countryCode}`);
      setCellphone(fullCellphone.replace(regex, ''));
    }
  }, [fullCellphone, countryCode]);

  useEffect(() => {
    cellphone && onChange && onChange(countryCode, cellphone);
  }, [cellphone]);

  return (
    <div className={`${className} flex w-full  flex-col`}>
      <label className="mb-2 flex text-sm font-medium">{label}</label>
      <PhoneInput
        value={fullCellphone}
        enableSearch={true}
        disableSearchIcon={true}
        specialLabel=""
        searchClass=""
        country="dk"
        enableTerritories
        onChange={(value, country: CountryData) => {
          setCountryCode(country.dialCode);
          setFullCellphone(value);
        }}
        inputClass={`${inputClassName} rounded-md max-w-full !w-full min-w-280 h-14 p-4 !text-xl`}
      />
    </div>
  );
};
