import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

// Customer portal display date format
export const DATE_FORMAT = 'DD-MM-YYYY';
// API DTO date format
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm:ss';

/**
 * Format time (Hours, Mins, Seconds) from a given DateTime ISO string
 * @param dateStr The serialized DateTime ISO string
 * @param timezone The output timezone, leaving undefined results in localized time
 * @returns Formatted time
 */
export const formatHours = (dateStr: string, timezone?: 'utc') => {
  const formattedHours =
    timezone === 'utc'
      ? dayjs.utc(dateStr).format(TIME_FORMAT)
      : dayjs(dateStr).format(TIME_FORMAT);

  if (formattedHours === 'Invalid Date')
    throw Error(`Error formatting date string: ${dateStr}.`);

  return formattedHours;
};

/**
 * Format date from a given DateTime ISO string
 * @param dateStr The serialized DateTime ISO string
 * @param timezone The output timezone, leaving undefined results in localized time
 * @param format The output format, leaving undefined results in inpay "standard" format
 * @returns Formatted time
 */
export const formatDate = (
  dateStr: string,
  timezone?: 'utc',
  format?: 'iso'
) => {
  const formattedDate =
    timezone === 'utc'
      ? format === 'iso'
        ? dayjs.utc(dateStr, DATE_FORMAT).format(API_DATE_FORMAT)
        : dayjs.utc(dateStr).format(DATE_FORMAT)
      : dayjs(dateStr).format(DATE_FORMAT);

  if (formattedDate === 'Invalid Date')
    throw Error(`Error formatting date string: ${dateStr}.`);

  return formattedDate;
};

export { Dayjs } from 'dayjs';
export default dayjs;
