import * as yup from 'yup';

export const withdrawalRequestDialogSchema = yup
  .object({
    virtual_account_number: yup
      .string()
      .label('Account')
      .required('Please select account'),
    withdrawal_account: yup
      .object()
      .shape({
        id: yup.string()
      })
      .label('Bank account')
      .required('Please select account'),
    end_to_end_id: yup.string().label('End-to-end ID').required(),
    currency_code: yup.string().label('Currency'),
    amount: yup
      .string()
      .matches(/^[^0].*$/, 'Amount must be without leading zero')
      .matches(/^([0-9]*[.])?[0-9]+$/, 'Amount must be a number')
      .typeError('Amount must be a number')
      .label('Amount')
      .required()
  })
  .required();
