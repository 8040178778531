import { Column, flexRender, Header } from '@tanstack/react-table';
import cn from 'classnames';
import { Fragment, useRef, useState } from 'react';
import { useElementSize, useOnClickOutside } from 'usehooks-ts';

import { SortingIcon, ExpandIcon } from '@atoms/Icon';

import { ExtendedColumnFilter } from './types';

interface ColumnHeaderProps<RowT, ColumnT> {
  header: Header<RowT, ColumnT>;
  allValues?: any[]; // Complete list of possible values to show filter options
}

export function ColumnHeader<RowT, ColumnT>({
  header,
  allValues
}: ColumnHeaderProps<RowT, ColumnT>) {
  const columnDef = header.column.columnDef;
  const filteringEnabled =
    columnDef.enableColumnFilter &&
    header.column.getCanFilter() &&
    allValues &&
    allValues?.length > 0;
  const sortingEnabled = columnDef.enableSorting && header.column.getCanSort();
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const ref = useRef(null);
  const [containerRef, { width = 0 }] = useElementSize();

  useOnClickOutside(ref, () => {
    setFilterVisible(false);
  });

  if (header.isPlaceholder) return null;

  return (
    <th
      colSpan={header.colSpan}
      ref={ref}
      className={cn(
        columnDef.meta?.className,
        'border-b-inpay-gray-primary-300 relative overflow-hidden border-b text-left text-sm font-normal',
        {
          'hover:text-inpay-green-primary-1000': sortingEnabled,
          'cursor-pointer select-none': sortingEnabled || filteringEnabled
        }
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setFilterVisible(!filterVisible);
        if (sortingEnabled) {
          const sortingHandler = header.column.getToggleSortingHandler();
          if (sortingHandler) sortingHandler(e);
        }
      }}
    >
      <div
        className={cn(
          'my-2 flex flex-row items-center gap-1 whitespace-nowrap hover:text-inpay-green-primary-1000',
          {
            'justify-end': columnDef.meta?.alignment === 'right',
            'justify-start': columnDef.meta?.alignment != 'right',
            'text-inpay-green-primary-1000': filterVisible
          }
        )}
      >
        {
          flexRender(
            columnDef.header,
            header.getContext() as any
          ) /* TODO: any is a bad type! */
        }
        {filteringEnabled ? (
          <ExpandIcon
            className="w-4 shrink-0 stroke-2 duration-200"
            expanded={filterVisible}
          />
        ) : null}
        {sortingEnabled && <SortingIcon sorted={header.column.getIsSorted()} />}
      </div>
      {filteringEnabled ? (
        <div className="absolute right-0 w-full max-w-42" ref={containerRef}>
          <FiltersList
            column={header.column}
            visible={filterVisible}
            onVisibleChange={setFilterVisible}
            width={width}
            filters={allValues}
          />
        </div>
      ) : null}
    </th>
  );
}

function FiltersList<RowT, ColumnT>(props: {
  column: Column<RowT, ColumnT>;
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
  filters: ExtendedColumnFilter[];
  width: number;
}) {
  const { column, onVisibleChange, visible, filters: values, width } = props;
  if (values?.length == 0) return null;

  return (
    <div
      role="listbox"
      style={{ width: width }}
      aria-label={`${column.id}-filters`}
      className={cn(
        'fixed flex max-w-42 flex-col items-end gap-2 p-6',
        'origin-top transform-gpu duration-200 ease-in-out',
        'rounded-b-lg bg-inpay-green-secondary-light-200 shadow-plain-bottom hover:cursor-auto',
        {
          'scale-y-10': visible,
          'scale-y-0': !visible
        }
      )}
    >
      {/* Prepend null representing "clear" filter */}
      {[null, ...values].map((value, i) => {
        // NOTE (LÞJ): A bit smelly. Maybe we should just accept an filtering type.
        //  But the problem with that is to override default filtering function
        const val = value?.value || value;
        const renderFunction = column.columnDef.meta?.renderValueFilter;

        return renderFunction ? (
          <Fragment key={i}>
            {renderFunction(
              value,
              column.getFilterValue() == val,
              (e: React.MouseEvent) => {
                e.preventDefault;
                e.stopPropagation();
                column.setFilterValue(val);
                onVisibleChange(false);
              },
              cn('transition-opacity duration-100', {
                'opacity-100': visible,
                'opacity-0': !visible
              })
            )}
          </Fragment>
        ) : (
          <span
            className={cn(
              'transition-opacity',
              'hover:cursor-pointer',
              'text-inpay-green-primary-400',
              visible ? 'opacity-100' : 'opacity-0'
            )}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              column.setFilterValue(val);
              onVisibleChange(false);
            }}
            key={i}
          >
            {value?.label}
          </span>
        );
      })}
    </div>
  );
}
