import { useParams } from 'react-router-dom';

import { useFetchFundingInformationQuery } from 'redux/api/accounts/virtualAccountApi';

import { BlindingDots } from '@atoms/BlindingDots/BlindingDots';
import { RollingLoader } from '@atoms/RollingLoader/RollingLoader';

import CryptoFundingInstructions from './CryptoFundingInstructions';

export const CryptoWalletFundingInformation = ({
  virtualAccountNumber
}: {
  virtualAccountNumber: string;
}) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const {
    data: { data } = {},
    isFetching,
    isError
  } = useFetchFundingInformationQuery({ organisationId, virtualAccountNumber });

  const { crypto: cryptoInformation } = data || {};
  const loadingText = 'Loading account funding information data';
  const isFundingInfoAvailable =
    cryptoInformation && cryptoInformation.length > 0;

  return (
    <div>
      {isFetching && (
        <div className="flex flex-col items-center gap-y-4 p-4">
          <RollingLoader ariaLabel={loadingText} />
          <div className="text-xs">
            <span className="invisible">...</span>
            <span>{loadingText}</span>
            <BlindingDots />
          </div>
        </div>
      )}

      {(isError || !isFundingInfoAvailable) && (
        <div className="rounded-md bg-inpay-gray-primary-400 p-3 text-sm">
          Funding information not available for this account.
        </div>
      )}
      {!isFetching && !isError && isFundingInfoAvailable && (
        <>
          <h3 className="mb-3 font-bold">Funding instructions</h3>
          <CryptoFundingInstructions instructions={cryptoInformation} />
        </>
      )}
    </div>
  );
};
