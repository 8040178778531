import { ReactNode, useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { useLocalState } from './localState';

interface Props {
  children: ReactNode;
}

export const StateGate = ({ children }: Props) => {
  const { '*': payoutId } = useParams();
  const [searchParams] = useSearchParams();
  const { search } = useLocation();
  const [loaded, setLoaded] = useState<boolean>(false);

  const localState = useLocalState();

  const updateState = () => {
    // updateFilters and updateSort have side-effect and reset pagination
    // because of it we need to init filters and sort before pagination
    localState.updateFilters({
      state: searchParams.get('state') || undefined,
      currency: searchParams.get('currency') || undefined,
      amountFrom: searchParams.get('amountFrom') || undefined,
      amountTo: searchParams.get('amountTo') || undefined
    });

    localState.updateSort(searchParams.get('sort') || '-date');
    localState.updateCursorBefore(
      searchParams.get('cursorBefore') || undefined
    );
    localState.updateCursorAfter(
      searchParams.get('cursorAfter') || payoutId || undefined
    );
    localState.updateIncludeCursor(!!payoutId || false);
  };

  useEffect(() => {
    updateState();
    setLoaded(true);
  }, [search]);

  return <>{loaded ? children : null}</>;
};
