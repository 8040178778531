import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  Location,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';

import { useLocalState } from 'components/pages/Search/localState';

import { SEARCH_BY_REF_TEST_ID } from './constants';

const searchPageRegex = /^\/org\/\d+\/search/;

interface Props {
  className?: string;
}

export const SearchWidget = ({ className }: Props) => {
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);
  const location = useLocation() as Location & {
    state: { from: string | undefined };
  };
  const currentPath = useRef<string>(location.pathname);
  const isSearchPage = searchPageRegex.test(location.pathname);

  const { state } = useLocalState();
  const [fromPath, setFromPath] = useState<string>();

  const { organisationId } = useParams();
  const [searchValue, setSearchValue] = useState('');

  // Update redirection path
  useEffect(() => {
    if (searchPageRegex.test(currentPath.current) && !isSearchPage) {
      setFromPath(undefined);
    } else if (location.state?.from) {
      setFromPath(location.state.from);
    }
    currentPath.current = location.pathname;
  }, [location.pathname]);

  // Update component search value state
  useEffect(() => {
    if (isSearchPage && state.q) {
      setSearchValue(state.q);
    } else if (!isSearchPage) {
      setSearchValue('');
    }
  }, [location, state]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setSearchValue(value);
    if (value === '') {
      if (fromPath) {
        navigate(fromPath);
      } else {
        navigate('..');
      }
    }
  };

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value.trim();
    if (event.key == 'Enter' && value) {
      const state = isSearchPage ? undefined : { from: location };
      navigate(`/org/${organisationId}/search?q=${value}`, {
        state
      });
    }
  };

  return (
    <div className={`relative h-11 ${className}`}>
      <MagnifyingGlassIcon className="absolute left-2 m-2.5 size-6 stroke-1" />
      <input
        data-testid={SEARCH_BY_REF_TEST_ID}
        ref={ref}
        type="text"
        name="search"
        aria-label="Search"
        value={searchValue}
        title="Search"
        placeholder="Search ref. or end-to-end id"
        className="size-full rounded-lg border-none bg-white p-2.5 pl-12 shadow-plain hover:shadow-plain-lg focus:shadow-plain-lg focus:outline-0 focus:ring-0"
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
    </div>
  );
};
