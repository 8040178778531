export const convertTypeToLabel = (paymentType: string) => {
  switch (paymentType) {
    case 'bank_transfer':
      return 'Bank transfer';
    case 'instant_bank_transfer':
      return 'Instant bank transfer';
    default:
      return paymentType.charAt(0).toUpperCase() + paymentType.slice(1);
  }
};
