import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { useCallback, useMemo } from 'react';
import { default as ReactCountryFlag } from 'react-country-flag';
import { useParams } from 'react-router-dom';

import { getCountryCode } from 'utils/formatters/getCountryCode';
import { MoneyFormatter } from 'utils/formatters/moneyFormatter';
import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';
import { virtualAccountLabel } from 'utils/helpers';

import { useFetchFundingInformationQuery } from 'redux/api/accounts/virtualAccountApi';
import { useFetchFeatureFlagsQuery } from 'redux/api/organisations/featureFlagsApi';
import { useFetchOrganisationConfigurationsQuery } from 'redux/api/organisations/organisationsApi';

import { VirtualAccount } from 'models/virtualAccount';

import { useOrgNavigate } from 'hooks/useOrgNavigate';

import { SharedAccountIcon } from '@atoms/Icon/SharedAccountIcon';

import { CURRENCY_SYMBOL_PATTERN } from './constants';

interface Props {
  accountInfo: VirtualAccount;
}

export const AccountWidget = ({ accountInfo }: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const virtualAccountNumber = accountInfo.account_number;
  const { data: { data: fundingInformation } = {}, isSuccess } =
    useFetchFundingInformationQuery({
      organisationId,
      virtualAccountNumber
    });

  // CBP-2466-account-funding: temporary until the feature flag is completely removed
  const { data: { data: featureFlagsData } = {} } = useFetchFeatureFlagsQuery();

  const navigate = useOrgNavigate();

  const { data: { data: orgSettings } = {} } =
    useFetchOrganisationConfigurationsQuery(organisationId);

  const format = new MoneyFormatter({ currency: accountInfo.currency_code });
  const value = format.format(accountInfo.balance);
  const currencySymbol = value.match(CURRENCY_SYMBOL_PATTERN);

  const accountLabel = virtualAccountLabel(accountInfo);
  let countryCode = '';
  try {
    countryCode = getCountryCode(accountInfo.currency_code);
  } catch (error) {
    // eslint-disable-next-line
    console.warn(
      '<AccountWidget /> Failure getting country code for currency ',
      accountInfo.currency_code
    );
  }

  const isSharedAccount = !accountInfo.owner;

  const handleRedirect = useCallback(() => {
    if (!isSharedAccount) {
      navigate(`accounts/${virtualAccountNumber}`);
    }
  }, [isSharedAccount, navigate, virtualAccountNumber]);

  const isAccountFundingInfoEnabled = useMemo(() => {
    return (
      !!featureFlagsData?.['CBP-2466-account-funding'] &&
      orgSettings?.account_funding_enabled !== undefined &&
      orgSettings.account_funding_enabled > 0
    );
  }, [featureFlagsData, orgSettings?.account_funding_enabled]);

  const isPaymentDetailsUpdated =
    isSuccess && fundingInformation?.wire?.changed;

  return (
    <section
      aria-label="Account widget"
      className={cn(
        'grid grid-cols-[3rem_3fr_1fr_2rem] items-center gap-2 lg:gap-4',
        'group h-14 w-full rounded-lg px-2 lg:px-4',
        'bg-white shadow-plain-lg ',
        {
          'cursor-pointer hover:bg-inpay-green-secondary-light-200 hover:text-inpay-green-primary-1000':
            !isSharedAccount
        }
      )}
      key={virtualAccountNumber}
      onClick={handleRedirect}
    >
      {isSharedAccount ? (
        <div className="relative size-8">
          <ReactCountryFlag
            countryCode={countryCode ?? ''}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '24px',
              height: '24px',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
            svg
          />
          <SharedAccountIcon
            height="24"
            width="24"
            className="absolute bottom-0 right-0"
          />
        </div>
      ) : (
        <ReactCountryFlag
          countryCode={countryCode ?? ''}
          style={{
            width: '32px',
            height: '32px',
            borderRadius: '50%',
            objectFit: 'cover'
          }}
          svg
        />
      )}
      <div className="flex items-center justify-between truncate">
        <div>
          <div className="font-bold text-inpay-green-primary-1000">
            {accountLabel}
          </div>
          <p
            className={`text-inpay-gray-primary-1000 ${isSharedAccount ? '' : 'group-hover:text-inpay-green-primary-400'}`}
          >
            No. {virtualAccountNumberFormatter(virtualAccountNumber)}
          </p>
        </div>
        {isPaymentDetailsUpdated && isAccountFundingInfoEnabled ? (
          <div className="h-fit rounded-md bg-inpay-yellow-400 p-2 text-sm">
            Funding details updated
          </div>
        ) : null}
      </div>
      <div
        className={`${isSharedAccount ? 'col-start-4' : ''} flex items-center justify-end gap-1 text-2xl font-medium`}
      >
        {currencySymbol && <span>{currencySymbol}</span>}
        <span>
          {value
            ? value.replaceAll(CURRENCY_SYMBOL_PATTERN, '').trim()
            : accountInfo.balance}
        </span>
      </div>
      {!isSharedAccount && (
        // NOTE (HJST): div is needed by safari to enforce svg size
        <div>
          <ArrowLongRightIcon className="stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        </div>
      )}
    </section>
  );
};
