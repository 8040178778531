import { capitalizeFirstLetter } from 'utils/capitalize-first-letter';

const fieldNames = {
  end_to_end_id: 'End-to-end ID',
  amount: 'Amount',
  virtual_account_number: 'Account',
  business_unit: 'Business Unit',
  name: 'Full Name',
  country_code: 'Country',
  address_lines: 'Address',
  city: 'City',
  postcode: 'Postal code',
  birthdate: 'Date of birth',
  iban: 'IBAN',
  id_issuing_date: 'ID issuing date',
  id_expiry_date: 'ID expiry date',
  state: 'State',
  email: 'Email'
};

export const fieldToDisplayableName = (keyName: string): string => {
  const name = fieldNames[keyName];
  if (name) return name;
  console.warn('Key not found in fieldNames', keyName);
  return capitalizeFirstLetter(
    keyName.replaceAll('_', ' ').replaceAll('id', 'ID')
  );
};
