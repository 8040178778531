import { ArrowDownTrayIcon as DownloadIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import cn from 'classnames';
import { useMemo } from 'react';

import { virtualAccountNumberFormatter } from 'utils/formatters/virtualAccountNumberFormatter';

import { ExportedTransactionsData } from 'models/exportedTransaction';
import { VirtualAccount } from 'models/virtualAccount';

import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';

export interface VirtualAccountOption {
  order: number;
  value: string;
  item: VirtualAccount;
}

export const useExportsColumns = (
  virtualAccountOptions: Array<VirtualAccountOption>
) => {
  const columnHelper = createColumnHelper<ExportedTransactionsData>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'download_icon',
        cell: () => (
          <a
            title="Export"
            className="group relative mx-auto flex size-11 cursor-pointer flex-col items-center justify-center"
          >
            <DownloadIcon className="w-6 stroke-1 group-hover:stroke-1.5" />
            <div className="invisible absolute -bottom-2 text-[10px] tracking-tighter group-hover:visible">
              Export
            </div>
          </a>
        ),
        meta: {
          className: 'w-24'
        }
      }),
      columnHelper.accessor('date_from', {
        id: 'date_from',
        header: 'From',
        cell: (info) => (
          <>
            <div className="text-xs font-normal">From</div>
            <FormattedDate
              className="text-base font-medium"
              value={info.getValue<string>()}
            />
          </>
        ),
        meta: {
          className: 'w-30'
        }
      }),
      columnHelper.accessor('date_to', {
        id: 'date_to',
        header: 'To',
        cell: (info) => (
          <>
            <div className="text-xs font-normal">To</div>
            <FormattedDate
              className="text-base font-medium"
              value={info.getValue<string>()}
            />
          </>
        ),
        meta: {
          className: 'w-30'
        }
      }),
      columnHelper.accessor(
        (row) => ({
          virtual_account_number: row.virtual_account_number,
          currency: row.currency
        }),
        {
          id: 'account_number',
          enableColumnFilter: true,
          cell: (info) => {
            const value = info.getValue<any>();
            return (
              <>
                <div className="text-xs font-normal uppercase">
                  {value.currency}
                </div>
                <div className="whitespace-nowrap text-base font-medium">
                  {virtualAccountNumberFormatter(
                    value.virtual_account_number.toString()
                  )}
                </div>
              </>
            );
          },
          header: (props: any) => {
            if (virtualAccountOptions.length === 0) {
              return <></>;
            }
            const currentFilter = props?.column?.getFilterValue();
            return (
              <span>
                Account:
                <b className="ml-1 truncate font-bold">
                  {currentFilter
                    ? virtualAccountNumberFormatter(currentFilter)
                    : 'All'}
                </b>
              </span>
            );
          },
          meta: {
            alignment: 'right',
            values: virtualAccountOptions?.map((a) => ({
              ...a,
              id: a.item.account_number
            })),
            renderValueFilter: (
              value: VirtualAccountOption,
              selected: boolean,
              onClick: (e: React.MouseEvent) => void
            ) => {
              return (
                <div
                  className={cn(
                    'w-fit overflow-hidden truncate rounded-lg px-2 py-1',
                    {
                      'hover:none cursor-default bg-inpay-green-secondary-light-200 text-inpay-green-primary-400':
                        selected,
                      'cursor-pointer hover:bg-inpay-green-primary-1000 hover:text-white':
                        !selected
                    }
                  )}
                  onClick={onClick}
                >
                  {value ? (
                    <>
                      <div className="text-right text-xs">
                        {value.item.currency_name}
                      </div>
                      <div className="whitespace-nowrap font-medium">
                        {virtualAccountNumberFormatter(
                          value.item.account_number
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="whitespace-nowrap font-medium">All</div>
                  )}
                </div>
              );
            }
          }
        }
      ),
      columnHelper.accessor('number_of_transactions', {
        id: 'transactions',
        header: 'Transactions',
        cell: (info) => (
          <div className="text-base font-medium">{info.getValue<string>()}</div>
        ),
        meta: {
          className: 'text-right pr-7',
          alignment: 'right'
        }
      })
    ],
    [virtualAccountOptions]
  );

  return columns;
};
