import {
  ArrowLeftIcon,
  InformationCircleIcon
} from '@heroicons/react/24/outline';

import { ApprovalAction } from 'models/ApprovalWorkFlow';
import { type BatchDetail } from 'models/batches';

import { ApprovalBadge } from '@atoms/badges/ApprovalBadge/ApprovalBadge';
import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { OrgLink } from '@atoms/OrgLink';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

import { ApprovalStatus } from './ApprovalStatus';

interface Props {
  toggleDeclineDialog: () => void;
  batchDetails: BatchDetail | undefined;
  isApprovalEnabled: boolean;
  isBatchSuccess: boolean;
  userId: number;
  requiredNumberOfApprovals: number;
  canApprove: boolean;
  formatBatchDetails: (batchDetails: BatchDetail) => string;
  handleApproveOrDecline: (selectedAction: ApprovalAction) => Promise<void>;
}

export const BatchPayoutsHeader = ({
  toggleDeclineDialog,
  batchDetails,
  isApprovalEnabled,
  isBatchSuccess,
  userId,
  requiredNumberOfApprovals,
  canApprove,
  formatBatchDetails,
  handleApproveOrDecline
}: Props) => {
  const backUrl = isApprovalEnabled
    ? isBatchSuccess && batchDetails?.status === 'completed'
      ? `/batches?status=${batchDetails?.status}`
      : `/batches/${batchDetails?.id}?status=${batchDetails?.status}`
    : '/batches';

  return (
    <div className="flex items-end justify-between pb-4">
      <div className="flex flex-col gap-4">
        <OrgLink
          to={backUrl}
          className="flex cursor-pointer items-center gap-2 font-medium text-inpay-green-primary-1000"
        >
          <ArrowLeftIcon className="size-6" />
          Back
        </OrgLink>
        <h1 className="text-2xl font-medium">{batchDetails?.name}</h1>
        <h2 className="text-lg font-medium">
          {batchDetails && formatBatchDetails(batchDetails)}
          {isApprovalEnabled && batchDetails?.status && (
            <span className="ml-3">
              <ApprovalBadge status={batchDetails?.status} />
            </span>
          )}
        </h2>

        {isApprovalEnabled &&
          batchDetails?.rejections &&
          batchDetails?.status === 'rejected' && (
            <div className="flex w-max items-center rounded-md bg-inpay-gray-primary-400 px-4 py-2 text-sm">
              <span className="font-bold">Declined by: </span>
              <ul>
                {batchDetails.rejections.map((rejection: any) => (
                  <li
                    key={rejection.user_id}
                    className="flex flex-row items-center gap-1"
                  >
                    <span>
                      {rejection.user_id === userId
                        ? 'You'
                        : rejection.user_name}
                    </span>
                    <div className="group relative cursor-pointer">
                      <InformationCircleIcon className="m-auto size-4" />
                      <div className="invisible absolute -left-2 bottom-8 min-w-max rounded-lg bg-inpay-black-primary-800 px-2 py-1.5 text-white group-hover:visible">
                        <FormattedDateTime value={rejection.created_at} />
                        <svg
                          viewBox="0 0 100 100"
                          className="invisible absolute size-4 fill-inpay-black-primary-800 group-hover:visible"
                        >
                          <polygon points="0,0 100,0 50,100" />
                        </svg>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        {(batchDetails?.status === 'waiting_review' ||
          batchDetails?.status === 'completed') &&
          isApprovalEnabled && (
            <ApprovalStatus
              batchDetails={batchDetails}
              userId={userId}
              requiredNumberOfApprovals={requiredNumberOfApprovals}
            />
          )}
      </div>
      {canApprove && (
        <div className="flex content-between gap-4">
          <SecondaryButton onClick={toggleDeclineDialog} type="submit">
            Decline batch
          </SecondaryButton>
          <PrimaryButton
            onClick={() => handleApproveOrDecline('approve')}
            type="submit"
          >
            Approve batch
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};
