import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { PaymentState } from 'models/payment';

import { PaymentBadge } from '@atoms/badges/PaymentBadge/PaymentBadge';
import { TransactionIconPlus } from '@atoms/Icon';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

import { PaymentRow } from '.';

export const usePaymentColumns = () => {
  const columnHelper = createColumnHelper<PaymentRow>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'icon',
        cell: () => (
          <div className="m-auto w-6">
            <TransactionIconPlus height="32" width="32" />
          </div>
        ),
        meta: {
          className: 'w-20'
        }
      }),
      columnHelper.accessor('created_at', {
        id: 'date',
        header: 'Created at',
        cell: (info) => {
          const dateTime = info.getValue<string>();

          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor('inpay_unique_reference', {
        id: 'inpay_unique_reference',
        header: 'Reference',
        cell: (info) => {
          return (
            <span className="w-20 text-left text-base font-medium">
              {info.getValue<string>()}
            </span>
          );
        }
      }),
      columnHelper.accessor(
        (row) => ({ amount: row.order.amount, currency: row.order.currency }),
        {
          id: 'amountWithCurrency',
          header: 'Amount',
          cell: (info) => {
            const { amount, currency } = info.getValue<{
              amount: number;
              currency: string;
            }>();

            const formattedAmount = new MoneyFormatter({ currency: currency })
              .format(amount)
              .replace(`-${currency}`, '');

            return (
              <>
                <div className="text-xs font-normal uppercase">{currency}</div>
                <div className="whitespace-nowrap text-base font-medium">
                  {formattedAmount}
                </div>
              </>
            );
          },
          meta: {
            className: 'w-28 pr-4',
            alignment: 'right'
          }
        }
      ),
      columnHelper.accessor('status', {
        id: 'status',
        header: 'Status',
        cell: (info) => <PaymentBadge type={info.getValue<PaymentState>()} />,
        meta: {
          className: 'w-48 pr-4',
          alignment: 'right'
        }
      }),
      columnHelper.display({
        id: 'column_params',
        cell: () => (
          <ChevronRightIcon className="h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000" />
        ),
        meta: {
          className: 'w-10 text-right'
        }
      })
    ],
    []
  );

  return columns;
};
