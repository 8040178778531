import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonProps } from './Button';

export const PrimaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, size = 'small', option = 'normal', ...props },
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const normalOption =
      'bg-inpay-green-primary-1000 hover:bg-inpay-dark-green-1000';
    const dangerOption = 'bg-inpay-red-1000 hover:bg-inpay-orange-1000';

    return (
      <button
        ref={ref}
        className={twMerge(
          cn(
            (!size || size === 'large') && 'h-11',
            size === 'small' && 'h-9 text-sm',
            option === 'normal' && normalOption,
            option === 'danger' && dangerOption,
            'rounded-md px-4 font-medium',
            'text-white',
            'outline-offset-2 outline-inpay-blue-1000',
            'cursor-pointer disabled:cursor-default',
            'disabled:bg-inpay-black-primary-400',
            className
          )
        )}
        {...props}
      />
    );
  }
);

PrimaryButton.displayName = 'PrimaryButton';
