import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import Dayzed, { DateObj } from 'dayzed';
import { useState } from 'react';

import { DayOfMonth } from './DayOfMonth';
import { monthNamesFull, weekdayNamesLetter } from './names';

interface Props {
  date?: Date;
  onDateSelected: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  selectedRange: {
    from: Date;
    to: Date;
  };
  monthsToDisplay?: number;
}

export const DateRangePicker = ({
  date,
  onDateSelected,
  minDate,
  maxDate,
  selectedRange,
  monthsToDisplay
}: Props) => {
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  const selectDate = ({ selectable, date }: DateObj) => {
    if (!selectable) return;

    onDateSelected(date);
  };

  const onMouseEnter = (date: Date) => {
    setHoveredDate(date);
  };
  const onMouseLeave = () => {
    setHoveredDate(null);
  };

  return (
    <Dayzed
      date={date}
      onDateSelected={selectDate}
      minDate={minDate}
      maxDate={maxDate}
      selected={[selectedRange.from, selectedRange.to]}
      monthsToDisplay={monthsToDisplay}
      firstDayOfWeek={1}
      showOutsideDays={true}
      render={({
        calendars,
        getDateProps,
        getBackProps,
        getForwardProps
      }: any) => {
        if (calendars.length) {
          return (
            <div
              className="w-64 rounded-lg bg-white shadow-plain"
              onMouseLeave={onMouseLeave}
            >
              {calendars.map((calendar: any) => (
                <div
                  key={`${calendar.month}${calendar.year}`}
                  className="text-sm"
                >
                  <div className="flex h-11 items-stretch justify-around border-b border-inpay-gray-primary-200">
                    <button {...getBackProps({ calendars })} className="w-16">
                      <ChevronLeftIcon className="m-auto h-3.5 stroke-1.5" />
                    </button>
                    <div className="m-auto">
                      {monthNamesFull[calendar.month]} {calendar.year}
                    </div>
                    <button
                      {...getForwardProps({ calendars })}
                      className="w-16"
                    >
                      <ChevronRightIcon className="m-auto h-3.5 stroke-1.5" />
                    </button>
                  </div>

                  <div className="grid grid-cols-7 place-items-stretch gap-y-px p-2">
                    {weekdayNamesLetter.map(
                      (weekday: string, index: number) => (
                        <div
                          key={`${calendar.month}${calendar.year}${index}`}
                          className={cn(
                            'flex h-8 items-center justify-center text-center',
                            {
                              'text-inpay-black-primary-1000': index < 5,
                              'text-inpay-orange-1000': index > 4
                            }
                          )}
                        >
                          {weekday}
                        </div>
                      )
                    )}

                    {calendar.weeks.map((week: any, windex: number) =>
                      week.map((dateObj: object, index: number) => {
                        const key = `${calendar.month}${calendar.year}${windex}${index}`;
                        if (!dateObj) {
                          return <div key={key} />;
                        }
                        const {
                          date,
                          selected,
                          selectable,
                          nextMonth,
                          prevMonth
                        } = dateObj as any;

                        return (
                          <DayOfMonth
                            key={key}
                            {...getDateProps({
                              dateObj,
                              onMouseEnter: () => {
                                onMouseEnter(date);
                              }
                            })}
                            date={date}
                            selectedRange={selectedRange}
                            dayIndex={index}
                            selected={selected}
                            selectable={selectable}
                            outsideDay={nextMonth || prevMonth}
                            hoveredDate={hoveredDate}
                          ></DayOfMonth>
                        );
                      })
                    )}
                  </div>
                </div>
              ))}
            </div>
          );
        }
        return null;
      }}
    />
  );
};
