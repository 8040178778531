import { merge as objectMapper } from 'object-mapper';

import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { OrdersRequest } from 'redux/api/collections/orders/requests';
import { DateRangeFilter } from 'redux/slices/globalFiltersSlice';

import { LocalState } from './localState';

export type Params = LocalState & { dateRange: DateRangeFilter };

export const apiParams = function (params: Params): OrdersRequest {
  const map = {
    'dateRange.from': [
      {
        key: 'created_after',
        default: dayjs().subtract(30, 'days').startOf('day').format(DATE_FORMAT)
      }
    ],
    'dateRange.to': [
      {
        key: 'created_before',
        default: dayjs().add(1, 'days').endOf('day').format(DATE_FORMAT)
      }
    ],
    'filters.status': 'status',
    'filters.accountNumber': 'virtual_account_number',
    sort: 'meta.sort',
    cursorAfter: 'meta.cursor_after',
    cursorBefore: 'meta.cursor_before'
  };
  return objectMapper(params, map) as OrdersRequest;
};
