import { nanoid } from 'nanoid';
import { forwardRef, useRef, ForwardedRef, ComponentPropsWithRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { InputError } from '@atoms/InputError/InputError';

import { Label } from 'components/ui/atoms/Label';
import { Wrap } from 'components/ui/atoms/Wrap';

export interface InputFieldProps extends ComponentPropsWithRef<'input'> {
  /**
   * String with class names that will be attached to the input default classes
   */
  inputClassName?: string;
  /**
   * Error message
   */
  errorMessage?: string;
  /**
   * Label to show before the input
   */
  label?: string;
}

/**
 * Simple text input
 */
export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      id,
      errorMessage,
      children,
      className,
      label,
      disabled,
      inputClassName = '',
      ...props
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const idRef = useRef<string>(id || nanoid());
    const inputProps = {
      ref,
      id: idRef.current,
      className: twMerge(
        'rounded-lg h-12 p-4 w-full shadow-plain',
        !disabled && 'group-hover:shadow-plain-lg focus:shadow-plain-lg',
        inputClassName
      ),
      'aria-invalid': !!errorMessage,
      'aria-errormessage': errorMessage ? `${idRef.current}-error` : undefined
    };

    return (
      <Wrap
        if={!!label}
        with={Label}
        wrapperProps={{
          text: label,
          className,
          disabled
        }}
      >
        <div className="relative">
          <input {...props} {...inputProps} disabled={disabled} />
          {children}
          <InputError
            errorMessage={errorMessage}
            id={inputProps['aria-errormessage']}
          />
        </div>
      </Wrap>
    );
  }
);

InputField.displayName = 'InputField';
