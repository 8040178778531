import 'styles/app.css';

import { ConfigProvider } from 'antd/es';
import { CookiesProvider } from 'react-cookie';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// TODO: potentially remove this dep and use the toasts from antd instead
import 'react-toastify/dist/ReactToastify.css';

import { AppRouter } from 'containers/AppRouter';

import { setupStore } from 'redux/store';

import { ANTD_INPAY_THEME } from './antdThemeConfig';

export const App = () => {
  return (
    <ConfigProvider theme={ANTD_INPAY_THEME}>
      <CookiesProvider>
        <ReduxProvider store={setupStore()}>
          <AppRouter />
          <ToastContainer />
        </ReduxProvider>
      </CookiesProvider>
    </ConfigProvider>
  );
};
