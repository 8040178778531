import {
  CalendarIcon,
  ChevronUpIcon,
  XMarkIcon
} from '@heroicons/react/24/outline';
import cn from 'classnames';
import dayjs from 'dayjs';
import { MouseEvent, useMemo } from 'react';

import { DATE_FORMAT } from 'utils/dayjs';

import { DateRangeOption } from 'models/dateRange';

interface Props {
  filter: DateRangeOption;
  isOpen: boolean;
  isCustomOpen: boolean;
  closeCustom: () => void;
  disabled: boolean;
}

export const DateRangeLabel = ({
  filter,
  isOpen,
  isCustomOpen,
  closeCustom,
  disabled
}: Props) => {
  const label = useMemo(() => {
    if (isCustomOpen && isOpen) {
      return 'Select custom range';
    }

    if (filter.value == 'custom') {
      return (
        <>
          {dayjs(filter.range.from).format(DATE_FORMAT)}&nbsp;/&nbsp;
          {dayjs(filter.range.to).format(DATE_FORMAT)}
        </>
      );
    }
    return filter.label;
  }, [filter.value, isCustomOpen, isOpen]);

  const onClickClose = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    closeCustom && closeCustom();
  };

  return (
    <div
      className={cn('flex items-center', {
        'cursor-pointer': !isCustomOpen
      })}
    >
      <CalendarIcon
        className={cn('-m-1 w-6', {
          'stroke-inpay-black-primary-1000': !disabled,
          'stroke-inpay-gray-primary-1000': disabled,
          'stroke-1': !isOpen,
          'stroke-1.5': isOpen,
          'group-hover:stroke-1.5': !isOpen && !disabled
        })}
      />
      <div
        className={cn('mx-2 flex flex-grow items-center truncate', {
          'group-hover:font-stroke-medium ': !disabled,
          'text-inpay-gray-primary-1000': disabled,
          'font-stroke-medium': isOpen
        })}
      >
        {label}
      </div>
      {isCustomOpen ? (
        <XMarkIcon
          className="-m-1 w-8 cursor-pointer stroke-1"
          onClick={onClickClose}
        />
      ) : (
        <ChevronUpIcon
          className={cn('-m-1 w-6 stroke-1.5', {
            'scale-flip stroke-inpay-gray-primary-1000': !isOpen,
            'group-hover:stroke-inpay-black-primary-1000': !isOpen && !disabled,
            'stroke-inpay-black-primary-1000': isOpen
          })}
        />
      )}
    </div>
  );
};
