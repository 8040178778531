import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOnClickOutside } from 'usehooks-ts';
import { type InferType } from 'yup';

import { amountFilterSchema as schema } from 'validators/amountFilter';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { TextButton } from '@atoms/buttons/TextButton';
import { ExpandIcon } from '@atoms/Icon';

import { InputField } from '@molecules/InputField';

export type AmountFilterValue = InferType<typeof schema>;

interface Props {
  onAmountChange: (value: { from: number | null; to: number | null }) => void;
  value: AmountFilterValue;
}

export const AmountFilter = ({ onAmountChange, value }: Props) => {
  const {
    register,
    formState,
    handleSubmit,
    reset: resetForm
  } = useForm<AmountFilterValue>({
    reValidateMode: 'onSubmit',
    resolver: yupResolver(schema),
    values: value
  });

  const [filterVisible, setFilterVisible] = useState<boolean>(false);

  const containerRef = useRef(null);
  useOnClickOutside(containerRef, () => {
    setFilterVisible(false);
  });

  const { errors, isSubmitted } = formState;
  return (
    <div
      ref={containerRef}
      className="relative right-0 flex h-full items-center justify-end whitespace-nowrap px-4 text-sm font-normal"
    >
      <div
        className="flex cursor-pointer items-center justify-end gap-1"
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      >
        <span
          className={cn('hover:text-inpay-green-primary-1000', {
            'text-inpay-green-primary-1000': filterVisible
          })}
        >
          Amount
          {value.from && value.to && (
            <>
              :
              <span className="ml-1 truncate font-bold">
                {value.from} - {value.to}
              </span>
            </>
          )}
        </span>
        <ExpandIcon
          className="w-4 shrink-0 stroke-2 duration-200"
          expanded={filterVisible}
        />
      </div>
      {filterVisible && (
        <form
          onSubmit={handleSubmit(onAmountChange)}
          autoComplete="off"
          className="absolute right-0 top-full w-full origin-top transform-gpu rounded-b-lg bg-inpay-green-secondary-light-200 p-4 shadow-plain-bottom duration-200 ease-in-out"
        >
          <div className="right-0 flex items-center pb-4">
            <InputField
              label="From:"
              {...register('from')}
              type="number"
              inputClassName="h-8"
            />
            {/* THIS COMMENTED UNTIL WE DECIDE TO GO WITH DECIMAL, MIRO DECISIONS. */}
            {/* <Controller
                name="from"
                control={control}
                render={({ field: { onChange } }) => (
                  <IMaskInput
                    type="text"
                    mask={MASK}
                    label="From:"
                    onAccept={(e) => {
                      onChange(e);
                      setFrom(e);
                    }}
                    value={from}
                  />
                )}
              /> */}
            <div className="mx-2 mt-4 flex grow justify-center font-bold">
              -
            </div>
            <InputField
              label="To:"
              {...register('to')}
              type="number"
              inputClassName="h-8"
            />
            {/* THIS COMMENTED UNTIL WE DECIDE TO GO WITH DECIMAL, MIRO DECISIONS. */}
            {/* <Controller
                name="to"
                control={control}
                render={({ field: { onChange } }) => (
                  <IMaskInput
                    type="text"
                    mask={MASK}
                    label="To:"
                    onAccept={(e) => {
                      onChange(e);
                      setTo(e);
                    }}
                    value={to}
                  />
                )}
              /> */}
          </div>
          <div className="flex justify-between">
            <TextButton
              className="mx-4 hover:bg-transparent"
              onClick={() => {
                onAmountChange({ from: null, to: null });
                // setFilterVisible(false) is actually a hack to force re-render
                // since resetForm() does not.
                resetForm();
                setFilterVisible(false);
              }}
            >
              Clear
            </TextButton>
            <PrimaryButton type="submit">Add filter</PrimaryButton>
          </div>
          {isSubmitted && errors.from && (
            <div
              role="alert"
              className="mt-2 whitespace-normal rounded-2xl bg-inpay-red-200 p-3 text-sm"
              style={{
                filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
              }}
            >
              {errors.from.message}
            </div>
          )}
          {isSubmitted && errors.to && (
            <div
              role="alert"
              className="mt-2 whitespace-normal rounded-2xl bg-inpay-red-200 p-3 text-sm"
              style={{
                filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))'
              }}
            >
              {errors.to.message}
            </div>
          )}
        </form>
      )}
    </div>
  );
};
