export const setFormErrors = ({
  errors,
  setError,
  fields
}: {
  errors: Record<string, string[]>;
  setError: any;
  fields: string[];
}) => {
  Object.entries(errors).forEach(([pointer, errors]) => {
    if (fields.concat(['base']).includes(pointer)) {
      setError(
        pointer as any,
        { type: 'custom', message: errors.join('. ') },
        { shouldFocus: true }
      );
    }
  });
};
