import { formatDate } from 'utils/dayjs';

interface Props {
  value: string;
  className?: string;
}

/**
 * Formatted date presentation element
 * @value string Serialized ISO DateTime value
 * @returns A rendered element presenting the date
 */
export const FormattedDate = ({ value, className = 'text-xs' }: Props) => {
  if (!value) {
    return <p className={className}>Not available</p>;
  }

  const date = formatDate(value, 'utc');

  return (
    <p aria-label={date} className={className}>
      <span>{date[0]}</span>
      <span className="tracking-wide">{date[1]}-</span>
      <span>{date[3]}</span>
      <span className="tracking-wide">{date[4]}-</span>
      <span>{date.substring(6, 10)}</span>
    </p>
  );
};
