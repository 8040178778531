import { DateRangeFilter } from '@organisms/DateRangeFilter';

import { useDownloadFile } from 'hooks/useDownloadFile';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { CheckCircleIcon } from '@atoms/Icon';

import { AccountSelector } from '@molecules/AccountSelector';

interface Props {
  onClick: () => void;
  accountNumber?: string;
  uuid?: string;
  dateRange?: any;
  reportName?: string;
}

export const DownloadReportForm = ({
  onClick,
  accountNumber,
  uuid,
  dateRange
}: Props) => {
  const downloadFile = useDownloadFile();

  const callDownloadReport = async () => {
    if (uuid && accountNumber) {
      await downloadFile(
        `reconciliation/accounts/${accountNumber}/reports/${uuid}/download`
      );
      onClick && onClick();
    }
  };

  return (
    <div className="flex w-72 flex-col">
      <div className="mb-2 text-center font-medium">
        <CheckCircleIcon className="mx-auto mb-4 h-12" />
        <span>Your report is ready</span>
      </div>
      <div className="mb-4">
        <label className="mb-0.5 pl-2 text-xs">Time interval</label>
        <DateRangeFilter defaultFilters={dateRange} disabled />
      </div>
      <div className="mb-4">
        <label className="mb-0.5 pl-2 text-xs">Account</label>
        <AccountSelector disabled defaultAccountNumber={accountNumber} />
      </div>
      <div className="mb-6 text-center">
        Format: <span className="font-medium">CSV</span>
      </div>
      <PrimaryButton onClick={callDownloadReport}>
        Download report
      </PrimaryButton>
    </div>
  );
};
