import { useParams } from 'react-router-dom';

import { useFetchContractedProductsQuery } from 'redux/api/organisations/organisationsApi';

import { OrgLink } from 'components/ui/atoms/OrgLink';
import { DateRangeFilter } from 'components/ui/organisms/DateRangeFilter';

import styles from './styles.module.css';

interface Props {
  defaultFilters: any;
  onChange: (value: any) => void;
  disabled: boolean;
}

export const DateRangeWrapper = ({
  defaultFilters,
  onChange,
  disabled
}: Props) => {
  const { organisationId } = useParams();
  if (!organisationId) throw new Error('Missing organisation id');

  const { data: productsData } =
    useFetchContractedProductsQuery(organisationId);

  const hasPayoutProducts =
    (productsData?.meta?.payout_products_count || 0) > 0;
  const hasCollectionProducts =
    (productsData?.meta?.collection_products_count || 0) > 0;

  const link = (key: string, label: string) => (
    <OrgLink className="text-inpay-blue-1000" to={key}>
      {label}
    </OrgLink>
  );

  const suggestions = (
    <>
      To use it, go to {link('/accounts', 'Accounts')}
      {hasCollectionProducts ? (
        <>
          {hasPayoutProducts ? ', ' : ' or '}
          {link('/collections/orders', 'Orders')}
        </>
      ) : null}
      {hasPayoutProducts ? (
        <>
          {' or '}
          {link('/payouts', 'Payouts')}
        </>
      ) : null}{' '}
      pages.
    </>
  );

  return (
    <div className="group relative">
      {disabled ? (
        <>
          <DateRangeFilter
            classNames={{ triggerClassName: 'h-10' }}
            defaultFilters={{ type: 'd30' }}
            disabled
            key="disabled-date-range-filter"
          />
          <div className="invisible absolute top-full w-full drop-shadow-plain group-hover:visible">
            <div
              className={`${styles['notification']} mt-2.5 rounded-lg bg-inpay-green-secondary-light-200 px-4 py-1.5 text-xs tracking-tighter`}
            >
              <span className="font-medium">The date picker</span> is yet to be
              released on this page.
              <br />
              {suggestions}
            </div>
          </div>
        </>
      ) : (
        <DateRangeFilter
          classNames={{ triggerClassName: 'h-10' }}
          defaultFilters={defaultFilters}
          onChange={onChange}
        />
      )}
    </div>
  );
};
