import cn from 'classnames';
import { ComponentPropsWithRef } from 'react';

import styles from './styles.module.css';

interface Props extends ComponentPropsWithRef<'input'> {
  errorMessage?: string;
}

export const InputError = ({ errorMessage, ...props }: Props) => {
  return errorMessage ? (
    <div
      role="alert"
      className={cn(
        styles['input-error'],
        'mt-2 rounded-2xl bg-inpay-red-200 p-3 text-sm first-letter:uppercase'
      )}
      style={{ filter: 'drop-shadow(0 0 1px rgb(0 0 0 / 0.16))' }}
      {...props}
    >
      {errorMessage}
    </div>
  ) : null;
};
