import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger
} from '@radix-ui/react-accordion';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { hasValue } from 'utils/hasValue';
import {
  appendURLSearchParams,
  updateHistoryState,
  updateURLSearchParams
} from 'utils/url';

import {
  useCreateManualPayoutDraftMutation,
  usePublishManualPayoutDraftMutation,
  useUpdateManualPayoutDraftMutation
} from 'redux/api/payouts/payoutsApi';
import { selectUserId } from 'redux/slices/authSlice';

import { PayoutFieldsDefinition } from 'models/payout';

import { StepItem } from './StepItem';
import { Steps } from './steps';
import { AcceptanceDialog } from './steps/AcceptanceDialog';
import { CommonStep } from './steps/CommonStep';
import { PayoutRequestStep } from './steps/PayoutRequestStep';
import { SelectProductStep } from './steps/SelectProductStep';
import { SenderStep } from './steps/SenderStep';
import { SummaryStep } from './steps/SummaryStep';
import { StepStatusCircle } from './StepStatusCircle';

interface Props {
  onFinish: () => void;
  onCancel: MouseEventHandler<HTMLButtonElement>;
}

export const Content = ({ onFinish, onCancel }: Props) => {
  const userId = useSelector(selectUserId);

  const [currentStep, setCurrentStep] = useState<string>('selectProduct');
  const [fieldsDefinition, setFieldsDefinition] =
    useState<PayoutFieldsDefinition>();

  const [finishedSteps, setFinishedSteps] = useState<Record<Steps, boolean>>({
    selectProduct: false,
    payoutRequest: false,
    sender: false,
    receiver: false,
    receiverBank: false,
    summary: false
  });

  const [stepsData, setStepsData] = useState<Partial<Record<Steps, any>>>({
    selectProduct: undefined,
    payoutRequest: undefined,
    sender: undefined,
    receiver: undefined,
    receiverBank: undefined
  });

  const [createDraft, { data: draftData }] =
    useCreateManualPayoutDraftMutation();
  const [updateDraft] = useUpdateManualPayoutDraftMutation();
  const [publishDraft] = usePublishManualPayoutDraftMutation();

  useEffect(() => {
    const url = appendURLSearchParams('createManualPayout', 'selectProduct');
    updateHistoryState(url);
  }, []);

  const updateStepQueryParam = (stepName: Steps) => {
    const url = updateURLSearchParams('createManualPayout', stepName);
    updateHistoryState(url);
  };

  const onSelectProductSubmit = async (data: any) => {
    if (!hasValue(userId)) return;

    const response =
      draftData ||
      (await createDraft({
        schema_id: data.productSchemaId,
        product_id: data.corridorId,
        user_id: userId.toString()
      }).unwrap());

    if (response?.data) {
      setFieldsDefinition(response.data);
      setCurrentStep('payoutRequest');
      setFinishedSteps((state) => ({
        ...state,
        selectProduct: true
      }));
      setStepsData((state) => ({
        ...state,
        selectProduct: {
          productId: data.corridorId
        }
      }));

      updateStepQueryParam('payoutRequest');
    }
  };

  const onStepSubmit = async (
    data: any,
    step: { responseField: string; nextStep: Steps; name: string }
  ) => {
    await updateDraft({ [step.responseField]: data }).unwrap();
    setCurrentStep(step.nextStep);
    setFinishedSteps((state) => ({
      ...state,
      [step.name]: true
    }));

    setStepsData((state) => ({
      ...state,
      [step.name]: data
    }));

    updateStepQueryParam(step.nextStep);
  };

  const onSenderSubmit = async (data: any) => {
    await updateDraft({ ultimate_debtor: data }).unwrap();

    const updatedData = { sender: data } as any;

    if (data.sameReceiver && !finishedSteps.receiver) {
      const senderFields = Object.keys(
        fieldsDefinition?.['ultimate_debtor'] || {}
      );
      const receiverFields = Object.keys(
        fieldsDefinition?.['ultimate_debtor'] || {}
      );

      const intersection = senderFields.filter((field) =>
        receiverFields.includes(field)
      );

      const receiverData = Object.fromEntries(
        intersection.map((field) => [field, data[field]])
      );

      updatedData.receiver = receiverData;
    }

    setStepsData((state) => ({
      ...state,
      ...updatedData
    }));

    setCurrentStep('receiver');
    setFinishedSteps((state) => ({
      ...state,
      sender: true
    }));

    updateStepQueryParam('payoutRequest');
  };

  const [inpayUniqueReference, setinpayUniqueReference] = useState();

  const onSummarySubmit = async () => {
    const response = await publishDraft().unwrap();
    const reference = response.data.inpay_unique_reference;
    setinpayUniqueReference(reference);
  };

  const onStepChange = (step: Steps) => {
    if (fieldsDefinition) {
      setCurrentStep(step);
      updateStepQueryParam(step);
    }
  };

  if (!userId) return null;

  return (
    <div className="overflow-auto rounded-b-lg bg-white">
      <Accordion
        type="single"
        className="flex flex-col gap-4 px-4 pb-4"
        value={currentStep}
        onValueChange={onStepChange}
      >
        <AccordionItem
          value="selectProduct"
          className="rounded-lg border border-inpay-gray-primary-800 bg-inpay-gray-secondary-600"
        >
          <AccordionHeader>
            <AccordionTrigger className="group flex w-full cursor-pointer items-center justify-start gap-2 px-6 py-4 outline-offset-[-1px] focus:!outline-none focus:ring-0 group-last:radix-state-closed:rounded-b-lg">
              <StepStatusCircle
                step="selectProduct"
                value="1"
                isFinished={finishedSteps['selectProduct']}
              />
              <h2 className="font-semibold">Select Product</h2>
              <ChevronDownIcon className="group-radix-state-open:scale-flip ml-auto w-7 transition-all duration-300 group-hover:!stroke-inpay-black-primary-1000 group-radix-state-closed:stroke-inpay-black-primary-400" />
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent forceMount className="radix-state-closed:hidden">
            <SelectProductStep
              onSubmit={onSelectProductSubmit}
              onCancel={onCancel}
              fields={fieldsDefinition?.['product']}
              isFinished={!!fieldsDefinition}
              responseField="product"
            />
          </AccordionContent>
        </AccordionItem>

        <StepItem
          key="payoutRequest"
          index="2"
          name="payoutRequest"
          header="Payout Request"
          isFinished={finishedSteps['payoutRequest']}
        >
          <PayoutRequestStep
            onSubmit={(data: any) =>
              onStepSubmit(data, {
                responseField: 'payout_request',
                nextStep: 'sender',
                name: 'payoutRequest'
              })
            }
            onCancel={onCancel}
            fields={fieldsDefinition?.['payout_request']}
            responseField="payout_request"
            defaultValues={stepsData.payoutRequest}
            productId={stepsData.selectProduct?.productId}
          />
        </StepItem>

        <StepItem
          key="sender"
          index="3"
          name="sender"
          header="Sender"
          isFinished={finishedSteps['sender']}
        >
          <SenderStep
            onSubmit={onSenderSubmit}
            onCancel={onCancel}
            fields={fieldsDefinition?.['ultimate_debtor']}
            responseField="ultimate_debtor"
            defaultValues={stepsData.sender}
            sameReceiverInactive={finishedSteps.receiver}
          />
        </StepItem>

        <StepItem
          key="receiver"
          index="4"
          name="receiver"
          header="Receiver"
          isFinished={finishedSteps['receiver']}
        >
          <CommonStep
            onSubmit={(data: any) =>
              onStepSubmit(data, {
                responseField: 'creditor',
                nextStep: 'receiverBank',
                name: 'receiver'
              })
            }
            onCancel={onCancel}
            fields={fieldsDefinition?.['creditor']}
            responseField="creditor"
            defaultValues={stepsData.receiver}
          />
        </StepItem>

        <StepItem
          key="receiverBank"
          index="5"
          name="receiverBank"
          header="Receiver Bank"
          isFinished={finishedSteps['receiverBank']}
        >
          <CommonStep
            onSubmit={(data: any) =>
              onStepSubmit(data, {
                responseField: 'creditor_account',
                nextStep: 'summary',
                name: 'receiverBank'
              })
            }
            onCancel={onCancel}
            fields={fieldsDefinition?.['creditor_account']}
            responseField="creditor_account"
            defaultValues={stepsData.receiverBank}
          />
        </StepItem>

        <AccordionItem
          value="summary"
          className="rounded-lg border border-inpay-gray-primary-800 bg-inpay-gray-secondary-600"
        >
          <AccordionHeader>
            <AccordionTrigger className="group flex w-full cursor-pointer items-center justify-start gap-2 px-6 py-4 outline-offset-[-1px] group-last:radix-state-closed:rounded-b-lg">
              <StepStatusCircle step="summary" value="6" />
              <h2 className="font-semibold">Summary</h2>
              <ChevronDownIcon className="group-radix-state-open:scale-flip ml-auto w-7 transition-all duration-300 group-hover:!stroke-inpay-black-primary-1000 group-radix-state-closed:stroke-inpay-black-primary-400" />
            </AccordionTrigger>
          </AccordionHeader>
          <AccordionContent>
            <SummaryStep onSubmit={onSummarySubmit} onCancel={onCancel} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {inpayUniqueReference && (
        <AcceptanceDialog reference={inpayUniqueReference} onClose={onFinish} />
      )}
    </div>
  );
};
