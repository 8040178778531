import { ToggleGroup, ToggleGroupItem } from '@radix-ui/react-toggle-group';

import { DateRangeType } from 'models/dateRange';

import { SecondaryButton } from '@atoms/buttons/SecondaryButton';

import { FilterToggle } from './FilterToggle';

export interface TogglingFiltersProps {
  value: string;
  disabledFilters?: DateRangeType[];
  onValueChange: (value: any) => void;
}

export const TogglingFilters = ({
  value,
  disabledFilters = [],
  onValueChange
}: TogglingFiltersProps) => {
  return (
    <ToggleGroup
      value={value}
      onValueChange={onValueChange}
      type="single"
      aria-label="Select date range"
    >
      <div className="grid grid-cols-2 gap-2">
        <FilterToggle
          value="today"
          aria-label="Today"
          disabled={disabledFilters.includes('today')}
        >
          Today
        </FilterToggle>
        <FilterToggle
          value="yesterday"
          aria-label="Yesterday"
          disabled={disabledFilters.includes('yesterday')}
        >
          Yesterday
        </FilterToggle>
        <FilterToggle
          value="d7"
          aria-label="Past 7 days"
          disabled={disabledFilters.includes('d7')}
        >
          Past 7 days
        </FilterToggle>
        <FilterToggle
          value="d30"
          aria-label="Past 30 days"
          disabled={disabledFilters.includes('d30')}
        >
          Past 30 days
        </FilterToggle>
      </div>
      <div className="mb-2 mt-6 text-center text-xs font-medium uppercase">
        Last
      </div>
      <div className="grid grid-cols-3 gap-2">
        <FilterToggle
          value="last_week"
          aria-label="Last week"
          disabled={disabledFilters.includes('last_week')}
        >
          Week
        </FilterToggle>
        <FilterToggle
          value="last_month"
          arai-label="Last month"
          disabled={disabledFilters.includes('last_month')}
        >
          Month
        </FilterToggle>
        <FilterToggle
          value="last_year"
          aria-label="Last year"
          disabled={disabledFilters.includes('last_year')}
        >
          Year
        </FilterToggle>
      </div>
      <div className="mb-2 mt-6 text-center text-xs font-medium uppercase">
        This
      </div>
      <div className="grid grid-cols-3 gap-2">
        <FilterToggle
          value="this_week"
          aria-label="Last week"
          disabled={disabledFilters.includes('this_week')}
        >
          Week
        </FilterToggle>
        <FilterToggle
          value="this_month"
          aria-label="Last month"
          disabled={disabledFilters.includes('this_month')}
        >
          Month
        </FilterToggle>
        <FilterToggle
          value="this_year"
          aria-label="Last year"
          disabled={disabledFilters.includes('this_year')}
        >
          Year
        </FilterToggle>
      </div>
      <ToggleGroupItem className="w-full" value="custom" asChild>
        <SecondaryButton
          aria-label="Custom date range"
          disabled={disabledFilters.includes('custom')}
          className="mt-8 w-full"
        >
          Custom date range
        </SecondaryButton>
      </ToggleGroupItem>
    </ToggleGroup>
  );
};
