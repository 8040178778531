import { ChevronDownIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { MouseEventHandler } from 'react';

import { Restricted } from 'containers/Restricted';

import { OrgNavLink } from 'components/ui/atoms/OrgNavLink';

import { SidebarMenuItem } from './SidebarItem';

interface Props {
  isExpanded: boolean;
  item: SidebarMenuItem;
  onClick?: MouseEventHandler<HTMLElement>;
  onSubItemClick?: MouseEventHandler<HTMLElement>;
  sidebarOpen?: boolean;
  isActive: boolean;
  'data-testid'?: string;
}

export const SidebarDropdownItem = ({
  isExpanded,
  item,
  onClick,
  onSubItemClick,
  isActive,
  'data-testid': dataTestId
}: Props) => {
  const Icon = item.icon;
  return (
    <div
      className={cn(
        'sidebar-dropdown group overflow-hidden rounded-lg transition-[max-height] duration-300 ease-in-out',
        {
          'max-h-full': isExpanded,
          'max-h-10': !isExpanded
        }
      )}
    >
      <div
        onClick={onClick}
        role="tablist"
        data-testid={dataTestId}
        aria-label={item.title}
        className={cn(
          'flex h-10 items-center px-2.5 hover:bg-inpay-dark-green-1000',
          {
            'bg-inpay-green-primary-mixes-60%-secondary': isActive,
            'bg-inpay-green-primary-mixes-20%-black': !isActive && isExpanded,
            'group-hover:bg-inpay-green-primary-mixes-20%-black':
              !isActive && !isExpanded
          }
        )}
      >
        <Icon className="size-5 shrink-0 scale-110 stroke-white stroke-1" />
        <div className="overflow-hidden">
          <span className="inline-block whitespace-nowrap p-2">
            {item.title}
          </span>
        </div>
        <ChevronDownIcon
          className={cn('ml-auto h-4 w-4 duration-300', {
            'scale-flip ': isExpanded
          })}
        />
      </div>
      <div
        role="tabpanel"
        className="sidebar__dropdown-content max-h-80 overflow-y-auto bg-inpay-green-primary-mixes-20%-black"
      >
        {item.subItems?.map((item) =>
          'path' in item ? (
            <Restricted
              requiredPermission={item.requiredPermission}
              key={item.id}
            >
              <OrgNavLink
                to={item.path}
                onClick={onSubItemClick}
                className={({ isActive }) =>
                  cn(
                    'group flex h-12 flex-col justify-center whitespace-nowrap pl-6 text-sm hover:bg-inpay-dark-green-1000',
                    {
                      'bg-inpay-green-primary-mixes-20%-secondary':
                        isActive && !item.noActive
                    }
                  )
                }
              >
                <span data-testid={item['data-testid']}>{item.title}</span>
                {item.subtitle && (
                  <div className="text-xs text-inpay-yellow-400 group-hover:text-inpay-yellow-600">
                    {item.subtitle}
                  </div>
                )}
              </OrgNavLink>
            </Restricted>
          ) : (
            item
          )
        )}
      </div>
    </div>
  );
};
