import { merge as objectMapper } from 'object-mapper';

import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { PaymentByUuidOrInpayUniqReferenceRequest } from 'redux/api/collections/payments/requests';

export const apiParams = function (
  params: any
): PaymentByUuidOrInpayUniqReferenceRequest {
  const map = {
    unique_identifier: 'search_reference',
    created_after: [
      {
        key: 'created_after',
        default: dayjs('1900-01-01').format(DATE_FORMAT)
      }
    ],
    created_before: [
      {
        key: 'created_before',
        default: dayjs().add(1, 'days').endOf('day').format(DATE_FORMAT)
      }
    ]
  };

  return objectMapper(params, map);
};
