import { customerApi } from '../customerApi';

export interface FeatureFlagsResponse {
  data: {
    [featureName: string]: boolean;
  };
}

export const featureFlagsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchFeatureFlags: builder.query<FeatureFlagsResponse, void>({
      query: () => '/v1/system/feature_flags',
      providesTags: ['FeatureFlags']
    })
  })
});

export const { useFetchFeatureFlagsQuery } = featureFlagsApi;
