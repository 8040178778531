import { LockClosedIcon } from '@heroicons/react/24/outline';
import { ForwardedRef, forwardRef } from 'react';

import {
  InputField,
  InputFieldProps
} from 'components/ui/molecules/InputField';

export const LockedPasswordField = forwardRef<
  HTMLInputElement,
  InputFieldProps
>((props, ref: ForwardedRef<HTMLInputElement>) => {
  return (
    <InputField {...props} ref={ref}>
      <LockClosedIcon className="absolute inset-y-0 right-2 h-12 w-6" />
    </InputField>
  );
});
LockedPasswordField.displayName = 'LockedPasswordField';
