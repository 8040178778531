import { useState } from 'react';

import { WithdrawalAccount, WithdrawalRequestsData } from 'models/withdrawal';

import { PrimaryButton } from '@atoms/buttons/PrimaryButton';
import { WithdrawFundsIcon } from '@atoms/Icon/WithdrawFundsIcon';

import { WithdrawalRequestDialog } from './WithdrawalRequestDialog';

interface Props {
  withdrawalsArePossible: boolean;
  withdrawalAccounts: WithdrawalAccount[];
  withdrawalRequestsData: WithdrawalRequestsData[];
  isWithdrawalAccountsError: boolean;
  isWithdrawalAccountsLoading: boolean;
  organisationId: number;
}

export const WithdrawalsHeader = ({
  withdrawalsArePossible,
  withdrawalRequestsData,
  ...props
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      {withdrawalsArePossible && withdrawalRequestsData.length > 0 && (
        <div className="pb-2">
          <PrimaryButton
            className="flex items-center gap-2"
            onClick={() => setOpen(true)}
          >
            <WithdrawFundsIcon className="size-5" stroke="white" />
            Withdraw funds
          </PrimaryButton>
          <WithdrawalRequestDialog open={open} setOpen={setOpen} {...props} />
        </div>
      )}
    </div>
  );
};
