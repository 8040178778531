import { Dialog, DialogPortal } from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { formatDate } from 'utils/dayjs';

import { Content, Overlay, Title } from '@organisms/Dialog';

import { useLazyFetchReportQuery } from 'redux/api/accounts/reportsApi';

import { DownloadReportForm } from './DownloadReportForm';

interface Props {
  open: boolean;
  onClose?: (value: boolean) => void;
}

interface State {
  accountNumber?: string;
  uuid?: string;
  reportName?: string;
  dateRange?: {
    type: 'custom';
    to: string;
    from: string;
  };
}

export const DownloadReportDialog = ({ open, onClose }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const downloadReport = searchParams.get('download_report');
  const [state, setState] = useState<State>({
    accountNumber: undefined,
    dateRange: undefined,
    uuid: undefined,
    reportName: undefined
  });

  const onDialogClose = (value: boolean) => {
    onClose && onClose(value);
  };

  const onReportDownloadClick = () => {
    searchParams.delete('download_report');
    setSearchParams(searchParams);
  };

  const [fetchReport] = useLazyFetchReportQuery();

  const getReportInfo = async () => {
    if (downloadReport) {
      const ids = JSON.parse(window.atob(downloadReport));

      if (Array.isArray(ids) && ids.length == 2) {
        // eslint-disable-next-line camelcase
        const [virtual_account_number, uuid] = ids;

        try {
          const data = await fetchReport({
            uuid,
            virtual_account_number
          }).unwrap();

          setState((state) => ({
            ...state,
            uuid,
            accountNumber: data.virtual_account_number,
            reportName: data.report_name,
            dateRange: {
              type: 'custom',
              from: formatDate(data.date_from),
              to: formatDate(data.date_to)
            }
          }));
        } catch {
          onClose && onClose(false);
          //TODO make something better when the design will be finished
          alert('You can’t access the requested report');
        }
      } else {
        onClose && onClose(false);
        alert('The link is broken');
      }
    }
  };

  useEffect(() => {
    getReportInfo();
  }, [downloadReport]);

  return (
    <Dialog open={open} onOpenChange={onDialogClose}>
      <DialogPortal>
        <Overlay>
          <Content onInteractOutside={(e) => e.preventDefault()}>
            <Title>
              <span>Download your account statement</span>
            </Title>
            <div className="rounded-b-lg bg-inpay-gray-primary-200 p-6">
              <DownloadReportForm
                onClick={onReportDownloadClick}
                accountNumber={state.accountNumber}
                dateRange={state.dateRange}
                uuid={state.uuid}
              />
            </div>
          </Content>
        </Overlay>
      </DialogPortal>
    </Dialog>
  );
};
