import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { ApprovalStatus } from 'models/ApprovalWorkFlow';
import { Batch } from 'models/batches';
import { CountryType } from 'models/country';
import { CurrencyType } from 'models/currency';

import { ApprovalBadge } from '@atoms/badges/ApprovalBadge/ApprovalBadge';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

interface BatchColumnArgument {
  isApprovalEnabled: boolean;
}

export const useBatchColumns = ({ isApprovalEnabled }: BatchColumnArgument) => {
  const columnHelper = createColumnHelper<Batch>();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor(
        (row) => ({
          name: row.name,
          localInstrument: row.local_instrument
        }),
        {
          id: 'name',
          header: 'Batch name',
          cell: (info) => {
            const { name, localInstrument } = info.getValue<{
              name: string;
              localInstrument: string;
            }>();
            return (
              <>
                <div className="text-xs">{name}</div>
                <div className="font-medium">{localInstrument}</div>
              </>
            );
          },
          meta: {
            className: 'pl-6 truncate w-28'
          }
        }
      ),
      columnHelper.accessor('created_at', {
        id: 'date',
        header: 'Create Date (UTC)',
        cell: (info) => {
          const dateTime = info.getValue<string>();
          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-42'
        }
      }),
      columnHelper.accessor('creator_name', {
        id: 'creator_name',
        header: 'Created by',
        cell: (info) => {
          const createdBy = info.getValue<string | null>();
          if (createdBy === null) return null;
          return <span title={createdBy}>{createdBy}</span>;
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor('country', {
        id: 'country',
        header: 'Country',
        cell: (info) => {
          const country = info.getValue<CountryType>();
          return (
            <span className="font-medium capitalize" title={country.name}>
              {country.name}
            </span>
          );
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor('total_payout_requests_count', {
        id: 'payout_request',
        header: 'Payout requests',
        cell: (info) => {
          const payoutRequestCount = info.getValue<number>();
          return <div className="font-medium">{payoutRequestCount}</div>;
        },
        meta: {
          className: 'w-36'
        }
      }),
      columnHelper.accessor(
        (row) => ({
          totalAmount: row.total_amount,
          currency: row.currency
        }),
        {
          id: 'amount',
          header: 'Total amount',
          cell: (info) => {
            const { totalAmount, currency } = info.getValue<{
              totalAmount: number;
              currency: CurrencyType;
            }>();
            const formattedAmount = new MoneyFormatter().format(totalAmount);

            return (
              <>
                <div className="text-xs font-normal uppercase">
                  {currency.iso}
                </div>
                <div className="whitespace-nowrap font-medium">
                  {formattedAmount}
                </div>
              </>
            );
          },
          meta: {
            className: 'w-32'
          }
        }
      ),
      columnHelper.accessor('status', {
        id: 'status',
        meta: {
          hidden: !isApprovalEnabled,
          className: 'w-40'
        },
        header: 'Status',
        cell: (info) => (
          <ApprovalBadge status={info.getValue<ApprovalStatus>()} />
        )
      }),
      columnHelper.display({
        id: 'column_params',
        meta: {
          className: 'w-12'
        },
        cell: () => (
          <ChevronRightIcon className="h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        )
      })
    ];
  }, [isApprovalEnabled]);

  return columns;
};
