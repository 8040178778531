import cn from 'classnames';
import { ForwardedRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonProps } from './Button';

export const SecondaryButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, size = 'small', option = 'normal', ...props },
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const normalOption =
      'hover:bg-inpay-green-primary-1000 text-inpay-green-primary-1000 border-inpay-green-primary-1000';
    const dangerOption =
      'hover:bg-inpay-red-1000 text-inpay-red-1000 border-inpay-red-1000';

    return (
      <button
        ref={ref}
        onClick={props.onClick}
        className={twMerge(
          cn(
            (!size || size === 'large') && 'h-11',
            size === 'small' && 'h-9 text-sm',
            option === 'normal' && normalOption,
            option === 'danger' && dangerOption,
            'rounded-md px-4 font-medium shadow-plain',
            'bg-white hover:text-white',
            'border-1.5 border-solid',
            'cursor-pointer disabled:cursor-default',
            'disabled:border-inpay-black-primary-400 disabled:bg-inpay-black-primary-400 disabled:text-white',
            className
          )
        )}
        {...props}
      />
    );
  }
);

SecondaryButton.displayName = 'SecondaryButton';
