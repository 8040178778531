import { CheckIcon } from '@heroicons/react/20/solid';
import cn from 'classnames';
import { ForwardedRef, forwardRef, useState } from 'react';

import {
  countPasswordMatchedValidations,
  passwordValidationRules
} from 'validators/password';

import { Meter } from '@atoms/Meter/Meter';

import { InputFieldProps } from 'components/ui/molecules/InputField';
import { PasswordField } from 'components/ui/molecules/PasswordField';

const passwordStrengthLabels: { [key: number]: string } = {
  1: 'Weak',
  2: 'Average',
  3: 'Strong'
};

export const NewPasswordField = forwardRef<HTMLInputElement, InputFieldProps>(
  function NewPasswordField(props, ref: ForwardedRef<HTMLInputElement>) {
    const [passwordStrength, setPasswordStrength] = useState<number>(0);
    const [value, setValue] = useState<string>();
    const { onChange, ...otherProps } = props;

    const onChangeInput = (event: any) => {
      onChange && onChange(event);

      setValue(event.target.value);
      setPasswordStrength(countPasswordMatchedValidations(event.target.value));
    };

    return (
      <div className="relative">
        {passwordStrength > 0 && (
          <Meter
            value={passwordStrength}
            label={passwordStrengthLabels[passwordStrength]}
            className="absolute right-0"
            barClassName="w-[4.5rem]"
          />
        )}
        <PasswordField
          {...otherProps}
          onChange={onChangeInput}
          type="password"
          ref={ref}
        />
        <ul className="mt-6">
          {passwordValidationRules.map((rule, index) => {
            const matched = rule.match(value);
            return (
              <li
                key={index}
                className={cn({
                  'text-inpay-green-primary-1000': matched,
                  'text-inpay-gray-primary-1000': !matched
                })}
              >
                <div
                  className={cn('mr-2 inline-block h-3 w-3 rounded-md', {
                    'bg-inpay-green-primary-1000': matched,
                    'bg-inpay-gray-primary-1000': !matched
                  })}
                >
                  {matched && (
                    <CheckIcon className="size-3 fill-white stroke-white" />
                  )}
                </div>
                {rule.label}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
);
