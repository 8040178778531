import { merge as objectMapper } from 'object-mapper';

import dayjs, { DATE_FORMAT } from 'utils/dayjs';

import { OrderByUuidOrInpayExternalReferenceRequest } from 'redux/api/collections/orders/requests';
import { OrderByUuidOrInpayExternalReferenceResponse } from 'redux/api/collections/orders/responses';

import { Order } from 'models/order';

export const apiParams = function (
  params: any
): OrderByUuidOrInpayExternalReferenceRequest {
  const map = {
    unique_identifier: 'search_reference',
    created_after: [
      {
        key: 'created_after',
        default: dayjs('1900-01-01').format(DATE_FORMAT)
      }
    ],
    created_before: [
      {
        key: 'created_before',
        default: dayjs().add(1, 'days').endOf('day').format(DATE_FORMAT)
      }
    ]
  };

  return objectMapper(params, map);
};

export const extractOrder = function (
  params: OrderByUuidOrInpayExternalReferenceResponse
): Order {
  return {
    uuid: params.uuid,
    created_at: params.created_at,
    amount: params.amount,
    currency: params.currency,
    status: params.status,
    reference: params.reference,
    debtor: params.debtor
  };
};
