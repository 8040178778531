import { useParams } from 'react-router-dom';

import { OrderDetails } from '.';

export const OrderSearchDetails = () => {
  const { orderId } = useParams();

  if (!orderId) return null;

  return <OrderDetails orderId={orderId} />;
};
