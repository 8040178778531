interface LoadingParams {
  text?: string;
  className?: string;
}

// A "full page" loading indicator
export const Loading = ({ text, className }: LoadingParams) => {
  return (
    <div
      className={`flex h-screen items-center justify-center bg-inpay-gray-secondary-1000 ${className}`}
    >
      <div
        role="progressbar"
        className="font-bold"
        data-testid="page-loading-indicator"
      >
        {text || 'Loading...'}
      </div>
    </div>
  );
};
