import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import { PaginatorProps } from './types';

export const Paginator = ({
  onClickPrev,
  onClickNext,
  showNext = true,
  showPrev = true,
  className = ''
}: PaginatorProps) => {
  return (
    <div className={classNames('flex space-x-4', className)}>
      <button
        type="button"
        aria-label="previous page"
        className="group rounded-lg bg-white px-5 py-1.5 enabled:hover:bg-inpay-green-primary-1000 enabled:hover:text-white enabled:hover:shadow-plain-lg disabled:text-inpay-gray-primary-1000"
        onClick={onClickPrev}
        disabled={showPrev !== true}
      >
        <ChevronLeftIcon className="-ml-2 inline-block h-5" />
        <span className="align-middle">Prev</span>
      </button>
      <button
        type="button"
        aria-label="next page"
        className="group rounded-lg bg-white px-5 py-1.5 enabled:hover:bg-inpay-green-primary-1000 enabled:hover:text-white enabled:hover:shadow-plain-lg disabled:text-inpay-gray-primary-1000"
        onClick={onClickNext}
        disabled={showNext !== true}
      >
        <span className="align-middle">Next</span>
        <ChevronRightIcon className="-mr-2 inline-block h-5" />
      </button>
    </div>
  );
};
