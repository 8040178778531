import { Policies } from 'hooks/usePermissions';

/**
 * Access policies for different roles.
 * "default" role used as a fallback.
 *
 * Each role should have an array of access rules.
 * The rule can have several forms:
 *   + '*' - permit any action in any namespace
 *   + 'namespace:*' - permit any action in 'namespace', for instance: 'accounts:*'
 *   + 'namespace:action' - permit 'action' in 'namespace', for instance: 'accounts:read'
 *   + '!namespace:*' - restrict any action in 'namespace', for instance: '!accounts:*'
 *   + '!namespace:action' - restrict 'action' in 'namespace', for instance: '!accounts:read'
 *
 * Convention for actions:
 *   + namespace is reflection of resource or the page (dependent on the context)
 *   + the basic set of actions is: read, create, update, destroy
 *   + there can be custom actions if required
 *
 * How combination work:
 *   + Restricted rules applies first. If a role has rules:
 *     - ['*', '!accounts:update'] the user can do everything except updating accounts
 *     - ['accounts:*', '!accounts:update'] the user can do everything with accounts except updating
 *   + If no restriction applied the wildcard goes next:
 *     - ['*', 'accounts:update'] - 'accounts:update' doesn't make any sense there because the wildcard overwrite everything
 *     - ['accounts:*', 'accounts:update'] - 'accounts:update' doesn't make any sense there because the wildcard overwrite everything for accounts
 */

export const policies: Policies = {
  admin: ['*'],
  admin_reviewer: ['*'],
  operations_member_reviewer: [
    'dashboard:read',
    'payouts:*',
    'payout_batches:*',
    'collections:*',
    'collection_orders:*',
    'accounts:read',
    'account_transactions:read',
    'accounts:funding',
    'withdrawals:read'
  ],
  'operations member': [
    'dashboard:read',
    'payouts:*',
    'payout_batches:*',
    'collections:*',
    'collection_orders:*',
    'accounts:read',
    'account_transactions:read',
    'accounts:funding',
    'withdrawals:read'
  ],
  default: ['dashboard:read']
};
