import { encodeQueryParams } from 'utils/url';

import {
  WithdrawalRequestsData,
  WithdrawalRequestData,
  GeneratedWithdrawalData,
  WithdrawalAccount
} from 'models/withdrawal';

import { customerApi } from '../customerApi';

interface WithdrawalRequestResponseMeta {
  has_previous_page: boolean;
  has_next_page: boolean;
  cursor_next: string;
  cursor_prev: string;
  total: number;
}

interface WithdrawalRequestResponse {
  data: WithdrawalRequestData;
}

interface WithdrawalRequestRequest {
  organisationId: string;
  id: string;
}

export interface WithdrawalRequestsResponse {
  data: Array<WithdrawalRequestsData>;
  meta: WithdrawalRequestResponseMeta;
}

export interface WithdrawalRequestsRequest {
  organisation_id?: string;
  filter?: { status?: string };
  page?: {
    size?: number;
    cursor_after?: string;
    cursor_before?: string;
    include_cursor?: boolean;
  };
}

export interface GenerateWithdrawalRequest {
  organisation_id: string;
  end_to_end_id: string;
  amount: number;
  currency_code: string;
  creditor_account: {
    id: string;
  };
  debtor_account: {
    scheme_name: string;
    id: string;
  };
}

export interface GenerateWithdrawalResponse {
  data: GeneratedWithdrawalData;
}

export interface WithdrawalAccountsResponse {
  data: WithdrawalAccount[];
}

export const withdrawalsApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchWithdrawalRequest: builder.query<
      WithdrawalRequestResponse,
      WithdrawalRequestRequest
    >({
      query: (params) =>
        `/v2/disbursement/organisations/${params.organisationId}/withdrawal_requests/${params.id}`,
      providesTags: ['WithdrawalRequests']
    }),
    fetchWithdrawalRequests: builder.query<
      WithdrawalRequestsResponse,
      WithdrawalRequestsRequest
    >({
      query: (params) =>
        `/v2/disbursement/organisations/${params?.organisation_id}/withdrawal_requests?${encodeQueryParams(params)}`,
      providesTags: ['WithdrawalRequests']
    }),
    fetchWithdrawalAccounts: builder.query<WithdrawalAccountsResponse, any>({
      query: (organisationId) =>
        `/v2/disbursement/organisations/${organisationId}/withdrawal_accounts`,
      providesTags: ['WithdrawalRequests']
    }),
    generateWithdrawal: builder.mutation<
      GenerateWithdrawalResponse,
      { params: GenerateWithdrawalRequest }
    >({
      query: ({ params }) => {
        return {
          url: `/v2/disbursement/organisations/${params.organisation_id}/withdrawal_requests`,
          credentials: 'include',
          method: 'POST',
          body: params
        };
      },
      invalidatesTags: ['WithdrawalRequests']
    })
  })
});

export const {
  useFetchWithdrawalRequestQuery,
  useFetchWithdrawalRequestsQuery,
  useFetchWithdrawalAccountsQuery,
  useGenerateWithdrawalMutation
} = withdrawalsApi;
