import { XMarkIcon } from '@heroicons/react/24/outline';
import { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDate, formatHours } from 'utils/dayjs';
import { getCountryName } from 'utils/formatters/getCountryName';

import { ConfirmationDialog } from '@organisms/ConfirmationDialog';
import { convertTypeToLabel } from 'constants/paymentType';

import {
  ordersApi,
  useCancelOrderMutation
} from 'redux/api/collections/orders';
import { type CancelOrderErrorResponse } from 'redux/api/collections/orders/responses';

import { type OrderStatusTransition } from 'models/order';
import { type Payment } from 'models/payment';

import { OrderBadge } from '@atoms/badges/OrderBadge/OrderBadge';
import { PaymentBadge } from '@atoms/badges/PaymentBadge/PaymentBadge';
import { SecondaryButton } from '@atoms/buttons/SecondaryButton';
import { FormattedDate } from '@atoms/FormattedDate/FormattedDate';
import { InformationIcon } from '@atoms/Icon/InformationIcon';
import { Tooltip } from '@atoms/Tooltip/Tooltip';

import { Loading } from 'components/pages/Loading';
import { DetailsBox, Item } from 'components/ui/organisms/DetailsBox';

import { TRANSLATIONS } from './constants';
import { formatErrors } from './util';

export interface OrderDetailsProps {
  orderId: string;
}

export const OrderDetails = ({ orderId }: OrderDetailsProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const { organisationId } = useParams();

  const {
    data: { data: orderData } = {},
    isLoading,
    isError,
    isSuccess
  } = ordersApi.useFetchOrderQuery({ reference: orderId });

  const [
    cancelOrder,
    {
      isError: cancelOrderIsError,
      isLoading: isCancelLoading,
      isSuccess: isCancelOrderIsSuccess
    }
  ] = useCancelOrderMutation();

  const cancelOrderCallback = useCallback(
    ({
      organisationId,
      orderId
    }: {
      organisationId?: string;
      orderId: string;
    }) => {
      if (organisationId && organisationId.length > 0) {
        return cancelOrder({ organisationId, orderId })
          .unwrap()
          .then(() => {
            toast.success(TRANSLATIONS.CANCEL_ORDER_SUCCESS(orderId), {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
          })
          .catch((error: CancelOrderErrorResponse) => {
            const errorMsg = error ? formatErrors(error) : '';
            toast.error(`${TRANSLATIONS.CANCEL_ORDER_ERROR} - ${errorMsg}`, {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true
            });
          })
          .finally(() => {
            setIsDialogOpen(false);
          });
      }
    },
    []
  );

  const data = useMemo<Array<Item>>(() => {
    const sections: Array<Item> = [];
    if (!orderData || !isSuccess) return sections;

    sections.push({
      header: 'Order',
      key: 'info',
      data: [
        {
          key: 'Business Unit',
          value: orderData.business_unit
        }
      ],
      customChildren: (
        <div className="my-4 flex items-center gap-2">
          <SecondaryButton
            type="submit"
            size="large"
            disabled={
              isCancelLoading ||
              isCancelOrderIsSuccess ||
              (orderData.status !== 'received' &&
                orderData.status !== 'pending')
            }
            option="danger"
            onClick={() => {
              setIsDialogOpen(true);
            }}
          >
            {TRANSLATIONS.CANCEL_ORDER}
          </SecondaryButton>

          <Tooltip content={<span>{TRANSLATIONS.DISABLED_CONDITIONS}</span>}>
            <span>
              <InformationIcon width="2em" />
            </span>
          </Tooltip>
        </div>
      )
    });

    // TODO:We need to improve naming to pass items to the sections.
    sections.push(
      {
        header: 'Debtor',
        key: 'debtor',
        data: [
          {
            key: 'Name',
            value: orderData.debtor.full_name
          },
          {
            key: 'Date of birth',
            value: formatDate(orderData.debtor.date_of_birth)
          },
          {
            key: 'Email',
            value: orderData.debtor.email
          },
          {
            key: 'Country of Residence',
            value:
              getCountryName(orderData.debtor.country_of_residence) ||
              orderData.debtor.country_of_residence
          }
        ]
      },
      {
        header: 'Payments',
        key: 'payments',
        data: [],
        customChildren: orderData.payments.length ? (
          orderData.payments.map((payment: Payment, index: number) => (
            <div
              className="border-inpay-gray-primary-300 flex justify-between gap-2 border-b py-6 first-of-type:pt-0 last-of-type:border-0"
              key={index}
            >
              <div>
                <div className="text-xs">
                  {convertTypeToLabel(payment.type)}
                </div>
                <PaymentBadge type={payment.status} />
              </div>
              <div className="text-right">
                <div className="text-xs">
                  {formatHours(payment.created_at, 'utc')}
                </div>
                <p className="font-semibold">
                  {payment.inpay_unique_reference}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="pb-6">No payments made to this order</div>
        )
      },
      {
        header: 'Status History',
        key: 'history',
        data: [],
        customChildren: orderData.status_history.map(
          (transition: OrderStatusTransition, index: number) => (
            <div
              className="border-inpay-gray-primary-300 flex justify-between gap-4 border-b py-6 first-of-type:pt-0 last-of-type:border-0"
              key={index}
            >
              <div>
                <div className="text-xs">Status</div>
                <OrderBadge state={transition.status} />
              </div>
              <div className="text-right">
                <FormattedDate
                  className="text-xs"
                  value={transition.created_at}
                />
                <div className="font-semibold">
                  {formatHours(transition.created_at, 'utc')}
                </div>
              </div>
            </div>
          )
        )
      }
    );

    return sections;
  }, [orderData, isSuccess, isCancelLoading, cancelOrderIsError]);

  return (
    <>
      {isDialogOpen && (
        <ConfirmationDialog
          option="danger"
          isOpen={isDialogOpen}
          loading={isCancelLoading}
          onConfirm={() => {
            cancelOrderCallback({ organisationId, orderId });
          }}
          onCancel={() => setIsDialogOpen(false)}
          title={TRANSLATIONS.CANCEL_ORDER}
          content={TRANSLATIONS.ARE_YOU_SURE}
          yesLabel={TRANSLATIONS.YES}
          noLabel={TRANSLATIONS.NO}
        />
      )}

      <div className="max-h-full overflow-hidden rounded-lg bg-white shadow-plain-lg">
        <div
          className="order-overview__header"
          role="complementary"
          aria-label="Order details"
        >
          <div className="flex items-center justify-between px-6 py-3.5">
            <span>{TRANSLATIONS.DETAILS}</span>
            <Link to=".." className="cursor-pointer" aria-label="Close">
              <XMarkIcon className="size-6" />
            </Link>
          </div>
          <div className="border-inpay-gray-primary-300 border-y bg-inpay-green-secondary-light-200 py-2 text-sm">
            &nbsp;
          </div>
        </div>
        <div className="overflow-auto">
          {isLoading && <Loading />}
          {isSuccess && <DetailsBox data={data} defaultValue="info" />}
          {isError && (
            <div className="flex h-full items-center justify-center">
              {TRANSLATIONS.SOMETHING_WENT_WRONG}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
