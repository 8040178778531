import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { AccountsRedirection } from 'containers/redirections/AccountsRedirection';
import { RedirectToDefaultOrganisation } from 'containers/redirections/DefaultOrganisationRedirection';
import { RequireAuth } from 'containers/RequireAuth';
import { RequireBatchesEnabled } from 'containers/RequireBatchesEnabled';
import { RequireFeatureFlag } from 'containers/RequireFeatureFlag';
import { RequireOrganisation } from 'containers/RequireOrganisation';
import { RequirePermission } from 'containers/RequirePermission';

import { AccountTransactions } from 'components/pages/AccountTransactions';
import { Batches } from 'components/pages/Batches';
import { BatchPayouts } from 'components/pages/BatchPayouts/BatchPayouts';
import { Dashboard } from 'components/pages/Dashboard';
import { ExportedStatements } from 'components/pages/ExportedStatements';
import { ExportedStatementsRedirection } from 'components/pages/ExportedStatementsRedirection';
import { ForgotPassword } from 'components/pages/ForgotPassword';
import { InvitationAccept } from 'components/pages/InvitationAccept';
import { InvitationExpired } from 'components/pages/InvitationsExpired';
import { Login } from 'components/pages/Login';
import { LoginTransition } from 'components/pages/LoginTransition';
import { Logout } from 'components/pages/Logout';
import { MfaSetup } from 'components/pages/MfaSetup';
import { MfaVerify } from 'components/pages/MfaVerify';
import { NoOrganisation } from 'components/pages/NoOrganisation';
import { Orders } from 'components/pages/Orders';
import { OrganisationsLoadingTransition } from 'components/pages/OrganisationsLoadingTransition';
import { PageNotFound } from 'components/pages/PageNotFound';
import { Payouts } from 'components/pages/Payouts';
import { Search } from 'components/pages/Search';
import { UpdatePassword } from 'components/pages/UpdatePassword';
import { UserManagement } from 'components/pages/UserManagement';
import { UserProfile } from 'components/pages/UserProfile';
import { Withdrawals } from 'components/pages/Withdrawals';
import { AppLayout } from 'components/ui/templates/AppLayout';
import { AuthenticationLayout } from 'components/ui/templates/AuthenticationLayout';

export function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthenticationLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="invitations">
            <Route path="expired" element={<InvitationExpired />} />
            <Route path="accept/:token" element={<InvitationAccept />} />
          </Route>
          <Route path="passwords">
            <Route path="forgot" element={<ForgotPassword />} />
            <Route path="reset/:token" element={<UpdatePassword />} />
          </Route>
          <Route path="logout" element={<Logout />} />
        </Route>

        <Route path="/no-organisation" element={<NoOrganisation />} />

        <Route
          element={
            <RequireAuth
              allowedScopes={['login', 'dashboard']}
              redirectionPath="/"
            />
          }
        >
          <Route element={<AuthenticationLayout />}>
            <Route path="mfa-verify" element={<MfaVerify />} />
            <Route path="mfa-setup" element={<MfaSetup />} />
          </Route>
        </Route>

        {/* routes with real data */}
        <Route element={<RequireAuth allowedScopes={['dashboard']} />}>
          {/* rendering transition page that loads the list of organisations*/}
          <Route
            path="/login_transition"
            element={<OrganisationsLoadingTransition />}
          />
          {/* MVP patch that could be removed after deploying test API*/}
          <Route
            path="organisations/:organisationId/reports/:report_id"
            element={<ExportedStatementsRedirection />}
          />
          {/* validation and setting current organisation */}
          <Route path="/org/:organisationId" element={<RequireOrganisation />}>
            <Route path="login_transition" element={<LoginTransition />} />

            <Route element={<AppLayout />}>
              <Route
                element={
                  <RequirePermission requiredPermission="dashboard:read" />
                }
              >
                <Route index element={<Dashboard />} />
              </Route>
              <Route path="search/*" element={<Search />} />
              <Route
                element={
                  <RequirePermission requiredPermission="payouts:read" />
                }
              >
                <Route path="payouts/*" element={<Payouts />} />
              </Route>

              <Route
                element={
                  <RequireFeatureFlag featureFlag="CBP-1761-customer-app-batches" />
                }
              >
                <Route
                  element={
                    <RequirePermission requiredPermission="payout_batches:read" />
                  }
                >
                  <Route element={<RequireBatchesEnabled />}>
                    <Route path="batches/*" element={<Batches />} />
                    <Route
                      path="batches/:batchId/batch_payouts/*"
                      element={<BatchPayouts />}
                    />
                  </Route>
                </Route>
              </Route>

              <>
                <Route path="settings/profile" element={<UserProfile />} />
                <Route
                  element={
                    <RequirePermission requiredPermission="users:read" />
                  }
                >
                  <Route path="users/*" element={<UserManagement />} />
                </Route>
              </>

              <Route
                path="collections/orders"
                element={
                  <RequirePermission requiredPermission="collection_orders:read" />
                }
              >
                <Route path=":orderId" element={<Orders />} />
                <Route path="" element={<Orders />} />
              </Route>
              <Route
                element={
                  <RequirePermission requiredPermission="accounts:read" />
                }
              >
                {/* NOTE (LTJ): This is a redirect from the legacy route to "accounts/exported_statements" */}
                {/* This is needed for the e-mails generated before updated. */}
                <Route
                  path="accounts/exported_statements"
                  element={<ExportedStatementsRedirection />}
                />
                <Route
                  path="accounts/exported_transactions"
                  element={<ExportedStatements />}
                />
                <Route
                  path="accounts/withdrawals/*"
                  element={<Withdrawals />}
                />
                <Route
                  path="accounts/:virtual_account_number/*"
                  element={<AccountTransactions />}
                />
                <Route path="accounts" element={<AccountsRedirection />} />
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Route>
          </Route>
          {/* redirect empty root url to default organisation */}
          <Route path="/" element={<RedirectToDefaultOrganisation />} />
        </Route>

        {/* catch unauthorized root url */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
