import { ComponentProps, MutableRefObject, useEffect } from 'react';
import { IMask, useIMask } from 'react-imask';
import { ReactMaskProps } from 'react-imask/dist/mixin';

interface Props
  extends Omit<ComponentProps<'input'>, 'ref' | 'value'>,
    ReactMaskProps {}

export const DateField = ({ onComplete, value, ...props }: Props) => {
  const opts = {
    mask: 'DD{-}MM{-}YYYY',
    autofix: true,
    blocks: {
      YYYY: {
        mask: IMask.MaskedRange,
        from: 2000,
        to: new Date().getFullYear() + 1,
        overwrite: true
      },
      MM: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 12,
        overwrite: true
      },
      DD: {
        mask: IMask.MaskedRange,
        from: 1,
        to: 31,
        overwrite: true
      }
    }
  };
  const { ref, setValue } = useIMask(opts, { onComplete });
  useEffect(() => {
    if (value) {
      setValue(value as string);
    }
  }, [value]);
  return <input {...props} ref={ref as MutableRefObject<HTMLInputElement>} />;
};
