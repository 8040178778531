import { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchPayoutsQuery } from 'redux/api/payouts/payoutsApi';

import { PayoutData } from 'models/payout';

import { NoDataFound } from '@atoms/NoDataFound/NoDataFound';

import { TABLE_NAME } from 'components/pages/Search/constants';

import { PAYOUTS_SEARCH_RESULTS_TEST_ID } from './constants';
import { usePayoutColumns } from './payoutColumns';

import { DataTable } from '../DataTable';

interface Props {
  onRowClick: (event: MouseEvent<HTMLElement>, rowData: PayoutData) => void;
  onSuccess: (payout: PayoutData | undefined) => void;
  searchParams: any;
}

export const PayoutsSearchResults = ({
  onRowClick,
  searchParams,
  onSuccess
}: Props) => {
  const { '*': detailsPath } = useParams();
  const { data, error, isFetching, isSuccess } =
    useFetchPayoutsQuery(searchParams);

  const columns = usePayoutColumns();

  useEffect(() => {
    if (!isFetching && isSuccess) {
      onSuccess(data?.data[0]);
    }
  }, [isFetching, isSuccess, data]);

  const id = detailsPath?.split('/')[1];
  const payouts = data?.data || [];

  return (
    <>
      {error && 'error' in error && (
        <div className="flex h-screen items-center justify-center">
          <div>{error.error}</div>
        </div>
      )}
      {payouts.length > 0 && (
        <DataTable
          name={TABLE_NAME.PAYOUT_REQUESTS}
          columns={columns}
          topBar="name-with-count"
          total={data?.meta?.total}
          className="h-auto"
          data={payouts}
          onRowClick={onRowClick}
          currentRow={{
            value: id,
            field: 'idReference'
          }}
          data-testid={PAYOUTS_SEARCH_RESULTS_TEST_ID}
          noDataMessage={
            <NoDataFound title="No payout requests found" className="mt-1">
              We have yet to receive any payout requests.
            </NoDataFound>
          }
        />
      )}
      {isFetching && (
        <div
          role="progressbar"
          aria-label="searching payouts"
          className="flex h-40 w-full items-center justify-center"
        >
          Searching payouts
        </div>
      )}
    </>
  );
};
