import { CountryType } from 'models/country';
import { CurrencyType } from 'models/currency';

import { customerApi } from '../customerApi';

export interface UploadBatchResponse {
  data: DataType;
  meta?: MetaType;
}
export interface DataType {
  content?: string;
  filename: string;
  format: string;
  batch_id?: number;
}
export interface MetaType {
  country: CountryType;
  currency: CurrencyType;
  local_instrument: string;
  total_payout_requests: number;
}

export const uploadBatchApi = customerApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadBatch: builder.mutation<
      UploadBatchResponse,
      { form: FormData; organisationId: string }
    >({
      query({ form, organisationId }) {
        return {
          url: `/v1/payouts/organisations/${organisationId}/batches`,
          method: 'POST',
          credentials: 'include',
          body: form
        };
      },
      invalidatesTags: ['Batches']
    })
  })
});
export const { useUploadBatchMutation } = uploadBatchApi;
