import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger
} from '@radix-ui/react-accordion';
import cn from 'classnames';
import { ReactNode } from 'react';

import { Badge } from '@atoms/badges/Badge/Badge';

import styles from './styles.module.css';

// TODO: We need to improve this interface to have a better naming.
// Suggestion (LTJ): sections => fields
// ATT. @dmuneras
// TODO (LTJ): De-couple error handling. Needs a header pill and connection to section
interface SubItem {
  key: string;
  value?: string | number;
  subValue?: string;
  secondValue?: string | number;
  errors?: Array<string>;
}
export interface Item {
  header: string | ReactNode;
  headerIcon?: ReactNode | null;
  key: string;
  customChildren?: ReactNode;
  errors?: Array<string>;
  data?: SubItem[];
}

interface Props {
  data: Item[];
  defaultValue?: string | undefined;
  // TODO (LTJ): Move updateActiveSection responsibility in here
  // TODO (LTJ): Remove any
  setActiveSection?: any;
  activeSection?: string | undefined;
}

export const DetailsBox = ({
  defaultValue,
  data,
  setActiveSection,
  activeSection
}: Props) => {
  const errorBadgesFor = (item: { errors?: Array<string> }) => {
    return (
      <>
        {item.errors && item.errors.length > 0 && (
          <div className="error-badges my-[3px] flex flex-wrap gap-[3px]">
            {item.errors.map((error, index) => {
              return (
                <Badge
                  onClick={(e) => {
                    if (!setActiveSection && activeSection === 'summary')
                      return;
                    e.stopPropagation();
                    setActiveSection('summary');
                  }}
                  key={index}
                  label={error}
                  className={
                    'bg-inpay-red-200' + ` ${error ? 'cursor-pointer' : ''}`
                  }
                />
              );
            })}
          </div>
        )}
      </>
    );
  };

  const countErrors = (item: Item) => {
    const entityErrors = item?.errors?.length || 0;
    const attributeErrors =
      item.data?.reduce(
        (sum, attribute) => sum + (attribute?.errors?.length || 0),
        0
      ) || 0;
    return entityErrors + attributeErrors;
  };
  const hasDetailSectionData = (subItem: SubItem) => {
    return (
      (subItem.errors && subItem.errors.length > 0) ||
      subItem.value ||
      subItem.secondValue
    );
  };

  return (
    <Accordion
      type="single"
      className="bg-white"
      collapsible
      value={activeSection}
      defaultValue={defaultValue}
      onValueChange={setActiveSection}
    >
      {data.map((item) => {
        const errorCounter = countErrors(item);
        return (
          <AccordionItem
            data-testid={item.key}
            key={item.key}
            value={item.key}
            className="border-t border-t-inpay-gray-primary-800 first:border-t-0 radix-state-open:bg-inpay-gray-secondary-600"
          >
            <AccordionHeader>
              <AccordionTrigger className="group flex h-18 w-full cursor-pointer items-center justify-between p-6 outline-offset-[-1px] hover:bg-inpay-gray-secondary-600 group-last:radix-state-closed:rounded-b-lg">
                <h1 className="flex items-center justify-center gap-2 text-lg group-hover:!text-inpay-black-primary-1000 group-radix-state-closed:text-inpay-black-primary-400 group-radix-state-open:font-semibold">
                  {item.header}
                  {item.headerIcon}
                  {errorCounter ? (
                    <span className="flex size-6 items-center justify-center rounded-full bg-inpay-red-200 text-sm font-medium text-white">
                      {errorCounter}
                    </span>
                  ) : (
                    ''
                  )}
                </h1>
                <ChevronDownIcon className="group-radix-state-open:scale-flip w-7 transition-all duration-300 group-hover:!stroke-inpay-black-primary-1000 group-radix-state-closed:stroke-inpay-black-primary-400" />
              </AccordionTrigger>
            </AccordionHeader>
            <AccordionContent
              className={cn(
                // NOTE (LÞJ): `data-hj-suppress` is used to disable hotjar recording
                // DetailsBox renders some of our most sensitive private data
                // Not only for our customers, but also their customers
                // TODO (LÞJ): Enable this on a section level
                'data-hj-suppress overflow-hidden px-6',
                styles['animated-content']
              )}
            >
              {item.customChildren ? (
                item.customChildren
              ) : (
                <div className="details-box-item">
                  <div className="pb-6">{errorBadgesFor(item)}</div>
                  {item.data?.map((subItem, index: any) => {
                    return (
                      subItem &&
                      hasDetailSectionData(subItem) && (
                        <div key={index} className="break-words pb-6">
                          <p className="break-all text-xs">{subItem.key}</p>

                          <h4 className="font-medium">
                            {subItem.value ? subItem.value : ''}
                            {subItem.secondValue ? subItem.secondValue : ''}
                          </h4>
                          {subItem.subValue && (
                            <p className="text-xs">No. {subItem.subValue}</p>
                          )}
                          {errorBadgesFor(subItem)}
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
