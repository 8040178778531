import { VirtualAccount } from 'models/virtualAccount';

export const randomElement = (array: Readonly<any[]>) =>
  array[Math.floor(Math.random() * array.length)];

export const randomArbitrary = (min: number, max: number) =>
  Math.round((Math.random() * (max - min) + min + Number.EPSILON) * 100) / 100;

export const randomDate = (start: Date, end: Date) =>
  new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));

export const virtualAccountLabel = (accountInfo: VirtualAccount) =>
  accountInfo.label
    ? accountInfo.label
    : `${accountInfo.currency_name} account`;
