import { useEffect } from 'react';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';

import { useValidateResetPasswordTokenQuery } from 'redux/api/authenticationApi';

import { AuthenticationContainer } from 'components/ui/atoms/AuthenticationContainer';
import { UpdatePasswordForm } from 'components/ui/organisms/UpdatePasswordForm';
import { UpdatePasswordSuccess } from 'components/ui/organisms/UpdatePasswordSuccess';

import { Loading } from '../Loading';

export const UpdatePassword = () => {
  const { token } = useParams<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const sent = searchParams.get('sent') != null;

  const { isLoading, error } = useValidateResetPasswordTokenQuery(
    {
      token: token as string
    },
    { skip: !!sent }
  );

  const onSent = () => {
    setSearchParams('sent');
  };

  useEffect(() => {
    if (error == undefined) return;

    if ('status' in error) {
      switch (error.status) {
        case 403: // expired
        case 404: // not exists
        default:
          navigate('/passwords/forgot?expired');
      }
    }
  }, [error]);

  return (
    <AuthenticationContainer>
      {isLoading ? (
        <Loading />
      ) : sent ? (
        <UpdatePasswordSuccess />
      ) : (
        <>
          <UpdatePasswordForm token={token as string} onSent={onSent} />
          <div className="mt-20 text-center">
            <Link to="/login" className="inpay-link font-medium">
              Back to login page
            </Link>
          </div>
        </>
      )}
    </AuthenticationContainer>
  );
};
