import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { policies as defaultPolicies } from 'config/policies';

import { selectRole } from 'redux/slices/authSlice';

export type PermitAction = `${string}:${string}` | '*';
export type Policies = Record<string, PermitAction[]>;

interface ActionsHash {
  restricted: Record<string, string[]>;
  permitted: Record<string, string[]>;
}

/**
 * Provides methods to work with policies and permissions
 *
 * Returns {
 *   isPermitted(requestedAction) - method that check if the requested action is permitted by policies for the current user role
 * }
 *
 * Example:
 * const { isPermitted } = usePermissions();
 *
 * if(isPermitted('accounts:read')) return <Accounts/>;
 */

export const usePermissions = (policies: Policies = defaultPolicies) => {
  const role = useSelector(selectRole) || 'default';

  const roleActions = policies[role] || policies.default;

  const parsedActions = useMemo<ActionsHash>(() => {
    const result = {
      restricted: {},
      permitted: {}
    } as ActionsHash;

    if (!roleActions) return result;

    roleActions.forEach((roleAction) => {
      if (roleAction === '*') return;

      const [_, restriction, namespace, action] =
        roleAction.match(/^(!?)(\w+):(\*|\w+)$/) || [];

      const resultGroup = restriction ? result.restricted : result.permitted;

      resultGroup[namespace] ||= [];

      if (action === '*') {
        resultGroup[namespace] = ['*'];
      } else if (resultGroup[namespace][0] !== '*') {
        resultGroup[namespace].push(action);
      }
    });

    return result;
  }, [roleActions]);

  const isPermitted = useCallback(
    (requestedAction: PermitAction): boolean => {
      const [namespace, action] = requestedAction.split(':');

      // action has wrong format
      if (!namespace && !action) return false;

      // no actions for role
      if (!roleActions) return false;

      const restrictedActions = parsedActions.restricted[namespace];

      // the action is directly restricted (to not permit it when we handle '*')
      if (restrictedActions && restrictedActions.includes(action)) return false;

      // all actions are permitted
      if (roleActions.includes('*')) return true;

      const permittedActions = parsedActions.permitted[namespace];

      // no permitted actions available
      if (!permittedActions) return false;

      // permitted actions contains '*' that permit anything or contains the requested action
      if (permittedActions.includes('*') || permittedActions.includes(action)) {
        return true;
      }

      return false;
    },
    [parsedActions]
  );

  return { isPermitted };
};
