import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/table-core';
import { useMemo } from 'react';

import { MoneyFormatter } from 'utils/formatters/moneyFormatter';

import { PayoutState, PayoutTableRow } from 'models/payout';

import { PayoutBadge } from '@atoms/badges/PayoutBadge/PayoutBadge';
import { FormattedIdentifier } from '@atoms/FormattedIdentifier/FormattedIdentifier';
import { TransactionIconMinus } from '@atoms/Icon/TransactionIconMinus';

import { FormattedDateTime } from '@molecules/FormattedDateTime';

export const usePayoutColumns = () => {
  const columnHelper = createColumnHelper<PayoutTableRow>();

  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'amount_icon',
        cell: () => (
          <div className="m-auto w-6">
            <TransactionIconMinus height="32" width="32" />
          </div>
        ),
        meta: {
          className: 'w-20'
        }
      }),
      columnHelper.accessor('created_at', {
        id: 'date',
        enableSorting: true,
        header: 'Date',
        cell: (info) => {
          const dateTime = info.getValue<string>();

          return <FormattedDateTime value={dateTime} />;
        },
        meta: {
          className: 'w-28'
        }
      }),
      columnHelper.accessor(
        (row) => ({
          id: row.end_to_end_id,
          reference: row.inpay_unique_reference
        }),
        {
          id: 'idReference',
          header: 'ID/Reference',
          cell: (info) => {
            const { id, reference } = info.getValue<{
              id: string;
              reference: string;
            }>();

            return (
              <div className="uppercase">
                <FormattedIdentifier value={id} className="text-xs" />
                <FormattedIdentifier
                  value={reference}
                  className="font-medium"
                />
              </div>
            );
          },
          meta: {
            className: 'w-28'
          }
        }
      ),
      columnHelper.accessor(({ amount, currency }) => ({ amount, currency }), {
        id: 'amount',
        enableSorting: true,
        header: 'Amount',
        meta: {
          alignment: 'right',
          className: 'w-28'
        },
        cell: (info) => {
          const value = info.getValue<{ amount: number; currency: string }>();
          return (
            <>
              <div className="text-xs font-normal uppercase">
                {value.currency}
              </div>
              <div className="whitespace-nowrap text-base font-medium">
                {new MoneyFormatter().format(value.amount)}
              </div>
            </>
          );
        }
      }),
      columnHelper.accessor('state', {
        id: 'state',
        header: 'Status',
        cell: (info) => <PayoutBadge state={info.getValue<PayoutState>()} />,
        meta: {
          alignment: 'right',
          className: 'w-48 pr-6'
        }
      }),
      columnHelper.display({
        id: 'column_params',
        meta: {
          className: 'w-12 pr-3.5 pl-1'
        },
        cell: () => (
          <ChevronRightIcon className="h-7 stroke-inpay-gray-primary-1000 group-hover:stroke-inpay-black-primary-1000 group-active:stroke-inpay-black-primary-1000" />
        )
      })
    ],
    []
  );

  return columns;
};
